import React, {useEffect, useState} from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from "@material-ui/core/InputAdornment";
import RenewableEnergyCredits from "./RenewableEnergyCredits";
import {cleanStringInput} from "../../../Utility/StringUtil";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";

const EnvironmentAttributes = (props) => {

    const [generatorType, setGeneratorType] = useState();
    const [validation, setValidation] = useState({});
    const [environmentalAttributes, setEnvironmentalAttributes] = useState({
        registeredRecs: []
    });
    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        if (props.data) {
            setEnvironmentalAttributes({
                carbonDioxideEmissions: props.data.carbonDioxideEmissions,
                nitrogenOxideEmissions: props.data.nitrogenOxideEmissions,
                sulfurDioxideEmissions: props.data.sulfurDioxideEmissions,
                particulatesEmissions: props.data.particulatesEmissions,
                nuclearWasteEmissions: props.data.nuclearWasteEmissions,
                registeredRecs: props.data.registeredRecs
            });
        }
        setGeneratorType(props.generatorType);
        setValidation(props.validation);
        setCanEdit(props.canEdit !== undefined ? props.canEdit : false);
    }, [props]);

    const onInputChangeHandler = (e) => {
        if (e.target.type === "number") {
            if (e.target.value) {
                environmentalAttributes[e.target.name] = Number.parseFloat(e.target.value);
            } else {
                environmentalAttributes[e.target.name] = e.target.value;
            }
        } else {
            environmentalAttributes[e.target.name] = e.target.value;
        }
        setEnvironmentalAttributes(environmentalAttributes);
        if (props.inputChangeHandler) {
            props.inputChangeHandler(e.target.name, e.target.value);
        }
    };

    const onRecChange = (registeredRecs) => {
        environmentalAttributes.registeredRecs = registeredRecs;
        setEnvironmentalAttributes(environmentalAttributes);
        if (props.inputChangeHandler) {
            props.inputChangeHandler('registeredRecs', environmentalAttributes.registeredRecs);
        }
    }

    const renderEnvironmentalAttributes = () => {
        switch (generatorType) {
            case 'Bio-Mass':
            case 'Coal':
            case 'Fuel Cell':
            case 'Gas':
            case 'Waste Energy':
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="Carbon Dioxide Emissions" name="carbonDioxideEmissions"
                                       value={cleanStringInput(environmentalAttributes.carbonDioxideEmissions)}
                                       type="number" InputProps={{
                                endAdornment: <InputAdornment position="start">lbs/MWH</InputAdornment>,
                                inputComponent: GenericNumberFormat,
                                inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: canEdit},

                            }} InputLabelProps={{shrink: !!environmentalAttributes.carbonDioxideEmissions}}
                                       onChange={onInputChangeHandler} disabled={!canEdit} fullWidth required error={validation.carbonDioxideEmissions}
                                       helperText={validation.carbonDioxideEmissionsMessage}/>
                        </Grid>
                        <Grid item xs={6} sm={2}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="Nitrogen Oxide Emissions" name="nitrogenOxideEmissions"
                                       value={cleanStringInput(environmentalAttributes.nitrogenOxideEmissions)}
                                       type="number" onChange={onInputChangeHandler} disabled={!canEdit} fullWidth
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">lbs/MWH</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: canEdit}
                                       }} InputLabelProps={{shrink: !!environmentalAttributes.nitrogenOxideEmissions}} required error={validation.nitrogenOxideEmissions}
                                       helperText={validation.nitrogenOxideEmissionsMessage}/>
                        </Grid>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="Sulfur Dioxide Emissions" name="sulfurDioxideEmissions"
                                       value={cleanStringInput(environmentalAttributes.sulfurDioxideEmissions)}
                                       type="number" onChange={onInputChangeHandler} disabled={!canEdit} fullWidth
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">lbs/MWH</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: canEdit}
                                       }} InputLabelProps={{shrink: !!environmentalAttributes.sulfurDioxideEmissions}} required error={validation.sulfurDioxideEmissions}
                                       helperText={validation.sulfurDioxideEmissionsMessage}/>
                        </Grid>
                        <Grid item xs={6} sm={2}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="Particulates Emissions" name="particulatesEmissions"
                                       value={cleanStringInput(environmentalAttributes.particulatesEmissions)}
                                       type="number" onChange={onInputChangeHandler} disabled={!canEdit} fullWidth
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">lbs/MWH</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: canEdit}
                                       }} InputLabelProps={{shrink: !!environmentalAttributes.particulatesEmissions}} required error={validation.particulatesEmissions}
                                       helperText={validation.particulatesEmissionsMessage}/>
                        </Grid>
                        <Grid item xs={6} sm={2}/>
                    </Grid>
                );
            case 'Nuclear':
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label={"Nuclear Waste Emissions"} name={"nuclearWasteEmissions"}
                                       value={cleanStringInput(environmentalAttributes.nuclearWasteEmissions)}
                                       type="number" onChange={onInputChangeHandler} disabled={!canEdit} fullWidth
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">lbs/MWH</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: canEdit}
                                       }} InputLabelProps={{shrink: !!environmentalAttributes.nuclearWasteEmissions}} required error={validation.nuclearWasteEmissions}
                                       helperText={validation.nuclearWasteEmissionsMessage}/>
                        </Grid>
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={12}/>
                    </Grid>
                );
            case 'Hydro':
            case 'Pumped Hydro':
            case 'Solar':
            case 'Storage':
            case 'Wind':
                return (
                    <RenewableEnergyCredits canEdit={canEdit} inputChangeHandler={onRecChange} registeredRecs={environmentalAttributes.registeredRecs} validation={validation}/>
                )
            default:
        }
    }

    return (
        <React.Fragment>
            {renderEnvironmentalAttributes()}
        </React.Fragment>
    )
}

export default EnvironmentAttributes;