import {ERROR, CLEAR_ERROR, SUCCESS, CLEAR_SUCCESS} from '../actionTypes';
const initialState = {
    error: null,
    success: null
};

export const popupReducer = (state = initialState, action) => {
    switch (action.type) {
        case ERROR:
            return handleError(state, action);
        case CLEAR_ERROR:
            return handleClearError(state, action);
        case SUCCESS:
            return handleSuccess(state, action);
        case CLEAR_SUCCESS:
            return handleClearSuccess(state, action);
        default:
            return state;
    }
};

const handleError = (state, action) => {
    if (action.error) {
        if (action.error.status === 401) {
            unauthorized(state, action);
        }
        if (action.error.status === 400) {
            return {...state, error: action.error.data.message};
        }
        if(action.error.status === 403) {
            return {...state, error: action.error.data.message};
        }
        if (action.error.status === 500) {
            return {...state, error: action.error.data.message};
        }
    }
    return {...state, error: 'Unknown'};
};

const handleSuccess = (state, action) => {
    return {...state, success: action.message};
}

const handleClearError = (state, action) => {
    return {...state, error: null};
};

const handleClearSuccess = (state, action) => {
    return {...state, success: null};
}

const unauthorized = (state, action) => {
    return {...state, error: 'Unauthorized'};
};

export default popupReducer;