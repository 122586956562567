import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import GeneratorComponent from "../Generator/GeneratorComponent";
import axios from '../../../axios/AxiosInterceptors';
import {onError} from '../../../store/actions/popupActions';
import {trackPromise} from "react-promise-tracker";
import SellOfferComponent from "./SellOfferComponent";
import {useParams} from "react-router";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
}));

const ViewSellOffer = () => {

    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const [generatorOpen, setGeneratorOpen] = useState(false);
    const [sellOffer, setSellOffer] = useState({});

    useEffect(() => {
        retrieveSellOffer();
        // eslint-disable-next-line
    }, [params]);

    const retrieveSellOffer = async () => {
        await trackPromise(axios.get("/spark-exchange/account/" + params.accountId + "/generators/" + params.generatorId + "/sell-offers/" + params.sellOfferId).then(response => {
            setSellOffer({...response.data});
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    };

    const generatePageTitle = () => {
        if (sellOffer && sellOffer.generator && sellOffer.generator.generatorName) {
            return sellOffer.generator.generatorName + " Sell Offer";
        }
        return "Sell Offer";
    };

    return (
        <main className={classes.layout}>
            <Typography variant="h3" align="center">{generatePageTitle()}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setGeneratorOpen(!generatorOpen)}>
                        {generatorOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" align="center">Generator</Typography>
                </Grid>
            </Grid>
            <Collapse in={generatorOpen} timeout="auto">
                <GeneratorComponent key={sellOffer.id} canEdit={false} generator={sellOffer.generator}/>
            </Collapse>
            {sellOffer &&
            <React.Fragment>
                <Typography variant="h6" align="center">Sell Offer</Typography>
                    <SellOfferComponent sellOffer={sellOffer} generator={sellOffer.generator} showButtons={true} isEdit={false} accountId={params.accountId}/>
            </React.Fragment>}
        </main>
    );

};

export default ViewSellOffer;