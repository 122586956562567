import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {retrieveDeliveryYearAuctions, retrieveLdas} from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import {trackPromise} from "react-promise-tracker";
import {onError, onSuccess} from "../../../store/actions/popupActions";
import {useHistory} from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {cleanStringInput} from "../../../Utility/StringUtil";
import InputAdornment from "@material-ui/core/InputAdornment";
import axios from "../../../axios/AxiosInterceptors";
import Button from "@material-ui/core/Button";
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";
import {useParams} from "react-router";
import TwoDigitCurrency from "../../NumberFormat/TwoDigitCurrency";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const EditResourceClearingPrice = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const deliveryYearAuctions = useSelector(state => state.capacity.deliveryYearAuctions);
    const roles = useSelector(state => state.auth.roles)
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const ldas = useSelector(state => state.capacity.ldas);
    const [resourceClearingPrice, setResourceClearingPrice] = useState();
    const [hasEdit, setHasEdit] = useState(false);
    const [hasDelete, setHasDelete] = useState(false);

    useEffect(() => {
        if (!deliveryYearAuctions) {
            dispatch(retrieveDeliveryYearAuctions());
        }
        if (!ldas) {
            dispatch(retrieveLdas());
        }
        retrieveResourceClearingPrice();
        setHasDelete(hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_delete_resource_clearing_price'));
        setHasEdit(hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_resource_clearing_price'));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, chosenAccount]);

    const retrieveResourceClearingPrice = async () => {
        await trackPromise(axios.get("/capacity/resourceClearingPrice/" + params.resourceClearingPriceId).then(response => {
            setResourceClearingPrice(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    const onSave = async () => {
        await trackPromise(axios.put("/capacity/resourceClearingPrice/" + props.match.params.resourceClearingPriceId, JSON.stringify(resourceClearingPrice)).then(() => {
            dispatch(onSuccess("Updated resource clearing price for Delivery Year " + resourceClearingPrice.deliveryYear + " and auction " + resourceClearingPrice.auction +
                " and LDA " + resourceClearingPrice.ldaName));
            history.push("/capacity/resourceClearingPrice/view");
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const onDelete = async () => {
        await trackPromise(axios.delete("/capacity/resourceClearingPrice/" + props.match.params.resourceClearingPriceId).then(() => {
            dispatch(onSuccess("Deleted resource clearingprice"));
            history.push("/capacity/resourceClearingPrice/view")
        }).catch(error => {
            dispatch(onError(error))
        }));
    }

    const renderLdaSelectBox = () => {
        if (ldas && ldas.length > 0) {
            return (
                <Autocomplete value={resourceClearingPrice.ldaName} options={['', ...ldas.map(lda => lda.ldaName)]}
                              renderInput={(params) => <TextField {...params} label="LDA"/>}
                              onChange={(event, value) => onLdaSelectChange(event, value)} disabled={!hasEdit}/>
            );
        }
    }

    const onLdaSelectChange = (event, value) => {
        resourceClearingPrice.ldaName = value;
        setResourceClearingPrice({...resourceClearingPrice});
    }

    const renderDeliveryYearSelectBox = () => {
        if (deliveryYearAuctions) {
            return (
                <Autocomplete value={resourceClearingPrice.deliveryYear} options={['', ...Object.keys(deliveryYearAuctions).sort((c1, c2) => c2.localeCompare(c1))]}
                              getOptionLabel={(option) => option} renderInput={(params) => <TextField {...params} label="Delivery Year"/>}
                              onChange={(event, value) => onDeliveryYearSelectChange(event, value)} disabled={!hasEdit}/>
            );
        }
    }

    const renderAuctionSelectBox = () => {
        if (resourceClearingPrice.deliveryYear) {
            return (
                <Autocomplete value={resourceClearingPrice.auction}
                              options={['', ...deliveryYearAuctions[resourceClearingPrice.deliveryYear].map(r => r.auction)]}
                              getOptionLabel={(option) => option} renderInput={(params) => <TextField {...params} label="Auction"/>}
                              onChange={(event, value) => onAuctionSelectChange(event, value)} disabled={!hasEdit}/>
            );
        }
        return (
            <Autocomplete value={resourceClearingPrice.auction} options={['']} getOptionLabel={() => ''}
                          renderInput={(params) => <TextField {...params} label="Auction"/>} disabled={!hasEdit}/>
        );
    }

    const onDeliveryYearSelectChange = (event, value) => {
        if (value) {
            resourceClearingPrice.deliveryYear = value;
        } else {
            resourceClearingPrice.deliveryYear = '';
            resourceClearingPrice.auction = '';
        }
        setResourceClearingPrice({...resourceClearingPrice});
    }

    const onAuctionSelectChange = (event, value) => {
        if (value) {
            resourceClearingPrice.auction = value;
        } else {
            resourceClearingPrice.auction = '';
        }
        setResourceClearingPrice({...resourceClearingPrice});
    }

    const onInputChange = (e) => {
        if (e.target.value) {
            resourceClearingPrice[e.target.name] = Number.parseFloat(e.target.value);
        } else {
            resourceClearingPrice[e.target.name] = '';
        }
        setResourceClearingPrice({...resourceClearingPrice});
    }

    const renderButtons = () => {
        if (hasEdit && hasDelete) {
            return (<React.Fragment>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <Button onClick={onSave} fullWidth>Save</Button>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <Button onClick={onDelete} fullWidth>Delete</Button>
                </Grid>
            </React.Fragment>);
        }
        if (!hasEdit && hasDelete) {
            return (<React.Fragment>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={4}>
                    <Button onClick={onDelete} fullWidth>Delete</Button>
                </Grid>
                <Grid item xs={6} sm={4}/>
            </React.Fragment>);
        }
        if (hasEdit && !hasDelete) {
            return (<React.Fragment>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={4}>
                    <Button onClick={onSave} fullWidth>Save</Button>
                </Grid>
                <Grid item xs={6} sm={4}/>
            </React.Fragment>);
        }
    }


    return (
        <main className={classes.layout}>
            {resourceClearingPrice &&
            <Grid container spacing={2}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    {deliveryYearAuctions && renderDeliveryYearSelectBox()}
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    {deliveryYearAuctions && renderAuctionSelectBox()}
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    {renderLdaSelectBox()}
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="System Marginal Price" name="systemMarginalPrice" value={cleanStringInput(resourceClearingPrice.systemMarginalPrice)}
                               onChange={onInputChange} fullWidth type="number"
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>,
                                   inputComponent: TwoDigitCurrency,
                                   inputProps: {isEdit: hasEdit}
                               }} InputLabelProps={{shrink: !!resourceClearingPrice.systemMarginalPrice}}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Locational Price Adder" name="locationalPriceAdder" value={cleanStringInput(resourceClearingPrice.locationalPriceAdder)}
                               onChange={onInputChange} fullWidth type="number"
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>,
                                   inputComponent: TwoDigitCurrency,
                                   inputProps: {isEdit: hasEdit}
                               }} InputLabelProps={{shrink: true}}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="CP Resource Clearing Price" name="capacityPerformanceResourceClearingPrice"
                               value={cleanStringInput(resourceClearingPrice.capacityPerformanceResourceClearingPrice)}
                               onChange={onInputChange} fullWidth type="number"
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>,
                                   inputComponent: TwoDigitCurrency,
                                   inputProps: {isEdit: hasEdit}
                               }} InputLabelProps={{shrink: !!resourceClearingPrice.capacityPerformanceResourceClearingPrice}}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                {resourceClearingPrice.deliveryYear <= '2019/2020' &&
                <React.Fragment>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={4}>
                        <TextField label="Base Clearing Price" name="baseCapacityResourceClearingPrice"
                                   value={cleanStringInput(resourceClearingPrice.baseCapacityResourceClearingPrice)}
                                   onChange={onInputChange} fullWidth type="number"
                                   InputProps={{
                                       endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>,
                                       inputComponent: TwoDigitCurrency,
                                       inputProps: {isEdit: hasEdit}
                                   }} InputLabelProps={{shrink: !!cleanStringInput(resourceClearingPrice.baseCapacityResourceClearingPrice)}}/>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={4}>
                        <TextField label="Base DR/EE Clearing Price" name="baseCapacityDrEeClearingPrice"
                                   value={cleanStringInput(resourceClearingPrice.baseCapacityDrEeClearingPrice)}
                                   onChange={onInputChange} fullWidth type="number"
                                   InputProps={{
                                       endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>,
                                       inputComponent: TwoDigitCurrency,
                                       inputProps: {isEdit: hasEdit}
                                   }} InputLabelProps={{shrink: !!cleanStringInput(resourceClearingPrice.baseCapacityDrEeClearingPrice)}}/>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                </React.Fragment>
                }
            </Grid>
            }
            <Grid container spacing={2}>
                {renderButtons()}
            </Grid>
        </main>
    );

}

export default EditResourceClearingPrice;