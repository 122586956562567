import React, {Component} from 'react';
import {withStyles, withTheme} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {trackPromise} from 'react-promise-tracker';

import axios from '../../../axios/AxiosInterceptors';
import {onError} from '../../../store/actions/popupActions';
import ViewRole from "./ViewRole";
import {updateApplicationRoles} from "../../../store/user-management/actions/applicationRoleActions";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
});

const initialState = {
    applicationRole: undefined
};

class ViewApplicationRole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            applicationRole: initialState.applicationRole
        };
    }

    async componentDidMount() {
        await trackPromise(axios.get("/auth/application/roles/" + this.props.match.params.roleId).then(response => {
            this.setState({applicationRole: response.data});
        }).catch(error => {
            this.props.onError(error.response);
        }));
    }

    handleSave = (role) => {
        this.props.updateRole(role.id, role);
        this.props.history.goBack();
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                {this.state.applicationRole &&
                <React.Fragment>
                    <ViewRole role={this.state.applicationRole} onSaveFunction={this.handleSave}/>
                </React.Fragment>
                }
            </main>
        );
    }
}

const mapStateToProps = state => {
    return {
        chosenAccount: state.chosenAccount.account
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateRole: (roleId, role) => dispatch(updateApplicationRoles(roleId, role)),
        onError: (response) => dispatch(onError(response))
    };
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ViewApplicationRole)));