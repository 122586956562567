export function calculateRowsPerPage(rowsPerPage) {
    if (!rowsPerPage) {
        return 10;
    }
    if (rowsPerPage <= 10) {
        return 10;
    }
    if (rowsPerPage <= 25) {
        return 25;
    }
    if (rowsPerPage <= 50) {
        return 50;
    }
    return rowsPerPage;
}