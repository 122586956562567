import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {TableFooter} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import PaginatedTableActions from "../../Table/PaginatedTableActions";
import {retrieveGeneratorProfile} from "../../../store/spark-exchange/actions/generatorActions";
import {useDispatch, useSelector} from "react-redux";
import NumberFormat from "react-number-format";

const EightySevenSixtyProfile = (props) => {

    const eightySevenSixtyProfile = useSelector(state => state.generator.eightySevenSixtyProfile);
    const dispatch = useDispatch();
    const [canEdit, setCanEdit] = useState(false);
    const [generatorId, setGeneratorId] = useState();
    const [accountId, setAccountId] = useState();
    const [uploaded8760Profile, setUploaded8760Profile] = useState('');

    useEffect(() => {
        setCanEdit(props.canEdit !== undefined ? props.canEdit : false);
        setGeneratorId(props.generatorId);
        setAccountId(props.accountId);
        if (generatorId && accountId) {
            dispatch(retrieveGeneratorProfile(generatorId, accountId, 0, 24));
        }
        // eslint-disable-next-line
    }, [props]);

    const handlePageChange = (pageNumber, pageSize) => {
        dispatch(retrieveGeneratorProfile(this.state.generatorId, this.state.accountId, pageNumber, pageSize));
    }

    const handleRowsPerPageChange = (e) => {
        dispatch(retrieveGeneratorProfile(this.state.generatorId, this.state.accountId, 0, e.target.value));
    }

    const fileChosen = (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0], e.target.files[0].name);
        setUploaded8760Profile(e.target.files[0].name);
        if (props.inputChangeHandler) {
            props.inputChangeHandler("profile", formData);
        }
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 12;
        }
        if (rowsPerPage <= 12) {
            return 12;
        }
        if (rowsPerPage <= 24) {
            return 24;
        }
        return rowsPerPage;
    }

    return (
        <React.Fragment>
            {canEdit === true &&
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={4}>
                    <label htmlFor="upload8760Profile">
                        <TextField value={uploaded8760Profile} label="Upload 8760 Profile"
                                   InputProps={{
                                       startAdornment: <Button component="span">Upload File</Button>
                                   }}/>
                        <input style={{display: "none"}} id="upload8760Profile" name="upload8760Profile" type="file" onChange={fileChosen}/>
                    </label>
                </Grid>
                <Grid item xs={6} sm={4}/>
            </Grid>
            }
            {eightySevenSixtyProfile && eightySevenSixtyProfile.results && eightySevenSixtyProfile.results.length > 0 &&
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Month</TableCell>
                        <TableCell align="center">Day of Month</TableCell>
                        <TableCell align="center">Hour Ending</TableCell>
                        <TableCell align="center">Expected Output (MW)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {eightySevenSixtyProfile.results.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell align="center">{row.month}</TableCell>
                            <TableCell align="center">{row.day}</TableCell>
                            <TableCell align="center">{row.hourEnding}</TableCell>
                            <TableCell align="center"><NumberFormat value={row.output} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination rowsPerPageOptions={[12, 24]} colSpan={4} count={eightySevenSixtyProfile.totalCount}
                                         rowsPerPage={calculateRowsPerPage(eightySevenSixtyProfile.pageSize)}
                                         page={eightySevenSixtyProfile.pageNumber} onChangePage={handlePageChange}
                                         onChangeRowsPerPage={handleRowsPerPageChange} ActionsComponent={PaginatedTableActions}/>
                    </TableRow>
                </TableFooter>
            </Table>
            }
        </React.Fragment>
    )

}

export default EightySevenSixtyProfile;