import React, {Component} from 'react';
import {withStyles, withTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BuyBidComponent from "./BuyBidComponent";


const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
});

class CreateBuyBid extends Component {

    state = {
        buyBid: {
            includePhysicalAttributes: true,
            includeEnvironmentalAttributes: true,
            market: '',
            includeMilestoneRequirements: false
        },
        error: {
            isValid: true,
            marketError: false
        },
        isEdit: this.props.isEdit !== undefined ? this.props.isEdit : true,
        buyBidProfileId: ''
    }
    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <Typography variant="h3" align="center">Create Buy Bid</Typography>
                <BuyBidComponent isEdit={true} showButtons={true}/>
            </main>
        );
    }

}

export default withTheme(withStyles(styles)(CreateBuyBid));
