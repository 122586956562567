import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {withTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import {hasPermission, hasPermissionForAccount} from '../../../Utility/PermissionsUtil';

const SparkExchangeAppBar = (props) => {

    const [generatorAnchorEl, setGeneratorAnchorEl] = React.useState(null);
    const [sellOfferAnchorEl, setSellOfferAnchorEl] = React.useState(null);
    const [buyBidAnchorEl, setBuyBidAnchorEl] = React.useState(null);
    const [buyBidProfileAnchorEl, setBuyBidProfileAnchorEl] = React.useState(null);
    const generatorMenuOpen = Boolean(generatorAnchorEl);
    const sellOfferMenuOpen = Boolean(sellOfferAnchorEl);
    const buyBidMenuOpen = Boolean(buyBidAnchorEl);
    const buyBidProfileMenuOpen = Boolean(buyBidProfileAnchorEl);

    const handleGeneratorClose = () => {
        setGeneratorAnchorEl(null);
    };

    const handleGeneratorMenuClick = (e) => {
        setSellOfferAnchorEl(null);
        setGeneratorAnchorEl(e.currentTarget);
    };

    const handleMenuItemClick = (path) => {
        props.history.push(path);
        setGeneratorAnchorEl(null);
        setBuyBidAnchorEl(null);
        setSellOfferAnchorEl(null);
        setBuyBidProfileAnchorEl(null);
    };

    const handleSellOfferClose = () => {
        setSellOfferAnchorEl(null);
        handleGeneratorClose();
    };

    const handleSellOfferMenuClick = (e) => {
        setSellOfferAnchorEl(e.currentTarget);
    };

    const handleBuyBidProfileMenuClick = (e) => {
        setBuyBidProfileAnchorEl(e.currentTarget);
    }

    const handleBuyBidProfileMenuClose = (e) => {
        setBuyBidProfileAnchorEl(null);
        handleBuyBidClose();
    }

    const handleBuyBidClose = () => {
        setBuyBidAnchorEl(null);
    };

    const handleBuyBidMenuClick = (e) => {
        setBuyBidProfileAnchorEl(null);
        setBuyBidAnchorEl(e.currentTarget);
    };

    const createGeneratorMenu = () => {
        let menuItems = [];
        let sellOfferMenuItems = [];
        let generatorMenu = (<React.Fragment>
            <Button onClick={handleGeneratorMenuClick}>Generator</Button>
            <Menu open={generatorMenuOpen} onClose={handleGeneratorClose} anchorEl={generatorAnchorEl} getContentAnchorEl={null}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                {menuItems}
            </Menu>
        </React.Fragment>);
        for (let role of props.roles) {
            if (role.accountId === props.chosenAccount.id) {
                if (hasPermission(role.servicePermissions, 'ROLE_get_generator') && menuItemNotIncluded("My Generators", menuItems)) {
                    menuItems.push(<MenuItem onClick={() => handleMenuItemClick("/spark-exchange/account/" + props.chosenAccount.id + "/generators")}>My Generators</MenuItem>);
                }
                if (hasPermission(role.servicePermissions, 'ROLE_create_generator') && menuItemNotIncluded("Create Generator", menuItems)) {
                    menuItems.push(<MenuItem onClick={() => handleMenuItemClick("/spark-exchange/account/" + props.chosenAccount.id + "/generators/create")}>Create
                        Generator</MenuItem>);
                }
                let getSellOfferPermission = hasPermission(role.servicePermissions, 'ROLE_get_sell_offer');
                if (getSellOfferPermission && menuItemNotIncluded("My Sell Offers", sellOfferMenuItems)) {
                    sellOfferMenuItems.push(<MenuItem onClick={() => handleMenuItemClick("/spark-exchange/account/" + props.chosenAccount.id + "/sell-offers")}
                                                      key={getSellOfferPermission}>My Sell Offers</MenuItem>);
                }
                let createSellOfferPermission = hasPermission(role.servicePermissions, 'ROLE_create_sell_offer');
                if (createSellOfferPermission && menuItemNotIncluded("Create Sell Offer", sellOfferMenuItems)) {
                    sellOfferMenuItems.push(<MenuItem onClick={() => handleMenuItemClick("/spark-exchange/account/" + props.chosenAccount.id + "/sell-offers/create")}
                                                      key={createSellOfferPermission}>Create Sell Offer</MenuItem>)
                }
                let searchSellOffer = hasPermission(role.servicePermissions, 'ROLE_search_sell_ffer');
                if (searchSellOffer && menuItemNotIncluded("Search Sell Offers", sellOfferMenuItems)) {
                    sellOfferMenuItems.push(<MenuItem onClick={() => handleMenuItemClick("/spark-exchange/sell-offers")} key={searchSellOffer}>Search Sell Offers</MenuItem>)
                }
            }
        }
        if (sellOfferMenuItems.length > 0) {
            menuItems.push(<MenuItem onClose={handleSellOfferClose} onClick={handleSellOfferMenuClick}>Sell Offers
                <Menu open={sellOfferMenuOpen} onClose={handleSellOfferClose} anchorEl={sellOfferAnchorEl} getContentAnchorEl={null}
                      anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    {sellOfferMenuItems}
                </Menu>
            </MenuItem>)
        }
        if (menuItems.length > 0) {
            return generatorMenu;
        }
    };

    const menuItemNotIncluded = (name, menuItems) => {
        const result = menuItems.filter(menuItem => menuItem.props.children === name);
        if (result.length > 0) {
            return false;
        }
        return true;
    }

    const createBuyBidMenu = () => {
        let menuItems = [];
        let buyBidProfileMenuItems = [];
        let buyBidMenu = (<React.Fragment>
            <Button onClick={handleBuyBidMenuClick}>Buy Bids</Button>
            <Menu open={buyBidMenuOpen} onClose={handleBuyBidClose} anchorEl={buyBidAnchorEl} getContentAnchorEl={null}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                {menuItems}
            </Menu>
        </React.Fragment>);
        for (let role of props.roles) {
            if (role.accountId === props.chosenAccount.id) {
                if (hasPermission(role.servicePermissions, 'ROLE_create_buy_bid') && menuItemNotIncluded("Create Buy Bid", menuItems)) {
                    menuItems.push(<MenuItem onClick={() => handleMenuItemClick("/spark-exchange/account/" + props.chosenAccount.id + "/buy-bids/create")}>Create Buy
                        Bid</MenuItem>)
                }
                if (hasPermission(role.servicePermissions, 'ROLE_get_buy_bid') && menuItemNotIncluded("My Buy Bids", menuItems)) {
                    menuItems.push(<MenuItem onClick={() => handleMenuItemClick("/spark-exchange/account/" + props.chosenAccount.id + "/buy-bids")}>My Bid Bids</MenuItem>);
                }
                if (hasPermission(role.servicePermissions, 'ROLE_search_buy_bid') && menuItemNotIncluded("Search Buy Bids", menuItems)) {
                    menuItems.push(<MenuItem onClick={() => handleMenuItemClick("/spark-exchange/buy-bids")}>Search Buy Bids</MenuItem>);
                }
                let createBuyBidProfile = hasPermission(role.servicePermissions, 'ROLE_create_buy_bid_profile');
                if (createBuyBidProfile && menuItemNotIncluded("Create Buy Bid Profile", buyBidProfileMenuItems)) {
                    buyBidProfileMenuItems.push(<MenuItem onClick={() => handleMenuItemClick("/spark-exchange/buy-bids/profiles/create")} key={createBuyBidProfile}>Create Buy Bid
                        Profile</MenuItem>)
                }
                let getBuyBidProfiles = hasPermission(role.servicePermissions, 'ROLE_get_buy_bid_profile');
                if (getBuyBidProfiles && menuItemNotIncluded("Get Buy Bid Profiles", buyBidProfileMenuItems)) {
                    buyBidProfileMenuItems.push(<MenuItem onClick={() => handleMenuItemClick("/spark-exchange/buy-bids/profiles")} key={getBuyBidProfiles}>Get Buy Bid
                        Profiles</MenuItem>)
                }
            }
        }
        if (buyBidProfileMenuItems.length > 0) {
            menuItems.push(<MenuItem onClose={handleBuyBidProfileMenuClose} onClick={handleBuyBidProfileMenuClick}>Buy Bid Profiles
                <Menu open={buyBidProfileMenuOpen} onClose={handleBuyBidProfileMenuClose} anchorEl={buyBidProfileAnchorEl} getContentAnchorEl={null}
                      anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    {buyBidProfileMenuItems}
                </Menu>
            </MenuItem>);
        }
        if (menuItems.length > 0) {
            return buyBidMenu;
        }
    };

    let generatorMenu = createGeneratorMenu();

    let buyBidMenu = createBuyBidMenu();

    return (<React.Fragment>
        <Button onClick={() => {
            props.history.push("/spark-exchange")
        }}>Dashboard</Button>
        {generatorMenu}
        {buyBidMenu}
        {(hasPermissionForAccount(props.roles, Number.parseInt(props.chosenAccount.id), 'ROLE_get_matched_sell_offer') ||
                hasPermissionForAccount(props.roles, Number.parseInt(props.chosenAccount.id), 'ROLE_get_matched_buy_bids')) &&
            <Button onClick={() => {
                props.history.push("/spark-exchange/account/" + props.chosenAccount.id + "/matches")
            }}>Matches</Button>}
    </React.Fragment>);

};

const mapStateToProps = state => {
    return {
        chosenAccount: state.chosenAccount.account, roles: state.auth.roles, accounts: state.auth.accounts
    };
};

export default connect(mapStateToProps)(withTheme(withRouter(SparkExchangeAppBar)));
