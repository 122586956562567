import {trackPromise} from 'react-promise-tracker';

import axios from '../../../axios/AxiosInterceptors';

import {RETRIEVE_DELIVERY_POINTS, RETRIEVE_REQUIRED_MILESTONES} from "../sparkExchangeActionTypes";
import {onError} from '../../actions/popupActions';

export const retrieveDeliveryPointsSuccess = (deliveryPoints) => {
    return {
        type: RETRIEVE_DELIVERY_POINTS,
        deliveryPoints: deliveryPoints
    };
}

export const retrieveRequiredMilestonesSuccess = (requiredMilestones) => {
    return {
        type: RETRIEVE_REQUIRED_MILESTONES,
        requiredMilestones: requiredMilestones
    }
}

export function retrieveDeliveryPoints() {
    return async (dispatch) => {
        await trackPromise(axios.get("/spark-exchange/delivery-points").then(response => {
            dispatch(retrieveDeliveryPointsSuccess(response.data));
        }).catch((error) => {
            onError(error.response);
        }));
    }
}

export function retrieveRequiredMilestones() {
    return async dispatch => {
        await trackPromise(axios.get("/spark-exchange/required-milestones").then(response => {
            dispatch(retrieveRequiredMilestonesSuccess(response.data));
        }).catch(error => {
            onError(error.response);
        }))
    }
}