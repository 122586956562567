import {trackPromise} from 'react-promise-tracker';

import {RETRIEVE_ACCOUNT_ROLES_SUCCESS, UPDATE_ACCOUNT_ROLE_SUCCESS, CREATE_ACCOUNT_ROLE_SUCCESS} from "../userManagementActionTypes";
import axios from '../../../axios/AxiosInterceptors';
import {onError} from '../../actions/popupActions';

export const retrieveRolesSuccess = (roles) => {
    return {
        type: RETRIEVE_ACCOUNT_ROLES_SUCCESS,
        roles: roles
    };
};

export const updateRoleSuccess = (role) => {
    return {
        type: UPDATE_ACCOUNT_ROLE_SUCCESS,
        role: role
    };
};

export const createRoleSuccess = (role) => {
    return {
        type: CREATE_ACCOUNT_ROLE_SUCCESS,
        role: role
    }
}

export function retrieveRoles(accountId) {
    return async (dispatch) => {
        await trackPromise(axios.get("/auth/account/" + accountId + "/roles").then(response => {
            return dispatch(retrieveRolesSuccess(response.data));
        }).catch((dispatch, error) => {
            dispatch(onError(error.response));
        }));
    }
}

export function updateRole(accountId, role) {
    return async (dispatch) => {
        await trackPromise(axios.put("/auth/account/" + accountId + "/roles/" + role.id, JSON.stringify(role)).then(response => {
            return dispatch(updateRoleSuccess(response.data));
        }).catch((dispatch, error) => {
            dispatch(onError(error.response));
        }));
    }
}

export function createRole(accountId, role) {
    return async (dispatch) => {
        await trackPromise(axios.post("/auth/account/" + accountId + "/roles", JSON.stringify(role)).then(response => {
            return dispatch(createRoleSuccess(response.data));
        }).catch((dispatch, error) => {
            dispatch(onError(error.response));
        }));
    }
}
