import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import RpmParameterComponent from "./RpmParameterComponent";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {onError, onSuccess} from "../../../store/actions/popupActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const EditRpmParameter = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const history = useHistory();
    const roles = useSelector(state => state.auth.roles)
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const [rpmParameter, setRpmParameter] = useState({});
    const [hasEdit, setHasEdit] = useState(false);
    const [hasDelete, setHasDelete] = useState(false);

    useEffect(() => {
        setHasDelete(hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_delete_rpm_parameter'));
        setHasEdit(hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_rpm_parameter'));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onSave = async () => {
        await trackPromise(axios.put("/capacity/parameters/" + props.match.params.rpmParameterId, JSON.stringify(rpmParameter)).then(response => {
            dispatch(onSuccess("Saved RPM Parameters for delivery year " + rpmParameter.deliveryYear + " and auction " + rpmParameter.auction));
            history.push("/capacity/rpmParameter/view");
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    const onDelete = async () => {
        await trackPromise(axios.delete("/capacity/parameters/" + props.match.params.rpmParameterId).then(response => {
            dispatch(onSuccess("Deleted RPM Parameter"));
            history.push("/capacity/rpmParameter/view");
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    const onChangeHandler = (rpmParameter) => {
        setRpmParameter({...rpmParameter});
    }

    const renderButtons = () => {
        if (hasEdit && hasDelete) {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={onSave} fullWidth>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={onDelete} fullWidth>Delete</Button>
                    </Grid>
                </React.Fragment>
            )
        }
        if (!hasEdit && hasDelete) {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={5}/>
                    <Grid item xs={6} sm={2}>
                        <Button onClick={onDelete} fullWidth>Delete</Button>
                    </Grid>
                </React.Fragment>
            )
        }
        if (hasEdit && !hasDelete) {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={5}/>
                    <Grid item xs={6} sm={2}>
                        <Button onClick={onSave} fullWidth>Save</Button>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    return (
        <main className={classes.layout}>
            <RpmParameterComponent rpmParameterId={props.match.params.rpmParameterId} onChangeHandler={onChangeHandler} isEdit={hasEdit}/>
            <Grid container spacing={2}>
                {renderButtons()}
            </Grid>
        </main>
    )

}

export default EditRpmParameter;