import React, {Component} from 'react';
import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
import {withStyles, withTheme} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import BuyBidProfile from "./BuyBidProfile";
import {saveBuyBidProfile} from '../../../store/spark-exchange/actions/buyBidProfileActions';

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
});

class CreateBuyBidProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    onSave = () => {
        this.props.saveBuyBidProfile(this.state.buyBidProfile);
        this.props.history.goBack();
    }

    onBack = () => {
        this.props.history.goBack();
    }

    onInputChangeHandler = (buyBidProfile) => {
        this.setState({buyBidProfile: buyBidProfile});
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <Typography variant="h3" align="center">Create Buy Bid Profile</Typography>
                <BuyBidProfile isEdit={true} inputChangeHandler={this.onInputChangeHandler}/>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}/>
                    <Grid item xs={6} sm={3}>
                        <Button onClick={this.onSave}>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={3}/>
                    <Grid item xs={6} sm={3}>
                        <Button onClick={this.onBack}>Back</Button>
                    </Grid>
                    <Grid item xs={6} sm={3}/>
                </Grid>
            </main>
        );
    }

}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        saveBuyBidProfile: (buyBidProfile) => dispatch(saveBuyBidProfile(buyBidProfile))
    };
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CreateBuyBidProfile)));