import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {trackPromise} from "react-promise-tracker";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {cleanStringInput} from "../../../Utility/StringUtil";
import axios from "../../../axios/AxiosInterceptors";
import {onError} from "../../../store/actions/popupActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

const AccountComponent = (props) => {

    const dispatch = useDispatch();
    const [account, setAccount] = useState({iso: ''});

    useEffect(() => {
        if (props.accountId) {
            retrieveAccount();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const retrieveAccount = async () => {
        await trackPromise(axios.get("/account-service/v3/account/" + props.accountId).then(response => {
            setAccount(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    const onInputChangeHandler = (e) => {
        account[e.target.name] = e.target.value;
        setAccount(account);
        if (props.onChange) {
            props.onChange(account);
        }
    }

    const onIsoChange = (e) => {
        account.iso = e.target.value;
        if (e.target.value === 'ISO-NE') {
            account.customerCode = null;
            account.pjmZone = null;
        }
        setAccount(account);
        if (props.onChange) {
            props.onChange(account);
        }
    }

    const renderPjmAccount = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="PJM Customer Id" name="customerId" value={cleanStringInput(account.customerId)} onChange={onInputChangeHandler} fullWidth
                               disabled={!props.canEdit}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="PJM Customer Code" name="customerCode" value={cleanStringInput(account.customerCode)} onChange={onInputChangeHandler} fullWidth
                               disabled={!props.canEdit}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="PJM Zone" name="pjmZone" value={cleanStringInput(account.pjmZone)} onChange={onInputChangeHandler} fullWidth disabled={!props.canEdit}
                               required/>
                </Grid>
            </Grid>
        )
    }

    const renderIsoNeAccount = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="ISO-NE Customer Id" name="customerId" value={cleanStringInput(account.customerId)} onChange={onInputChangeHandler} fullWidth
                               disabled={!props.canEdit}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={1}/>
            </Grid>
        )
    }

    const renderIsoSpecificDetails = () => {
        if (account.iso === 'PJM') {
            return renderPjmAccount();
        }
        if (account.iso === 'ISO-NE') {
            return renderIsoNeAccount();
        }
        return null;
    }


    return (
        <React.Fragment>
            <Typography variant="h4" align="center">{account.customerAccount ? account.customerAccount : ''}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Account Name" name="customerAccount" value={cleanStringInput(account.customerAccount)} onChange={onInputChangeHandler} fullWidth
                               disabled={!props.canEdit}/>
                </Grid>
                <Grid item xs={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Transmission Provider" name="transmissionProvider" value={cleanStringInput(account.transmissionProvider)} onChange={onInputChangeHandler}
                               fullWidth disabled={!props.canEdit}/>
                </Grid>
                <Grid item xs={1}/>
                <Grid item xs={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="PNode Id" name="pnodeId" value={cleanStringInput(account.pnodeId)} onChange={onInputChangeHandler} fullWidth disabled={!props.canEdit}/>
                </Grid>
                <Grid item xs={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="EDC" name="edc" value={cleanStringInput(account.edc)} onChange={onInputChangeHandler} fullWidth disabled={!props.canEdit}/>
                </Grid>
                <Grid item xs={1}/>
                <Grid item xs={4}/>
                <Grid item xs={4}>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel>ISO/RTO</FormLabel>
                        <RadioGroup name="iso" value={account.iso} onChange={onIsoChange} row>
                            <FormControlLabel control={<Radio/>} label="PJM" value="PJM" disabled={!props.canEdit}/>
                            <FormControlLabel control={<Radio/>} label="ISO-NE" value="ISO-NE" disabled={!props.canEdit}/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={4}/>
            </Grid>
            {renderIsoSpecificDetails()}
        </React.Fragment>
    )

}

export default AccountComponent;