import React, {Component} from 'react';
import {connect} from 'react-redux';
import {trackPromise} from 'react-promise-tracker';

import axios from 'axios';
import {withStyles, withTheme} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import {onError, onSuccess} from "../../../store/actions/popupActions";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

class ResetPassword extends Component {

    constructor(props) {
        super(props);
        const paramsString = this.props.location.search;
        const params = new URLSearchParams(paramsString);
        const token = params.get('token');
        this.state = {
            password: '',
            passwordConfirm: '',
            token: token
        }
    }

    componentDidMount() {
        if (this.state.token) {
            this.retrieveUser();
        }
    }

    retrieveUser = async () => {
        await trackPromise(
            axios.create({
                baseURL: '/wpec/services', timeout: 300000, headers: {'Content-Type': 'application/json'}
            }).get("/auth/users/password/" + this.state.token).then(response => {
                this.setState({user: response.data});
            }).catch(error => {
                this.props.onError(error)
            }));
    }

    onPasswordInputChangeHandler = (e) => {
        this.setState({password: e.target.value});
    }

    onPasswordConfirmInputChangeHandler = (e) => {
        this.setState({passwordConfirm: e.target.value});
    }

    handlePasswordChange = async () => {
        if (this.state.password === this.state.passwordConfirm) {
            const password = {password: this.state.password};
            await trackPromise(
                axios.create({
                    baseURL: '/wpec/services', timeout: 300000, headers: {'Content-Type': 'application/json'}
                }).post("/auth/users/" + encodeURIComponent( this.state.token) + "/setPassword", JSON.stringify(password)).then(
                    response => {
                        this.props.onSuccess("Password successfully changed");
                        this.props.history.push("/login");
                    }).catch(error => {
                        console.log(error);
                        this.props.onError(error)
                })
            )
        }
    }


    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                {this.state.token && this.state.user &&
                <React.Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="Username" name="username" value={this.state.user.username} disabled={true} fullWidth/>
                        </Grid>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="First Name" name="firstName" value={this.state.user.firstName} disabled={true} fullWidth/>
                        </Grid>
                        <Grid item xs={6} sm={2}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="Last Name" name="lastName" value={this.state.user.lastName} disabled={true} fullWidth/>
                        </Grid>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="Email Address" name="emailAddress" value={this.state.user.emailAddress} disabled={true} fullWidth/>
                        </Grid>
                        <Grid item xs={6} sm={2}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="Phone Number" name="phoneNumber" value={this.state.user.phoneNumber} disabled={true} fullWidth/>
                        </Grid>
                    </Grid>
                    <Typography variant="h4" align="center">Please enter a new password</Typography>
                    <form onSubmit={(e) => {e.preventDefault(); this.handlePasswordChange()}}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="Password" name="password" value={this.state.password} type='password' fullWidth onChange={this.onPasswordInputChangeHandler}/>
                        </Grid>
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="Re-enter Password" name="passwordConfirmation" value={this.state.passwordConfirmation} type='password'  fullWidth
                                       onChange={this.onPasswordConfirmInputChangeHandler}/>
                        </Grid>
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={4}>
                            <Button type="submit" fullWidth>Submit</Button>
                        </Grid>
                        <Grid item xs={6} sm={4}/>
                    </Grid>
                    </form>
                </React.Fragment>
                }
            </main>
        );
    }

}

const mapDispatchToProps = dispatch => {
    return {
        onError: (error) => dispatch(onError(error)),
        onSuccess: (message) => dispatch(onSuccess(message))
    };
};

export default withTheme(withStyles(styles)(connect(null, mapDispatchToProps)(ResetPassword)));
