import {trackPromise} from 'react-promise-tracker';

import axios from '../../../axios/AxiosInterceptors';
import {SAVE_BUY_BID_PROFILE_SUCCESS, RETRIEVE_BUY_BID_PROFILES_SUCCESS, UPDATE_BUY_BID_PROFILE_SUCCESS} from '../sparkExchangeActionTypes';
import {onError} from "../../actions/popupActions";

export const saveBuyBidProfileSuccess = (buyBidProfile) => {
    return {
        type: SAVE_BUY_BID_PROFILE_SUCCESS,
        buyBidProfile: buyBidProfile
    };
}

export const retrieveBuyBidProfilesSuccess = (buyBidProfiles) => {
    return {
        type: RETRIEVE_BUY_BID_PROFILES_SUCCESS,
        buyBidProfiles: buyBidProfiles
    }
}

export const updateBuyBidProfileSuccess = (buyBidProfile) => {
    return {
        type: UPDATE_BUY_BID_PROFILE_SUCCESS,
        buyBidProfile: buyBidProfile
    }
}

export const saveBuyBidProfile = (buyBidProfile) => {
    return async (dispatch) => {
        await trackPromise(axios.post("/spark-exchange/buy-bids/profiles", JSON.stringify(buyBidProfile)).then(response => {
            return dispatch(saveBuyBidProfileSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    };
}

export const retrieveBuyBidProfiles = () => {
    return async (dispatch) => {
      await trackPromise((axios.get("/spark-exchange/buy-bids/profiles").then(response => {
          return dispatch(retrieveBuyBidProfilesSuccess(response.data));
      }).catch(error => {
          dispatch(onError(error.response));
      })));
    };
}

export const updateBuyBidProfile = (buyBidProfileId, buyBidProfile) => {
    return async (dispatch) => {
        await trackPromise((axios.put("/spark-exchange/buy-bids/profiles/" + buyBidProfileId, JSON.stringify(buyBidProfile)).then(response => {
            return dispatch(updateBuyBidProfileSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        })));
    };
}