import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router";
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";
import {useDispatch, useSelector} from "react-redux";
import {retrieveBuyBidMatchesForAccount, retrievePotentialBuyBidMatchesForAccount} from "../../../store/spark-exchange/actions/buyBidActions";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import {Dialog, DialogActions, DialogContent, DialogTitle, TableCell, Tooltip} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NumberFormat from "react-number-format";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import IconButton from "@material-ui/core/IconButton";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {onError} from "../../../store/actions/popupActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import {SUCCESS} from "../../../store/actionTypes";
import {retrievePotentialSellOfferMatchesForAccount, retrieveSellOfferMatchesForAccount} from "../../../store/spark-exchange/actions/sellOfferActions";


const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(5),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const MatchesView = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const exactBuyBidMatches = useSelector(state => state.match.buyBidMatches);
    const potentialBuyBidMatches = useSelector(state => state.match.potentialBuyBidMatches);
    const exactSellOfferMatches = useSelector(state => state.match.sellOfferMatches);
    const potentialSellOfferMatches = useSelector(state => state.match.potentialSellOfferMatches);
    const roles = useSelector(state => state.auth.roles)
    const [declineDialogOpen, setDeclineDialogOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [workingMatch, setWorkingMatch] = useState({});
    const matchesRetrieved = useRef(true);
    const {accountId} = useParams();

    useEffect(() => {
        retrieveMatches();
        // eslint-disable-next-line
    }, [accountId, exactBuyBidMatches])

    const retrieveMatches = () => {
        if (matchesRetrieved.current) {
            if (hasPermissionForAccount(roles, Number.parseInt(accountId), 'ROLE_get_matched_sell_offer')) {
                dispatch(retrievePotentialBuyBidMatchesForAccount(accountId));
                dispatch(retrieveBuyBidMatchesForAccount(accountId));
            }
            if (hasPermissionForAccount(roles, Number.parseInt(accountId), 'ROLE_get_matched_buy_bids')) {
                dispatch(retrievePotentialSellOfferMatchesForAccount(accountId));
                dispatch(retrieveSellOfferMatchesForAccount(accountId));
            }
            matchesRetrieved.current = false;
        }
    }

    const downloadBuyBidTermSheet = async (buyBidId) => {
        await downloadTermSheet("/spark-exchange/account/" + accountId + "/buy-bids/" + buyBidId);
    }

    const downloadSellOfferTermSheet = async (sellOfferId, generatorId) => {
        await downloadTermSheet("/spark-exchange/account/" + accountId + "/generators/" + generatorId + "/sell-offers/" + sellOfferId);
    }

    const downloadTermSheet = async (url) => {
        await trackPromise(axios.get(url, {
            headers: {
                'Content-Type': 'application/pdf',
                'Accept': 'application/pdf'
            },
            responseType: "arraybuffer"
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a')
            link.href = url;
            link.setAttribute('download', "term-sheet.pdf");
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const renderPendingBuyBidMatches = () => {
        return (
            <React.Fragment>
                <Typography variant="h5" align="center">Pending Matches for Buy Bids</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Start Date</TableCell>
                            <TableCell align="center">Term Length</TableCell>
                            <TableCell align="center">Delivery Point</TableCell>
                            <TableCell align="center">Energy Payment</TableCell>
                            <TableCell align="center">Market</TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {exactBuyBidMatches && exactBuyBidMatches.filter(r => r.confirmed === false).map(match => {
                            return (
                                <React.Fragment key={match.id}>
                                    {renderTableRow(match, 'BUY_BID')}
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </React.Fragment>
        )
    }

    const renderPendingSellOfferMatches = () => {
        return (
            <React.Fragment>
                <Typography variant="h5" align="center">Pending Matches for Sell Offers</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Start Date</TableCell>
                            <TableCell align="center">Term Length</TableCell>
                            <TableCell align="center">Delivery Point</TableCell>
                            <TableCell align="center">Energy Payment</TableCell>
                            <TableCell align="center">Market</TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {exactSellOfferMatches && exactSellOfferMatches.filter(r => r.confirmed === false).map(match => {
                            return (
                                <React.Fragment key={match.id}>
                                    {renderTableRow(match, 'SELL_OFFER')}
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </React.Fragment>
        )
    }

    const renderConfirmedBuyBidMatches = () => {
        return (
            <React.Fragment>
                <Typography variant="h5" align="center">Confirmed Matches for Buy Bids</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Start Date</TableCell>
                            <TableCell align="center">Term Length</TableCell>
                            <TableCell align="center">Delivery Point</TableCell>
                            <TableCell align="center">Energy Payment</TableCell>
                            <TableCell align="center">Market</TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {exactBuyBidMatches && exactBuyBidMatches.filter(r => r.confirmed === true).map(match => {
                            return (
                                <React.Fragment key={match.id}>
                                    {renderTableRow(match, 'BUY_BID')}
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </React.Fragment>
        )
    }

    const renderConfirmedSellOfferMatches = () => {
        return (
            <React.Fragment>
                <Typography variant="h5" align="center">Confirmed Matches for Sell Offers</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Start Date</TableCell>
                            <TableCell align="center">Term Length</TableCell>
                            <TableCell align="center">Delivery Point</TableCell>
                            <TableCell align="center">Energy Payment</TableCell>
                            <TableCell align="center">Market</TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {exactSellOfferMatches && exactSellOfferMatches.filter(r => r.confirmed === true).map(match => {
                            return (
                                <React.Fragment key={match.id}>
                                    {renderTableRow(match, 'SELL_OFFER')}
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </React.Fragment>
        )
    }

    const renderPotentialBuyBidMatches = () => {
        return (
            <React.Fragment>
                <Typography variant="h5" align="center">Potential Matches for Buy Bids</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Start Date</TableCell>
                            <TableCell align="center">Term Length</TableCell>
                            <TableCell align="center">Delivery Point</TableCell>
                            <TableCell align="center">Energy Payment</TableCell>
                            <TableCell align="center">Market</TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {potentialBuyBidMatches && Object.keys(potentialBuyBidMatches).map(key => {
                            const buyBid = findBuyBidForPotentialMatches(potentialBuyBidMatches[key]);
                            return (
                                <React.Fragment key={key}>
                                    <TableRow key={key}>
                                        <TableCell align="center">{buyBid.startDate}</TableCell>
                                        <TableCell align="center">{buyBid.termLengthMonth}</TableCell>
                                        <TableCell align="center">{buyBid.deliveryPoint}</TableCell>
                                        <TableCell align="center"><NumberFormat value={buyBid.variablePaymentMwh} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                                                                prefix="$"/></TableCell>
                                        <TableCell align="center">{formatMarket(buyBid.market)}</TableCell>
                                        <TableCell align="center">{formatOfferType(buyBid.buyBidType)}</TableCell>
                                    </TableRow>
                                    {potentialBuyBidMatches[key].exactMatches && potentialBuyBidMatches[key].exactMatches.map(match => {
                                        return (
                                            <TableRow key={match.sellOffer.id}>

                                            </TableRow>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </React.Fragment>
        );
    }

    const renderPotentialSellOfferMatches = () => {
        return (
            <React.Fragment>
                <Typography variant="h5" align="center">Potential Matches for Sell Offers</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Start Date</TableCell>
                            <TableCell align="center">Term Length</TableCell>
                            <TableCell align="center">Delivery Point</TableCell>
                            <TableCell align="center">Energy Payment</TableCell>
                            <TableCell align="center">Market</TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {potentialSellOfferMatches && Object.keys(potentialSellOfferMatches).map(key => {
                            const sellOffer = findSellOfferForPotentialMatches(potentialBuyBidMatches[key]);
                            return (
                                <React.Fragment key={key}>
                                    <TableRow key={key}>
                                        <TableCell align="center">{sellOffer.startDate}</TableCell>
                                        <TableCell align="center">{sellOffer.termLengthMonth}</TableCell>
                                        <TableCell align="center">{sellOffer.deliveryPoint}</TableCell>
                                        <TableCell align="center"><NumberFormat value={sellOffer.variablePaymentMwh} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                                                                prefix="$"/></TableCell>
                                        <TableCell align="center">{formatMarket(sellOffer.market)}</TableCell>
                                        <TableCell align="center">{formatOfferType(sellOffer.buyBidType)}</TableCell>
                                    </TableRow>
                                    {potentialBuyBidMatches[key].exactMatches && potentialBuyBidMatches[key].exactMatches.map(match => {
                                        return (
                                            <TableRow key={match.buyBid.id}>

                                            </TableRow>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </React.Fragment>
        );
    }


    const renderTableRow = (match, direction) => {
        return (
            <TableRow key={match.id}>
                <TableCell align="center">{match.buyBid.startDate}</TableCell>
                <TableCell align="center">{match.buyBid.termLengthMonth}</TableCell>
                <TableCell align="center">{match.buyBid.deliveryPoint}</TableCell>
                <TableCell align="center"><NumberFormat value={match.buyBid.variablePaymentMwh} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                                        prefix="$"/></TableCell>
                <TableCell align="center">{formatMarket(match.buyBid.market)}</TableCell>
                <TableCell align="center">{formatOfferType(match.buyBid.buyBidType)}</TableCell>
                <TableCell>
                    {renderRowOptions(match, direction)}
                </TableCell>
            </TableRow>
        )
    }

    const renderRowOptions = (match, direction) => {
        let rowOptions = [];
        rowOptions.push(
            <Tooltip title="Download Term Sheet">
                <IconButton size="small"
                            onClick={direction === 'BUY_BID' ? () => downloadBuyBidTermSheet(match.buyBid.id) : () => downloadSellOfferTermSheet(match.sellOffer.id, match.sellOffer.generator.id)}>
                    <CloudDownload/>
                </IconButton>
            </Tooltip>);
        if (match.confirmed === false && (hasPermissionForAccount(roles, Number.parseInt(accountId), 'ROLE_create_sell_offer') ||
            hasPermissionForAccount(roles, Number.parseInt(accountId), 'ROLE_create_buy_bid'))) {
            rowOptions.push(<Tooltip title="Confirm Match"><IconButton size="small"
                                                                       onClick={direction === 'BUY_BID' ? () => handleBuyBidConfirmClick(match) : () => handleSellOfferConfirmClick(match)}>
                <CheckIcon/></IconButton></Tooltip>);
            rowOptions.push(<Tooltip title="Decline Match"><IconButton size="small"
                                                                       onClick={direction === 'BUY_BID' ? () => handleBuyBidDeclineClick(match) : () => handleSellOfferDeclineClick(match)}>
                <BlockIcon/></IconButton></Tooltip>);
        }
        return rowOptions;
    }

    const renderDeclineDialog = () => {
        return (
            <Dialog open={declineDialogOpen} onClose={handleDeclineDialogClose}>
                <DialogTitle>Decline Match</DialogTitle>
                <DialogContent>
                    <DialogContentText>By clicking decline below your match will be deleted. This cannot be undone. If you do nothing, the match will automatically be confirmed.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDecline} variant="outlined">Decline</Button>
                    <Button onClick={handleDeclineDialogClose} variant="outlined">Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const renderConfirmDialog = () => {
        return (
            <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
                <DialogTitle>Confirm Match</DialogTitle>
                <DialogContent>
                    <DialogContentText>By clicking confirm below your match will be officially confirmed. This cannot be undone. If you do nothing, the match will automatically be
                        confirmed.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirm} variant="outlined">Confirm</Button>
                    <Button onClick={handleConfirmDialogClose} variant="outlined">Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const handleDeclineDialogClose = () => {
        setDeclineDialogOpen(false);
    }

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    }

    const handleDecline = async () => {
        let url = "/spark-exchange/account/";
        if (workingMatch.matchingDirection === "BUY_BID") {
            url += workingMatch.buyBid.accountId + "/buy-bids/" + workingMatch.buyBid.id + "/match/" + workingMatch.id;
        } else if (workingMatch.matchingDirection === "SELL_OFFER") {
            url += workingMatch.sellOffer.accountId + "/generators/" + workingMatch.sellOffer.generator.id + "/sell-offers/" + workingMatch.sellOffer.id + "/match";
        }
        await trackPromise(axios.delete(url).then(() => {
            dispatch({type: SUCCESS, message: "Match Declined"});
            handleDeclineDialogClose();
            matchesRetrieved.current = true;
            retrieveMatches();
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const handleConfirm = async () => {
        let url = "/spark-exchange/account/";
        if (workingMatch.matchingDirection === "BUY_BID") {
            url += workingMatch.buyBid.accountId + "/buy-bids/" + workingMatch.buyBid.id + "/match/" + workingMatch.id;
        } else if (workingMatch.matchingDirection === "SELL_OFFER") {
            url += workingMatch.sellOffer.accountId + "/generators/" + workingMatch.sellOffer.generator.id + "/sell-offers/" + workingMatch.sellOffer.id + "/match";
        }
        await trackPromise(axios.put(url).then(() => {
            dispatch({type: SUCCESS, message: "Match Confirmed"});
            handleConfirmDialogClose();
            matchesRetrieved.current = true;
            retrieveMatches();
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }

    const handleBuyBidDeclineClick = (match) => {
        setWorkingMatch(match);
        setDeclineDialogOpen(true);
    }

    const handleBuyBidConfirmClick = (match) => {
        setWorkingMatch(match);
        setConfirmDialogOpen(true);
    }

    const handleSellOfferConfirmClick = (match) => {
        setWorkingMatch(match);
        setConfirmDialogOpen(true);
    }

    const handleSellOfferDeclineClick = (match) => {
        setWorkingMatch(match);
        setDeclineDialogOpen(true);
    }

    const formatOfferType = (offerType) => {
        if (offerType === "unitContingent") {
            return "Unit Contingent";
        }
        return "Firm";
    }

    const formatMarket = (market) => {
        if (market === "dayAhead") {
            return "Day Ahead";
        }
        if (market === "realTime") {
            return "Real Time";
        }
        return market;
    }

    const findBuyBidForPotentialMatches = (potentialMatch) => {
        if (potentialMatch.exactMatches && potentialMatch.exactMatches.length > 0) {
            return potentialMatch.exactMatches[0].buyBid;
        }
        if (potentialMatch.fuzzyMatches && potentialMatch.fuzzyMatches.length > 0) {
            return potentialMatch.fuzzyMatches[0].buyBid;
        }
    }

    const findSellOfferForPotentialMatches = (potentialMatch) => {
        if (potentialMatch.exactMatches && potentialMatch.exactMatches.length > 0) {
            return potentialMatch.exactMatches[0].sellOffer;
        }
        if (potentialMatch.fuzzyMatches && potentialMatch.fuzzyMatches.length > 0) {
            return potentialMatch.fuzzyMatches[0].sellOffer;
        }
    }


    return (
        <main className={classes.layout}>
            {(hasPermissionForAccount(roles, Number.parseInt(accountId), 'ROLE_create_sell_offer') ||
                hasPermissionForAccount(roles, Number.parseInt(accountId), 'ROLE_create_buy_bid')) && renderDeclineDialog()}
            {(hasPermissionForAccount(roles, Number.parseInt(accountId), 'ROLE_create_sell_offer') ||
                hasPermissionForAccount(roles, Number.parseInt(accountId), 'ROLE_create_buy_bid')) && renderConfirmDialog()}
            {hasPermissionForAccount(roles, Number.parseInt(accountId), 'ROLE_get_matched_sell_offer') && renderPendingBuyBidMatches()}
            {hasPermissionForAccount(roles, Number.parseInt(accountId), 'ROLE_get_matched_sell_offer') && renderConfirmedBuyBidMatches()}
            {hasPermissionForAccount(roles, Number.parseInt(accountId), 'ROLE_get_matched_sell_offer') && renderPotentialBuyBidMatches()}
            {hasPermissionForAccount(roles, Number.parseInt(accountId), 'ROLE_get_matched_buy_bids') && renderPendingSellOfferMatches()}
            {hasPermissionForAccount(roles, Number.parseInt(accountId), 'ROLE_get_matched_buy_bids') && renderConfirmedSellOfferMatches()}
            {hasPermissionForAccount(roles, Number.parseInt(accountId), 'ROLE_get_matched_buy_bids') && renderPotentialSellOfferMatches()}
        </main>
    )
}

export default MatchesView;