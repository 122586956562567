import {
    BUY_BID_SEARCH_ADDITIONAL_SEARCH_FIELDS,
    BUY_BID_SEARCH_CAPACITY_ESCALATION_DELAY,
    BUY_BID_SEARCH_CAPACITY_ESCALATION_FREQUENCY,
    BUY_BID_SEARCH_CAPACITY_ESCALATION_PERCENTAGE,
    BUY_BID_SEARCH_DELIVERY_POINT,
    BUY_BID_SEARCH_ENERGY_ESCALATION_DELAY,
    BUY_BID_SEARCH_ENERGY_ESCALATION_FREQUENCY,
    BUY_BID_SEARCH_ENERGY_ESCALATION_PERCENTAGE,
    BUY_BID_SEARCH_ENVIRONMENTAL_ATTRIBUTES,
    BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_DELAY,
    BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_FREQUENCY,
    BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_PERCENTAGE,
    BUY_BID_SEARCH_FIXED_PAYMENT,
    BUY_BID_SEARCH_GENERATOR_TYPE,
    BUY_BID_SEARCH_INCLUDE_CAPACITY_ESCALATION,
    BUY_BID_SEARCH_INCLUDE_ENERGY_ESCALATION,
    BUY_BID_SEARCH_INCLUDE_ENVIRONMENTAL_ESCALATION,
    BUY_BID_SEARCH_INCLUDED_RECS,
    BUY_BID_SEARCH_MARKET,
    BUY_BID_SEARCH_MILESTONE,
    BUY_BID_SEARCH_OFFER_TYPE,
    BUY_BID_SEARCH_QUANTITY_MW,
    BUY_BID_SEARCH_REMOVE_SEARCH_FIELD,
    BUY_BID_SEARCH_SELL_OFFER_TYPE,
    BUY_BID_SEARCH_START_DATE, BUY_BID_SEARCH_STATE,
    BUY_BID_SEARCH_TERM_LENGTH,
    BUY_BID_SEARCH_VARIABLE_PAYMENT,
} from "../sparkExchangeActionTypes";

const initialState = {
    search: {
        quantityMw: '',
        variablePaymentMwh: '',
        fixedPaymentMwday: '',
        environmentalAttributesMwh: '',
        includeCapacityEscalation: undefined,
        capacityEscalationPercentage: '',
        capacityEscalationDelay: '',
        capacityEscalationFrequency: '',
        includeEnergyEscalation: undefined,
        energyEscalationPercentage: '',
        energyEscalationDelay: '',
        energyEscalationFrequency: '',
        includeEnvironmentalEscalation: undefined,
        environmentalEscalationPercentage: '',
        environmentalEscalationDelay: '',
        environmentalEscalationFrequency: '',
        deliveryPoint: '',
        termLengthMonth: '',
        market: '',
        offerType: '',
        sellOfferType: '',
        startDate: null,
        includedRecs: [],
        generatorTypes: [],
        requiredMilestones: [],
        states: []
    },
    additionalSearchFields: []
}

const buyBidSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case BUY_BID_SEARCH_QUANTITY_MW:
            return updateSearchValue("quantityMw", state, action);
        case BUY_BID_SEARCH_VARIABLE_PAYMENT:
            return updateSearchValue("variablePaymentMwh", state, action);
        case BUY_BID_SEARCH_FIXED_PAYMENT:
            return updateSearchValue("fixedPaymentMwday", state, action);
        case BUY_BID_SEARCH_ENVIRONMENTAL_ATTRIBUTES:
            return updateSearchValue("environmentalAttributesMwh", state, action);
        case BUY_BID_SEARCH_INCLUDE_CAPACITY_ESCALATION:
            return updateSearchValue("includeCapacityEscalation", state, action);
        case BUY_BID_SEARCH_CAPACITY_ESCALATION_PERCENTAGE:
            return updateSearchValue("capacityEscalationPercentage", state, action);
        case BUY_BID_SEARCH_CAPACITY_ESCALATION_DELAY:
            return updateSearchValue("capacityEscalationDelay", state, action);
        case BUY_BID_SEARCH_CAPACITY_ESCALATION_FREQUENCY:
            return updateSearchValue("capacityEscalationFrequency", state, action);
        case BUY_BID_SEARCH_INCLUDE_ENERGY_ESCALATION:
            return updateSearchValue("includeEnergyEscalation", state, action);
        case BUY_BID_SEARCH_ENERGY_ESCALATION_PERCENTAGE:
            return updateSearchValue("energyEscalationPercentage", state, action);
        case BUY_BID_SEARCH_ENERGY_ESCALATION_DELAY:
            return updateSearchValue("energyEscalationDelay", state, action);
        case BUY_BID_SEARCH_ENERGY_ESCALATION_FREQUENCY:
            return updateSearchValue("energyEscalationFrequency", state, action);
        case BUY_BID_SEARCH_INCLUDE_ENVIRONMENTAL_ESCALATION:
            return updateSearchValue("includeEnvironmentalEscalation", state, action);
        case BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_PERCENTAGE:
            return updateSearchValue("environmentalEscalationPercentage", state, action);
        case BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_DELAY:
            return updateSearchValue("environmentalEscalationDelay", state, action);
        case BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_FREQUENCY:
            return updateSearchValue("environmentalEscalationFrequency", state, action);
        case BUY_BID_SEARCH_DELIVERY_POINT:
            return updateSearchValue("deliveryPoint", state, action);
        case BUY_BID_SEARCH_TERM_LENGTH:
            return updateSearchValue("termLengthMonth", state, action);
        case BUY_BID_SEARCH_MARKET:
            return updateSearchValue("market", state, action);
        case BUY_BID_SEARCH_OFFER_TYPE:
            return updateSearchValue('offerType', state, action);
        case BUY_BID_SEARCH_SELL_OFFER_TYPE:
            return updateSearchValue('sellOfferType', state, action);
        case BUY_BID_SEARCH_START_DATE:
            return updateSearchValue('startDate', state, action);
        case BUY_BID_SEARCH_INCLUDED_RECS:
            return updateIncludedRecs(state, action);
        case BUY_BID_SEARCH_GENERATOR_TYPE:
            return updateGeneratorTypes(state, action);
        case BUY_BID_SEARCH_MILESTONE:
            return updateMilestones(state, action);
        case BUY_BID_SEARCH_STATE:
            return updateStates(state, action);
        case BUY_BID_SEARCH_ADDITIONAL_SEARCH_FIELDS:
            return ({...state, additionalSearchFields: [...state.additionalSearchFields, action.searchField]})
        case BUY_BID_SEARCH_REMOVE_SEARCH_FIELD:
            return removeSearchField(state, action);
        default:
            return state;
    }
}

const updateIncludedRecs = (state, action) => {
    let search = {...state.search};
    let value = action.value;
    search.includedRecs = [...value];
    return {...state, search: search};
}

const updateGeneratorTypes = (state, action) => {
    let search = {...state.search};
    let value = action.value;
    search.generatorTypes = [...value];
    return {...state, search: search};
}

const updateStates = (state, action) => {
    let search = {...state.search};
    let value = action.value;
    search.states = [...value];
    return {...state, search: search};
}

const updateMilestones = (state, action) => {
    let search = {...state.search};
    if (search.requiredMilestones && search.requiredMilestones.length >= 0 && action.value) {
        let index = -1;
        for (let i = 0; i < search.requiredMilestones.length; i++) {
            if (search.requiredMilestones[i].milestoneName === action.value.milestoneName) {
                index = i;
            }
        }
        if (index !== -1) {
            if (action.value.date) {
                search.requiredMilestones[index].requiredDate = action.value.requiredDate;
            } else {
                search.requiredMilestones.splice(index, 1);
            }
        } else {
            search.requiredMilestones.push(action.value);
        }
    } else if (!action.value) {
        search.requiredMilestones = [];
    }
    return {...state, search: search};
}

const updateSearchValue = (fieldName, state, action) => {
    let search = {...state.search};
    search[fieldName] = action.value;
    return {...state, search}
}

const removeSearchField = (state, action) => {
    let searchFields = state.additionalSearchFields;
    let index = -1;
    for (let i = 0; i < searchFields.length; i++) {
        if (action.value === searchFields[i]) {
            index = i;
        }
    }
    if (index !== -1) {
        searchFields.splice(index, 1);
    }
    return ({...state, additionalSearchFields: [...searchFields]});
}

export default buyBidSearchReducer;