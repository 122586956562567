import React, {useEffect, useState} from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from "@material-ui/core/InputAdornment";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import {cleanStringInput} from "../../../Utility/StringUtil";

const GeneratorAttributes = (props) => {

    const [generatorType, setGeneratorType] = useState();
    const [generatorAttributes, setGeneratorAttributes] = useState({});
    const [validation, setValidation] = useState({});
    const [isEdit, setIsEdit] = useState();

    useEffect(() => {
        if (props.data) {
            generatorAttributes.primaryFuelType = props.data.primaryFuelType;
            generatorAttributes.secondaryFuelType = props.data.secondaryFuelType;
            generatorAttributes.heatRate = props.data.heatRate;
            generatorAttributes.fuelRate = props.data.fuelRate;
            setGeneratorAttributes({...generatorAttributes});
        }
        setGeneratorType(props.generatorType);
        setValidation(props.validation);
        setIsEdit(props.canEdit !== undefined ? props.canEdit : false);
        // eslint-disable-next-line
    }, [props]);

    const onInputChangeHandler = (e) => {
        if (e.target.type === "number") {
            if (e.target.value) {
                generatorAttributes[e.target.name] = Number.parseFloat(e.target.value);
            } else {
                generatorAttributes[e.target.name] = e.target.value;
            }
        } else {
            generatorAttributes[e.target.name] = e.target.value;
        }
        setGeneratorAttributes({...generatorAttributes});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(e.target.name, e.target.value);
        }
    };

    const renderGeneratorAttributes = () => {
        switch (generatorType) {
            case 'Bio-Mass':
            case 'Coal':
            case 'Fuel Cell':
            case 'Gas':
            case 'Waste Energy':
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="Primary Fuel Type" name="primaryFuelType" value={cleanStringInput(generatorAttributes.primaryFuelType)}
                                       onChange={onInputChangeHandler} disabled={!isEdit} fullWidth required error={validation.primaryFuelType}
                                       helperText={validation.primaryFuelTypeMessage} InputLabelProps={{shrink: !!generatorAttributes.primaryFuelType}}/>
                        </Grid>
                        <Grid item xs={6} sm={2}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="Secondary Fuel Type" name="secondaryFuelType" value={cleanStringInput(generatorAttributes.secondaryFuelType)}
                                       onChange={onInputChangeHandler} disabled={!isEdit} fullWidth InputLabelProps={{shrink: !!generatorAttributes.secondaryFuelType}}/>
                        </Grid>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label={"Heat Rate (HHV)"} name={"heatRate"} value={cleanStringInput(generatorAttributes.heatRate)}
                                       onChange={onInputChangeHandler} disabled={!isEdit} fullWidth required
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">BTU/MWh</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}
                                       }} error={validation.heatRate} helperText={validation.heatRateMessage}  InputLabelProps={{shrink: !!generatorAttributes.heatRate}}/>
                        </Grid>
                        <Grid item xs={6} sm={2}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label={"Fuel Rate"} name={"fuelRate"} value={cleanStringInput(generatorAttributes.fuelRate)}
                                       onChange={onInputChangeHandler} disabled={!isEdit} fullWidth required
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">BTU/MWh</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}
                                       }} error={validation.fuelRate} helperText={validation.fuelRateMessage} InputLabelProps={{shrink: !!generatorAttributes.fuelRate}}/>
                        </Grid>
                        <Grid item xs={6} sm={1}/>
                    </Grid>
                );
            case 'Nuclear':
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label={"Heat Rate (HHV)"} name={"heatRate"} value={cleanStringInput(generatorAttributes.heatRate)}
                                       onChange={onInputChangeHandler}
                                       fullWidth required
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">BTU/MWh</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}
                                       }} error={validation.heatRate} helperText={validation.heatRateMessage}  InputLabelProps={{shrink: !!generatorAttributes.heatRate}}/>
                        </Grid>
                        <Grid item xs={6} sm={2}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label={"Fuel Rate"} name={"fuelRate"} value={cleanStringInput(generatorAttributes.fuelRate)}
                                       onChange={onInputChangeHandler} fullWidth required
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">BTU/MWh</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}
                                       }} error={validation.fuelRate} helperText={validation.fuelRateMessage}  InputLabelProps={{shrink: !!generatorAttributes.fuelRate}}/>
                        </Grid>
                        <Grid item xs={6} sm={1}/>
                    </Grid>
                );
            case 'Hydro':
            case 'Pumped Hydro':
            case 'Storage':
            case 'Solar':
            case 'Wind':
            default:
                return null;
        }
    }

    return (
        <React.Fragment>
            {renderGeneratorAttributes()}
        </React.Fragment>
    )
}

export default GeneratorAttributes;