import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, withTheme} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {retrieveAccounts} from '../../../store/account-management/actions/accountActions';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {TableFooter} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import PaginatedTableActions from "../../Table/PaginatedTableActions";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
});

class AccountTable extends Component {

    componentDidMount = () => {
        this.retrieveAccounts(0, 10);
    }

    retrieveAccounts = (pageNumber, pageSize) => {
        this.props.retrieveAccounts(pageNumber, pageSize);
    }

    handleRowClick = (accountId) => {
        this.props.history.push("/account-management/accounts/" + accountId);
    }

    handlePageChange = (pageNumber, pageSize) => {
        this.retrieveAccounts(pageNumber, pageSize);
    }

    handleRowsPerPageChange = (e) => {
        this.retrieveAccounts(0, e.target.value);
    }

    calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    render() {
        const {classes} = this.props;
        return (
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Account Name</TableCell>
                        <TableCell>ISO</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(this.props.accounts && this.props.accounts.results) && this.props.accounts.results.map(account => {
                        return (
                            <TableRow className={classes.tableRow} key={account.id} onClick={() => this.handleRowClick(account.id)}>
                                <TableCell>{account.customerAccount}</TableCell>
                                <TableCell>{account.iso}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={10} count={this.props.accounts.totalCount ? this.props.accounts.totalCount : 0}
                                         rowsPerPage={this.calculateRowsPerPage(this.props.accounts.pageSize)}
                                         page={this.props.accounts.pageNumber ? this.props.accounts.pageNumber : 0} onChangePage={this.handlePageChange}
                                         onChangeRowsPerPage={this.handleRowsPerPageChange}
                                         ActionsComponent={PaginatedTableActions}/>
                    </TableRow>
                </TableFooter>
            </Table>
        )
    }

}

const mapStateToProps = state => {
    return {
        accounts: state.accounts.accounts
    };
}

const mapDispatchToProps = dispatch => {
    return {
        retrieveAccounts: (pageNumber, pageSize) => dispatch(retrieveAccounts(pageNumber, pageSize))
    };
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountTable))));