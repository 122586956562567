import React, {Component} from 'react';
import {connect} from "react-redux";

import {withStyles, withTheme} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import Button from '@material-ui/core/Button';

import {retrieveApplicationRoles} from "../../../store/user-management/actions/applicationRoleActions";
import ViewRoles from "./ViewRoles";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
});

class ViewApplicationRoles extends Component {

    componentDidMount() {
        this.props.retrieveRoles();
    }

    onRoleClick = (roleId) => {
        this.props.history.push("/user-management/application/roles/" + roleId);
    }

    renderViewRoles = () => {
    if(this.props.superUser) {
        return (<ViewRoles roles={this.props.roles} roleClickFunction={this.onRoleClick} />);
    } else {
        return (<ViewRoles roles={this.props.roles}/>);
    }
}

    render() {
        const {classes} = this.props;
        return (
          <main className={classes.layout}>
              <Typography variant="h3" align="center">Application Roles</Typography>
              {this.props.superUser &&
              <Button onClick={() => this.props.history.push("/user-management/application/roles/create")}>Create Application Role</Button>}
              {this.renderViewRoles()}
          </main>
        );
    }

}

const mapStateToProps = state => {
    return {
        chosenAccount: state.chosenAccount.account,
        roles: state.applicationRoles.roles,
        superUser: state.auth.superUser,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        retrieveRoles: () => dispatch(retrieveApplicationRoles())
    };
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ViewApplicationRoles)));