import React from 'react';
import {useSelector} from 'react-redux';

import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import GeneratorTable from './GeneratorTable';
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";
import {useHistory, useParams} from "react-router";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
}));

const MyGenerators = () => {

    const classes = useStyles();
    const history = useHistory();
    const {accountId} = useParams();
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);

    const handleCreateGenerator = () => {
        history.push('/spark-exchange/account/' + accountId + '/generators/create');
    };

    return (
        <main className={classes.layout}>
            <Typography variant="h3" align="center">My Generators</Typography>
            {hasPermissionForAccount(roles, chosenAccount.id, "ROLE_create_generator") &&
            <Grid container spacing={2}>
                <Grid item xs={2} sm={4}/>
                <Grid item xs={2} sm={4} align="center">
                    <Button onClick={handleCreateGenerator} variant="contained">Create Generator</Button>
                </Grid>
                <Grid item xs={2} sm={4}/>
            </Grid>
            }
            <GeneratorTable accountId={accountId}/>
        </main>
    );
}

export default MyGenerators;