import {
    SAVE_BUY_BID,
    RETRIEVE_BUY_BIDS,
    SEARCH_BUY_BIDS,
    RETRIEVE_BUY_BID,
    RETRIEVE_SELL_OFFER_FOR_BUY_BID, RETRIEVE_POTENTIAL_SELL_OFFERS_FOR_BUY_BID
} from '../sparkExchangeActionTypes';
import {ACCOUNT_CHANGED} from '../../actionTypes';

const initialState = {
    buyBids: [],
    buyBid: {
        includePhysicalAttributes: false,
        includeEnergyEscalation: false,
        includeCapacity: false,
        includeCapacityEscalation: false,
        includeEnvironmentalAttributes: false,
        market: '',
        buyBidType: '',
        firmType: '',
        standardProductType: ''
    },
    matchedSellOffer: {
        includePhysicalAttributes: false,
        includeEnergyEscalation: false,
        includeCapacity: false,
        includeCapacityEscalation: false,
        includeEnvironmentalAttributes: false,
        market: '',
        offerType: '',
        firmOfferType: '',
        standardProductType: ''
    },
    matchedBuyBidSellOffer: {},
};

const buyBidReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_BUY_BID:
            return saveBuyBid(state, action);
        case RETRIEVE_BUY_BIDS:
            return retrieveBuyBids(state, action);
        case SEARCH_BUY_BIDS:
            return searchBuyBids(state, action);
        case RETRIEVE_BUY_BID:
            return retrieveBuyBid(state, action);
        case RETRIEVE_SELL_OFFER_FOR_BUY_BID:
            return matchedSellOfferFound(state, action);
        case RETRIEVE_POTENTIAL_SELL_OFFERS_FOR_BUY_BID:
            return potentialMatchesFound(state, action);
        case ACCOUNT_CHANGED:
            return initialState;
        default:
            return state;
    }
};

const saveBuyBid = (state, action) => {
    return {...state, sellOffers: [...state.buyBids, action.buyBid]};
};

const retrieveBuyBids = (state, action) => {
    return {...state, buyBids: action.buyBids};
};

const searchBuyBids = (state, action) => {
    return {...state, pagedBuyBidSearchResults: action.buyBidResults}
};

const retrieveBuyBid = (state, action) => {
    return {...state, buyBid: action.buyBid};
};

const matchedSellOfferFound = (state, action) => {
    return {...state, matchedSellOffer: action.matchedBuyBidSellOffer.sellOffer, matchedBuyBidSellOffer: action.matchedBuyBidSellOffer};
};

const potentialMatchesFound = (state, action) => {
    return {...state, potentialMatches: action.potentialMatches};
};

export default buyBidReducer;