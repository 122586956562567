import React from 'react';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const StartDateSearch = (props) => {

    const search = useSelector(props.selector);
    const dispatch = useDispatch();

    const onInputChange = (date) => {
        dispatch({type: props.searchDispatch, value: date});
    }

    const onRemoveIconClick = () => {
        dispatch({type: props.searchDispatch, value: undefined});
        dispatch({type: props.removeDispatch, value: props.name})
    }

    return (
        <Grid item xs={6} sm={2}>
            <Box display="flex" flexwrap="nowrap">
                <Box>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate" onChange={onInputChange}
                                            value={search.startDate} fullWidth autoOk={true}/>
                    </MuiPickersUtilsProvider>
                </Box>
                <Box>
                    <IconButton onClick={onRemoveIconClick}>
                        <RemoveCircleOutlineIcon/>
                    </IconButton>
                </Box>
            </Box>
        </Grid>
    )
}

export default StartDateSearch;