import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import _ from 'lodash';
import moment from 'moment';
import FirmMonthlyShapeOutput from "./FirmMonthlyShapeOutput";
import UnitContingentMonthlyOutput from "../UnitContingentMonthlyOutput/UnitContingentMonthlyOutput";
import FirmStandardProductOutput from "./FirmStandardProductOutput";
import {cleanStringInput} from "../../../Utility/StringUtil";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {saveSellOffer, updateSellOffer} from "../../../store/spark-exchange/actions/sellOfferActions";
import {useHistory} from "react-router";
import {FormHelperText} from "@material-ui/core";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import {lessThanOneNotAllowed} from "../../../Utility/NumberFormatUtil";
import TwoDigitCurrency from "../../NumberFormat/TwoDigitCurrency";
import {retrieveDeliveryPoints} from "../../../store/spark-exchange/actions/sparkExchangeActions";
import {Autocomplete} from "@material-ui/lab";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {onError} from "../../../store/actions/popupActions";
import TermSheetComponent from "../TermSheet/TermSheetComponent";
import {retrieveGeneratorProfileNoError} from "../../../store/spark-exchange/actions/generatorActions";

const SellOfferComponent = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const deliveryPoints = useSelector(state => state.sparkExchange.deliveryPoints);
    const [sellOffer, setSellOffer] = useState({
        market: '',
        includePhysicalAttributes: true,
        includeEnergyEscalation: false,
        includeCapacity: true,
        includeCapacityEscalation: false,
        includeEnvironmentalAttributes: true,
        includeEnvironmentalEscalation: false,
        sellOfferType: '',
        firmOfferType: '',
        standardProductType: '',
        includedRecs: [],
        startDate: null,
        includeRecEscalation: false,
        recEscalationPercentage: '',
        recEscalationDelay: '',
        recEscalationFrequency: '',
        includeComplianceRecs: true,
    });
    const [generator, setGenerator] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [isWaitingForConfirm, setIsWaitingForConfirm] = useState(false);
    const [validation, setValidation] = useState({});
    const eightySevenSixtyProfile = useSelector(state => state.generator.eightySevenSixtyProfile);
    const {showButtons, accountId} = props;
    const [termSheet, setTermSheet] = useState('');


    useEffect(() => {
        if (props.sellOffer) {
            let localSellOffer = _.cloneDeep(props.sellOffer);
            let localGenerator = _.cloneDeep(props.generator);
            let includedRecs = [];
            if (localSellOffer.includedRecs) {
                localSellOffer.includedRecs.forEach(includedRec => {
                    let rec = {...includedRec};
                    rec.include = true;
                    includedRecs.push(rec);
                });
                if (localGenerator && localGenerator.registeredRecs) {
                    localGenerator.registeredRecs.forEach(registeredRec => {
                        let rec = localSellOffer.includedRecs.filter(r => r.state === registeredRec.state);
                        if (rec.length === 0) {
                            rec = {...registeredRec};
                            includedRecs.push({state: rec.state});
                        }
                    })
                }
                includedRecs.sort((c1, c2) => c1.state.localeCompare(c2.state));
                localSellOffer.includedRecs = includedRecs;
            }
            if (localSellOffer.startDate) {
                localSellOffer.startDate = moment(localSellOffer.startDate).toDate();
            }
            setGenerator({...localGenerator});
            setSellOffer({...localSellOffer});
        } else {
            if (props.generator.registeredRecs && props.generator.registeredRecs.length > 0) {
                props.generator.registeredRecs.forEach(registeredRec => {
                    sellOffer.includedRecs.push({state: registeredRec.state, include: true});
                });
                setSellOffer({...sellOffer});
            }
            setGenerator(_.cloneDeep(props.generator));
        }
        if(props.generator) {
            dispatch(retrieveGeneratorProfileNoError(props.generator.id, accountId, 0, 10));
        }
        if ((!eightySevenSixtyProfile || !eightySevenSixtyProfile.results || !eightySevenSixtyProfile.results.length > 0) && props.isEdit === true) {
            sellOffer.offerType = 'firm';
            setSellOffer({...sellOffer});
        }
        setIsEdit(props.isEdit !== undefined ? props.isEdit : true);
        if (!deliveryPoints || deliveryPoints.length < 1) {
            dispatch(retrieveDeliveryPoints());
        }
        // eslint-disable-next-line
    }, [props]);

    const onInputChangeHandler = (e) => {
        let localSellOffer = sellOffer;
        if (e.target.type === "number") {
            if (e.target.name) {
                if (e.target.name === "termLength") {
                    localSellOffer[e.target.name] = Number.parseInt(e.target.value);
                } else if (e.target.name === 'quantityMw' || e.target.name === 'percentage') {
                    localSellOffer = onQuantityOrPercentageChange(e, sellOffer);
                } else {
                    localSellOffer[e.target.name] = Number.parseFloat(e.target.value);
                }
            } else {
                localSellOffer[e.target.name] = e.target.value;
            }
        } else if (e.target.type === "checkbox") {
            localSellOffer[e.target.name] = !localSellOffer[e.target.name];
        } else {
            if (e.target.name === 'quantityMw' || e.target.name === 'percentage') {
                localSellOffer = onQuantityOrPercentageChange(e, sellOffer);
            } else {
                localSellOffer[e.target.name] = e.target.value;
            }
        }
        setSellOffer({...localSellOffer});
    };

    const onIncludeEnergyChangeHandler = () => {
        sellOffer.includePhysicalAttributes = !sellOffer.includePhysicalAttributes;
        if (!sellOffer.includePhysicalAttributes) {
            sellOffer.variablePaymentMwh = null;
            sellOffer.includeEnergyEscalation = false;
            sellOffer.energyEscalationPercentage = null;
            sellOffer.energyEscalationDelay = null;
            sellOffer.energyEscalationFrequency = null;
        }
        setSellOffer({...sellOffer});
    }

    const onIncludeEnergyEscalationChangeHandler = () => {
        sellOffer.includeEnergyEscalation = !sellOffer.includeEnergyEscalation;
        if (!sellOffer.includeEnergyEscalation) {
            sellOffer.energyEscalationPercentage = null;
            sellOffer.energyEscalationDelay = null;
            sellOffer.energyEscalationFrequency = null;
        }
        setSellOffer({...sellOffer});
    }

    const onIncludeCapacityChangeHandler = () => {
        sellOffer.includeCapacity = !sellOffer.includeCapacity;
        if (!sellOffer.includeCapacity) {
            sellOffer.fixedPaymentMwday = null;
            sellOffer.includeCapacityEscalation = false;
            sellOffer.capacityEscalationPercentage = null;
            sellOffer.capacityEscalationDelay = null;
            sellOffer.capacityEscalationFrequency = null;
        }
        setSellOffer({...sellOffer});
    }

    const onIncludeCapacityEscalationChangeHandler = () => {
        sellOffer.includeCapacityEscalation = !sellOffer.includeCapacityEscalation;
        if (!sellOffer.includeCapacityEscalation) {
            sellOffer.capacityEscalationPercentage = null;
            sellOffer.capacityEscalationDelay = null;
            sellOffer.capacityEscalationFrequency = null;
        }
        setSellOffer({...sellOffer});
    }

    const onIncludeEnvironmentalEscalationChangeHandler = () => {
        sellOffer.includeEnvironmentalEscalation = !sellOffer.includeEnvironmentalEscalation;
        if (!sellOffer.includeEnvironmentalEscalation) {
            sellOffer.environmentalEscalationPercentage = null;
            sellOffer.environmentalEscalationDelay = null;
            sellOffer.environmentalEscalationFrequency = null;
        }
        setSellOffer({...sellOffer});
    }

    const onIncludeEnvironmentalAttributesChangeHandler = () => {
        sellOffer.includeEnvironmentalAttributes = !sellOffer.includeEnvironmentalAttributes;
        if (!sellOffer.includeEnvironmentalAttributes) {
            sellOffer.environmentalAttributesMwh = null;
            sellOffer.includeEnvironmentalEscalation = false;
            sellOffer.environmentalEscalationPercentage = null;
            sellOffer.environmentalEscalationDelay = null;
            sellOffer.environmentalEscalationFrequency = null;
        }
        setSellOffer({...sellOffer});
    }

    const onIncludeComplianceRecsChangeHandler = () => {
        sellOffer.includeComplianceRecs = !sellOffer.includeComplianceRecs;
        sellOffer.recPayment = null;
        sellOffer.includeRecEscalation = false;
        sellOffer.recEscalationPercentage = null;
        sellOffer.recEscalationDelay = null;
        sellOffer.recEscalationFrequency = null;
        for (let includedRec of sellOffer.includedRecs) {
            includedRec.include = false;
            for (let [key, value] of Object.entries(includedRec)) {
                if (typeof value === "boolean" && key !== "include") {
                    includedRec[key] = false;
                }
            }
        }
        setSellOffer({...sellOffer});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(sellOffer);
        }
    };

    const onIncludeRecEscalationChangeHandler = () => {
        sellOffer.includeRecEscalation = !sellOffer.includeRecEscalation;
        if (!sellOffer.includeRecEscalation) {
            sellOffer.recEscalationPercentage = null;
            sellOffer.recEscalationDelay = null;
            sellOffer.recEscalationFrequency = null;
        }
        setSellOffer({...sellOffer});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(sellOffer);
        }
    }

    const onMonthlyOutputsChange = (fieldName, value) => {
        sellOffer[fieldName] = value;
        setSellOffer({...sellOffer});
    }

    const onUnitContingentOutputChange = (value) => {
        setSellOffer({...value});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(value);
        }
    }

    const onQuantityOrPercentageChange = (e, sellOffer) => {
        if (e.target.name === 'percentage') {
            sellOffer.percentage = Number.parseFloat(e.target.value);
            sellOffer.quantityMw = (generator.maximumOutput * (sellOffer.percentage / 100));
            return sellOffer;
        }
        if (e.target.name === 'quantityMw') {
            sellOffer.quantityMw = Number.parseFloat(e.target.value);
            sellOffer.percentage = ((sellOffer.quantityMw / generator.maximumOutput) * 100);
            return sellOffer;
        }
        return sellOffer;
    }

    const onSellOfferTypeChange = (e) => {
        sellOffer.offerType = e.target.value;
        if (e.target.value === "unitContingent") {
            sellOffer.firmOfferType = '';
            sellOffer.standardProductType = '';
        } else if (e.target.value === "firm") {
            sellOffer.monthlyOutputs = null;
        }
        setSellOffer({...sellOffer});
    }

    const onFirmOfferTypeChange = (e) => {
        sellOffer.firmOfferType = e.target.value;
        let monthlyOutputs = [];
        for (let i = 1; i < 13; i++) {
            monthlyOutputs.push({month: i, output: 0});
        }
        sellOffer.monthlyOutputs = monthlyOutputs;
        setSellOffer({...sellOffer});
    }

    const startDateChangeHandler = (date) => {
        sellOffer.startDate = date;
        setSellOffer({...sellOffer});
    };

    const handleDeliveryPointChange = (event, value) => {
        if (value) {
            sellOffer.deliveryPoint = value.pnodeName;
            sellOffer.pnodeId = value.pnodeId;
        } else {
            sellOffer.deliveryPoint = null;
            sellOffer.pnodeId = null;
        }
        setSellOffer({...sellOffer});
    }

    const calculatePercentage = () => {
        if (sellOffer.quantityMw && generator.maximumOutput) {
            return ((sellOffer.quantityMw / generator.maximumOutput) * 100).toFixed(2);
        }
        return '';
    }

    const onSaveSellOffer = (isPublished) => {
        let includedRecs = [];
        for (let includedRec of sellOffer.includedRecs) {
            if (includedRec.include && includedRec.recClass) {
                includedRecs.push(includedRec);
            }
        }
        if (sellOffer.includeComplianceRecs) {
            sellOffer.includedRecs = includedRecs;
        } else {
            sellOffer.includedRecs = [];
        }
        sellOffer.published = isPublished;
        if (sellOffer.id) {
            dispatch(updateSellOffer(accountId, generator.id, sellOffer.id, sellOffer));
        } else {
            dispatch(saveSellOffer(accountId, generator.id, sellOffer));
        }
        history.push("/spark-exchange/account/" + accountId + "/generator/" + generator.id);
    };

    const onConfirmSellOffer = async () => {
        if (isValid()) {
            setIsEdit(false);
            setIsWaitingForConfirm(true);
            await trackPromise(axios.post("/spark-exchange/account/" + accountId + "/generators/" + generator.id + "/sell-offers/" + sellOffer.id, JSON.stringify(sellOffer), {
                headers: {
                    'Accept': 'application/pdf'
                }, responseType: "arraybuffer"
            }).then(response => {
                setTermSheet(response.data);
            }).catch(error => {
                dispatch(onError(error.response));
            }))
        }
    }

    const onEdit = () => {
        setIsEdit(true);
        setIsWaitingForConfirm(false);
    }

    const onBack = () => {
        history.goBack()
    }

    const onRecInputChangeHandler = (e, registeredState) => {
        if (sellOffer.includedRecs.length === 0) {
            sellOffer.includedRecs.push({state: registeredState, recClass: e.target.value});
        } else {
            for (let rec of sellOffer.includedRecs) {
                if (rec.state === registeredState) {
                    rec.recClass = e.target.value;
                    if(!rec.include) {
                        rec.include = true;
                    }
                }
            }
        }
        setSellOffer({...sellOffer});
    }

    const onIncludeRecInputChangeHandler = (e) => {
        for (let includedRec of sellOffer.includedRecs) {
            if (includedRec.state === e.target.name) {
                includedRec.include = !includedRec.include;
                includedRec.recClass = '';
            }
        }
        setSellOffer({...sellOffer});
    }

    const isValid = () => {
        let isValid = true;
        if (!sellOffer.market) {
            validation.market = true;
            validation.marketMessage = "Market type must be selected";
            isValid = false;
        } else {
            validation.market = false;
            validation.marketMessage = '';
        }
        if (!sellOffer.startDate) {
            validation.startDate = true;
            validation.startDateMessage = "Start date is required";
            isValid = false;
        } else {
            if (sellOffer.startDate instanceof Date) {
                if (sellOffer.startDate.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
                    validation.startDate = true;
                    validation.startDateMessage = "Start date must be after today";
                    isValid = false;
                } else {
                    validation.startDate = false;
                    validation.startDateMessage = "";
                }
            } else {
                validation.startDate = false;
                validation.startDateMessage = "";
            }
        }
        if (!sellOffer.termLengthMonth) {
            validation.termLengthMonth = true;
            validation.termLengthMonthMessage = "Term length is required";
            isValid = false;
        } else {
            validation.termLengthMonth = false;
            validation.termLengthMonthMessage = "";
        }
        if (!sellOffer.deliveryPoint || sellOffer.deliveryPoint.length < 3) {
            validation.deliveryPoint = true;
            validation.deliveryPointMessage = "Delivery Point is required and must be at least 3 characters";
            isValid = false;
        } else {
            validation.deliveryPoint = false;
            validation.deliveryPointMessage = "";
        }
        if (sellOffer.includePhysicalAttributes && !sellOffer.variablePaymentMwh) {
            validation.variablePaymentMwh = true;
            validation.variablePaymentMwhMessage = "Energy payment is required if physical energy box is checked";
            isValid = false;
        } else {
            validation.variablePaymentMwh = false;
            validation.variablePaymentMwhMessage = "";
        }
        if (sellOffer.includeEnergyEscalation) {
            if (!sellOffer.energyEscalationPercentage) {
                validation.energyEscalationPercentage = true;
                validation.energyEscalationPercentageMessage = "Required if include energy escalation is checked";
                isValid = false;
            } else {
                validation.energyEscalationPercentage = false;
                validation.energyEscalationPercentageMessage = "";
            }
            if (!sellOffer.energyEscalationDelay) {
                validation.energyEscalationDelay = true;
                validation.energyEscalationDelayMessage = "Required if include energy escalation is checked";
                isValid = false;
            } else {
                validation.energyEscalationDelay = false;
                validation.energyEscalationDelayMessage = "";
            }
            if (!sellOffer.energyEscalationFrequency) {
                validation.energyEscalationFrequency = true;
                validation.energyEscalationFrequencyMessage = "Required if include energy escalation is checked";
                isValid = false;
            } else {
                validation.energyEscalationFrequency = false;
                validation.energyEscalationFrequencyMessage = "";
            }
        } else {
            validation.energyEscalationPercentage = false;
            validation.energyEscalationPercentageMessage = "";
            validation.energyEscalationDelay = false;
            validation.energyEscalationDelayMessage = "";
            validation.energyEscalationFrequency = false;
            validation.energyEscalationFrequencyMessage = "";
        }
        if (sellOffer.includeCapacity && !sellOffer.fixedPaymentMwday) {
            validation.fixedPaymentMwday = true;
            validation.fixedPaymentMwdayMessage = "Capacity payment is required if capacity box is checked";
            isValid = false;
        } else {
            validation.fixedPaymentMwday = false;
            validation.fixedPaymentMwdayMessage = "";
        }
        if (sellOffer.includeCapacityEscalation === true) {
            if (!sellOffer.capacityEscalationPercentage) {
                validation.capacityEscalationPercentage = true;
                validation.capacityEscalationPercentageMessage = "Required if include capacity escalation is checked";
                isValid = false;
            } else {
                validation.capacityEscalationPercentage = false;
                validation.capacityEscalationPercentageMessage = "";
            }
            if (!sellOffer.capacityEscalationDelay) {
                validation.capacityEscalationDelay = true;
                validation.capacityEscalationDelayMessage = "Required if include capacity escalation is checked";
                isValid = false;
            } else {
                validation.capacityEscalationDelay = false;
                validation.capacityEscalationDelayMessage = "";
            }
            if (!sellOffer.capacityEscalationFrequency) {
                validation.capacityEscalationFrequency = true;
                validation.capacityEscalationFrequencyMessage = "Required if include capacity escalation is checked";
                isValid = false;
            } else {
                validation.capacityEscalationFrequency = false;
                validation.capacityEscalationFrequencyMessage = "";
            }
        } else {
            validation.capacityEscalationPercentage = false;
            validation.capacityEscalationPercentageMessage = "";
            validation.capacityEscalationDelay = false;
            validation.capacityEscalationDelayMessage = "";
            validation.capacityEscalationFrequency = false;
            validation.capacityEscalationFrequencyMessage = "";
        }
        if (sellOffer.includeEnvironmentalAttributes) {
            let registeredRecs = [];
            for (let includedRec of sellOffer.includedRecs) {
                let keys = Object.keys(includedRec);
                let isValidRec = false;
                if (includedRec.include === true) {
                    keys.forEach(key => {
                        if (key !== 'state' && key !== 'include' && key !== 'id' && includedRec[key]) {
                            isValidRec = true;
                        }
                    });
                    if (isValidRec === false) {
                        registeredRecs.push(includedRec.state);
                    }
                }
            }
            if (registeredRecs.length > 0) {
                isValid = false;
            }
            validation.registeredRecs = registeredRecs;
        }
        if (sellOffer.includeEnvironmentalEscalation === true) {
            if (!sellOffer.environmentalEscalationPercentage) {
                validation.environmentalEscalationPercentage = true;
                validation.environmentalEscalationPercentageMessage = "Required if include environmental escalation is checked";
                isValid = false;
            } else {
                validation.environmentalEscalationPercentage = false;
                validation.environmentalEscalationPercentageMessage = "";
            }
            if (!sellOffer.environmentalEscalationDelay) {
                validation.environmentalEscalationDelay = true;
                validation.environmentalEscalationDelayMessage = "Required if include environmental escalation is checked";
                isValid = false;
            } else {
                validation.environmentalEscalationDelay = true;
                validation.environmentalEscalationDelayMessage = "";
            }
            if (!sellOffer.environmentalEscalationFrequency) {
                validation.environmentalEscalationFrequency = true;
                validation.environmentalEscalationFrequencyMessage = "Required if include environmental escalation is checked";
                isValid = false;
            } else {
                validation.environmentalEscalationFrequency = false;
                validation.environmentalEscalationFrequencyMessage = "";
            }
        }
        if (!sellOffer.offerType) {
            validation.offerType = true;
            validation.offerTypeMessage = "A sell offer type must be selected";
            isValid = false;
        } else {
            validation.offerType = false;
            validation.offerTypeMessage = "";
        }
        if (sellOffer.offerType === 'firm') {
            if (!sellOffer.firmOfferType) {
                validation.firmOfferType = true;
                validation.firmOfferTypeMessage = "A firm offer type must be selected";
                isValid = false;
            } else {
                validation.firmOfferType = false;
                validation.firmOfferTypeMessage = "";
            }
            if (sellOffer.firmOfferType === 'standardProduct') {
                if (!sellOffer.standardProductType) {
                    validation.standardProductType = true;
                    validation.standardProductTypeMessage = "A standard product type must be selected";
                    isValid = false;
                } else {
                    validation.standardProductType = false;
                    validation.standardProductTypeMessage = "";
                }
            }
        }
        if (sellOffer.offerType !== 'unitContingent' && !sellOffer.quantityMw) {
            validation.quantityMw = true;
            validation.quantityMwMessage = "A MW quantity is required";
            isValid = false;
        } else {
            validation.quantityMw = false;
            validation.quantityMwMessage = "";
        }
        if (sellOffer.offerType === 'unitContingent') {
            validation.unitContingentMonthlyOutputs = [];
            if (!sellOffer.unitContingentMonthlyOutputs || sellOffer.unitContingentMonthlyOutputs.length === 0) {
                for (let i = 0; i < 12; i++) {
                    validation.unitContingentMonthlyOutputs.push({month: i + 1, error: true, message: 'Target, Minimum, and Maximum MWhs must be provided'});
                }
                isValid = false;
            } else {
                let valid = true;
                for (let output of sellOffer.unitContingentMonthlyOutputs) {
                    if (!output.minMwhs || !output.targetMwhs || !output.maxMwhs) {
                        validation.unitContingentMonthlyOutputs.push({month: output.month, error: true, message: 'Target, Minimum, and Maximum MWhs must be provided'});
                        valid = false;
                    } else {
                        validation.unitContingentMonthlyOutputs.push({month: output.month, error: false, message: ''});
                    }
                }
                if (!valid) {
                    isValid = false;
                } else {
                    validation.unitContingentMonthlyOutputs = [];
                }
            }
        }
        setValidation({...validation});
        return isValid;
    }

    const renderEnvironmentalAttributesSellOffer = () => {
        if (sellOffer.includeComplianceRecs) {
            if (sellOffer.includedRecs && sellOffer.includedRecs.length > 0) {
                let displayedRecs = [];
                sellOffer.includedRecs.forEach(registeredRec => {
                    displayedRecs.push(<Grid container spacing={2} key={registeredRec.state}>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={1}>
                            <Checkbox name={registeredRec.state} checked={registeredRec.include} onChange={onIncludeRecInputChangeHandler} disabled={!isEdit}/>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <TextField value={registeredRec.state} name={registeredRec.state} fullWidth disabled={true}/>
                        </Grid>
                        <Grid item xs={1}/>
                        <Grid item xs={6} sm={6}>
                            {renderRecCheckBoxes(registeredRec)}
                        </Grid>
                    </Grid>)
                });
                return displayedRecs;
            }
        }
    }

    const displayRecCheckBox = (registeredState, type) => {
        if (generator && generator.registeredRecs) {
            for (let i = 0; i < generator.registeredRecs.length; i++) {
                if (registeredState === generator.registeredRecs[i].state) {
                    return generator.registeredRecs[i][type];
                }
            }
        }
        return false;
    }

    const isRecChecked = (registeredRec) => {
        if (registeredRec.recClass) {
            return registeredRec.recClass;
        }
        return undefined;
    }

    const renderRecCheckBoxes = (registeredRec) => {
        let recState = registeredRec.state;
        switch (recState) {
            case 'Delaware':
                return (<React.Fragment>
                    <FormControl component="fieldset">
                        <RadioGroup row name="recClass" value={cleanStringInput(isRecChecked(registeredRec))} onChange={(e) => onRecInputChangeHandler(e, recState)}>
                            {displayRecCheckBox(registeredRec.state, "solar") && <FormControlLabel control={<Radio/>} value="solar" disabled={!isEdit} label="Solar"/>}
                            {displayRecCheckBox(registeredRec.state, "eligible") && <FormControlLabel control={<Radio/>} value="eligible" disabled={!isEdit} label="Eligible"/>}
                            {displayRecCheckBox(registeredRec.state, "newEligible") && <FormControlLabel control={<Radio/>} value="newEligible" disabled={!isEdit} label="New Eligible"/>}
                        </RadioGroup>
                        {validation.registeredRecs && validation.registeredRecs.filter(r => r === recState).length >= 1 && <FormHelperText error={true}>REC type must be selected</FormHelperText>}
                    </FormControl>
                </React.Fragment>);
            case 'District of Columbia':
            case 'Maryland':
            case 'Pennsylvania':
                return (<React.Fragment>
                    <FormControl component="fieldset">
                        <RadioGroup row name="recClass" value={cleanStringInput(isRecChecked(registeredRec))} onChange={(e) => onRecInputChangeHandler(e, recState)}>
                            {displayRecCheckBox(registeredRec.state, "solar") && <FormControlLabel control={<Radio/>} value="solar" disabled={!isEdit} label="Solar"/>}
                            {displayRecCheckBox(registeredRec.state, "tierI") && <FormControlLabel control={<Radio/>} value="tierI" disabled={!isEdit} label="Tier I"/>}
                            {displayRecCheckBox(registeredRec.state, "tierII") && <FormControlLabel control={<Radio/>} value="tierII" disabled={!isEdit} label="Tier II"/>}
                        </RadioGroup>
                        {validation.registeredRecs && validation.registeredRecs.filter(r => r === recState).length >= 1 && <FormHelperText error={true}>REC type must be selected</FormHelperText>}
                    </FormControl>
                </React.Fragment>);
            case 'Illinois':
                return (<React.Fragment>
                    <FormControl component="fieldset">
                        <RadioGroup row name="recClass" value={cleanStringInput(isRecChecked(registeredRec))} onChange={(e) => onRecInputChangeHandler(e, recState)}>
                            {displayRecCheckBox(registeredRec.state, "solar") && <FormControlLabel control={<Radio/>} value="solar" disabled={!isEdit} label="Solar"/>}
                            {displayRecCheckBox(registeredRec.state, "renewable") && <FormControlLabel control={<Radio/>} value="renewable" disabled={!isEdit} label="Renewable"/>}
                            {displayRecCheckBox(registeredRec.state, "utilityOnly") && <FormControlLabel control={<Radio/>} value="utilityOnly" disabled={!isEdit} label="Utility Only"/>}
                        </RadioGroup>
                        {validation.registeredRecs && validation.registeredRecs.filter(r => r === recState).length >= 1 && <FormHelperText error={true}>REC type must be selected</FormHelperText>}
                    </FormControl>
                </React.Fragment>);
            case 'New Jersey':
                return (<React.Fragment>
                    <FormControl component="fieldset">
                        <RadioGroup row name="recClass" value={cleanStringInput(isRecChecked(registeredRec))} onChange={(e) => onRecInputChangeHandler(e, recState)}>
                            {displayRecCheckBox(registeredRec.state, "solar") && <FormControlLabel control={<Radio/>} value="solar" disabled={!isEdit} label="Solar"/>}
                            {displayRecCheckBox(registeredRec.state, "classI") && <FormControlLabel control={<Radio/>} value="classI" disabled={!isEdit} label="Class I"/>}
                            {displayRecCheckBox(registeredRec.state, "classII") && <FormControlLabel control={<Radio/>} value="classII" disabled={!isEdit} label="Class II"/>}
                            {displayRecCheckBox(registeredRec.state, "transition") && <FormControlLabel control={<Radio/>} value="transition" disabled={!isEdit} label="Transition"/>}
                        </RadioGroup>
                        {validation.registeredRecs && validation.registeredRecs.filter(r => r === recState).length >= 1 && <FormHelperText error={true}>REC type must be selected</FormHelperText>}
                    </FormControl>
                </React.Fragment>);
            case 'Ohio':
                return (<React.Fragment>
                    <FormControl component="fieldset">
                        <RadioGroup row name="recClass" value={cleanStringInput(isRecChecked(registeredRec))} onChange={(e) => onRecInputChangeHandler(e, recState)}>
                            {displayRecCheckBox(registeredRec.state, "solar") && <FormControlLabel control={<Radio/>} value="solar" disabled={!isEdit} label="Solar"/>}
                            {displayRecCheckBox(registeredRec.state, "renewable") && <FormControlLabel control={<Radio/>} value="renewable" disabled={!isEdit} label="Renewable"/>}
                        </RadioGroup>
                        {validation.registeredRecs && validation.registeredRecs.filter(r => r === recState).length >= 1 && <FormHelperText error={true}>REC type must be selected</FormHelperText>}
                    </FormControl>
                </React.Fragment>);
            case 'West Virginia':
                return (<React.Fragment>
                    <FormControl component="fieldset">
                        <RadioGroup row name="recClass" value={cleanStringInput(isRecChecked(registeredRec))} onChange={(e) => onRecInputChangeHandler(e, recState)}>
                            {displayRecCheckBox(registeredRec.state, "renewableEnergyResource") &&
                                <FormControlLabel control={<Radio/>} value="renewableEnergyResource" disabled={!isEdit} label="Renewable Energy Resource"/>}
                            {displayRecCheckBox(registeredRec.state, "alternativeEnergyResource") &&
                                <FormControlLabel control={<Radio/>} value="alternativeEnergyResource" disabled={!isEdit} label="Alternative Energy Resource"/>}
                        </RadioGroup>
                        {validation.registeredRecs && validation.registeredRecs.filter(r => r === recState).length >= 1 && <FormHelperText error={true}>REC type must be selected</FormHelperText>}
                    </FormControl>
                </React.Fragment>);
            case 'Virginia':
                return (<React.Fragment>
                    <FormControl component="fieldset">
                        <RadioGroup row name="recClass" value={cleanStringInput(isRecChecked(registeredRec))} onChange={(e) => onRecInputChangeHandler(e, recState)}>
                            {displayRecCheckBox(registeredRec.state, "renewable") && <FormControlLabel control={<Radio/>} value="renewable" disabled={!isEdit} label="Renewable"/>}
                        </RadioGroup>
                        {validation.registeredRecs && validation.registeredRecs.filter(r => r === recState).length >= 1 && <FormHelperText error={true}>REC type must be selected</FormHelperText>}
                    </FormControl>
                </React.Fragment>);
            default:
                return;
        }
    }

    const renderSellOfferType = () => {
        if (eightySevenSixtyProfile && eightySevenSixtyProfile.results && eightySevenSixtyProfile.results.length > 0) {
            return (<FormControl component="fieldset" required>
                <FormLabel>Sell Offer Type</FormLabel>
                <RadioGroup name="offerType" value={cleanStringInput(sellOffer.offerType)} onChange={onSellOfferTypeChange} row>
                    <FormControlLabel control={<Radio/>} label="Unit Contingent" value="unitContingent" disabled={!isEdit}/>
                    <FormControlLabel control={<Radio/>} label="Firm" value="firm" disabled={!isEdit}/>
                </RadioGroup>
                <FormHelperText error={validation.offerType}>{validation.offerTypeMessage}</FormHelperText>
            </FormControl>);
        }
        return (<FormControl component="fieldset" required>
            <FormLabel>Sell Offer Type</FormLabel>
            <RadioGroup name="offerType" value={cleanStringInput(sellOffer.offerType)} onChange={onSellOfferTypeChange} row>
                <FormControlLabel control={<Radio/>} label="Firm" value="firm" disabled={true}/>
            </RadioGroup>
        </FormControl>);
    }

    const renderQuantityPercentageFields = () => {
        if (sellOffer.offerType && (sellOffer.offerType === 'firm' && sellOffer.firmOfferType === 'monthlyShape')) {
            return (<React.Fragment>
                <FormControl>
                    <FormLabel>MW Quantity</FormLabel>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6}>
                            <TextField label="Quantity" name="quantityMw" value={cleanStringInput(sellOffer.quantityMw)}
                                       onChange={onInputChangeHandler}
                                       fullWidth InputProps={{
                                endAdornment: <InputAdornment position="start">MW</InputAdornment>,
                                inputComponent: GenericNumberFormat,
                                inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                            }} disabled={!isEdit} required error={validation.quantityMw} helperText={validation.quantityMwMessage}
                                       InputLabelProps={{shrink: !!sellOffer.quantityMw}}/>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField label="Percentage" name="percentage" value={calculatePercentage()}
                                       onChange={onInputChangeHandler}
                                       fullWidth InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                inputComponent: GenericNumberFormat,
                                inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                            }} disabled={!isEdit} required InputLabelProps={{shrink: !!sellOffer.quantityMw}}/>
                        </Grid>
                    </Grid>
                </FormControl>
            </React.Fragment>);
        } else if (sellOffer.offerType !== 'unitContingent') {
            return (<FormControl>
                <FormLabel>MW Quantity</FormLabel>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={12}>
                        <TextField label="Quantity" name="quantityMw" value={cleanStringInput(sellOffer.quantityMw)}
                                   onChange={onInputChangeHandler}
                                   fullWidth InputProps={{
                            endAdornment: <InputAdornment position="start">MW</InputAdornment>,
                            inputComponent: GenericNumberFormat,
                            inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                        }} disabled={!isEdit} required error={validation.quantityMw} helperText={validation.quantityMwMessage} InputLabelProps={{shrink: !!sellOffer.quantityMw}}/>
                    </Grid>
                </Grid>
            </FormControl>)
        }
    }

    const renderMonthlyOutput = () => {
        if (sellOffer.offerType === "unitContingent") {
            return (<UnitContingentMonthlyOutput inputChangeHandler={onUnitContingentOutputChange} bidOffer={sellOffer} isEdit={isEdit} validation={validation}/>);
        }
        if (sellOffer.offerType === "firm" && sellOffer.firmOfferType === "monthlyShape") {
            return (<React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={12}/>
                    <Grid item xs={6} sm={12}>
                        <Typography align="center">Start date and quantity fields must be populated for sample monthly outputs to be populated.</Typography>
                    </Grid>
                </Grid>
                <FirmMonthlyShapeOutput onInputChangeHandler={onMonthlyOutputsChange} quantity={sellOffer.quantityMw}
                                        maximumOutput={generator.maximumOutput} isEdit={isEdit}
                                        monthlyOutputs={sellOffer.firmShapeMonthlyOutputs}/>
            </React.Fragment>);
        }
        if (sellOffer.offerType === "firm" && sellOffer.firmOfferType === "standardProduct") {
            return (<React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={12}/>
                    <Grid item xs={6} sm={12}>
                        <Typography align="center">Start date, quantity, and standard product type fields must be populated for sample monthly outputs to be
                            populated.</Typography>
                    </Grid>
                </Grid>
                <FirmStandardProductOutput onInputChangeHandler={onMonthlyOutputsChange} quantity={sellOffer.quantityMw} standardProductType={sellOffer.standardProductType}
                                           startDate={sellOffer.startDate} isEdit={isEdit}
                                           monthlyOutputs={sellOffer.firmStandardProductMonthlyOutputs}/>
            </React.Fragment>);

        }
        return (<Grid item xs={6} sm={12}/>);
    }

    const renderButtons = () => {
        if (isEdit) {
            return (<React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={() => onSaveSellOffer(false)}>Save Draft</Button>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={onConfirmSellOffer}>Publish</Button>
                    </Grid>
                </Grid>
            </React.Fragment>)
        } else if (isWaitingForConfirm) {
            return (<React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={12}>
                        <Typography align="center">By clicking Submit below the sell offer will be created and will be immediately matched against buy bids. If a match is
                            found, you will be given a brief window to confirm. If no match is found the sell offer will remain active for comparison against new buy bids.
                            During
                            this time
                            you may modify your sell offer. When a buy bid is created that matches this sell offer, as a seller you will not receive a confirmation
                            window.</Typography>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={onEdit}>Edit</Button>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={() => onSaveSellOffer(true)}>Publish</Button>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                </Grid>
            </React.Fragment>);
        } else if (!sellOffer.published) {
            return (<React.Fragment>
                <Grid container spacing={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={2}/>
                        <Grid item xs={6} sm={3} align="center">
                            <Button variant="contained" onClick={onEdit}>Edit</Button>
                        </Grid>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={3} align="center">
                            <Button variant="contained" onClick={onBack}>Back</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>)
        } else {
            return (<React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={6} sm={4} align="center">
                        <Button variant="contained" onClick={onBack}>Back</Button>
                    </Grid>
                    <Grid item xs={6} sm={4}/>
                </Grid>
            </React.Fragment>)
        }
    }

    const getOptionLabel = (option) => {
        if (!option.pnodeName) {
            return "";
        }
        return option.pnodeName;
    }

    const renderSellOffer = () => {
        if (isWaitingForConfirm) {
            return (<TermSheetComponent termSheet={termSheet}/>);
        } else if (sellOffer.matched) {
            onConfirmSellOffer();
            return (<TermSheetComponent termSheet={termSheet}/>);
        }
        return renderSellOfferScreen();
    }

    const renderSellOfferScreen = () => {
        return (<React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3}/>
                <Grid item xs={6} sm={6}>
                    <FormControl component="fieldset" required error={validation.market}>
                        <FormLabel>Market</FormLabel>
                        <RadioGroup name="market" value={sellOffer.market} onChange={onInputChangeHandler} row>
                            <FormControlLabel control={<Radio/>} label="Day Ahead" value="dayAhead" disabled={!isEdit}/>
                            <FormControlLabel control={<Radio/>} label="Real Time" value="realTime" disabled={!isEdit}/>
                        </RadioGroup>
                        <FormHelperText>{validation.marketMessage}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}/>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate" onChange={startDateChangeHandler}
                                            value={sellOffer.startDate} disabled={!isEdit} fullWidth error={validation.startDate} autoOk={true} disablePast
                                            helperText={validation.startDateMessage}/>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Term Length" name="termLengthMonth" value={cleanStringInput(sellOffer.termLengthMonth)}
                               onChange={onInputChangeHandler} fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                    }} disabled={!isEdit} required error={validation.termLengthMonth} helperText={validation.termLengthMonthMessage}
                               InputLabelProps={{shrink: !!sellOffer.termLengthMonth}}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    {deliveryPoints && deliveryPoints.length > 0 &&
                        <Autocomplete options={deliveryPoints.sort((a, b) => a.pnodeSubType.localeCompare(b.pnodeSubType) || a.pnodeName.localeCompare(b.pnodeName))}
                                      value={{pnodeId: sellOffer.pnodeId, pnodeName: sellOffer.deliveryPoint}}
                                      getOptionSelected={(option, value) => value.pnodeId === option.pnodeId}
                                      getOptionLabel={(option) => getOptionLabel(option)} groupBy={(option) => option.pnodeSubType}
                                      renderInput={(params) => <TextField {...params} label="Delivery Point" error={validation.deliveryPoint}
                                                                          helperText={validation.deliveryPointMessage}/>}
                                      onChange={(event, value) => handleDeliveryPointChange(event, value)} disabled={!isEdit}/>}
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Milestone Cure Period" name="milestoneCurePeriod" value={sellOffer.milestoneCurePeriod} onChange={onInputChangeHandler}
                               fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}
                    }} disabled={!isEdit} InputLabelProps={{shrink: !!sellOffer.milestoneCurePeriod}}/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                    <FormControlLabel control={<Checkbox name="includePhysicalAttributes" checked={sellOffer.includePhysicalAttributes}
                                                         onChange={onIncludeEnergyChangeHandler} disabled={!isEdit}/>}
                                      label="Physical Energy"/>
                </Grid>
                {sellOffer.includePhysicalAttributes && <React.Fragment>
                    <Grid item xs={6} sm={3}>
                        <TextField label="Energy Payment" name="variablePaymentMwh" value={cleanStringInput(sellOffer.variablePaymentMwh)}
                                   onChange={onInputChangeHandler} type="number" fullWidth
                                   InputProps={{
                                       endAdornment: <InputAdornment position="start">$/MWh</InputAdornment>, inputComponent: TwoDigitCurrency, inputProps: {isEdit: isEdit}
                                   }}
                                   disabled={!isEdit} required error={validation.variablePaymentMwh} helperText={validation.variablePaymentMwhMessage}
                                   InputLabelProps={{shrink: !!sellOffer.variablePaymentMwh}}/>
                    </Grid>
                    <Grid item xs={6} sm={6}/>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel
                            control={<Checkbox name="includeEnergyEscalation" checked={sellOffer.includeEnergyEscalation} onChange={onIncludeEnergyEscalationChangeHandler}
                                               disabled={!isEdit}/>} label="Include Energy Escalation"/>
                    </Grid>
                    {sellOffer.includeEnergyEscalation && <React.Fragment>
                        <Grid item xs={6} sm={2}>
                            <TextField label="Percentage" name="energyEscalationPercentage"
                                       value={cleanStringInput(sellOffer.energyEscalationPercentage)}
                                       onChange={onInputChangeHandler} fullWidth
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                                       }} disabled={!isEdit} required helperText={validation.energyEscalationPercentageMessage} error={validation.energyEscalationPercentage}
                                       InputLabelProps={{shrink: !!sellOffer.energyEscalationPercentage}}/>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <TextField label="Delay" name="energyEscalationDelay"
                                       value={cleanStringInput(sellOffer.energyEscalationDelay)}
                                       onChange={onInputChangeHandler} fullWidth
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                                       }} disabled={!isEdit} required helperText={validation.energyEscalationDelayMessage} error={validation.energyEscalationDelay}
                                       InputLabelProps={{shrink: !!sellOffer.energyEscalationDelay}}/>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <TextField label="Frequency" name="energyEscalationFrequency"
                                       value={cleanStringInput(sellOffer.energyEscalationFrequency)}
                                       onChange={onInputChangeHandler} fullWidth
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                                       }} disabled={!isEdit} required helperText={validation.energyEscalationFrequencyMessage} error={validation.energyEscalationFrequency}
                                       InputLabelProps={{shrink: !!sellOffer.energyEscalationFrequency}}/>
                        </Grid>
                    </React.Fragment>}
                </React.Fragment>}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                    <FormControlLabel control={<Checkbox name="includeCapacity" checked={sellOffer.includeCapacity}
                                                         onChange={onIncludeCapacityChangeHandler} disabled={!isEdit}/>} label="Capacity"/>
                </Grid>
                {sellOffer.includeCapacity && <React.Fragment>
                    <Grid item xs={6} sm={3}>
                        <TextField label="Capacity Payment" name="fixedPaymentMwday" value={cleanStringInput(sellOffer.fixedPaymentMwday)}
                                   onChange={onInputChangeHandler}
                                   type="number" fullWidth InputProps={{
                            endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>, inputComponent: TwoDigitCurrency, inputProps: {isEdit: isEdit}
                        }} disabled={!isEdit || !sellOffer.includeCapacity} required error={validation.fixedPaymentMwday} helperText={validation.fixedPaymentMwdayMessage}
                                   InputLabelProps={{shrink: !!sellOffer.fixedPaymentMwday}}/>
                    </Grid>
                    <Grid item xs={6} sm={6}/>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel
                            control={<Checkbox name="includeCapacityEscalation" checked={sellOffer.includeCapacityEscalation} onChange={onIncludeCapacityEscalationChangeHandler}
                                               disabled={!isEdit}/>} label="Include Capacity Escalation"/>
                    </Grid>
                    {sellOffer.includeCapacityEscalation && <React.Fragment>
                        <Grid item xs={6} sm={2}>
                            <TextField label="Percentage" name="capacityEscalationPercentage"
                                       value={cleanStringInput(sellOffer.capacityEscalationPercentage)}
                                       onChange={onInputChangeHandler} fullWidth
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                                       }} disabled={!isEdit} required error={validation.capacityEscalationPercentage} helperText={validation.capacityEscalationPercentageMessage}
                                       InputLabelProps={{shrink: !!sellOffer.capacityEscalationPercentage}}/>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <TextField label="Delay" name="capacityEscalationDelay"
                                       value={cleanStringInput(sellOffer.capacityEscalationDelay)}
                                       onChange={onInputChangeHandler} fullWidth
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                                       }} disabled={!isEdit} required error={validation.capacityEscalationDelay} helperText={validation.capacityEscalationDelayMessage}
                                       InputLabelProps={{shrink: !!sellOffer.capacityEscalationDelay}}/>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <TextField label="Frequency" name="capacityEscalationFrequency"
                                       value={cleanStringInput(sellOffer.capacityEscalationFrequency)}
                                       onChange={onInputChangeHandler} fullWidth
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                                       }}
                                       disabled={!isEdit} required error={validation.capacityEscalationFrequency} helperText={validation.capacityEscalationFrequencyMessage}
                                       InputLabelProps={{shrink: !!sellOffer.capacityEscalationFrequency}}/>
                        </Grid>
                    </React.Fragment>}
                </React.Fragment>}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                    <FormControlLabel control={<Checkbox name="includeEnvironmentalAttributes" checked={sellOffer.includeEnvironmentalAttributes}
                                                         onChange={onIncludeEnvironmentalAttributesChangeHandler} disabled={!isEdit}/>} label="Environmental Attributes"/>
                </Grid>
                {sellOffer.includeEnvironmentalAttributes && <React.Fragment>
                    <Grid item xs={6} sm={3}>
                        <TextField label="Payment" name="environmentalAttributesMwh" value={cleanStringInput(sellOffer.environmentalAttributesMwh)}
                                   onChange={onInputChangeHandler} type="number" fullWidth
                                   InputProps={{endAdornment: <InputAdornment position="start">$/MWh</InputAdornment>, inputProps: {step: 0.01}}}
                                   disabled={!isEdit}/>
                    </Grid>
                    <Grid item xs={6} sm={6}/>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel
                            control={<Checkbox name="includeEnvironmentalEscalation" checked={sellOffer.includeEnvironmentalEscalation}
                                               onChange={onIncludeEnvironmentalEscalationChangeHandler}
                                               disabled={!isEdit}/>} label="Include Environmental Escalation"/>
                    </Grid>
                    {sellOffer.includeEnvironmentalEscalation && <React.Fragment>
                        <Grid item xs={6} sm={2}>
                            <TextField label="Percentage" name="environmentalEscalationPercentage"
                                       value={cleanStringInput(sellOffer.environmentalEscalationPercentage)}
                                       onChange={onInputChangeHandler} fullWidth
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                                       }} disabled={!isEdit} required error={validation.environmentalEscalationPercentage}
                                       helperText={validation.environmentalEscalationPercentageMessage}
                                       InputLabelProps={{shrink: !!sellOffer.environmentalEscalationPercentage}}/>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <TextField label="Delay" name="environmentalEscalationDelay"
                                       value={cleanStringInput(sellOffer.environmentalEscalationDelay)}
                                       onChange={onInputChangeHandler} fullWidth
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                                       }} disabled={!isEdit} required error={validation.environmentalEscalationDelay} helperText={validation.environmentalEscalationDelayMessage}
                                       InputLabelProps={{shrink: !!sellOffer.environmentalEscalationDelay}}/>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <TextField label="Frequency" name="environmentalEscalationFrequency"
                                       value={cleanStringInput(sellOffer.environmentalEscalationFrequency)}
                                       onChange={onInputChangeHandler} fullWidth
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                           inputComponent: GenericNumberFormat,
                                           inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                                       }}
                                       disabled={!isEdit} required error={validation.environmentalEscalationFrequency}
                                       helperText={validation.environmentalEscalationFrequencyMessage}
                                       InputLabelProps={{shrink: !!sellOffer.environmentalEscalationFrequency}}/>
                        </Grid>
                    </React.Fragment>}
                </React.Fragment>}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                    <FormControlLabel control={<Checkbox name="includeComplianceRecs" checked={sellOffer.includeComplianceRecs}
                                                         onChange={onIncludeComplianceRecsChangeHandler} disabled={!isEdit}/>} label="Compliance RECs"/>
                </Grid>
                {sellOffer.includeComplianceRecs && <React.Fragment>
                    <Grid item xs={6} sm={3}>
                        <TextField label="REC Payment" name="recPayment" value={cleanStringInput(sellOffer.recPayment)} onChange={onInputChangeHandler} fullWidth
                                   InputProps={{
                                       endAdornment: <InputAdornment position="start">$/MWh</InputAdornment>, inputComponent: TwoDigitCurrency, inputProps: {isEdit: isEdit}
                                   }} InputLabelProps={{shrink: !!sellOffer.recPayment}}/>
                    </Grid>
                    <Grid item xs={6} sm={6}/>
                    <Grid item xs={1} sm={1}/>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel control={<Checkbox name="includeRecEscalation" checked={sellOffer.includeRecEscalation}
                                                             onChange={onIncludeRecEscalationChangeHandler} disabled={!isEdit}/>} label="Include REC Escalation"/>
                    </Grid>
                    {sellOffer.includeRecEscalation && <React.Fragment>
                        <Grid item xs={6} sm={2}>
                            <TextField label="Percentage" name="recEscalationPercentage" value={cleanStringInput(sellOffer.recEscalationPercentage)}
                                       onChange={onInputChangeHandler} fullWidth InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                inputComponent: GenericNumberFormat,
                                inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                            }} disabled={!isEdit} required InputLabelProps={{shrink: !!sellOffer.recEscalationPercentage}}/>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <TextField label="Delay" name="recEscalationDelay" value={cleanStringInput(sellOffer.recEscalationDelay)}
                                       onChange={onInputChangeHandler} fullWidth InputProps={{
                                endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                inputComponent: GenericNumberFormat,
                                inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                            }} disabled={!isEdit} required InputLabelProps={{shrink: !!sellOffer.recEscalationDelay}}/>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <TextField label="Frequency" name="recEscalationFrequency" value={cleanStringInput(sellOffer.recEscalationFrequency)}
                                       onChange={onInputChangeHandler} fullWidth InputProps={{
                                endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                inputComponent: GenericNumberFormat,
                                inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                            }}
                                       disabled={!isEdit} required InputLabelProps={{shrink: !!sellOffer.recEscalationFrequency}}/>
                        </Grid>
                    </React.Fragment>}
                </React.Fragment>}
            </Grid>
            {renderEnvironmentalAttributesSellOffer()}
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3}/>
                <Grid item xs={6} sm={6}>
                    {renderSellOfferType()}
                </Grid>
                <Grid item xs={6} sm={3}/>
            </Grid>
            {sellOffer.offerType === "firm" && <Grid container spacing={2}>
                <Grid item xs={6} sm={3}/>
                <Grid item xs={6} sm={6}>
                    <FormControl component="fieldset">
                        <FormLabel>Firm Offer Type</FormLabel>
                        <RadioGroup name="firmOfferType" value={sellOffer.firmOfferType} onChange={onFirmOfferTypeChange} row>
                            <FormControlLabel control={<Radio/>} label="Monthly Shape" value="monthlyShape" disabled={!isEdit}/>
                            <FormControlLabel control={<Radio/>} label="Standard Product" value="standardProduct" disabled={!isEdit}/>
                        </RadioGroup>
                        <FormHelperText error={validation.firmOfferType}>{validation.firmOfferTypeMessage}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}/>
            </Grid>}
            {(sellOffer.firmOfferType && sellOffer.firmOfferType === "standardProduct") && <Grid container spacing={2}>
                <Grid item xs={6} sm={3}/>
                <Grid item xs={6} sm={6}>
                    <FormControl component="fieldset">
                        <FormLabel>Standard Product Type</FormLabel>
                        <RadioGroup name="standardProductType" value={sellOffer.standardProductType} onChange={onInputChangeHandler} row>
                            <FormControlLabel control={<Radio/>} label="On Peak" value="onPeak" disabled={!isEdit}/>
                            <FormControlLabel control={<Radio/>} label="Off Peak" value="offPeak" disabled={!isEdit}/>
                            <FormControlLabel control={<Radio/>} label="Around the Clock" value="aroundTheClock" disabled={!isEdit}/>
                            <FormControlLabel control={<Radio/>} label="7x16" value="sevenBySixteen" disabled={!isEdit}/>
                        </RadioGroup>
                        <FormHelperText error={validation.standardProductType}>{validation.standardProductTypeMessage}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}/>
            </Grid>}
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3}/>
                <Grid item xs={6} sm={6}>
                    {renderQuantityPercentageFields()}
                </Grid>
                <Grid item xs={6} sm={3}/>
            </Grid>
            <Grid container spacing={2}>
                {renderMonthlyOutput()}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}/>
            </Grid>
        </React.Fragment>);
    }

    return (<React.Fragment>
        {renderSellOffer()}
        {showButtons && <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}/>
            </Grid>
            {renderButtons()}
        </React.Fragment>}
    </React.Fragment>);
}

export default SellOfferComponent;