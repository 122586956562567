import React from 'react';
import {makeStyles, Snackbar} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {CLEAR_MATCH_ALERT} from "../../../store/spark-exchange/sparkExchangeActionTypes";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(10),
        },
    },
    matchedSnackbar: {
        background: 'blue',
        width: '100%',
    }
}));

const MatchAlert = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const {exactMatch, multipleMatches, noMatch, matchType, accountId} = useSelector(state => state.matchAlert);

    const handleClose = () => {
        dispatch({type: CLEAR_MATCH_ALERT});
    }

    const renderExactMatchString = () => {
        return ("A match for the " + matchType + " has been found. Please click here to view it.  You have a limited time to cancel this match before it is permanent.");
    }

    const renderMultipleMatchesString = () => {
        return ("Multiple matches for the " + matchType + " has been found.  Please click here to view them.");
    }

    const renderNoMatchString = () => {
        return ("No matches for the " + matchType + " has been found.  Your " + matchType + " will be stored for others to match against.");
    }

    const handleSingleMatchViewClick = () => {
            history.push("/spark-exchange/account/" + accountId + "/matches");
            handleClose();
    }

    const handleMultipleMatchViewClick = () => {
        history.push("/spark-exchange/account/" + accountId + "/matches");
        handleClose();
    }

    return (
        <div className={classes.root}>
            <Snackbar ContentProps={{classes: {root: classes.matchedSnackbar}}} anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={exactMatch} onClose={handleClose}
                      message={renderExactMatchString()} action={
                <React.Fragment>
                    <Button onClick={handleSingleMatchViewClick}>View</Button>
                    <Button onClick={handleClose}>Dismiss</Button>
                </React.Fragment>
            }/>
            <Snackbar ContentProps={{classes: {root: classes.matchedSnackbar}}} anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={multipleMatches} onClose={handleClose}
                      message={renderMultipleMatchesString()} action={
                <React.Fragment>
                    <Button onClick={handleMultipleMatchViewClick}>View</Button>
                    <Button onClick={handleClose}>Dismiss</Button>
                </React.Fragment>
            }/>
            <Snackbar ContentProps={{classes: {root: classes.matchedSnackbar}}} anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={Boolean(noMatch)} onClose={handleClose}
                      message={renderNoMatchString()} action={
                <React.Fragment>
                    <Button onClick={handleClose}>Dismiss</Button>
                </React.Fragment>
            }/>
        </div>
    )
}

export default MatchAlert;