import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import {searchBuyBids} from '../../../store/spark-exchange/actions/buyBidActions'
import {
    BUY_BID_SEARCH_ADDITIONAL_SEARCH_FIELDS,
    BUY_BID_SEARCH_CAPACITY_ESCALATION_DELAY,
    BUY_BID_SEARCH_CAPACITY_ESCALATION_FREQUENCY,
    BUY_BID_SEARCH_CAPACITY_ESCALATION_PERCENTAGE,
    BUY_BID_SEARCH_DELIVERY_POINT,
    BUY_BID_SEARCH_ENERGY_ESCALATION_DELAY,
    BUY_BID_SEARCH_ENERGY_ESCALATION_FREQUENCY,
    BUY_BID_SEARCH_ENERGY_ESCALATION_PERCENTAGE,
    BUY_BID_SEARCH_ENVIRONMENTAL_ATTRIBUTES,
    BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_DELAY,
    BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_FREQUENCY,
    BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_PERCENTAGE,
    BUY_BID_SEARCH_FIXED_PAYMENT,
    BUY_BID_SEARCH_GENERATOR_TYPE,
    BUY_BID_SEARCH_INCLUDE_CAPACITY_ESCALATION,
    BUY_BID_SEARCH_INCLUDE_ENERGY_ESCALATION,
    BUY_BID_SEARCH_INCLUDE_ENVIRONMENTAL_ESCALATION,
    BUY_BID_SEARCH_INCLUDED_RECS,
    BUY_BID_SEARCH_MARKET,
    BUY_BID_SEARCH_MILESTONE,
    BUY_BID_SEARCH_OFFER_TYPE,
    BUY_BID_SEARCH_QUANTITY_MW,
    BUY_BID_SEARCH_REMOVE_SEARCH_FIELD,
    BUY_BID_SEARCH_SELL_OFFER_TYPE,
    BUY_BID_SEARCH_START_DATE,
    BUY_BID_SEARCH_STATE,
    BUY_BID_SEARCH_TERM_LENGTH,
    BUY_BID_SEARCH_VARIABLE_PAYMENT
} from '../../../store/spark-exchange/sparkExchangeActionTypes';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import TableRow from "@material-ui/core/TableRow";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import BuyBidComponent from "./BuyBidComponent";
import Box from '@material-ui/core/Box';
import IncludeCapacityEscalationSearch from "../SearchComponents/IncludeCapacityEscalationSearch";
import DeliveryPointSearch from "../SearchComponents/DeliveryPointSearch";
import IncludeEnergyEscalationSearch from "../SearchComponents/IncludeEnergyEscalationSearch";
import IncludeEnvironmentalEscalationSearch from "../SearchComponents/IncludeEnvironmentalEscalationSearch";
import IncludedRecSearch from "../SearchComponents/IncludedRecSearch";
import MarketSearch from "../SearchComponents/MarketSearch";
import OfferTypeSearch from "../SearchComponents/OfferTypeSearch";
import SellOfferTypeSearch from "../SearchComponents/SellOfferTypeSearch";
import StartDateSearch from "../SearchComponents/StartDateSearch";
import {TableFooter, TableSortLabel} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import PaginatedTableActions from "../../Table/PaginatedTableActions";
import {Autocomplete} from "@material-ui/lab";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import {cleanStringInput} from "../../../Utility/StringUtil";
import TwoDigitCurrency from "../../NumberFormat/TwoDigitCurrency";
import NumberFormat from "react-number-format";
import {lessThanOneNotAllowed} from "../../../Utility/NumberFormatUtil";
import IncludeMilestoneSearch from "../SearchComponents/IncludeMilestoneSearch";
import GeneratorTypeSearch from "../SearchComponents/GeneratorTypeSearch";
import GeneratorStateSearch from "../SearchComponents/GeneratorStateSearch";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200, marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const SearchBuyBids = (props) => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const search = useSelector(state => state.buyBidSearch.search)
    const additionalSearchFields = useSelector(state => state.buyBidSearch.additionalSearchFields);
    const pagedBuyBids = useSelector(state => state.buyBid.pagedBuyBidSearchResults);
    const [fieldDropDown, setFieldDropDown] = useState({name: ''});
    const [rowOpen, setRowOpen] = useState([]);
    const [orderBy, setOrderBy] = useState();
    const [direction, setDirection] = useState();
    const [pageSize, setPageSize] = useState(10);

    const additionalFields = [{name: ''},
        {
            name: 'Capacity Escalation',
            search: <IncludeCapacityEscalationSearch name="Capacity Escalation" key="includeCapacityEscalation"
                                                     includeCapacityEscalationDispatch={BUY_BID_SEARCH_INCLUDE_CAPACITY_ESCALATION}
                                                     capacityEscalationPercentageDispatch={BUY_BID_SEARCH_CAPACITY_ESCALATION_PERCENTAGE}
                                                     capacityEscalationFrequencyDispatch={BUY_BID_SEARCH_CAPACITY_ESCALATION_FREQUENCY}
                                                     capacityEscalationDelayDispatch={BUY_BID_SEARCH_CAPACITY_ESCALATION_DELAY}
                                                     removeDispatch={BUY_BID_SEARCH_REMOVE_SEARCH_FIELD}
                                                     selector={state => state.buyBidSearch.search}/>
        },
        {
            name: 'Delivery Point',
            search: <DeliveryPointSearch name="Delivery Point" key="deliveryPoint"
                                         searchDispatch={BUY_BID_SEARCH_DELIVERY_POINT}
                                         removeDispatch={BUY_BID_SEARCH_REMOVE_SEARCH_FIELD}
                                         selector={state => state.buyBidSearch.search}/>
        },
        {
            name: 'Energy Escalation',
            search: <IncludeEnergyEscalationSearch name="Energy Escalation" key="includeEnergyEscalation" includeEnergyEscalationDispatch={BUY_BID_SEARCH_INCLUDE_ENERGY_ESCALATION}
                                                   energyEscalationPercentageDispatch={BUY_BID_SEARCH_ENERGY_ESCALATION_PERCENTAGE}
                                                   energyEscalationFrequencyDispatch={BUY_BID_SEARCH_ENERGY_ESCALATION_FREQUENCY}
                                                   energyEscalationDelayDispatch={BUY_BID_SEARCH_ENERGY_ESCALATION_DELAY}
                                                   removeDispatch={BUY_BID_SEARCH_REMOVE_SEARCH_FIELD}
                                                   selector={state => state.buyBidSearch.search}/>
        },
        {
            name: 'Environmental Escalation',
            search: <IncludeEnvironmentalEscalationSearch name="Environmental Escalation" key="includeEnvironmentalEscalation"
                                                          includeEnvironmentalEscalationDispatch={BUY_BID_SEARCH_INCLUDE_ENVIRONMENTAL_ESCALATION}
                                                          environmentalEscalactionPercentageDispatch={BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_PERCENTAGE}
                                                          environmentalEscalationDelayDispatch={BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_DELAY}
                                                          environmentalEscalactionFrequencyDispatch={BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_FREQUENCY}
                                                          selector={state => state.buyBidSearch.search}/>
        },
        {
            name: 'Generator State',
            search: <GeneratorStateSearch name="State" key="generatorState" searchDispatch={BUY_BID_SEARCH_STATE} removeDispatch={BUY_BID_SEARCH_REMOVE_SEARCH_FIELD}
                                          selector={state => state.buyBidSearch.search}/>
        },
        {
            name: 'Generator Type',
            search: <GeneratorTypeSearch name="Generator Type" key="generatorType"
                                         searchDispatch={BUY_BID_SEARCH_GENERATOR_TYPE}
                                         removeDispatch={BUY_BID_SEARCH_REMOVE_SEARCH_FIELD}
                                         selector={state => state.buyBidSearch.search}/>
        },
        {
            name: 'Milestones',
            search: <IncludeMilestoneSearch name="Milestones" key="includeMilestones"
                                            searchDispatch={BUY_BID_SEARCH_MILESTONE}
                                            removeDispatch={BUY_BID_SEARCH_REMOVE_SEARCH_FIELD}
                                            selector={state => state.buyBidSearch.search}/>
        },
        {
            name: 'Included RECs',
            search: <IncludedRecSearch name="Included RECs" key="includedRECs"
                                       searchDispatch={BUY_BID_SEARCH_INCLUDED_RECS}
                                       removeDispatch={BUY_BID_SEARCH_REMOVE_SEARCH_FIELD}
                                       selector={state => state.buyBidSearch.search}/>
        },
        {
            name: 'Market',
            search: <MarketSearch name="Market" key="market" searchDispatch={BUY_BID_SEARCH_MARKET}
                                  removeDispatch={BUY_BID_SEARCH_REMOVE_SEARCH_FIELD}
                                  selector={state => state.buyBidSearch.search}/>
        },
        {
            name: 'Offer Type',
            search: <OfferTypeSearch name="Offer Type" key="offerType" searchDispatch={BUY_BID_SEARCH_OFFER_TYPE}
                                     removeDispatch={BUY_BID_SEARCH_REMOVE_SEARCH_FIELD}
                                     selector={state => state.buyBidSearch.search}/>
        },
        {
            name: 'Sell Offer Type',
            search: <SellOfferTypeSearch name="Sell Offer Type" key="sellOfferType"
                                         searchDispatch={BUY_BID_SEARCH_SELL_OFFER_TYPE}
                                         removeDispatch={BUY_BID_SEARCH_REMOVE_SEARCH_FIELD}
                                         selector={state => state.buyBidSearch.search}/>
        },
        {
            name: 'Start Date',
            search: <StartDateSearch name="Start Date" key="startDate" searchDispatch={BUY_BID_SEARCH_START_DATE}
                                     removeDispatch={BUY_BID_SEARCH_REMOVE_SEARCH_FIELD}
                                     selector={state => state.buyBidSearch.search}/>
        }];

    const toggleOpenRow = (id) => {
        if (rowOpen) {
            for (let row of rowOpen) {
                if (row.id === id) {
                    row.open = !row.open;
                }
            }
            setRowOpen([...rowOpen]);
        }
    }

    const isRowOpen = (id) => {
        for (let row of rowOpen) {
            if (row.id === id) {
                return row.open;
            }
        }
        return false;
    }

    const handlePageChange = (pageNumber, pageSize) => {
        dispatch(searchBuyBids(search, pageNumber, pageSize, orderBy, direction));
    }

    const handleRowsPerPageChange = (e) => {
        setPageSize(e.target.value);
        dispatch(searchBuyBids(search, 0, e.target.value, orderBy, direction));
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    const onInputChange = (type, value) => {
        dispatch({type: type, value: value})
    }

    const doSearchBuyBids = () => {
        if (pageSize) {
            dispatch(searchBuyBids(search, 0, pageSize, orderBy, direction));
        } else {
            dispatch(searchBuyBids(search, 0, 10, orderBy, direction));
        }
    }

    const addSearchField = (searchField) => {
        if (searchField) {
            dispatch({type: BUY_BID_SEARCH_ADDITIONAL_SEARCH_FIELDS, searchField: searchField.name});
        }
        setFieldDropDown({name: ''});
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        setOrderBy(column);
        setDirection(sortDirection);
        dispatch(searchBuyBids(search, 0, pageSize, column, sortDirection));
    }

    const renderAdditionalFieldsDropDown = () => {
        return (
            <Autocomplete value={fieldDropDown}
                          renderInput={(params) => <TextField {...params} label="Additional Fields"/>}
                          options={additionalFields}
                          onChange={(event, value) => addSearchField(value)}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) => option.name === value.name}/>
        )
    }

    const renderSearchFields = () => {
        return (<Grid container spacing={2}>
                <Grid item sm={2}>
                    <TextField label="Quantity" name="quantityMw" value={cleanStringInput(search.quantityMw)}
                               onChange={(e) => onInputChange(BUY_BID_SEARCH_QUANTITY_MW, e.target.value)}
                               fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">MW</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: true}
                    }}/>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <TextField label="Term Length" name="termLengthMonth"
                               value={cleanStringInput(search.termLengthMonth)}
                               onChange={(e) => onInputChange(BUY_BID_SEARCH_TERM_LENGTH, e.target.value)}
                               type="number" fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: {
                            fixedDecimalScale: true,
                            decimalScale: 0,
                            allowNegative: false,
                            isAllowed: lessThanOneNotAllowed,
                            isEdit: true
                        }
                    }}/>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <TextField label="Energy" name="variablePaymentMwh"
                               value={cleanStringInput(search.variablePaymentMwh)} helperText="Less than or Equal"
                               onChange={(e) => onInputChange(BUY_BID_SEARCH_VARIABLE_PAYMENT, e.target.value)}
                               type="number" fullWidth
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">$/MWh</InputAdornment>,
                                   inputComponent: TwoDigitCurrency,
                                   inputProps: {isEdit: true}
                               }}/>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <TextField label="Capacity" name="fixedPaymentMwday"
                               value={cleanStringInput(search.fixedPaymentMwday)} helperText="Less than or Equal"
                               onChange={(e) => onInputChange(BUY_BID_SEARCH_FIXED_PAYMENT, e.target.value)}
                               type="number" fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>,
                        inputComponent: TwoDigitCurrency,
                        inputProps: {isEdit: true}
                    }}/>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <TextField label="Environmental" name="environmentalAttributesMwh"
                               value={cleanStringInput(search.environmentalAttributesMwh)}
                               helperText="Less than or Equal"
                               onChange={(e) => onInputChange(BUY_BID_SEARCH_ENVIRONMENTAL_ATTRIBUTES, e.target.value)}
                               type="number" fullWidth
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">$/MWh</InputAdornment>,
                                   inputComponent: TwoDigitCurrency,
                                   inputProps: {isEdit: true}
                               }}/>
                </Grid>
                <Grid item xs={6} sm={2}>
                    {renderAdditionalFieldsDropDown()}
                </Grid>
                {additionalSearchFields && additionalSearchFields.length > 0 && additionalSearchFields.map(field => {
                    return additionalFields.filter(additionalField => additionalField.name === field)[0].search;
                })}
                <Grid item xs={6} sm={2} align="center">
                    <Button variant="contained" onClick={doSearchBuyBids}>Search</Button>
                </Grid>
            </Grid>
        );
    }

    return (
        <main className={classes.layout}>
            <Typography variant="h3" align="center">Search Buy Bids</Typography>
            {renderSearchFields()}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'startDate'}
                                            direction={orderBy === 'startDate' ? direction : 'asc'}
                                            onClick={() => handleSort("startDate")}>
                                Start Date
                                {orderBy === 'startDate' ? (
                                    <span
                                        className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'quantityMw'}
                                            direction={orderBy === 'quantityMw' ? direction : 'asc'}
                                            onClick={() => handleSort('quantityMw')}>
                                Quantity (MWs)
                                {orderBy === 'quantityMw' ? (
                                    <span
                                        className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'termLengthMonth'}
                                            direction={orderBy === 'termLengthMonth' ? direction : 'asc'}
                                            onClick={() => handleSort('termLengthMonth')}>
                                Term Length (Months)
                                {orderBy === 'termLengthMonth' ? (
                                    <span
                                        className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'fixedPaymentMwday'}
                                            direction={orderBy === 'fixedPaymentMwday' ? direction : 'asc'}
                                            onClick={() => handleSort('fixedPaymentMwday')}>
                                Capacity Payment ($/MW-day)
                                {orderBy === 'fixedPaymentMwday' ? (
                                    <span
                                        className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'variablePaymentMwh'}
                                            direction={orderBy === 'variablePaymentMwh' ? direction : 'asc'}
                                            onClick={() => handleSort('variablePaymentMwh')}>
                                Energy Payment ($/MWh)
                                {orderBy === 'variablePaymentMwh' ? (
                                    <span
                                        className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'deliveryPoint'}
                                            direction={orderBy === 'deliveryPoint' ? direction : 'asc'}
                                            onClick={() => handleSort('deliveryPoint')}>
                                Delivery Point
                                {orderBy === 'deliveryPoint' ? (
                                    <span
                                        className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'includePhysicalAttributes'}
                                            direction={orderBy === 'includePhysicalAttributes' ? direction : 'asc'}
                                            onClick={() => handleSort('includePhysicalAttributes')}>
                                Include Physical Attributes
                                {orderBy === 'includePhysicalAttributes' ? (
                                    <span
                                        className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'includeEnvironmentalAttributes'}
                                            direction={orderBy === 'includeEnvironmentalAttributes' ? direction : 'asc'}
                                            onClick={() => handleSort('includeEnvironmentalAttributes')}>
                                Include Environmental Attributes
                                {orderBy === 'includeEnvironmentalAttributes' ? (
                                    <span
                                        className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                {pagedBuyBids && pagedBuyBids.results &&
                    <React.Fragment>
                        <TableBody>
                            {pagedBuyBids.results.map(buyBid => {
                                rowOpen.push({id: buyBid.id, open: false});
                                return (
                                    <React.Fragment key={buyBid.id}>
                                        <TableRow key={buyBid.id} onClick={() => toggleOpenRow(buyBid.id)}
                                                  className={classes.tableRow}>
                                            <TableCell align="center">{buyBid.startDate}</TableCell>
                                            <TableCell align="center"><NumberFormat value={buyBid.quantityMw}
                                                                                    displayType={'text'}
                                                                                    fixedDecimalScale={true}
                                                                                    decimalScale={2}/></TableCell>
                                            <TableCell align="center"><NumberFormat value={buyBid.termLengthMonth}
                                                                                    displayType={'text'}
                                                                                    fixedDecimalScale={true}
                                                                                    decimalScale={0}/></TableCell>
                                            <TableCell align="center"><NumberFormat value={buyBid.fixedPaymentMwday}
                                                                                    displayType={'text'}
                                                                                    fixedDecimalScale={true}
                                                                                    decimalScale={2}
                                                                                    prefix="$"/></TableCell>
                                            <TableCell align="center"><NumberFormat value={buyBid.variablePaymentMwh}
                                                                                    displayType={'text'}
                                                                                    fixedDecimalScale={true}
                                                                                    decimalScale={2}
                                                                                    prefix="$"/></TableCell>
                                            <TableCell align="center">{buyBid.deliveryPoint}</TableCell>
                                            <TableCell align="center">{buyBid.includePhysicalAttributes ? <CheckIcon/> :
                                                <BlockIcon/>}</TableCell>
                                            <TableCell align="center">{buyBid.includeEnvironmentalAttributes ?
                                                <CheckIcon/> : <BlockIcon/>}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={7}>
                                                <Collapse in={isRowOpen(buyBid.id)} timeout="auto" unmountOnExit>
                                                    <Box margin={1}>
                                                        <BuyBidComponent buyBid={buyBid} isEdit={false}/>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination count={pagedBuyBids.totalCount ? pagedBuyBids.totalCount : 0}
                                                 onPageChange={handlePageChange}
                                                 page={pagedBuyBids.pageNumber ? pagedBuyBids.pageNumber : 0}
                                                 rowsPerPage={calculateRowsPerPage(pagedBuyBids.pageSize)}
                                                 onRowsPerPageChange={handleRowsPerPageChange}
                                                 rowsPerPageOptions={[10, 25, 50]} colSpan={8}
                                                 ActionsComponent={PaginatedTableActions}/>
                            </TableRow>
                        </TableFooter>
                    </React.Fragment>
                }
            </Table>
        </main>
    );
}

export default SearchBuyBids;