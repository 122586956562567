import {trackPromise} from 'react-promise-tracker';

import axios from '../../../axios/AxiosInterceptors';

import {RETRIEVE_DELIVERY_YEAR_AUCTION, RETRIEVE_DELIVERY_YEAR_AUCTIONS, RETRIEVE_LDAS, RETRIEVE_ZONES} from "../capacityExchangeActionTypes";
import {SUCCESS} from "../../actionTypes";
import {onError} from "../../actions/popupActions";

export const retrieveLdasSuccess = (ldas) => {
    return {
        type: RETRIEVE_LDAS,
        ldas: ldas
    };
}

export const capacityTransferRightSuccess = (message) => {
    return {
        type: SUCCESS,
        message: message
    }
}

export const retrieveDeliveryYearAuctionsSuccess = (deliveryYearAuctions) => {
    return {
        type: RETRIEVE_DELIVERY_YEAR_AUCTIONS,
        deliveryYearAuctions: deliveryYearAuctions
    }
}

export const retrieveDeliveryYearAuctionSuccess = (deliveryYearAuction) => {
    return {
        type: RETRIEVE_DELIVERY_YEAR_AUCTION,
        deliveryYearAuction: deliveryYearAuction
    }
}

export const retrieveZonesSuccess = (deliveryYear, zones) => {
    return {
        type: RETRIEVE_ZONES,
        deliveryYear: deliveryYear,
        zones: zones
    }
}

export function saveCapacityTransferRights(deliveryYear, auction, capacityTransferRights) {
    return async (dispatch) => {
        await trackPromise(axios.post("/capacity/deliveryYear/" + deliveryYear.replace("/", "-") + "/auction/" + auction + "/transferRight", JSON.stringify(capacityTransferRights)).then(response => {
            return dispatch(capacityTransferRightSuccess("Saved Capacity Transfer Rights for " + auction + " auction for DY " + deliveryYear));
        }).catch(error => {
            return dispatch(onError(error.response));
        }));
    };
}

export function updateCapacityTransferRight(id, capacityTransferRight) {
    return async (dispatch) => {
        await trackPromise(axios.put("/capacity/transferRight/" + id, JSON.stringify(capacityTransferRight)).then(response => {
            return dispatch(capacityTransferRightSuccess("Updated Capacity Transfer Rights for " + capacityTransferRight.auction + " auction for DY " +
                capacityTransferRight.deliveryYear + " and LDA " + capacityTransferRight.lda));
        }).catch(error => {
            return dispatch(onError(error.response));
        }));
    }
}

export function deleteCapacityTransferRight(id) {
    return async (dispatch) => {
        await trackPromise(axios.delete("/capacity/transferRight/" + id).then(response => {
            return dispatch(capacityTransferRightSuccess("Deleted Capacity Transfer Rights for id " + id));
        }).catch(error => {
            return dispatch(onError(error.response));
        }));
    }
}

export function retrieveLdas() {
    return async (dispatch) => {
        await trackPromise(axios.get("/capacity/lda").then(response => {
            return dispatch(retrieveLdasSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    };
}

export function retrieveDeliveryYearAuctions() {
    return async (dispatch) => {
        await trackPromise(axios.get("/capacity/deliveryYearAuction").then(response => {
            return dispatch(retrieveDeliveryYearAuctionsSuccess(response.data));
        }).catch(error => {
            return dispatch(onError(error.response));
        }));
    };
}

export function retrieveDeliveryYearAuction(deliveryYear) {
    return async (dispatch) => {
        await trackPromise(axios.get("/capacity/deliveryYearAuction/" + deliveryYear.replace("/", "-")).then(response => {
            return dispatch(retrieveDeliveryYearAuctionSuccess(response.data));
        }).catch(error => {
            return dispatch(onError(error.response));
        }));
    };
}

export function retrieveZones(deliveryYear) {
    return async (dispatch) => {
        await trackPromise(axios.get('/capacity/zone?deliveryYear=' + deliveryYear).then(response => {
            return dispatch(retrieveZonesSuccess(deliveryYear, response.data));
        }).catch(error => {
            return dispatch(onError(error.response));
        }))
    }
}


