import {trackPromise} from 'react-promise-tracker';

import axios from '../../../axios/AxiosInterceptors';

import {
    EXACT_MATCH,
    MULTIPLE_MATCHES,
    NO_MATCH,
    RETRIEVE_BUY_BID_FOR_SELL_OFFER,
    RETRIEVE_POTENTIAL_SELL_OFFER_MATCHES_FOR_ACCOUNT,
    RETRIEVE_SELL_OFFER,
    RETRIEVE_SELL_OFFER_MATCHES_FOR_ACCOUNT,
    RETRIEVE_SELL_OFFERS,
    SEARCH_SELL_OFFERS
} from '../sparkExchangeActionTypes';
import {onError} from '../../actions/popupActions';
import {SUCCESS} from "../../actionTypes";
import {Client} from "@stomp/stompjs";
import SockJS from "sockjs-client";

export const retrieveSellOffersSuccess = (sellOffers) => {
    return {
        type: RETRIEVE_SELL_OFFERS,
        sellOffers: sellOffers
    };
};

export const retrieveSellOfferSuccess = (sellOffer) => {
    return {
        type: RETRIEVE_SELL_OFFER,
        sellOffer: sellOffer
    };
};

export const saveSellOfferSuccess = (message) => {
    return {
        type: SUCCESS,
        message: message
    }
};

export const searchSellOffersSuccess = (sellOffersResult) => {
    return {
        type: SEARCH_SELL_OFFERS,
        sellOffersResult: sellOffersResult
    }
};

export const updateSellOfferSuccess = (message) => {
    return {
        type: SUCCESS,
        message: message
    }
}

export const retrieveMatchedBuyBidSuccess = (matchedBuyBid) => {
    return {
        type: RETRIEVE_BUY_BID_FOR_SELL_OFFER,
        matchedBuyBid: matchedBuyBid
    }
}

export function saveSellOffer(accountId, generatorId, sellOffer) {
    return async (dispatch) => {
        if (sellOffer.includedRecs) {
            sellOffer.includedRecs = sellOffer.includedRecs.filter(r => r.include === true);
        }
        await trackPromise(axios.post("/spark-exchange/account/" + accountId + "/generators/" + generatorId + "/sell-offers", JSON.stringify(sellOffer)).then(response => {
            dispatch(saveSellOfferSuccess("Sell Offer saved"));
            if (sellOffer.published) {
                openSellOfferWebSocket(accountId, generatorId, response.data.id, dispatch);
            }
        }).catch(error => {
                dispatch(onError(error.response));
            }
        ));
    }
}

export function updateSellOffer(accountId, generatorId, sellOfferId, sellOffer) {
    return async (dispatch) => {
        if (sellOffer.includedRecs) {
            sellOffer.includedRecs = sellOffer.includedRecs.filter(r => r.include === true);
        }
        await trackPromise(axios.put("/spark-exchange/account/" + accountId + "/generators/" + generatorId + "/sell-offers/" + sellOfferId, JSON.stringify(sellOffer)).then(response => {
            dispatch(updateSellOfferSuccess("Sell Offer saved"));
            if (sellOffer.published) {
                openSellOfferWebSocket(accountId, generatorId, sellOfferId, dispatch);
            }
            return dispatch(retrieveSellOffers(accountId, 0, 10));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }
}

function openSellOfferWebSocket(accountId, generatorId, sellOfferId, dispatch) {
    let client = new Client();
    client.configure({
        webSocketFactory: () => {
            return new SockJS("/wpec/services/spark-exchange/match")
        }, debug: (str) => {
            console.log(str);
        }, onConnect: () => {
            client.subscribe("/spark-exchange/topic/match/account/" + accountId + "/generators/" + generatorId + "/sell-offers/" + sellOfferId, (output) => {
                let response = JSON.parse(output.body);
                if (response.exactMatchCount === 1 || response.fuzzyMatchCount === 1) {
                    dispatch({type: EXACT_MATCH, matchType: 'Sell Offer', accountId: accountId});
                } else if (response.exactMatch > 1 || response.fuzzyMatchCount > 1) {
                    dispatch({type: MULTIPLE_MATCHES, matchType: 'Sell Offer', accountId: accountId});
                } else {
                    dispatch({type: NO_MATCH, matchType: 'Sell Offer'});
                }
                client.deactivate();
            });

        }
    });
    client.activate();
}

export function retrieveSellOffersForGenerator(accountId, generatorId, pageNumber, pageSize, orderBy, direction) {
    let url = "/spark-exchange/account/" + accountId + "/generators/" + generatorId + "/sell-offers?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    if (orderBy) {
        url = url + "&orderBy=" + orderBy;
    }
    if (direction) {
        url = url + "&direction=" + direction;
    }
    return async (dispatch) => {
        await trackPromise(axios.get(url).then(response => {
            return dispatch(retrieveSellOffersSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    };
}

export function retrieveSellOffers(accountId, pageNumber, pageSize, orderBy, direction) {
    let url = "/spark-exchange/account/" + accountId + "/sell-offers?pageNumber=" + pageNumber + "&pageSize=" + pageSize
    if (orderBy) {
        url = url + "&orderBy=" + orderBy;
    }
    if (direction) {
        url = url + "&direction=" + direction;
    }
    return async (dispatch) => {
        await trackPromise(axios.get(url).then(response => {
            return dispatch(retrieveSellOffersSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    };
}

export function searchSellOffers(sellOfferSearch, pageNumber, pageSize, orderBy, direction) {
    let queryParams = '?pageNumber=' + pageNumber + '&pageSize=' + pageSize;
    let url = '/spark-exchange/sell-offers/search' + queryParams;
    if (orderBy) {
        url = url + "&orderBy=" + orderBy;
    }
    if (direction) {
        url = url + "&direction=" + direction;
    }
    return async (dispatch) => {
        await trackPromise(axios.post(url, JSON.stringify(sellOfferSearch)).then(response => {
            return dispatch(searchSellOffersSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    };
}

export function retrieveSellOffer(accountId, generatorId, sellOfferId) {
    return async (dispatch) => {
        await trackPromise(axios.get("/spark-exchange/account/" + accountId + "/generators/" + generatorId + "/sell-offers/" + sellOfferId).then(response => {
            return dispatch(retrieveSellOfferSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    };
}

export function retrieveMatchedBuyBid(accountId, generatorId, sellOfferId) {
    return async (dispatch) => {
        await trackPromise(axios.get("/spark-exchange/account/" + accountId + "/generators/" + generatorId + "/sell-offers/" + sellOfferId + "/match").then(response => {
            return dispatch(retrieveMatchedBuyBidSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    };
}

export function retrieveSellOfferMatchesForAccount(accountId) {
    return async (dispatch) => {
        await trackPromise(axios.get("/spark-exchange/account/" + accountId + "/sell-offers/match").then(response => {
            dispatch({type: RETRIEVE_SELL_OFFER_MATCHES_FOR_ACCOUNT, matches: response.data})
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }
}

export function retrievePotentialSellOfferMatchesForAccount(accountId) {
    return async (dispatch) => {
        await trackPromise(axios.get("/spark-exchange/account/" + accountId + "/sell-offers/match/potential").then(response => {
            dispatch({type: RETRIEVE_POTENTIAL_SELL_OFFER_MATCHES_FOR_ACCOUNT, potentialMatches: response.data});
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }
}