import {ACCOUNT_CHOSEN} from '../actionTypes';

const initialState = {
    account: {id: ''},
    isDefault: true
};

const chosenAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_CHOSEN:
            if (!state.isDefault && action.isDefault) {
                return state;
            }
            return {...state, ...{account: action.account, isDefault: action.isDefault}};
        default:
            return state;
    }
};

export default chosenAccountReducer;