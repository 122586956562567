import React, {Component} from 'react';
import {connect} from 'react-redux';

import {withStyles, withTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Button} from "@material-ui/core";
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";
import UserTable from "./UserTable";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
});

class ViewUsers extends Component {

    handleCreateUser = () => {
        if(this.props.superUser) {
            this.props.history.push('/user-management/superuser/users/create');
        } else {
            this.props.history.push('/user-management/account/' + this.props.chosenAccount.id + '/users/create');
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <Typography variant="h3" align="center">Users</Typography>
                {(hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, "ROLE_create_user") || this.props.superUser) &&
                <Button onClick={this.handleCreateUser}>Create User</Button>}
                <UserTable />
            </main>
        );
    }

}

const mapStateToProps = state => {
    return {
        users: state.users.users,
        chosenAccount: state.chosenAccount.account,
        roles: state.auth.roles,
        superUser: state.auth.superUser
    };
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, null)(ViewUsers)));