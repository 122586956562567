import React from 'react';
import NumberFormat from "react-number-format";
import {TextField} from "@material-ui/core";

const GenericNumberFormat = (props) => {
    return (
        <NumberFormat {...props.other} getInputRef={props.inputRef} customInput={TextField}
                      onValueChange={(values) => props.onChange({target: {name: props.name, value: values.value}})}
                      thousandSeparator={props.thousandSeparator !== undefined ? props.thousandSeparator : true} displayType="input" fixedDecimalScale={props.fixedDecimalScale}
                      decimalScale={props.decimalScale} allowNegative={props.allowNegative}
                      isAllowed={props.isAllowed} disabled={!props.isEdit} value={props.value} fullWidth/>
    )
}

export default GenericNumberFormat;