import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, withTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import {cleanStringInput} from "../../../Utility/StringUtil";
import {retrieveDeliveryYearAuctions, retrieveLdas, saveCapacityTransferRights} from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import Button from "@material-ui/core/Button";
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
});

class CreateCapacityTransferRight extends Component {

    constructor(props) {
        super(props);
        this.state = {
            capacityTransferRight: {
                auction: '',
                deliveryYear: '',
                capacityTransferRights: []
            }
        };
    }

    componentDidMount() {
        this.props.retrieveLdas();
        this.props.retrieveDeliveryYearAuction();
    }

    renderLdaSelectBox = (ldaCtr) => {
        if (this.props.ldas && this.props.ldas.length > 0) {
            return (
                <Autocomplete value={this.findLdaForLdaName(ldaCtr.lda)} options={this.props.ldas} getOptionLabel={(option) => option.ldaName}
                              renderInput={(params) => <TextField {...params} label="LDA"/>}
                              onChange={(event, value) => this.onLdaSelectChange(event, value)}/>
            );
        }
    }

    findLdaForLdaName = (ldaName) => {
        for (let lda of this.props.ldas) {
            if (lda.ldaName === ldaName) {
                return lda;
            }
        }
    }

    renderDeliveryYearSelectBox = () => {
        if (this.props.deliveryYearAuctions) {
            return (
                <Autocomplete options={[...Object.keys(this.props.deliveryYearAuctions).sort((r1, r2) => r2.localeCompare(r1))]} getOptionLabel={(option) => option}
                              renderInput={(params) => <TextField {...params} label="Delivery Year"/>}
                              onChange={(event, value) => this.onDeliveryYearSelectChange(event, value)}/>
            );
        }
    }

    renderAuctionSelectBox = () => {
        if (this.state.capacityTransferRight.deliveryYear) {
            return (
                <Autocomplete value={this.state.capacityTransferRight.auction}
                              options={['', ...this.props.deliveryYearAuctions[this.state.capacityTransferRight.deliveryYear].map(r => r.auction)]}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => <TextField {...params} label="Auction"/>}
                              onChange={(event, value) => this.onAuctionSelectChange(event, value)}/>
            );
        }
        return (
            <Autocomplete value={this.state.capacityTransferRight.auction} options={['']} getOptionLabel={(option) => ''}
                          renderInput={(params) => <TextField {...params} label="Auction"/>}/>
        );
    }

    onDeliveryYearSelectChange = (event, value) => {
        let capacityTransferRight = {...this.state.capacityTransferRight};
        if (value) {
            capacityTransferRight.deliveryYear = value;
        } else {
            capacityTransferRight.deliveryYear = '';
            capacityTransferRight.auction = '';
        }
        this.setState({capacityTransferRight: capacityTransferRight});
    }

    onAuctionSelectChange = (event, value) => {
        let capacityTransferRight = {...this.state.capacityTransferRight};
        if (value) {
            capacityTransferRight.auction = value;
        } else {
            capacityTransferRight.auction = '';
        }
        this.setState({capacityTransferRight: capacityTransferRight});
    }

    onSave = () => {
        this.props.saveCapacityTransferRights(this.state.capacityTransferRight.deliveryYear, this.state.capacityTransferRight.auction, this.state.capacityTransferRight.capacityTransferRights);
        this.props.history.push("/capacity/capacityTransferRights/view?deliveryYear=" + this.state.capacityTransferRight.deliveryYear + "&auction=" + this.state.capacityTransferRight.auction);
    }

    onCtrInputChange = (event, ldaCtr) => {
        let capacityTransferRight = {...this.state.capacityTransferRight};
        for (let ctr of capacityTransferRight.capacityTransferRights) {
            if (ctr.lda === ldaCtr.lda) {
                if (event.target.value) {
                    ctr[event.target.name] = Number.parseFloat(event.target.value)
                } else {
                    ctr[event.target.name] = event.target.value;
                }
            }
        }
        this.setState({capacityTransferRight: capacityTransferRight});
    }

    onLdaSelectChange = (event, value) => {
        let capacityTransferRight = {...this.state.capacityTransferRight};
        if (capacityTransferRight.capacityTransferRights && capacityTransferRight.capacityTransferRights.length === 0) {
            capacityTransferRight.capacityTransferRights = [{lda: value.ldaName}];
        } else {
            let index = -1;
            for (let i = 0; i < capacityTransferRight.capacityTransferRights.length; i++) {
                if (capacityTransferRight.capacityTransferRights[i].lda === value.ldaName) {
                    index = i;
                }
            }
            if (index !== -1) {
                capacityTransferRight.capacityTransferRights.splice(index, 1, {
                    lda: value.ldaName,
                    remainingCtrs: capacityTransferRight.capacityTransferRights[index].remainingCtrs
                });
            } else {
                capacityTransferRight.capacityTransferRights.splice(capacityTransferRight.capacityTransferRights.length - 1, 1, {lda: value.ldaName});
            }
        }
        this.setState({capacityTransferRight: capacityTransferRight})
    }

    addIconClick = () => {
        let capacityTransferRight = {...this.state.capacityTransferRight};
        capacityTransferRight.capacityTransferRights.push({lda: ""});
        this.setState({capacityTransferRight: capacityTransferRight});
    }

    removeIconClick = (lda) => {
        let capacityTransferRight = {...this.state.capacityTransferRight};
        let index = -1;
        for (let i = 0; i < capacityTransferRight.capacityTransferRights.length; i++) {
            if (capacityTransferRight.capacityTransferRights[i].lda === lda) {
                index = i;
            }
        }
        if (index !== -1) {
            capacityTransferRight.capacityTransferRights.splice(index, 1);
        }
        this.setState({capacityTransferRight: capacityTransferRight});
    }

    renderAddIcon = (totalCount, index) => {
        if (index === totalCount) {
            return (
                <IconButton onClick={this.addIconClick}>
                    <AddIcon/>
                </IconButton>
            )
        }
    }

    displayLdaCtrs = () => {
        let displayedLdaCtrs = [];
        let ctrs = this.state.capacityTransferRight.capacityTransferRights;
        if (!ctrs || ctrs.length === 0) {
            ctrs.push({lda: '', remainingCtrs: ''});
        }
        for (let i = 0; i < ctrs.length; i++) {
            displayedLdaCtrs.push(this.displayLdaCtr(ctrs[i], ctrs.length - 1, i));
        }
        return displayedLdaCtrs;
    }

    displayLdaCtr = (ldaCtr, totalCount, index) => {
        return (
            <Grid container spacing={2} key={ldaCtr.lda}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    {this.renderLdaSelectBox(ldaCtr)}
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Remaining CTRs" name="remainingCtrs" value={cleanStringInput(ldaCtr.remainingCtrs)}
                               onChange={(event) => this.onCtrInputChange(event, ldaCtr)} fullWidth
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWs</InputAdornment>, inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 1, allowNegative: true, isEdit: true}
                               }}/>
                </Grid>
                <Grid item xs={6} sm={2}>
                    {this.renderAddIcon(totalCount, index)}
                    <IconButton onClick={() => this.removeIconClick(ldaCtr.lda)}>
                        <RemoveIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        )
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3}>
                        {this.renderDeliveryYearSelectBox()}
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={3}>
                        {this.renderAuctionSelectBox()}
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                </Grid>
                {this.displayLdaCtrs()}
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={6} sm={2}>
                        <Button onClick={this.onSave} fullWidth>Save</Button>
                    </Grid>
                </Grid>
            </main>
        );
    }

}

const mapStateToProps = state => {
    return {
        ldas: state.capacity.ldas,
        deliveryYearAuctions: state.capacity.deliveryYearAuctions
    };
}

const mapDispatchToProps = dispatch => {
    return {
        saveCapacityTransferRights: (deliveryYear, auction, capacityTransferRights) => dispatch(saveCapacityTransferRights(deliveryYear, auction, capacityTransferRights)),
        retrieveLdas: () => dispatch(retrieveLdas()),
        retrieveDeliveryYearAuction: () => dispatch(retrieveDeliveryYearAuctions())
    };
}

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CreateCapacityTransferRight)));