import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SellOfferTable from "./SellOfferTable";
import {useParams} from "react-router";


const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const MySellOffers = () => {

    const classes = useStyles();
    const params = useParams();

        return (
            <main className={classes.layout}>
                <Typography variant="h3" align="center">Sell Offers</Typography>
                <SellOfferTable accountId={params.accountId}/>
            </main>
        );
}

export default MySellOffers;