import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {trackPromise} from "react-promise-tracker";
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SellOfferTable from '../SellOffer/SellOfferTable'
import axios from "../../../axios/AxiosInterceptors";
import {onError} from "../../../store/actions/popupActions";
import GeneratorComponent from "./GeneratorComponent";
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";
import {useParams} from "react-router";
import SellOfferComponent from "../SellOffer/SellOfferComponent";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
}));

const ViewGenerator = () => {

    const {accountId, generatorId} = useParams();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [generator, setGenerator] = useState({});
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);

    useEffect(() => {
        if (generatorId) {
            retrieveGenerator();
        } else {
            generator.accountId = accountId;
            generator.generatorType = undefined;
            setGenerator({...generator});
        }
        // eslint-disable-next-line
    }, [accountId, generatorId]);

    const retrieveGenerator = async () => {
        await trackPromise(axios.get("/spark-exchange/account/" + accountId + "/generators/" + generatorId).then(response => {
            setGenerator({...response.data});
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    };

    const onGeneratorChange = (generator) => {
        setGenerator({...generator});
    }

    return (
        <main className={classes.layout}>
            <Typography variant="h3" align="center">{generator.generatorName ? generator.generatorName : 'View Generator'}</Typography>
            <GeneratorComponent generator={generator} key={generator.id} isEdit={false} inputChangeHandler={onGeneratorChange} showButtons={true}/>
            {hasPermissionForAccount(roles, chosenAccount.id, "ROLE_get_sell_offer") &&
            <React.Fragment>
                <Typography variant="h4" align="center">Sell Offers</Typography>
                <SellOfferTable accountId={accountId} generatorId={generatorId}/>
            </React.Fragment>}
            {hasPermissionForAccount(roles, chosenAccount.id, "ROLE_create_sell_offer") &&
            <React.Fragment>
                <Typography variant="h6" align="center">Payment and Delivery</Typography>
                <SellOfferComponent accountId={accountId} generator={generator} isEdit={true} showButtons={true} key={generator.id}/>
            </React.Fragment>}
        </main>
    );
}

export default ViewGenerator;