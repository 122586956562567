import {
    SELL_OFFER_SEARCH_ADDITIONAL_SEARCH_FIELDS,
    SELL_OFFER_SEARCH_CAPACITY_ESCALATION_DELAY,
    SELL_OFFER_SEARCH_CAPACITY_ESCALATION_FREQUENCY,
    SELL_OFFER_SEARCH_CAPACITY_ESCALATION_PERCENTAGE,
    SELL_OFFER_SEARCH_DELIVERY_POINT,
    SELL_OFFER_SEARCH_ENERGY_ESCALATION_DELAY,
    SELL_OFFER_SEARCH_ENERGY_ESCALATION_FREQUENCY,
    SELL_OFFER_SEARCH_ENERGY_ESCALATION_PERCENTAGE,
    SELL_OFFER_SEARCH_ENVIRONMENTAL_ATTRIBUTES,
    SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_DELAY,
    SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_FREQUENCY,
    SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_PERCENTAGE,
    SELL_OFFER_SEARCH_FIXED_PAYMENT,
    SELL_OFFER_SEARCH_GENERATOR_TYPE,
    SELL_OFFER_SEARCH_INCLUDE_CAPACITY_ESCALATION,
    SELL_OFFER_SEARCH_INCLUDE_ENERGY_ESCALATION,
    SELL_OFFER_SEARCH_INCLUDE_ENVIRONMENTAL_ESCALATION,
    SELL_OFFER_SEARCH_INCLUDED_RECS,
    SELL_OFFER_SEARCH_MARKET,
    SELL_OFFER_SEARCH_OFFER_TYPE,
    SELL_OFFER_SEARCH_QUANTITY_MW,
    SELL_OFFER_SEARCH_REMOVE_SEARCH_FIELD,
    SELL_OFFER_SEARCH_SELL_OFFER_TYPE,
    SELL_OFFER_SEARCH_START_DATE, SELL_OFFER_SEARCH_STATE,
    SELL_OFFER_SEARCH_TERM_LENGTH,
    SELL_OFFER_SEARCH_VARIABLE_PAYMENT
} from "../sparkExchangeActionTypes";

const initialState = {
    search: {
        quantityMw: '',
        variablePaymentMwh: '',
        fixedPaymentMwday: '',
        environmentalAttributesMwh: '',
        includeCapacityEscalation: undefined,
        capacityEscalationPercentage: '',
        capacityEscalationDelay: '',
        capacityEscalationFrequency: '',
        includeEnergyEscalation: undefined,
        energyEscalationPercentage: '',
        energyEscalationDelay: '',
        energyEscalationFrequency: '',
        includeEnvironmentalEscalation: undefined,
        environmentalEscalationPercentage: '',
        environmentalEscalationDelay: '',
        environmentalEscalationFrequency: '',
        deliveryPoint: '',
        termLengthMonth: '',
        market: '',
        offerType: '',
        sellOfferType: '',
        startDate: undefined,
        includedRecs: [],
        generatorTypes: [],
        states: []
    },
    additionalSearchFields: []
}

const sellOfferSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELL_OFFER_SEARCH_QUANTITY_MW:
            return updateSearchValue("quantityMw", state, action);
        case SELL_OFFER_SEARCH_VARIABLE_PAYMENT:
            return updateSearchValue("variablePaymentMwh", state, action);
        case SELL_OFFER_SEARCH_FIXED_PAYMENT:
            return updateSearchValue("fixedPaymentMwday", state, action);
        case SELL_OFFER_SEARCH_ENVIRONMENTAL_ATTRIBUTES:
            return updateSearchValue("environmentalAttributesMwh", state, action);
        case SELL_OFFER_SEARCH_INCLUDE_CAPACITY_ESCALATION:
            return updateSearchValue("includeCapacityEscalation", state, action);
        case SELL_OFFER_SEARCH_CAPACITY_ESCALATION_PERCENTAGE:
            return updateSearchValue("capacityEscalationPercentage", state, action);
        case SELL_OFFER_SEARCH_CAPACITY_ESCALATION_DELAY:
            return updateSearchValue("capacityEscalationDelay", state, action);
        case SELL_OFFER_SEARCH_CAPACITY_ESCALATION_FREQUENCY:
            return updateSearchValue("capacityEscalationFrequency", state, action);
        case SELL_OFFER_SEARCH_INCLUDE_ENERGY_ESCALATION:
            return updateSearchValue("includeEnergyEscalation", state, action);
        case SELL_OFFER_SEARCH_ENERGY_ESCALATION_PERCENTAGE:
            return updateSearchValue("energyEscalationPercentage", state, action);
        case SELL_OFFER_SEARCH_ENERGY_ESCALATION_DELAY:
            return updateSearchValue("energyEscalationDelay", state, action);
        case SELL_OFFER_SEARCH_ENERGY_ESCALATION_FREQUENCY:
            return updateSearchValue("energyEscalationFrequency", state, action);
        case SELL_OFFER_SEARCH_INCLUDE_ENVIRONMENTAL_ESCALATION:
            return updateSearchValue("includeEnvironmentalEscalation", state, action);
        case SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_PERCENTAGE:
            return updateSearchValue("environmentalEscalationPercentage", state, action);
        case SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_DELAY:
            return updateSearchValue("environmentalEscalationDelay", state, action);
        case SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_FREQUENCY:
            return updateSearchValue("environmentalEscalationFrequency", state, action);
        case SELL_OFFER_SEARCH_DELIVERY_POINT:
            return updateSearchValue("deliveryPoint", state, action);
        case SELL_OFFER_SEARCH_TERM_LENGTH:
            return updateSearchValue("termLengthMonth", state, action);
        case SELL_OFFER_SEARCH_MARKET:
            return updateSearchValue("market", state, action);
        case SELL_OFFER_SEARCH_OFFER_TYPE:
            return updateSearchValue('offerType', state, action);
        case SELL_OFFER_SEARCH_SELL_OFFER_TYPE:
            return updateSearchValue('sellOfferType', state, action);
        case SELL_OFFER_SEARCH_START_DATE:
            return updateSearchValue('startDate', state, action);
        case SELL_OFFER_SEARCH_INCLUDED_RECS:
            return updateIncludedRecs(state, action);
        case SELL_OFFER_SEARCH_GENERATOR_TYPE:
            return updateGeneratorTypes(state, action);
        case SELL_OFFER_SEARCH_STATE:
            return updateStates(state, action);
        case SELL_OFFER_SEARCH_ADDITIONAL_SEARCH_FIELDS:
            return ({...state, additionalSearchFields: [...state.additionalSearchFields, action.searchField]})
        case SELL_OFFER_SEARCH_REMOVE_SEARCH_FIELD:
            return removeSearchField(state, action);
        default:
            return state;
    }
}

const updateIncludedRecs = (state, action) => {
    let search = {...state.search};
    let value = action.value;
    search.includedRecs = [...value];
    return {...state, search: search};
}

const updateGeneratorTypes = (state, action) => {
    let search = {...state.search};
    let value = action.value;
    search.generatorTypes = [...value];
    return {...state, search: search};
}

const updateStates = (state, action) => {
    let search = {...state.search};
    let value = action.value;
    search.states = [...value];
    return {...state, search: search};
}

const updateSearchValue = (fieldName, state, action) => {
    let search = {...state.search};
    search[fieldName] = action.value;
    return {...state, search}
}

const removeSearchField = (state, action) => {
    let searchFields = state.additionalSearchFields;
    let index = -1;
    for (let i = 0; i < searchFields.length; i++) {
        if (action.value === searchFields[i].name) {
            index = i;
        }
    }
    if (index !== -1) {
        searchFields.splice(index, 1);
    }
    return ({...state, additionalSearchFields: [...searchFields]});
}

export default sellOfferSearchReducer;