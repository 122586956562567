import React from 'react';

const PeakDaySignalAppBar = () => {

    return (
      <React.Fragment>

      </React.Fragment>
    );

}

export default PeakDaySignalAppBar;