export const LOGOUT = 'LOGOUT';
export const ACCOUNT_CHOSEN = 'ACCOUNT_CHOSEN';
export const ERROR = 'ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const ACCOUNT_CHANGED = 'ACCOUNT_CHANGE';
export const ROLES_SUCCESS = 'ROLES_SUCCESS';
export const AUTO_LOGIN_SUCCESS = 'AUTO_LOGIN_SUCCESS';
export const APPLICATION_CHOSEN = 'APPLICATION_CHOSEN';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';
export const RESPONSE_RECEIVED = 'RESPONSE_RECEIVED';
export const SUCCESS = 'SUCCESS';
export const CLEAR_SUCCESS = 'CLEAR_SUCCESS';