import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {onError} from "../../../store/actions/popupActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {TableCell} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {useDispatch} from "react-redux";
import {useParams} from "react-router";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const ViewCostAllocation = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const params = useParams();
    const [costAllocations, setCostAllocations] = useState();

    useEffect(() => {
        retrieveCostAllocations();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const retrieveCostAllocations = async () => {
        await (trackPromise(axios.get("/capacity/project/status/" + params.upgradeId + "/costAllocation").then(response => {
            setCostAllocations(response.data);
        }).catch(error => {
            dispatch(onError(error));
        })));
    }

    return (
        <main className={classes.layout}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Upgrade ID</TableCell>
                        <TableCell align="center">Zone</TableCell>
                        <TableCell align="center">Allocation Percent</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {costAllocations && costAllocations.map(costAllocation => {
                        return (
                            <TableRow key={costAllocation.id}>
                                <TableCell align="center">{costAllocation.upgradeId}</TableCell>
                                <TableCell align="center">{costAllocation.zone}</TableCell>
                                <TableCell align="center"><NumberFormat value={costAllocation.allocationPercent} displayType='text' fixedDecimalScale={true} decimalScale={2}
                                                                        suffix="%"/></TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </main>
    );

}

export default ViewCostAllocation;