import React, {useEffect, useState} from 'react';
import Plot from "react-plotly.js";
import {Grid, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import GenericNumberFormat from "../NumberFormat/GenericNumberFormat";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    minMaxPeakText: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const LoadForecastGraph = (props) => {

    const classes = useStyles();
    const {loadForecast, prevYearPeaks, currentYearPeaks, weatherData} = props;
    const [data, setData] = useState([]);
    const [shapes, setShapes] = useState([]);
    const [minMaxPeaks, setMinMaxPeaks] = useState([]);
    const [displayPrevYearMinPeak, setDisplayPrevYearMinPeak] = useState(true);
    const [displayPrevYearMaxPeak, setDisplayPrevYearMaxPeak] = useState(true);
    const [displayCurrentYearMinPeak, setDisplayCurrentYearMinPeak] = useState(true);
    const [displayCurrentYearMaxPeak, setDisplayCurrentYearMaxPeak] = useState(true);

    useEffect(() => {
        generateData();
        generatePeaks();
        // eslint-disable-next-line
    }, [props, displayPrevYearMinPeak, displayPrevYearMaxPeak, displayCurrentYearMinPeak, displayCurrentYearMaxPeak])

    const generateData = () => {
        let localData = []
        if (loadForecast && loadForecast.length > 0) {
            localData.push({
                x: loadForecast.map(r => r.hourEndingDateTime.hourEndingDate + " " + r.hourEndingDateTime.hourEnding),
                y: loadForecast.map(r => r.load), type: 'line', name: 'Load Forecast'
            })
        }
        if (weatherData) {
            let keys = Object.keys(weatherData);
            keys.forEach(key => localData.push({
                x: weatherData[key].map(r => r.hourEndingDateTime.hourEndingDate + " " + r.hourEndingDateTime.hourEnding),
                y: weatherData[key].map(r => r.temperature),
                type: 'line',
                connectgaps: true,
                name: key,
                yaxis: 'y2'
            }));
        }
        setData(localData);
    }

    const generatePeaks = () => {
        let toggles = [];
        let localShapes = [];
        if (prevYearPeaks && prevYearPeaks.length > 0) {
            prevYearPeaks.sort((r1, r2) => r2.load - r1.load);
            toggles.push(<Grid item xs={6} sm={2} key={'prevPeakMax'}><TextField value={prevYearPeaks[0].load} label="Prev Year Max Peak" helperText="Official" InputProps={{
                endAdornment: <InputAdornment position="start">MWs</InputAdornment>,
                inputComponent: GenericNumberFormat,
                inputProps: {
                    fixedDecimalScale: true,
                    decimalScale: 0,
                    thousandSeparator: true,
                    suffix: "MWs",
                    isEdit: false,
                },
            }} disabled={true} onClick={() => setDisplayPrevYearMaxPeak(!displayPrevYearMaxPeak)} InputLabelProps={{
                className: classes.minMaxPeakText,
                style: {color: '#438607'},
                shrink: true
            }}/></Grid>);
            displayPrevYearMaxPeak && localShapes.push({
                type: 'line',
                xref: 'paper',
                line: {color: '#438607', width: 1},
                x0: 0,
                y0: prevYearPeaks[0].load,
                x1: 1,
                y1: prevYearPeaks[0].load
            })
            if (prevYearPeaks.length > 1) {
                toggles.push(<Grid item xs={6} sm={2} key={'prevPeakMin'}><TextField value={prevYearPeaks[prevYearPeaks.length - 1].load} label="Prev Year Min Peak"
                                                                                     helperText="Official" InputProps={{
                    endAdornment: <InputAdornment position="start">MWs</InputAdornment>,
                    inputComponent: GenericNumberFormat,
                    inputProps: {
                        fixedDecimalScale: true,
                        decimalScale: 0,
                        thousandSeparator: true,
                        suffix: "MWs",
                        isEdit: false,
                    },
                }} disabled={true} onClick={() => setDisplayPrevYearMinPeak(!displayPrevYearMinPeak)} InputLabelProps={{
                    className: classes.minMaxPeakText,
                    style: {color: '#4d6f2f'},
                    shrink: true
                }}/></Grid>);
                displayPrevYearMinPeak && localShapes.push({
                    type: 'line',
                    xref: 'paper',
                    line: {color: '#4d6f2f', width: 1},
                    x0: 0,
                    y0: prevYearPeaks[prevYearPeaks.length - 1].load,
                    x1: 1,
                    y1: prevYearPeaks[prevYearPeaks.length - 1].load
                });
            }
        }
        if (currentYearPeaks && currentYearPeaks.length > 0) {
            currentYearPeaks.sort((r1, r2) => r2.load - r1.load);
            toggles.push(<Grid item xs={6} sm={2} key={'currentPeakMax'}><TextField value={currentYearPeaks[0].load} label="Current Year Max Peak" helperText="Tentative"
                                                                                    InputProps={{
                                                                                        endAdornment: <InputAdornment position="start">MWs</InputAdornment>,
                                                                                        inputComponent: GenericNumberFormat,
                                                                                        inputProps: {
                                                                                            fixedDecimalScale: true,
                                                                                            decimalScale: 0,
                                                                                            thousandSeparator: true,
                                                                                            suffix: "MWs",
                                                                                            isEdit: false,
                                                                                        },
                                                                                    }} disabled={true} onClick={() => setDisplayCurrentYearMaxPeak(!displayCurrentYearMaxPeak)}
                                                                                    InputLabelProps={{
                                                                                        className: classes.minMaxPeakText,
                                                                                        style: {color: '#9e091f'},
                                                                                        shrink: true
                                                                                    }}/></Grid>);
            displayCurrentYearMaxPeak && localShapes.push({
                type: 'line',
                xref: 'paper',
                line: {color: '#9e091f', width: 1},
                x0: 0,
                y0: currentYearPeaks[0].load,
                x1: 1,
                y1: currentYearPeaks[0].load
            })
            if (currentYearPeaks.length > 1) {
                toggles.push(<Grid item xs={6} sm={2} key={'currentPeakMin'}><TextField value={currentYearPeaks[currentYearPeaks.length - 1].load} label="Current Year Min Peak"
                                                                                        helperText="Tentative"
                                                                                        InputProps={{
                                                                                            endAdornment: <InputAdornment position="start">MWs</InputAdornment>,
                                                                                            inputComponent: GenericNumberFormat,
                                                                                            inputProps: {
                                                                                                fixedDecimalScale: true,
                                                                                                decimalScale: 0,
                                                                                                thousandSeparator: true,
                                                                                                suffix: "MWs",
                                                                                                isEdit: false,
                                                                                            },
                                                                                        }} disabled={true} onClick={() => setDisplayCurrentYearMinPeak(!displayCurrentYearMinPeak)}
                                                                                        InputLabelProps={{
                                                                                            className: classes.minMaxPeakText,
                                                                                            style: {color: '#62262f'},
                                                                                            shrink: true
                                                                                        }}/></Grid>);
                displayCurrentYearMinPeak && localShapes.push({
                    type: 'line',
                    xref: 'paper',
                    x0: 0,
                    line: {color: '#62262f', width: 1},
                    y0: currentYearPeaks[currentYearPeaks.length - 1].load,
                    x1: 1,
                    y1: currentYearPeaks[currentYearPeaks.length - 1].load
                })
            }
        }
        setMinMaxPeaks(toggles);
        setShapes(localShapes);
    }

    return (
        <React.Fragment>
            {data && data.length > 0 &&
            <React.Fragment>
                <Plot data={data}
                      layout={{
                          width: 1200,
                          height: 500,
                          title: 'Load Forecast',
                          showlegend: true,
                          xaxis: {title: {text: 'Date (Hour Ending)'}},
                          yaxis: {title: {text: 'Load (MWs)'}},
                          yaxis2: {title: {text: 'Degrees (F)'}, side: 'right', overlaying: "y"},
                          legend: {orientation: "h"},
                          shapes: shapes
                      }} config={{displaylogo: false}}/>
                <Grid container spacing={2}><Grid item xs={6} sm={1}/>{minMaxPeaks}</Grid>
            </React.Fragment>
            }
        </React.Fragment>
    )

}

export default LoadForecastGraph;