import React, {Component} from 'react';
import {withStyles, withTheme} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {trackPromise} from 'react-promise-tracker';

import axios from '../../../axios/AxiosInterceptors';
import {onError} from '../../../store/actions/popupActions';
import {updateRole} from '../../../store/user-management/actions/accountRoleActions';
import ViewRole from "./ViewRole";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
});

const initialState = {
    accountRole: undefined
};

class ViewAccountRole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accountRole: initialState.accountRole
        };
    }

    async componentDidMount() {
        await trackPromise(axios.get("/auth/account/" + this.props.match.params.accountId + "/roles/" + this.props.match.params.roleId).then(response => {
            this.setState({accountRole: response.data});
        }).catch((dispatch, error) => {
            dispatch(onError(error.response));
        }));
    }

    handleSave = (role) => {
        role.accountId = this.props.match.params.accountId;
        this.props.updateRole(role.accountId, role);
        this.props.history.goBack();
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                {this.state.accountRole &&
                <React.Fragment>
                    <ViewRole role={this.state.accountRole} onSaveFunction={this.handleSave}/>
                </React.Fragment>
                }
            </main>
        );
    }

}

const mapStateToProps = state => {
    return {
        chosenAccount: state.chosenAccount.account
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateRole: (accountId, role) => dispatch(updateRole(accountId, role)),
    };
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ViewAccountRole)));