import {RETRIEVE_LDAS, RETRIEVE_DELIVERY_YEAR_AUCTIONS, RETRIEVE_DELIVERY_YEAR_AUCTION, RETRIEVE_ZONES} from "../capacityExchangeActionTypes";

const initialState = {
    ldas: undefined,
    deliveryYearAuctions: undefined,
    deliveryYearAuction: undefined,
    zones: undefined
}

const capacityExchangeReducer = (state = initialState, action) => {
    switch (action.type) {
        case RETRIEVE_LDAS:
            return retrieveLdas(state, action);
        case RETRIEVE_DELIVERY_YEAR_AUCTIONS:
            return retrieveDeliveryYearAuctions(state, action);
        case RETRIEVE_DELIVERY_YEAR_AUCTION:
            return retrieveDeliveryYearAuction(state, action);
        case RETRIEVE_ZONES:
            return retrieveZones(state, action);
        default:
            return state;
    }
}

const retrieveLdas = (state, action) => {
    return {...state, ldas: action.ldas};
}

const retrieveDeliveryYearAuctions = (state, action) => {
    return {...state, deliveryYearAuctions: action.deliveryYearAuctions}
}

const retrieveDeliveryYearAuction = (state, action) => {
    return {...state, deliveryYearAuction: action.deliveryYearAuction};
}

const retrieveZones = (state, action) => {
    let zones = new Map();
    zones.set(action.deliveryYear, action.zones);
    return {...state, zones: zones};
}

export default capacityExchangeReducer;