export const hasPermission = (servicePermissions, permissionName) => {
    let servicePermission = servicePermissions.filter(servicePermission => servicePermission.permissionName === permissionName);
    if (servicePermission.length >= 1) {
        return servicePermission[0].id;
    }
};

export const hasPermissionForAccount = (roles, accountId, permissionName) => {
    for(let role of roles) {
        if(role.accountId === accountId) {
            if(hasPermission(role.servicePermissions, permissionName)) {
                return true;
            }
        }
    }
    return false;
}

export const findServicePermissionsForCurrentAccount = (roles, currentAccountId) => {
    if (roles.length === 1 && roles.accountId === currentAccountId) {
        return roles;
    }
    let accountRoles = roles.filter(role => role.accountId === currentAccountId);
    if (accountRoles.length >= 1) {
        let servicePermissions = [];
        servicePermissions = servicePermissions.concat(accountRoles.flatMap(ar => ar.servicePermissions));
        return servicePermissions;
    }
};

export const hasApplicationAccessForCurrentAccount = (roles, currentAccountId, applicationName) => {
    for(let role of roles) {
        if(role.accountId === currentAccountId) {
            for(let servicePermission of role.servicePermissions) {
                if(servicePermission.serviceName === applicationName) {
                    return true;
                }
            }
        }
    }
    return false;
}

export const isSuperUser = (user) => {
    return user.superUser;
}