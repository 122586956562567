import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {Checkbox, FormControlLabel, TextField} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import {cleanStringInput} from "../../../Utility/StringUtil";
import {lessThanOneNotAllowed} from "../../../Utility/NumberFormatUtil";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const IncludeEnvironmentalEscalationSearch = (props) => {

    const dispatch = useDispatch();
    const search = useSelector(props.selector);

    const onIncludeEnvironmentalEscalationChangeHandler = (e) => {
        dispatch({type: props.includeEnvironmentalEscalationDispatch, value: !search.includeEnvironmentalEscalation});
    }

    const onInputChangeHandler = (e) => {
        if(e.target.name === 'environmentalEscalationPercentage') {
            dispatch({type: props.environmentalEscalactionPercentageDispatch, value: e.target.value});
        }else if(e.target.name === 'environmentalEscalationDelay') {
            dispatch({type: props.environmentalEscalationDelayDispatch, value: e.target.value});
        }else if(e.target.name === 'environmentalEscalationFrequency') {
            dispatch({type: props.environmentalEscalactionFrequencyDispatch, value: e.target.value});
        }
    }

    const onRemoveIconClick = () => {
        dispatch({type: props.includeEnvironmentalEscalationDispatch, value: undefined});
        dispatch({type: props.environmentalEscalactionPercentageDispatch, value: ''});
        dispatch({type: props.environmentalEscalationDelayDispatch, value: ''});
        dispatch({type: props.environmentalEscalactionFrequencyDispatch, value: ''});
        dispatch({type: props.removeDispatch, value: props.name})
    }

    return (
        <Grid item xs={6} sm={8} key="includeEnvironmentalEscalation">
            <Box display="flex" flexWrap="nowrap">
                <Box>
                    <FormControlLabel
                        control={<Checkbox name="includeEnvironmentalEscalation" value={search.includeEnvironmentalEscalation} onChange={onIncludeEnvironmentalEscalationChangeHandler}/>}
                        label="Include Environmental Escalation"/>
                </Box>
                <Box mr={2}>
                    <TextField label="Percentage" name="environmentalEscalationPercentage" value={cleanStringInput(search.environmentalEscalationPercentage)} onChange={onInputChangeHandler}
                               type="number" fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: true}
                    }} helperText="Less than or Equal"/>
                </Box>
                <Box mr={2}>
                    <TextField label="Delay" name="environmentalEscalationDelay" value={cleanStringInput(search.environmentalEscalationDelay)} onChange={onInputChangeHandler}
                               helperText="Greater than or Equal"
                               type="number" fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: true}
                    }}/>
                </Box>
                <Box mr={2}>
                    <TextField label="Frequency" name="environmentalEscalationFrequency" value={cleanStringInput(search.environmentalEscalationFrequency)} onChange={onInputChangeHandler}
                               type="number" fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: true}
                    }} helperText="Greater than or Equal"/>
                </Box>
                <Box>
                    <IconButton onClick={onRemoveIconClick}>
                        <RemoveCircleOutlineIcon/>
                    </IconButton>
                </Box>
            </Box>
        </Grid>
    )
}

export default IncludeEnvironmentalEscalationSearch;