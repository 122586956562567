import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import Table from "@material-ui/core/Table";
import Box from '@material-ui/core/Box';
import {searchSellOffers} from '../../../store/spark-exchange/actions/sellOfferActions';
import SellOfferComponent from "./SellOfferComponent";
import {Autocomplete} from "@material-ui/lab";
import IncludeCapacityEscalationSearch from '../SearchComponents/IncludeCapacityEscalationSearch';
import DeliveryPointSearch from "../SearchComponents/DeliveryPointSearch";
import IncludeEnergyEscalationSearch from "../SearchComponents/IncludeEnergyEscalationSearch";
import MarketSearch from "../SearchComponents/MarketSearch";
import OfferTypeSearch from "../SearchComponents/OfferTypeSearch";
import SellOfferTypeSearch from "../SearchComponents/SellOfferTypeSearch";
import StartDateSearch from "../SearchComponents/StartDateSearch";
import IncludedRecSearch from "../SearchComponents/IncludedRecSearch";
import GeneratorTypeSearch from "../SearchComponents/GeneratorTypeSearch";
import {Dialog, DialogActions, DialogContent, DialogTitle, TableFooter, TableSortLabel} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import PaginatedTableActions from "../../Table/PaginatedTableActions";
import {
    SELL_OFFER_SEARCH_ADDITIONAL_SEARCH_FIELDS,
    SELL_OFFER_SEARCH_CAPACITY_ESCALATION_DELAY,
    SELL_OFFER_SEARCH_CAPACITY_ESCALATION_FREQUENCY,
    SELL_OFFER_SEARCH_CAPACITY_ESCALATION_PERCENTAGE,
    SELL_OFFER_SEARCH_DELIVERY_POINT,
    SELL_OFFER_SEARCH_ENERGY_ESCALATION_DELAY,
    SELL_OFFER_SEARCH_ENERGY_ESCALATION_FREQUENCY,
    SELL_OFFER_SEARCH_ENERGY_ESCALATION_PERCENTAGE,
    SELL_OFFER_SEARCH_ENVIRONMENTAL_ATTRIBUTES,
    SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_DELAY,
    SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_FREQUENCY,
    SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_PERCENTAGE,
    SELL_OFFER_SEARCH_FIXED_PAYMENT,
    SELL_OFFER_SEARCH_GENERATOR_TYPE,
    SELL_OFFER_SEARCH_INCLUDE_CAPACITY_ESCALATION,
    SELL_OFFER_SEARCH_INCLUDE_ENERGY_ESCALATION,
    SELL_OFFER_SEARCH_INCLUDE_ENVIRONMENTAL_ESCALATION,
    SELL_OFFER_SEARCH_INCLUDED_RECS,
    SELL_OFFER_SEARCH_MARKET,
    SELL_OFFER_SEARCH_OFFER_TYPE,
    SELL_OFFER_SEARCH_QUANTITY_MW,
    SELL_OFFER_SEARCH_REMOVE_SEARCH_FIELD,
    SELL_OFFER_SEARCH_SELL_OFFER_TYPE,
    SELL_OFFER_SEARCH_START_DATE,
    SELL_OFFER_SEARCH_STATE,
    SELL_OFFER_SEARCH_TERM_LENGTH,
    SELL_OFFER_SEARCH_VARIABLE_PAYMENT
} from '../../../store/spark-exchange/sparkExchangeActionTypes';
import {createBuyBidForSellOffer} from "../../../store/spark-exchange/actions/buyBidActions";
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";
import DialogContentText from "@material-ui/core/DialogContentText";
import {cleanStringInput} from "../../../Utility/StringUtil";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import {lessThanOneNotAllowed} from "../../../Utility/NumberFormatUtil";
import TwoDigitCurrency from "../../NumberFormat/TwoDigitCurrency";
import NumberFormat from "react-number-format";
import IncludeEnvironmentalEscalationSearch from "../SearchComponents/IncludeEnvironmentalEscalationSearch";
import GeneratorStateSearch from "../SearchComponents/GeneratorStateSearch";


const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const SearchSellOffers = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const pagedSellOffers = useSelector(state => state.sellOffer.pagedSellOffersSearchResult);
    const additionalSearchFields = useSelector(state => state.sellOfferSearch.additionalSearchFields);
    const search = useSelector(state => state.sellOfferSearch.search);
    const roles = useSelector(state => state.auth.roles);
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const [fieldDropDown, setFieldDropDown] = useState({name: ''});
    const [rowOpen, setRowOpen] = useState([]);
    const [orderBy, setOrderBy] = useState();
    const [direction, setDirection] = useState();
    const [pageSize, setPageSize] = useState(10);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [selectedSellOffer, setSelectedSellOffer] = useState(undefined);

    const additionalFields = [{name: ''},
        {
            name: 'Capacity Escalation',
            search: <IncludeCapacityEscalationSearch name="Capacity Escalation" key="includeCapacityEscalation"
                                                     includeCapacityEscalationDispatch={SELL_OFFER_SEARCH_INCLUDE_CAPACITY_ESCALATION}
                                                     capacityEscalationPercentageDispatch={SELL_OFFER_SEARCH_CAPACITY_ESCALATION_PERCENTAGE}
                                                     capacityEscalationFrequencyDispatch={SELL_OFFER_SEARCH_CAPACITY_ESCALATION_FREQUENCY}
                                                     capacityEscalationDelayDispatch={SELL_OFFER_SEARCH_CAPACITY_ESCALATION_DELAY}
                                                     removeDispatch={SELL_OFFER_SEARCH_REMOVE_SEARCH_FIELD} selector={state => state.sellOfferSearch.search}/>
        },
        {
            name: 'Delivery Point',
            search: <DeliveryPointSearch name="Delivery Point" key="deliveryPoint" searchDispatch={SELL_OFFER_SEARCH_DELIVERY_POINT}
                                         removeDispatch={SELL_OFFER_SEARCH_REMOVE_SEARCH_FIELD} selector={state => state.sellOfferSearch.search}/>
        },
        {
            name: 'Energy Escalation', search: <IncludeEnergyEscalationSearch name="Energy Escalation" key="includeEnergyEscalation"
                                                                              includeEnergyEscalationDispatch={SELL_OFFER_SEARCH_INCLUDE_ENERGY_ESCALATION}
                                                                              energyEscalationPercentageDispatch={SELL_OFFER_SEARCH_ENERGY_ESCALATION_PERCENTAGE}
                                                                              energyEscalationFrequencyDispatch={SELL_OFFER_SEARCH_ENERGY_ESCALATION_FREQUENCY}
                                                                              energyEscalationDelayDispatch={SELL_OFFER_SEARCH_ENERGY_ESCALATION_DELAY}
                                                                              removeDispatch={SELL_OFFER_SEARCH_REMOVE_SEARCH_FIELD}
                                                                              selector={state => state.sellOfferSearch.search}/>
        },
        {
            name: 'Environmental Escalation',
            search: <IncludeEnvironmentalEscalationSearch name="Environmental Escalation" key="includeEnvironmentalEscalation"
                                                          includeEnvironmentalEscalationDispatch={SELL_OFFER_SEARCH_INCLUDE_ENVIRONMENTAL_ESCALATION}
                                                          environmentalEscalactionPercentageDispatch={SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_PERCENTAGE}
                                                          environmentalEscalationDelayDispatch={SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_DELAY}
                                                          environmentalEscalactionFrequencyDispatch={SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_FREQUENCY}
                                                          selector={state => state.sellOfferSearch.search}/>
        },
        {
            name: 'Generator State',
            search: <GeneratorStateSearch name="State" key="generatorState" searchDispatch={SELL_OFFER_SEARCH_STATE} removeDispatch={SELL_OFFER_SEARCH_REMOVE_SEARCH_FIELD}
                                          selector={state => state.sellOfferSearch.search}/>
        },
        {
            name: 'Generator Types',
            search: <GeneratorTypeSearch name="Generator Types" key="generatorTypes" searchDispatch={SELL_OFFER_SEARCH_GENERATOR_TYPE}
                                         removeDispatch={SELL_OFFER_SEARCH_REMOVE_SEARCH_FIELD} selector={state => state.sellOfferSearch.search}/>
        },
        {
            name: 'Included RECs',
            search: <IncludedRecSearch name="Included RECs" key="includedRECs" searchDispatch={SELL_OFFER_SEARCH_INCLUDED_RECS}
                                       removeDispatch={SELL_OFFER_SEARCH_REMOVE_SEARCH_FIELD} selector={state => state.sellOfferSearch.search}/>
        },
        {
            name: 'Market',
            search: <MarketSearch name="Market" key="market" searchDispatch={SELL_OFFER_SEARCH_MARKET} removeDispatch={SELL_OFFER_SEARCH_REMOVE_SEARCH_FIELD}
                                  selector={state => state.sellOfferSearch.search}/>
        },
        {
            name: 'Offer Type',
            search: <OfferTypeSearch name="Offer Type" key="offerType" searchDispatch={SELL_OFFER_SEARCH_OFFER_TYPE} removeDispatch={SELL_OFFER_SEARCH_REMOVE_SEARCH_FIELD}
                                     selector={state => state.sellOfferSearch.search}/>
        },
        {
            name: 'Sell Offer Type',
            search: <SellOfferTypeSearch name="Sell Offer Type" key="sellOfferType" searchDispatch={SELL_OFFER_SEARCH_SELL_OFFER_TYPE}
                                         removeDispatch={SELL_OFFER_SEARCH_REMOVE_SEARCH_FIELD} selector={state => state.sellOfferSearch.search}/>
        },
        {
            name: 'Start Date',
            search: <StartDateSearch name="Start Date" key="startDate" searchDispatch={SELL_OFFER_SEARCH_START_DATE} removeDispatch={SELL_OFFER_SEARCH_REMOVE_SEARCH_FIELD}
                                     selector={state => state.sellOfferSearch.search}/>
        }];

    const toggleOpenRow = (id) => {
        if (rowOpen) {
            for (let row of rowOpen) {
                if (row.id === id) {
                    row.open = !row.open;
                }
            }
            setRowOpen([...rowOpen]);
        }
    }

    const isRowOpen = (id) => {
        for (let row of rowOpen) {
            if (row.id === id) {
                return row.open;
            }
        }
        return false;
    }

    const handlePageChange = (pageNumber, pageSize) => {
        dispatch(searchSellOffers(search, pageNumber, pageSize, orderBy, direction));
    }

    const handleRowsPerPageChange = (e) => {
        setPageSize(e.target.value);
        dispatch(searchSellOffers(search, 0, e.target.value, orderBy, direction));
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    const addSearchField = (searchField) => {
        if (searchField) {
            dispatch({type: SELL_OFFER_SEARCH_ADDITIONAL_SEARCH_FIELDS, searchField: searchField});
        }
        setFieldDropDown({name: ''});
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        setOrderBy(column);
        setDirection(sortDirection);
        dispatch(searchSellOffers(search, 0, pageSize, column, sortDirection));
    }

    const onInputChange = (type, value) => {
        dispatch({type: type, value: value})
    }

    const renderAdditionalFieldsDropDown = () => {
        return (
            <Autocomplete value={fieldDropDown} renderInput={(params) => <TextField {...params} label="Additional Fields"/>} options={additionalFields}
                          onChange={(event, value) => addSearchField(value)}
                          getOptionLabel={(option) => option.name} getOptionSelected={(option, value) => option.name === value.name}/>
        )
    }

    const renderSearchFields = () => {
        return (<Grid container spacing={2}>
                <Grid item sm={2}>
                    <TextField label="Quantity" name="quantityMw" value={cleanStringInput(search.quantityMw)}
                               onChange={(e) => onInputChange(SELL_OFFER_SEARCH_QUANTITY_MW, e.target.value)}
                               fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">MW</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: true}
                    }}/>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <TextField label="Term Length" name="termLengthMonth" value={cleanStringInput(search.termLengthMonth)}
                               onChange={(e) => onInputChange(SELL_OFFER_SEARCH_TERM_LENGTH, e.target.value)}
                               fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">Months</InputAdornment>, inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: true}
                    }}/>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <TextField label="Energy" name="variablePaymentMwh" value={cleanStringInput(search.variablePaymentMwh)} helperText="Less than or Equal"
                               onChange={(e) => onInputChange(SELL_OFFER_SEARCH_VARIABLE_PAYMENT, e.target.value)}
                               fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">$/MWh</InputAdornment>, inputComponent: TwoDigitCurrency,
                        inputProps: {isEdit: true}
                    }}/>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <TextField label="Capacity" name="fixedPaymentMwday" value={cleanStringInput(search.fixedPaymentMwday)} helperText="Less than or Equal"
                               onChange={(e) => onInputChange(SELL_OFFER_SEARCH_FIXED_PAYMENT, e.target.value)}
                               fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>, inputComponent: TwoDigitCurrency,
                        inputProps: {isEdit: true}
                    }}/>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <TextField label="Environmental" name="environmentalAttributesMwh" value={cleanStringInput(search.environmentalAttributesMwh)}
                               helperText="Less than or Equal"
                               onChange={(e) => onInputChange(SELL_OFFER_SEARCH_ENVIRONMENTAL_ATTRIBUTES, e.target.value)}
                               fullWidth
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">$/MWh</InputAdornment>, inputComponent: TwoDigitCurrency,
                                   inputProps: {isEdit: true}
                               }}/>
                </Grid>
                <Grid item xs={6} sm={2}>
                    {renderAdditionalFieldsDropDown()}
                </Grid>
                {additionalSearchFields && additionalSearchFields.length > 0 && additionalSearchFields.map(field => {
                    return field.search
                })}
                <Grid item xs={6} sm={2} align="center">
                    <Button variant="contained" onClick={() => dispatch(searchSellOffers(search, 0, pageSize, orderBy, direction))}>Search</Button>
                </Grid>
            </Grid>
        );
    }

    const handleClose = () => {
        setConfirmDialogOpen(false);
        setSelectedSellOffer(undefined);
    }

    const handleContinue = () => {
        dispatch(createBuyBidForSellOffer(chosenAccount.id, selectedSellOffer));
        setConfirmDialogOpen(false);
        setSelectedSellOffer(undefined);
    }

    const renderConfirmDialog = (sellOffer) => {
        setConfirmDialogOpen(true);
        setSelectedSellOffer(sellOffer);
    }

    return (
        <main className={classes.layout}>
            <Typography variant="h3" align="center">Search Sell Offers</Typography>
            <Dialog open={confirmDialogOpen} onClose={handleClose}>
                <DialogTitle>Create Matching Buy Bid</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        By clicking Continue below a buy bid will be created that matches this sell offer. The buy bid and sell offer will be immediately matched. This action
                        is irreversible.
                        Please click Continue to create the buy bid or Cancel to close this window.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">Cancel</Button>
                    <Button onClick={handleContinue} variant="outlined">Continue</Button>
                </DialogActions>
            </Dialog>
            {renderSearchFields()}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'generatorType'} direction={orderBy === 'generatorType' ? direction : 'asc'}
                                            onClick={() => handleSort("g_generatorType")}>
                                Generator Type
                                {orderBy === 'generatorType' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'quantityMw'} direction={orderBy === 'quantityMw' ? direction : 'asc'}
                                            onClick={() => handleSort("quantityMw")}>
                                Quantity (MWs)
                                {orderBy === 'quantityMw' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'termLengthMonth'} direction={orderBy === 'termLengthMonth' ? direction : 'asc'}
                                            onClick={() => handleSort("termLengthMonth")}>
                                Term Length (Months)
                                {orderBy === 'termLengthMonth' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'fixedPaymentMwday'} direction={orderBy === 'fixedPaymentMwday' ? direction : 'asc'}
                                            onClick={() => handleSort("fixedPaymentMwday")}>
                                Capacity Payment ($/MW-day)
                                {orderBy === 'fixedPaymentMwday' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'variablePaymentMwh'} direction={orderBy === 'variablePaymentMwh' ? direction : 'asc'}
                                            onClick={() => handleSort("variablePaymentMwh")}>
                                Energy Payment ($/MWh)
                                {orderBy === 'variablePaymentMwh' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'deliveryPoint'} direction={orderBy === 'deliveryPoint' ? direction : 'asc'}
                                            onClick={() => handleSort("deliveryPoint")}>
                                Delivery Point
                                {orderBy === 'deliveryPoint' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'generatorState'} direction={orderBy === 'generatorState' ? direction : 'asc'}
                                            onClick={() => handleSort("generatorState")}>
                                State
                                {orderBy === 'state' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'includePhysicalAttributes'} direction={orderBy === 'includePhysicalAttributes' ? direction : 'asc'}
                                            onClick={() => handleSort("includePhysicalAttributes")}>
                                Include Physical Attributes
                                {orderBy === 'includePhysicalAttributes' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'includeEnvironmentalAttributes'} direction={orderBy === 'includeEnvironmentalAttributes' ? direction : 'asc'}
                                            onClick={() => handleSort("includeEnvironmentalAttributes")}>
                                Include Environmental Attributes
                                {orderBy === 'includeEnvironmentalAttributes' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                {pagedSellOffers &&
                    <React.Fragment>
                        <TableBody>
                            {pagedSellOffers.results.map(sellOffer => {
                                rowOpen.push({id: sellOffer.id, open: false});
                                sellOffer.generator.registeredRecs = sellOffer.includedRecs;
                                return (
                                    <React.Fragment key={sellOffer.id}>
                                        <TableRow key={sellOffer.id} className={classes.tableRow} onClick={() => toggleOpenRow(sellOffer.id)}>
                                            <TableCell align="center">{sellOffer.generator.generatorType}</TableCell>
                                            <TableCell align="center"><NumberFormat value={sellOffer.quantityMw} displayType={'text'} fixedDecimalScale={true}
                                                                                    decimalScale={2}/></TableCell>
                                            <TableCell align="center"><NumberFormat value={sellOffer.termLengthMonth} displayType={'text'} fixedDecimalScale={true}
                                                                                    decimalScale={0}/></TableCell>
                                            <TableCell align="center"><NumberFormat value={sellOffer.fixedPaymentMwday} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                                                                    prefix="$"/></TableCell>
                                            <TableCell align="center"><NumberFormat value={sellOffer.variablePaymentMwh} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                                                                    prefix="$"/></TableCell>
                                            <TableCell align="center">{sellOffer.deliveryPoint}</TableCell>
                                            <TableCell align="center">{sellOffer.generator.state}</TableCell>
                                            <TableCell align="center">{sellOffer.includePhysicalAttributes ? <CheckIcon/> : <BlockIcon/>}</TableCell>
                                            <TableCell align="center">{sellOffer.includeEnvironmentalAttributes ? <CheckIcon/> : <BlockIcon/>}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                                                <Collapse in={isRowOpen(sellOffer.id)} timeout="auto" unmountOnExit>
                                                    <Box margin={1}>
                                                        <SellOfferComponent sellOffer={sellOffer} generator={sellOffer.generator} isEdit={false}/>
                                                    </Box>
                                                    {hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_create_buy_bid') &&
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6} sm={4}/>
                                                            <Grid item xs={6} sm={4}>
                                                                <Button variant="contained" onClick={() => renderConfirmDialog(sellOffer)}>Create Matching Buy Bid</Button>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination count={pagedSellOffers.totalCount ? pagedSellOffers.totalCount : 0} onChangePage={handlePageChange}
                                                 page={pagedSellOffers.pageNumber ? pagedSellOffers.pageNumber : 0}
                                                 rowsPerPage={calculateRowsPerPage(pagedSellOffers.pageSize)} onChangeRowsPerPage={handleRowsPerPageChange}
                                                 rowsPerPageOptions={[10, 25, 50]} colSpan={9} ActionsComponent={PaginatedTableActions}/>
                            </TableRow>
                        </TableFooter>
                    </React.Fragment>
                }
            </Table>
        </main>
    );
}

export default SearchSellOffers;