import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {cleanStringInput} from "../../../Utility/StringUtil";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useDispatch, useSelector} from "react-redux";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {onError, onSuccess} from "../../../store/actions/popupActions";
import {retrieveDeliveryYearAuctions, retrieveLdas} from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import TwoDigitCurrency from "../../NumberFormat/TwoDigitCurrency";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const ViewIncrementalCapacityTransferRight = (props) => {

    const classes = useStyles(props);
    const deliveryYearAuctions = useSelector(state => state.capacity.deliveryYearAuctions);
    const ldas = useSelector(state => state.capacity.ldas);
    const roles = useSelector(state => state.auth.roles)
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const dispatch = useDispatch();
    const history = useHistory();
    const [incrementalCtr, setIncrementalCtr] = useState();
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (!ldas) {
            dispatch(retrieveLdas());
        }
        if (!deliveryYearAuctions) {
            dispatch(retrieveDeliveryYearAuctions());
        }
        retrieveIncremtentalCapacityTransferRight();
        setIsEdit(hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_ictr'));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const retrieveIncremtentalCapacityTransferRight = async () => {
        await trackPromise(axios.get("/capacity/project/status/ictr/" + props.match.params.iCtrId).then(response => {
            setIncrementalCtr(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const renderDeliveryYearSelectBox = () => {
        if (deliveryYearAuctions) {
            return (
                <Autocomplete value={incrementalCtr.deliveryYear} options={Object.keys(deliveryYearAuctions).sort((c1, c2) => c2.localeCompare(c1))}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => <TextField {...params} label="Delivery Year"/>}
                              onChange={(event, value) => onDeliveryYearSelectChange(event, value)} disabled={!isEdit}/>
            );
        }
    }

    const renderAuctionSelectBox = () => {
        if (incrementalCtr.deliveryYear && deliveryYearAuctions) {
            return (
                <Autocomplete value={incrementalCtr.auction}
                              options={['', ...deliveryYearAuctions[incrementalCtr.deliveryYear].map(r => r.auction)]}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => <TextField {...params} label="Auction"/>}
                              onChange={(event, value) => onAuctionSelectChange(event, value)} disabled={!isEdit}/>
            );
        }
        return (
            <Autocomplete value={incrementalCtr.auction} options={['']} getOptionLabel={(option) => ''}
                          renderInput={(params) => <TextField {...params} label="Auction"/>}/>
        );
    }

    const renderLdaSelectBox = () => {
        if (ldas && ldas.length > 0) {
            return (
                <Autocomplete value={findLdaForLdaName(incrementalCtr.ldaName)} options={ldas} getOptionLabel={(option) => option.ldaName}
                              renderInput={(params) => <TextField {...params} label="LDA"/>}
                              onChange={(event, value) => onLdaSelectChange(event, value)} disabled={!isEdit}/>
            );
        }
    }

    const onDeliveryYearSelectChange = (event, value) => {
        if (value) {
            incrementalCtr.deliveryYear = value;
        } else {
            incrementalCtr.deliveryYear = '';
            incrementalCtr.auction = '';
        }
        setIncrementalCtr({...incrementalCtr});
    }

    const onAuctionSelectChange = (event, value) => {
        if (value) {
            incrementalCtr.auction = value;
        } else {
            incrementalCtr.auction = '';
        }
        setIncrementalCtr({...incrementalCtr});
    }

    const onLdaSelectChange = (event, value, index) => {
        incrementalCtr.ldaName = value.ldaName;
        setIncrementalCtr({...incrementalCtr});
    }

    const findLdaForLdaName = (ldaName) => {
        for (let lda of ldas) {
            if (lda.ldaName === ldaName) {
                return lda;
            }
        }
    }

    const onInputChange = (e) => {
        if (e.target.type === "number") {
            if (e.target.value) {
                incrementalCtr[e.target.name] = Number.parseFloat(e.target.value);
            } else {
                incrementalCtr[e.target.name] = e.target.value;
            }
        } else {
            incrementalCtr[e.target.name] = e.target.value;
        }
        setIncrementalCtr({...incrementalCtr});
    }

    const onSave = async () => {
        await trackPromise(axios.put("/capacity/project/status/ictr/" + props.match.params.iCtrId, JSON.stringify(incrementalCtr)).then(response => {
            dispatch(onSuccess("Successfully updated ICTR."));
        }).catch(error => {
            dispatch(onError(error));
        }));
        history.push("/capacity/projectStatus/ictr/view");
    }

    const onDelete = async () => {
        await trackPromise(axios.delete("/capacity/project/status/ictr/" + props.match.params.iCtrId).then(response => {
            dispatch(onSuccess("Successfully updated ICTR."));
        }).catch(error => {
            dispatch(onError(error));
        }));
        history.push("/capacity/projectStatus/ictr/view");
    }

    const renderButtons = () => {
        if (isEdit && hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_delete_ictr')) {
            return (
                <React.Fragment>
                    <Grid item xs={4}>
                        <Button onClick={onSave} fullWidth>Save</Button>
                    </Grid>
                    <Grid item xs={2}/>
                    <Grid item xs={4}>
                        <Button onClick={onDelete} fullWidth>Delete</Button>
                    </Grid>
                </React.Fragment>
            )
        }
        if (isEdit && !hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_delete_ictr')) {
            return (
                <React.Fragment>
                    <Grid item xs={4}/>
                    <Grid item xs={4}>
                        <Button onClick={onSave} fullWidth>Save</Button>
                    </Grid>
                </React.Fragment>
            )
        }
        if (!isEdit && hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_delete_ictr')) {
            return (
                <React.Fragment>
                    <Grid item xs={3}/>
                    <Grid item xs={4}>
                        <Button onClick={onDelete} fullWidth>Delete</Button>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    return (
        <main className={classes.layout}>
            {incrementalCtr &&
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                        {renderDeliveryYearSelectBox()}
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={4}>
                        {renderAuctionSelectBox()}
                    </Grid>
                    <Grid item xs={5} sm={1}/>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={4}>
                        {renderLdaSelectBox()}
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel id="type-label">Type</InputLabel>
                            <Select labelId="type-label" name="type" value={incrementalCtr.type ? incrementalCtr.type : ''}
                                    onChange={(e) => onInputChange(e)} fullWidth disabled={!isEdit}>
                                <MenuItem value={"REGIONAL"}>Regional</MenuItem>
                                <MenuItem value={"Customer Funded"}>Customer Funded</MenuItem>
                                <MenuItem value={"Lower Voltage"}>Lower Voltage</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={4}>
                        <TextField label="Upgrade ID" name="upgradeId" value={cleanStringInput(incrementalCtr.upgradeId)}
                                   onChange={(e) => onInputChange(e)} fullWidth disabled={!isEdit}/>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={4}>
                        <TextField label="Parent Upgrade ID" name="parentUpgradeId" value={cleanStringInput(incrementalCtr.parentUpgradeId)}
                                   onChange={(e) => onInputChange(e)} fullWidth disabled={!isEdit}/>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={4}>
                        <TextField label="Certified ICTR" name="certifiedIctr" type="number" value={cleanStringInput(incrementalCtr.certifiedIctr)}
                                   onChange={(e) => onInputChange(e)} fullWidth
                                   InputProps={{
                                       endAdornment: <InputAdornment position="start">MWs</InputAdornment>, inputComponent: GenericNumberFormat,
                                       inputProps: {fixedDecimalScale: true, decimalScale: 1, allowNegative: false, isEdit: isEdit}
                                   }} InputLabelProps={{shrink: !!incrementalCtr.certifiedIctr}} disabled={!isEdit}/>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={4}>
                        <TextField label="Adjusted ICTR" name="adjustedIctr" type="number" value={cleanStringInput(incrementalCtr.adjustedIctr)}
                                   onChange={(e) => onInputChange(e)} fullWidth
                                   InputProps={{
                                       endAdornment: <InputAdornment position="start">MWs</InputAdornment>, inputComponent: GenericNumberFormat,
                                       inputProps: {fixedDecimalScale: true, decimalScale: 1, allowNegative: false, isEdit: isEdit}
                                   }} InputLabelProps={{shrink: !!incrementalCtr.adjustedIctr}} disabled={!isEdit}/>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={4}>
                        <TextField label="Economic Value" name="economicValue" type="number" value={cleanStringInput(incrementalCtr.economicValue)}
                                   onChange={(e) => onInputChange(e)} fullWidth
                                   InputProps={{
                                       endAdornment: <InputAdornment position="start">$/MW-day</InputAdornment>,
                                       inputComponent: TwoDigitCurrency,
                                       inputProps: {isEdit: isEdit}
                                   }}
                                   InputLabelProps={{shrink: !!cleanStringInput(incrementalCtr.certifiedIctr)}} disabled={!isEdit}/>
                    </Grid>
                    <Grid item xs={6} sm={6}/>
                    <Grid item xs={6} sm={3}/>
                </Grid>
                <Grid container spacing={2}>
                    {renderButtons()}
                </Grid>
            </React.Fragment>
            }
        </main>
    )

}

export default ViewIncrementalCapacityTransferRight;