import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {withStyles, withTheme} from '@material-ui/core/styles';
import {TableFooter} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import PaginatedTableActions from "../../Table/PaginatedTableActions";
import {retrieveUsers, retrieveUsersAsSuperUser} from '../../../store/user-management/actions/userActions';
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";


const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
});

class UserTable extends Component {

    componentDidMount = () => {
        this.retrieveUsers(0, 10);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.accountId !== prevProps.match.params.accountId) {
            this.props.retrieveUsers(this.props.match.params.accountId);
        }
    }

    handleRowClick(userId) {
        if(this.props.superUser) {
            this.props.history.push('/user-management/superuser/users/' + userId);
        } else if (this.props.match.params.accountId) {
            this.props.history.push('/user-management/account/' + this.props.match.params.accountId + '/users/' + userId);
        } else {
            this.props.history.push('/user-management/account/' + this.props.chosenAccount.id + '/users/' + userId);
        }
    }

    retrieveUsers = (pageNumber, pageSize) => {
        if (this.props.superUser) {
            this.props.retrieveUsersAsSuperUser(pageNumber, pageSize);
        } else if (this.props.match.params.accountId) {
            this.props.retrieveUsers(this.props.match.params.accountId, pageNumber, pageSize);
        } else {
            this.props.retrieveUsers(this.props.chosenAccount.id, pageNumber, pageSize);
        }
    }

    handlePageChange = (pageNumber, pageSize) => {
        this.retrieveUsers(pageNumber, pageSize);
    }

    handleRowsPerPageChange = (e) => {
        this.retrieveUsers(0, e.target.value);
    }

    calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    renderTableRow = (user) => {
        const {classes} = this.props;
        if (hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, "ROLE_retrieve_user_role") || this.props.superUser) {
            return (
                <TableRow className={classes.tableRow} key={user.id} onClick={(e) => this.handleRowClick(user.id)}>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.emailAddress}</TableCell>
                    <TableCell>{user.phoneNumber}</TableCell>
                </TableRow>
            );
        }
        return (
            <TableRow key={user.id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{user.emailAddress}</TableCell>
                <TableCell>{user.phoneNumber}</TableCell>
            </TableRow>
        );
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>User Name</TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>E-Mail Address</TableCell>
                            <TableCell>Phone Number</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(this.props.users && this.props.users.results) && this.props.users.results.map(user => {return (this.renderTableRow(user))})}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={10} count={this.props.users.totalCount ? this.props.users.totalCount : 0}
                                             rowsPerPage={this.calculateRowsPerPage(this.props.users.pageSize)}
                                             page={this.props.users.pageNumber ? this.props.users.pageNumber : 0} onChangePage={this.handlePageChange}
                                             onChangeRowsPerPage={this.handleRowsPerPageChange}
                                             ActionsComponent={PaginatedTableActions}/>
                        </TableRow>
                    </TableFooter>
                </Table>

            </main>
        );
    }

}

const mapStateToProps = state => {
    return {
        users: state.users.users,
        chosenAccount: state.chosenAccount.account,
        roles: state.auth.roles,
        superUser: state.auth.superUser
    };
}

const mapDispatchToProps = dispatch => {
    return {
        retrieveUsers: (accountId, pageNumber, pageSize) => dispatch(retrieveUsers(accountId, pageNumber, pageSize)),
        retrieveUsersAsSuperUser: (pageNumber, pageSize) => dispatch(retrieveUsersAsSuperUser(pageNumber, pageSize))
    };
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withRouter(UserTable))));