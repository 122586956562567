import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {doLogout, stayLoggedIn} from "../../store/actions/authActions";


const LogoutTimer = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const lastRequestTime = useSelector(state => state.auth.lastRequestTime);
    const username = useSelector(state => state.auth.username);
    const [displayModal, setDisplayModal] = useState(false);
    const [logoutWarnTimer, setLogoutWarnTimer] = useState(null);
    const [logoutTimer, setLogoutTimer] = useState(null);

    useEffect(() => {
        if (logoutWarnTimer) {
            setLogoutWarnTimer(clearTimeout(logoutWarnTimer));
        }
        setLogoutWarnTimer(setTimeout(() => {
            triggerAutoLogoutTimer();
        }, 3300000));
        return function cleanup() {
            clearTimeout(logoutWarnTimer);
            clearTimeout(logoutTimer);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastRequestTime]);

    const handleClose = () => {
        setDisplayModal(false);
    }

    const handleStayLoggedIn = () => {
        setLogoutWarnTimer(clearTimeout(logoutWarnTimer));
        setLogoutTimer(clearTimeout(logoutTimer));
        dispatch(stayLoggedIn(username));
        handleClose();
    }

    const handleLogout = () => {
        setDisplayModal(false);
        dispatch(doLogout());
        history.replace("/logout");
    }

    const triggerAutoLogoutTimer = () => {
        setDisplayModal(true);
        setLogoutTimer(setTimeout(() => {
            handleLogout();
        }, 300000));
    }

    return (
        <Dialog open={displayModal} onClose={handleClose}>
            <DialogContent>
                <DialogContentText>You will be logged out in 5 minutes.  Please click stay logged in to stay logged in or logout to be logged out.</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleStayLoggedIn}>
                    Stay Logged In
                </Button>
                <Button onClick={handleLogout}>
                    Log Out
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default LogoutTimer;