import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

import {retrieveBuyBidProfiles} from '../../../store/spark-exchange/actions/buyBidProfileActions';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PopOver from '@material-ui/core/Popover';
import {useHistory} from "react-router";
import {Grid, TableSortLabel} from "@material-ui/core";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    popover: {
        pointerEvents: 'none',
        width: '1200px',
        height: '600px'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const BuyBidProfiles = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const buyBidProfiles = useSelector(state => state.buyBidProfiles.buyBidProfiles);
    const [popOverAnchorEl, setPopOverAnchorEl] = useState(null);
    const [openedPopOverId, setOpenedPopOverId] = useState(null);
    const [orderBy, setOrderBy] = useState();
    const [direction, setDirection] = useState();

    useEffect(() => {
        dispatch(retrieveBuyBidProfiles());
        // eslint-disable-next-line
    }, [])

    const handleCreateBuyBidProfile = () => {
        history.push("/spark-exchange/buy-bids/profiles/create");
    }

    const handlePopOverOpen = (buyBidProfile, e) => {
        if (buyBidProfile.description.length > 0) {
            setPopOverAnchorEl(e.currentTarget);
            setOpenedPopOverId(buyBidProfile.id);
        }
    }

    const handlePopOverClose = () => {
        setPopOverAnchorEl(null);
        setOpenedPopOverId(null);
    }

    const handleSort = (name) => {
        let sortDirection = name === orderBy && direction === 'asc' ? 'desc' : 'asc'
        setOrderBy(name);
        setDirection(sortDirection);
        if (buyBidProfiles) {
            buyBidProfiles.sort((a, b) => {
                if (sortDirection === 'asc') {
                    return descendingComparator(a, b, name);
                } else {
                    return -descendingComparator(a, b, name);
                }
            })
        }
    }

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    return (
        <main className={classes.layout}>
            <Typography variant="h3" align="center">Buy Bid Profiles</Typography>
            <Grid container spacing={2}>
                <Grid item xs={2} sm={4}/>
                <Grid item xs={2} sm={4} align="center">
                    <Button variant="contained" onClick={handleCreateBuyBidProfile}>Create Buy Bid Profile</Button>
                </Grid>
                <Grid item xs={2} sm={4}/>
            </Grid>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center"><TableSortLabel active={orderBy === 'name'} direction={orderBy === 'name' ? direction : 'asc'} onClick={() => handleSort('name')}>
                            Name
                            {orderBy === 'name' ? (<span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">Description</TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'name'} direction={orderBy === 'heatRate' ? direction : 'asc'} onClick={() => handleSort('heatRate')}>
                                Heat Rate (BTU/MWh)
                                {orderBy === 'heatRate' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'fuelRate'} direction={orderBy === 'fuelRate' ? direction : 'asc'} onClick={() => handleSort('fuelRate')}>
                                Fuel Rate (BTU/MWh)
                                {orderBy === 'fuelRate' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'carbonDioxideEmissions'} direction={orderBy === 'carbonDioxideEmissions' ? direction : 'asc'}
                                            onClick={() => handleSort('carbonDioxideEmissions')}>
                                Carbon Dioxide Emissions (lbs/MWh)
                                {orderBy === 'carbonDioxideEmissions' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'sulfurDioxideEmissions'} direction={orderBy === 'sulfurDioxideEmissions' ? direction : 'asc'}
                                            onClick={() => handleSort('sulfurDioxideEmissions')}>
                                Sulfur Dioxide Emissions (lbs/MWh)
                                {orderBy === 'sulfurDioxideEmissions' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'nitrogenOxideEmissions'} direction={orderBy === 'nitrogenOxideEmissions' ? direction : 'asc'}
                                            onClick={() => handleSort('nitrogenOxideEmissions')}>
                                Nitrogen Oxide Emissions (lbs/MWh)
                                {orderBy === 'nitrogenOxideEmissions' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'particulatesEmissions'} direction={orderBy === 'particulatesEmissions' ? direction : 'asc'}
                                            onClick={() => handleSort('particulatesEmissions')}>
                                Particulates Emissions (lbs/MWh)
                                {orderBy === 'particulatesEmissions' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {buyBidProfiles && buyBidProfiles.map(buyBidProfile => {
                        return (<TableRow key={buyBidProfile.id} className={classes.tableRow}
                                          onClick={() => history.push("/spark-exchange/buy-bids/profiles/" + buyBidProfile.id)}>
                                <TableCell align="center">{buyBidProfile.name}</TableCell>
                                <TableCell onMouseEnter={(e) => handlePopOverOpen(buyBidProfile, e)}
                                           onMouseLeave={handlePopOverClose} align="center">
                                    {buyBidProfile.description.length < 30 ? buyBidProfile.description : buyBidProfile.description.substr(0, 30)}
                                </TableCell>
                                <TableCell align="center"><NumberFormat value={buyBidProfile.heatRate} displayType={'text'} fixedDecimalScale={true} decimalScale={0}
                                                                        thousandSeparator/></TableCell>
                                <TableCell align="center"><NumberFormat value={buyBidProfile.fuelRate} displayType={'text'} fixedDecimalScale={true} decimalScale={0}
                                                                        thousandSeparator/></TableCell>
                                <TableCell align="center"><NumberFormat value={buyBidProfile.carbonDioxideEmissions} displayType={'text'} fixedDecimalScale={true}
                                                                        decimalScale={0} thousandSeparator/></TableCell>
                                <TableCell align="center"><NumberFormat value={buyBidProfile.sulfurDioxideEmissions} displayType={'text'} fixedDecimalScale={true}
                                                                        decimalScale={0} thousandSeparator/></TableCell>
                                <TableCell align="center"><NumberFormat value={buyBidProfile.nitrogenOxideEmissions} displayType={'text'} fixedDecimalScale={true}
                                                                        decimalScale={0} thousandSeparator/></TableCell>
                                <TableCell align="center"><NumberFormat value={buyBidProfile.particulatesEmissions} displayType={'text'} fixedDecimalScale={true} decimalScale={0}
                                                                        thousandSeparator/></TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
            {buyBidProfiles && buyBidProfiles.map(buyBidProfile => {
                return (<PopOver className={classes.popover} key={buyBidProfile.id} open={openedPopOverId === buyBidProfile.id}
                                 anchorEl={popOverAnchorEl}>
                        <Typography>{buyBidProfile.description}</Typography>
                    </PopOver>
                )
            })}
        </main>
    );

}

export default BuyBidProfiles;