import React, {Component} from 'react';
import {connect} from 'react-redux';
import {trackPromise} from 'react-promise-tracker';

import {withStyles, withTheme} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {Button} from "@material-ui/core";

import axios from '../../../axios/AxiosInterceptors';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import CheckBox from "@material-ui/core/Checkbox";
import {updateUser} from '../../../store/user-management/actions/userActions';
import {hasPermissionForAccount} from '../../../Utility/PermissionsUtil';
import UserComponent from "../User/UserComponent";
import {onError} from "../../../store/actions/popupActions";


const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

const editableState = {
    user: {
        username: '',
        emailAddress: '',
        firstName: '',
        lastName: '',
        accountIds: [],
        userAccountRoles: [],
        userApplicationRoles: [],
        superUser: false
    },
    accountRoles: [],
    applicationRoles: [],
    applicationRoleToggles: [],
    accountRoleToggles: []
}

class ViewUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            applicationRoleToggles: [],
            accountRoleToggles: [],
            applicationRoles: [],
            accountRoles: [],
            canEdit: false,
            user: editableState.user,
        };
    }

    componentDidMount() {
        this.retrieveUserByUserId();
    }

    retrieveUserByUserId = async () => {
        if (this.props.superUser) {
            await trackPromise(
                axios.get("/auth/admin/users/" + this.props.match.params.userId).then(response => {
                    this.setState({user: response.data});
                }).catch(error => {
                    this.props.onError(error);
                }));
        } else {
            await trackPromise(
                axios.get("/auth/account/" + this.props.match.params.accountId + "/users/" + this.props.match.params.userId).then(response => {
                    this.setState({user: response.data});
                }).catch(error => {
                    this.props.onError(error);
                }));
        }
    }

    retrieveRoles = async () => {
        await trackPromise(axios.get("/auth/account/" + this.props.match.params.accountId + "/roles").then(response => {
            let accountRoleToggles = this.state.accountRoleToggles;
            response.data.forEach((role) => {
                accountRoleToggles.push({roleId: role.id, open: false})
            });
            this.setState({accountRoleToggles: accountRoleToggles, accountRoles: response.data});
        }).catch(error => {
            this.props.onError(error);
        }));
        await trackPromise(axios.get("/auth/application/roles").then(response => {
            let applicationRoleToggles = this.state.applicationRoleToggles;
            response.data.forEach((role) => {
                applicationRoleToggles.push({roleId: role.id, open: false})
            });
            this.setState({applicationRoleToggles: applicationRoleToggles, applicationRoles: response.data});
        }).catch(error => {
            this.props.onError(error);
        }));
    }

    cleanStringInput = (input) => {
        if (input) {
            return input;
        } else {
            return '';
        }
    };

    onEditClick = () => {
        this.retrieveRoles();
        this.setState({canEdit: true});
    };

    onSaveClick = () => {
        this.props.updateUser(this.props.match.params.accountId, this.state.user);
        this.props.history.goBack();
    }

    onInputChangeHandler = (user) => {
        this.setState({user: user});
    };

    toggleAccountRoleVisibility = (roleId) => {
        let accountRoleToggles = this.state.accountRoleToggles;
        accountRoleToggles = this.toggleRoleVisibility(accountRoleToggles, roleId);
        this.setState({accountRoleToggles});
    };

    toggleApplicationRoleVisibility = (roleId) => {
        let applicationRoleToggles = this.state.applicationRoleToggles;
        applicationRoleToggles = this.toggleRoleVisibility(applicationRoleToggles, roleId);
        this.setState({applicationRoleToggles});
    };

    toggleRoleVisibility = (toggles, roleId) => {
        if (!toggles || toggles.length === 0) {
            toggles.push({roleId: roleId, open: true});
            return toggles;
        }
        let notFound = true;
        for (let toggle of toggles) {
            if (toggle.roleId === roleId) {
                toggle.open = !toggle.open;
                notFound = false;
            }
        }
        if (notFound) {
            toggles.push({roleId: roleId, open: true});
        }
        return toggles;
    }

    isAccountRoleVisible = (roleId) => {
        return this.isRoleVisible(this.state.accountRoleToggles, roleId);
    };

    isApplicationRoleVisible = (roleId) => {
        return this.isRoleVisible(this.state.applicationRoleToggles, roleId);
    };

    isRoleVisible = (toggles, roleId) => {
        for (let toggle of toggles) {
            if (toggle.roleId === roleId) {
                return toggle.open;
            }
        }
        return false;
    };

    handleAccountRoleToggle = (e) => {
        let user = {...this.state.user};
        user.userAccountRoles = this.handleRoleToggle(user.userAccountRoles, Number.parseInt(e.target.value));
        this.setState({user});
    };

    handleApplicationRoleToggle = (e) => {
        let user = {...this.state.user};
        user.userApplicationRoles = this.handleRoleToggle(user.userApplicationRoles, Number.parseInt(e.target.value));
        this.setState({user: user});
    };

    handleRoleToggle = (roles, roleId) => {
        let index = -1;
        for (let i = 0; i < roles.length; i++) {
            if (roles[i].roleId === roleId) {
                index = i;
            }
        }
        if (index !== -1) {
            roles.splice(index, 1);
        } else {
            roles.push({roleId: roleId, accountId: Number.parseInt(this.props.match.params.accountId)});
        }
        return roles;
    };

    isAccountRoleChecked = (roleId) => {
        return this.isRoleChecked(this.state.user.userAccountRoles, roleId);
    };

    isApplicationRoleChecked = (roleId) => {
        return this.isRoleChecked(this.state.user.userApplicationRoles, roleId);
    };

    isRoleChecked = (roles, roleId) => {
        for (let role of roles) {
            if (role.roleId === roleId) {
                return true;
            }
        }
        return false;
    };

    renderListRow = (role, visibilityToggle, isVisible, roleCheckedToggle, isRoleChecked) => {
        const {classes} = this.props;
        if (this.state.canEdit) {
            return (
                <React.Fragment key={role.id}>
                    <ListItem key={role.id} onClick={() => visibilityToggle(role.id)}>
                        <ListItemText>{role.roleName}</ListItemText>
                        {isVisible(role.id) ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        <ListItemSecondaryAction>
                            <CheckBox edge="end" disableRipple value={role.id} onChange={roleCheckedToggle} checked={isRoleChecked(role.id)}/>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Collapse in={isVisible(role.id)} timeout="auto" unmountOnExit>
                        <List className={classes.nested} component="div" disablePadding dense={true}>
                            {role.servicePermissions.map((servicePermission) => (
                                <ListItem key={servicePermission.id}>
                                    <ListItemText>{servicePermission.permissionPrettyName}</ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </React.Fragment>)
        } else {
            return (
                <React.Fragment key={role.roleId}>
                    <ListItem key={role.roleId} onClick={() => visibilityToggle(role.roleId)}>
                        <ListItemText>{role.roleName}</ListItemText>
                        {isVisible(role.roleId) ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </ListItem>
                    <Collapse in={isVisible(role.roleId)} timeout="auto" unmountOnExit>
                        <List className={classes.nested} component="div" disablePadding dense={true}>
                            {role.servicePermissions.map((servicePermission) => (
                                <ListItem key={servicePermission.id}>
                                    <ListItemText>{servicePermission.permissionPrettyName}</ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </React.Fragment>);
        }
    };

    renderRoles(roles, visibilityToggle, isVisible, roleCheckedToggle, isRoleChecked) {
        if (this.state.canEdit) {
            return (
                <List dense={true}>{
                    roles.map((role) => (
                        this.renderListRow(role, visibilityToggle, isVisible, roleCheckedToggle, isRoleChecked)
                    ))
                }</List>
            )
        } else {
            if (roles) {
                return (
                    <List dense={true}>{
                        roles.map((role) => (
                            this.renderListRow(role, visibilityToggle, isVisible, roleCheckedToggle, isRoleChecked)
                        ))
                    }</List>
                )
            } else {
                return (<List/>);
            }
        }
    }

    renderButtons = () => {
        if (this.state.canEdit) {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={this.onSaveClick}>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={this.retrieveUser}>Clear</Button>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={() => this.props.history.goBack()}>Back</Button>
                    </Grid>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={6} sm={4}>
                        {hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, "ROLE_update_user_role") &&
                        <Button onClick={this.onEditClick}>Edit</Button>}
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={() => this.props.history.goBack()}>Back</Button>
                    </Grid>
                </React.Fragment>
            );
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                {this.state.user &&
                <React.Fragment>
                    <Typography variant="h4" align="center">{this.state.user ? this.state.user.firstName + " " + this.state.user.lastName : 'NA'}</Typography>
                    {this.state.user &&
                    <UserComponent canEdit={this.state.canEdit} inputChangeHandler={this.onInputChangeHandler} accountId={this.props.match.params.accountId}
                                   user={this.state.user} displayUsername={true}/>}
                    <Paper className={classes.paper}>
                        <Typography variant="h5" align="center">Roles</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                                <Typography variant="h6" align="center">Account Roles</Typography>
                                {this.state.canEdit ? this.renderRoles(this.state.accountRoles, this.toggleAccountRoleVisibility, this.isAccountRoleVisible, this.handleAccountRoleToggle, this.isAccountRoleChecked) :
                                    this.renderRoles(this.state.user.userAccountRoles, this.toggleAccountRoleVisibility, this.isAccountRoleVisible, this.handleAccountRoleToggle, this.isAccountRoleChecked)}
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Typography variant="h6" align="center">Application Roles</Typography>
                                {this.state.canEdit ? this.renderRoles(this.state.applicationRoles, this.toggleApplicationRoleVisibility, this.isApplicationRoleVisible, this.handleApplicationRoleToggle, this.isApplicationRoleChecked) :
                                    this.renderRoles(this.state.user.userApplicationRoles, this.toggleApplicationRoleVisibility, this.isApplicationRoleVisible, this.handleApplicationRoleToggle, this.isApplicationRoleChecked)}
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container spacing={2}>
                        {this.renderButtons()}
                    </Grid>
                </React.Fragment>}
            </main>
        );
    }
}

const mapStateToProps = state => {
    return {
        chosenAccount: state.chosenAccount.account,
        roles: state.auth.roles,
        superUser: state.auth.superUser
    }
}

const mapDispatchToProps = dispatch => {
        return {
            updateUser: (accountId, user) => dispatch(updateUser(accountId, user)),
            onError: (error) => dispatch(onError(error))
        };
    }
;

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ViewUser)));