import {RETRIEVE_DELIVERY_POINTS, RETRIEVE_REQUIRED_MILESTONES} from "../sparkExchangeActionTypes";

const initialState = {
    deliveryPoints: [],
    requiredMilestones: []
}

const sparkExchangeReducer = (state = initialState, action) => {
    switch(action.type) {
        case RETRIEVE_DELIVERY_POINTS:
            return {...state, deliveryPoints: [...action.deliveryPoints]};
        case RETRIEVE_REQUIRED_MILESTONES:
            return {...state, requiredMilestones: [...action.requiredMilestones]};
        default:
            return state;
    }
}

export default sparkExchangeReducer;