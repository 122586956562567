import {CREATE_APPLICATION_ROLE_SUCCESS, RETRIEVE_APPLICATION_ROLES_SUCCESS, UPDATE_APPLICATION_ROLE_SUCCESS} from "../userManagementActionTypes";

const initialState = {
    roles: []
}

const createRole = (state, action) => {
    return {...state, roles: [...state.roles, action.role]};
}

const retrieveRoles = (state, action) => {
    return {...state, roles: action.roles};
}

const updateRole = (state, action) => {
    let roles = state.roles;
    let index = -1;
    for(let i = 0; i < roles.length;  i++) {
        if(roles[i].id === action.role.id) {
            index = i;
        }
    }
    if(index !== -1) {
        roles.splice(index, 1);
    }
    return {...state, roles: [...roles, action.role]};
}

const applicationRoleReducer = (state = initialState, action) => {
    switch(action.type) {
        case CREATE_APPLICATION_ROLE_SUCCESS:
            return createRole(state, action);
        case RETRIEVE_APPLICATION_ROLES_SUCCESS:
            return retrieveRoles(state, action);
        case UPDATE_APPLICATION_ROLE_SUCCESS:
            return updateRole(state, action);
        default:
            return state;
    }
}

export default applicationRoleReducer;