import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, withTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BuyBidComponent from "./BuyBidComponent";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {onError} from "../../../store/actions/popupActions";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
});


class ViewBuyBid extends Component {

    state = {
        isEdit: false,
        isWaitingForConfirm: false,
        buyBidOpen: true,
        matchedSellOfferOpen: false,
        buyBid: {
            market: '',
            includePhysicalAttributes: true,
            includeCapacity: true,
            includeEnergyEscalation: false,
            includeCapacityEscalation: false,
            includeEnvironmentalAttributes: true,
            includeComplianceRecs: true,
            includeEnvironmentalEscalation: false,
            includedRecs: [],
            buyBidType: '',
            firmType: '',
            standardProductType: '',
            includeMilestoneRequirements: false
        }
    };

    componentDidMount = async () => {
        await trackPromise(axios.get("/spark-exchange/account/" + this.props.match.params.accountId + "/buy-bids/" + this.props.match.params.buyBidId).then(response => {
            this.setState({buyBid: response.data});
        }).catch(error => {
            this.props.onError(error.response);
        }));
    };

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <Typography variant="h3" align="center">Buy Bid</Typography>
                <BuyBidComponent isEdit={false} buyBid={this.state.buyBid} key={this.state.buyBid.id} showButtons={true}/>
            </main>
        );
    }
}


const mapStateToProps = state => {
    return {
        buyBid: state.buyBid.buyBid
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onError: (error) => dispatch(onError(error))
    };
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ViewBuyBid)));

