import React from 'react';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const MarketSearch = (props) => {

    const search = useSelector(props.selector);
    const dispatch = useDispatch();

    const onInputChange = (e) => {
        dispatch({type: props.searchDispatch, value: e.target.value});
    }

    const onRemoveIconClick = () => {
        dispatch({type: props.searchDispatch, value: ''});
        dispatch({type: props.removeDispatch, value:props.name})
    }

    return (
        <Grid item xs={6} sm={4}>
            <Box display="flex" flexWrap="nowrap">
                <Box>
            <FormControl component="fieldset">
                <FormLabel>Market</FormLabel>
                <RadioGroup name="market" value={search.market} onChange={onInputChange} row>
                    <FormControlLabel control={<Radio/>} label="Day Ahead" value="dayAhead"/>
                    <FormControlLabel control={<Radio/>} label="Real Time" value="realTime"/>
                </RadioGroup>
            </FormControl>
                </Box>
                <Box>
                    <IconButton onClick={onRemoveIconClick}>
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                </Box>
            </Box>
        </Grid>
    )
}

export default MarketSearch;