import React, {useEffect, useState} from 'react';

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import {cleanStringInput} from "../../../Utility/StringUtil";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    columnHeader: {
        position: 'sticky',
        left: 0,
        background: 'white',
        zIndex: 1
    }
}));

const FirmMonthlyShapeOutput = (props) => {

    const [monthlyShapeOutputs, setMonthlyShapeOutputs] = useState([]);
    const [quantity, setQuantity] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        if (props.quantity && props.quantity !== quantity) {
            setQuantity(props.quantity);
            updateMonthlyOutputs(props.quantity);
        } else if (props.monthlyShapeOutputs && props.monthlyShapeOutputs.length > 0) {
            setMonthlyShapeOutputs(props.monthlyShapeOutputs);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const updateMonthlyOutputs = (quantity) => {
        let outputs = []
        for (let i = 1; i <= 12; i++) {
            for (let j = 1; j <= 24; j++) {
                outputs.push({output: Number.parseFloat(quantity), month: i, hourEnding: j});
            }
        }
        setMonthlyShapeOutputs([...outputs]);
        if (props.onInputChangeHandler) {
            props.onInputChangeHandler('firmShapeMonthlyOutputs', outputs);
        }
    };

    const onFirmMonthlyShapeChange = async (e, month, hourEnding) => {
        let outputs = monthlyShapeOutputs;
        let valueUpdated = false;
        for (let output of outputs) {
            if (output.month === month && output.hourEnding === hourEnding) {
                output.output = Number.parseFloat(e.target.value);
                valueUpdated = true;
            }
        }
        if (!valueUpdated) {
            outputs.push({month: month, hourEnding: hourEnding, output: Number.parseFloat(e.target.value)});
        }
        setMonthlyShapeOutputs([...outputs]);
        if (props.onInputChangeHandler) {
            props.onInputChangeHandler('monthlyShapeOutputs', outputs);
        }
    }

    const renderFirmMonthlyShapeRow = (month) => {
        let columns = [];
        for (let i = 1; i <= 24; i++) {
            columns.push(<TableCell key={month + '' + i}>
                {renderFirmMonthlyShapeTextField(month, i)}
            </TableCell>);
        }
        return columns;
    }

    const renderFirmMonthlyShapeTextField = (month, hourEnding) => {
        if (props.isEdit !== undefined && props.isEdit) {
            let value = cleanStringInput(findMonthlyShapeOutput(month, hourEnding));
            return (<TextField value={value}
                               onChange={(e) => onFirmMonthlyShapeChange(e, month, hourEnding)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: props.isEdit}
                               }} InputLabelProps={{shrink: !!value}}/>);
        } else {
            return (
                `${findMonthlyShapeOutput(month, hourEnding)} MWs`
            );
        }
    }

    const findMonthlyShapeOutput = (month, hourEnding) => {
        let outputs = monthlyShapeOutputs;
        for (let monthlyShapeOutput of outputs) {
            if (monthlyShapeOutput && monthlyShapeOutput.month === month && monthlyShapeOutput.hourEnding === hourEnding) {
                if (monthlyShapeOutput.output || monthlyShapeOutput.output === 0) {
                    return monthlyShapeOutput.output;
                }
                return '';
            }
        }
        return '';
    }

    return (
        <TableContainer style={{border: "1px solid black", maxHeight: 500}}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell style={{minWidth: 100}}>Hour Ending</TableCell>
                        <TableCell style={{minWidth: 100}}>1</TableCell>
                        <TableCell style={{minWidth: 100}}>2</TableCell>
                        <TableCell style={{minWidth: 100}}>3</TableCell>
                        <TableCell style={{minWidth: 100}}>4</TableCell>
                        <TableCell style={{minWidth: 100}}>5</TableCell>
                        <TableCell style={{minWidth: 100}}>6</TableCell>
                        <TableCell style={{minWidth: 100}}>7</TableCell>
                        <TableCell style={{minWidth: 100}}>8</TableCell>
                        <TableCell style={{minWidth: 100}}>9</TableCell>
                        <TableCell style={{minWidth: 100}}>10</TableCell>
                        <TableCell style={{minWidth: 100}}>11</TableCell>
                        <TableCell style={{minWidth: 100}}>12</TableCell>
                        <TableCell style={{minWidth: 100}}>13</TableCell>
                        <TableCell style={{minWidth: 100}}>14</TableCell>
                        <TableCell style={{minWidth: 100}}>15</TableCell>
                        <TableCell style={{minWidth: 100}}>16</TableCell>
                        <TableCell style={{minWidth: 100}}>17</TableCell>
                        <TableCell style={{minWidth: 100}}>18</TableCell>
                        <TableCell style={{minWidth: 100}}>19</TableCell>
                        <TableCell style={{minWidth: 100}}>20</TableCell>
                        <TableCell style={{minWidth: 100}}>21</TableCell>
                        <TableCell style={{minWidth: 100}}>22</TableCell>
                        <TableCell style={{minWidth: 100}}>23</TableCell>
                        <TableCell style={{minWidth: 100}}>24</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.columnHeader} component="th" scope="row">January</TableCell>
                        {renderFirmMonthlyShapeRow(1).map(r => {
                            return r;
                        })}
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.columnHeader}>February</TableCell>
                        {renderFirmMonthlyShapeRow(2).map(r => {
                            return r;
                        })}
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.columnHeader}>March</TableCell>
                        {renderFirmMonthlyShapeRow(3).map(r => {
                            return r;
                        })}
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.columnHeader}>April</TableCell>
                        {renderFirmMonthlyShapeRow(4).map(r => {
                            return r;
                        })}
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.columnHeader}>May</TableCell>
                        {renderFirmMonthlyShapeRow(5).map(r => {
                            return r;
                        })}
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.columnHeader}>June</TableCell>
                        {renderFirmMonthlyShapeRow(6).map(r => {
                            return r;
                        })}
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.columnHeader}>July</TableCell>
                        {renderFirmMonthlyShapeRow(7).map(r => {
                            return r;
                        })}
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.columnHeader}>August</TableCell>
                        {renderFirmMonthlyShapeRow(8).map(r => {
                            return r;
                        })}
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.columnHeader}>September</TableCell>
                        {renderFirmMonthlyShapeRow(9).map(r => {
                            return r;
                        })}
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.columnHeader}>October</TableCell>
                        {renderFirmMonthlyShapeRow(10).map(r => {
                            return r;
                        })}
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.columnHeader}>November</TableCell>
                        {renderFirmMonthlyShapeRow(11).map(r => {
                            return r;
                        })}
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.columnHeader}>December</TableCell>
                        {renderFirmMonthlyShapeRow(12).map(r => {
                            return r;
                        })}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );

}

export default FirmMonthlyShapeOutput;