import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";
import {cleanStringInput} from "../../../Utility/StringUtil";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {onError} from "../../../store/actions/popupActions";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';

const DeliveryYearAuctionComponent = (props) => {

    const {deliveryYear, isEdit} = props;
    const dispatch = useDispatch();
    const [deliveryYearAuction, setDeliveryYearAuction] = useState({deliveryYear: '', deliveryYearAuctions: []});

    useEffect(() => {
        if (deliveryYear) {
            retrieveDeliveryYearAuction(deliveryYear);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryYear])

    const retrieveDeliveryYearAuction = async () => {
        await trackPromise(axios.get("/capacity/deliveryYearAuction/deliveryYear/" + deliveryYear.replace("/", "-")).then(response => {
            let auctions = response.data;
            for (let auction of auctions) {
                auction.auctionOpenDate = moment(auction.auctionOpenDate).toDate();
                auction.auctionCloseDate = moment(auction.auctionCloseDate).toDate();
                auction.resultsPostedDate = moment(auction.resultsPostedDate).toDate();
            }
            let dyAuction = {deliveryYear: deliveryYear, deliveryYearAuctions: auctions};
            setDeliveryYearAuction(dyAuction);
            if (props.onChangeHandler) {
                props.onChangeHandler(dyAuction);
            }
        }).catch(error => {
            return dispatch(onError(error.response));
        }));
    }

    const onDeliveryYearChange = (e) => {
        deliveryYearAuction.deliveryYear = e.target.value;
        setDeliveryYearAuction(deliveryYearAuction);
        if (props.onChangeHandler) {
            props.onChangeHandler(deliveryYearAuction);
        }
    }

    const renderAuctions = () => {
        let renderedAuctions = [];
        let deliveryYearAuctions = deliveryYearAuction.deliveryYearAuctions;
        if (!deliveryYearAuctions || deliveryYearAuctions.length === 0) {
            deliveryYearAuctions.push({auction: ''});
        }
        for (let i = 0; i < deliveryYearAuctions.length; i++) {
            renderedAuctions.push(renderAuction(deliveryYearAuctions[i], deliveryYearAuctions.length - 1, i));
        }
        return renderedAuctions;
    }

    const renderAuction = (auction, totalCount, index) => {
        return (
            <Grid container spacing={2} key={index}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Auction Name" name="auction" value={cleanStringInput(auction.auction)}
                               onChange={(e) => onAuctionNameChange(e, index)} fullWidth disabled={!isEdit}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Auction Open" name="auctionOpen"
                                            onChange={(date) => onAuctionDateChange(date, index, "auctionOpenDate")} value={auction.auctionOpenDate} fullWidth disabled={!isEdit}/>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Auction Close" name="auctionOpen"
                                            onChange={(date) => onAuctionDateChange(date, index, "auctionCloseDate")} value={auction.auctionCloseDate} fullWidth
                                            disabled={!isEdit}/>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Results Posted" name="resultsPOstedDate"
                                            onChange={(date) => onAuctionDateChange(date, index, "resultsPostedDate")} value={auction.resultsPostedDate} fullWidth
                                            disabled={!isEdit}/>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1}/>
                {isEdit &&
                <Grid item xs={6} sm={3}>
                    {renderAddIcon(totalCount, index)}
                    <IconButton onClick={() => removeIconClick(index)}>
                        <RemoveIcon/>
                    </IconButton>
                </Grid>
                }
            </Grid>
        )
    }

    const onAuctionDateChange = (date, index, name) => {
        let deliveryYearAuctions = deliveryYearAuction.deliveryYearAuctions;
        deliveryYearAuctions[index][name] = date;
        deliveryYearAuction.deliveryYearAuctions = deliveryYearAuctions;
        setDeliveryYearAuction(deliveryYearAuction);
        if (props.onChangeHandler) {
            props.onChangeHandler(deliveryYearAuction);
        }
        console.log(deliveryYearAuction);
    }


    const onAuctionNameChange = (e, index) => {
        let auction = deliveryYearAuction.deliveryYearAuctions[index];
        auction.auction = e.target.value;
        deliveryYearAuction.deliveryYearAuctions.splice(index, 1, auction);
        setDeliveryYearAuction(deliveryYearAuction);
        if (props.onChangeHandler) {
            props.onChangeHandler(deliveryYearAuction);
        }
    }

    const renderAddIcon = (totalCount, index) => {
        if (index === totalCount) {
            return (
                <IconButton onClick={addIconClick}>
                    <AddIcon/>
                </IconButton>
            )
        }
    }

    const addIconClick = () => {
        deliveryYearAuction.deliveryYearAuctions.push({});
        setDeliveryYearAuction(deliveryYearAuction);
        if (props.onChangeHandler) {
            props.onChangeHandler(deliveryYearAuction);
        }
    }

    const removeIconClick = (index) => {
        deliveryYearAuction.deliveryYearAuctions.splice(index, 1);
        setDeliveryYearAuction(deliveryYearAuction);
        if (props.onChangeHandler) {
            props.onChangeHandler(deliveryYearAuction);
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Delivery Year" name="deliveryYear" value={cleanStringInput(deliveryYearAuction.deliveryYear)}
                               onChange={onDeliveryYearChange} fullWidth disabled={!isEdit}/>
                </Grid>
                <Grid item xs={6} sm={4}/>
            </Grid>
            {renderAuctions()}
        </React.Fragment>
    );

}

export default DeliveryYearAuctionComponent;