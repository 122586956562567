import React, {Component} from "react";
import {withStyles, withTheme} from '@material-ui/core/styles';
import AccountComponent from "./AccountComponent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {saveAccount} from "../../../store/account-management/actions/accountActions";
import {connect} from "react-redux";


const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

class CreateAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            account: {}
        }
    }

    accountChangeHandler = (account) => {
        this.setState({account: account});
    }

    handleSave = () => {
        this.props.saveAccount(this.state.account);
        this.props.history.goBack();
    }

    handleBack = () => {
        this.props.history.goBack();
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <Typography variant="h2" align="center">Create Account</Typography>
                <AccountComponent canEdit={true} onChange={this.accountChangeHandler} />
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={this.handleSave} fullWidth>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={2} />
                    <Grid item xs={6} sm={4}>
                        <Button onClick={this.handleBack} fullWidth>Back</Button>
                    </Grid>
                    <Grid item xs={6} sm={1} />
                </Grid>
            </main>

        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        saveAccount: (account) => dispatch(saveAccount(account))
    }
}

export default withTheme(withStyles(styles)(connect(null,mapDispatchToProps)(CreateAccount)));