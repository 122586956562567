import React, {Component} from 'react';
import {connect} from 'react-redux';

import {withStyles, withTheme} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import AccountTable from "./AccountTable";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
});

class SearchAccounts extends Component {

    handleButtonClick = () => {
        this.props.history.push("/account-management/accounts/create");
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <Typography variant="h3" align="center">Accounts</Typography>
                {this.props.superUser && <Button onClick={this.handleButtonClick}>Create Account</Button>}
                <AccountTable />
            </main>
        )
    }

}

const mapStateToProps = state => {
    return {
        superUser: state.auth.superUser
    }
}

export default withTheme(withStyles(styles)(connect(mapStateToProps,null)(SearchAccounts)));