import axios from 'axios';

import store from '../store/store';
import {RESPONSE_RECEIVED} from "../store/actionTypes";
import qs from 'qs';

const instance = axios.create({
    baseURL: '/wpec/services', timeout: 300000, headers: { 'Content-Type': 'application/json' }, withCredentials: true, paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
});

const {dispatch} = store;

instance.interceptors.response.use(
    response => {
        dispatch({type: RESPONSE_RECEIVED});
        return response;
    },
    error => {
        const {status} = error.response;
        if(status !== 401) {
            dispatch({type: RESPONSE_RECEIVED});
        }
        return Promise.reject(error);
    }
);

export default instance;
