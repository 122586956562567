import {RETRIEVE_USERS_SUCCESS} from '../userManagementActionTypes';
import {ACCOUNT_CHANGED} from "../../actionTypes";

const initialState = {
    users: [],
    user: undefined,
    accountRoles: []
};

const retrieveUsers = (state, action) => {
    return {...state, users: action.users};
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case RETRIEVE_USERS_SUCCESS:
            return retrieveUsers(state, action);
        case ACCOUNT_CHANGED:
            return initialState;
        default:
            return state;
    }
};

export default userReducer;