import React from 'react';
import {connect} from "react-redux";
import {withTheme} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import {doLogout} from "../../store/actions/authActions";

const UserProfile = (props) => {

    const [accountMenuAnchorEl, setAccountMenuAnchorEl] = React.useState(null);
    const accountMenuOpen = Boolean(accountMenuAnchorEl);

    const handleAccountMenuClick = (e) => {
        setAccountMenuAnchorEl(e.currentTarget);
    };

    const handleAccountMenuClose = () => {
        setAccountMenuAnchorEl(null);
    };

    const handleLogoutClick = () => {
        setAccountMenuAnchorEl(null);
        props.history.replace("/logout");
        props.logout();
    };

    const handleUserProfileClick = () => {
        setAccountMenuAnchorEl(null);
        props.history.push("/user-management/users/" + props.username);
    }

    return (
        <React.Fragment>
            <IconButton edge="end" aria-label="account" onClick={handleAccountMenuClick}>
                <AccountCircle/>
            </IconButton>
            <Menu open={accountMenuOpen} onClose={handleAccountMenuClose} anchorEl={accountMenuAnchorEl} getContentAnchorEl={null}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                <MenuItem onClick={handleUserProfileClick}>My Profile</MenuItem>
                <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
            </Menu>
        </React.Fragment>);

};

const mapStateToProps = state => {
    return {
        chosenAccount: state.chosenAccount.account,
        username: state.auth.username
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(doLogout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withRouter(UserProfile)));