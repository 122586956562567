import {trackPromise} from 'react-promise-tracker';

import axios from '../../../axios/AxiosInterceptors';
import {
    EXACT_MATCH,
    MULTIPLE_MATCHES,
    NO_MATCH,
    RETRIEVE_BUY_BID,
    RETRIEVE_BUY_BID_MATCHES_FOR_ACCOUNT,
    RETRIEVE_BUY_BIDS,
    RETRIEVE_POTENTIAL_BUY_BID_MATCHES_FOR_ACCOUNT,
    RETRIEVE_POTENTIAL_SELL_OFFERS_FOR_BUY_BID,
    RETRIEVE_SELL_OFFER_FOR_BUY_BID,
    SEARCH_BUY_BIDS
} from '../sparkExchangeActionTypes';
import {onError, onSuccess} from "../../actions/popupActions";
import {SUCCESS} from "../../actionTypes";
import {Client} from '@stomp/stompjs';
import SockJS from 'sockjs-client'

export const retrieveBuyBidsSuccess = (buyBids) => {
    return {
        type: RETRIEVE_BUY_BIDS,
        buyBids: buyBids
    };
};

export const retrieveBuyBidSuccess = (buyBid) => {
    return {
        type: RETRIEVE_BUY_BID,
        buyBid: buyBid
    }
};

export const saveBuyBidSuccess = (message) => {
    return {
        type: SUCCESS,
        message: message
    }
};

export const searchBuyBidsSuccess = (buyBidResults) => {
    return {
        type: SEARCH_BUY_BIDS,
        buyBidResults: buyBidResults
    }
};

export const retrieveMatchedSellOfferSuccess = (matchedBuyBidSellOffer) => {
    return {
        type: RETRIEVE_SELL_OFFER_FOR_BUY_BID,
        matchedBuyBidSellOffer: matchedBuyBidSellOffer
    };
};

export const retrievePotentialMatchesSuccess = (potentialMatches) => {
    return {
        type: RETRIEVE_POTENTIAL_SELL_OFFERS_FOR_BUY_BID,
        potentialMatches: potentialMatches
    };
}

export const updateBuyBidSuccess = (message) => {
    return {
        type: SUCCESS,
        message: message
    }
}

export function createBuyBid(accountId, buyBid) {
    return async (dispatch) => {
        await trackPromise(axios.post("/spark-exchange/account/" + accountId + "/buy-bids", JSON.stringify(buyBid)).then(response => {
            dispatch(saveBuyBidSuccess("Buy Bid saved"));
            if (buyBid.published) {
                openBuyBidWebSocket(accountId, response.data.id, dispatch);
            }
            return dispatch(retrieveBuyBids(accountId, 0, 10));
        }).catch(error => {
                console.log(error);
                dispatch(onError(error.response));
            }
        ));
    };
}

function openBuyBidWebSocket(accountId, buyBidId, dispatch) {
    let client = new Client();
    client.configure({
        webSocketFactory: () => {
            return new SockJS("/wpec/services/spark-exchange/match")
        }, debug: (str) => {
            console.log(str);
        }, onConnect: () => {
            client.subscribe("/spark-exchange/topic/match/account/" + accountId + "/buy-bid/" + buyBidId, (output) => {
                let response = JSON.parse(output.body);
                if (response.exactMatchCount === 1 || response.fuzzyMatchCount === 1) {
                    dispatch({type: EXACT_MATCH, matchType: 'Buy Bid', accountId: accountId});
                } else if (response.exactMatch > 1 || response.fuzzyMatchCount > 1) {
                    dispatch({type: MULTIPLE_MATCHES, matchType: 'Buy Bid', accountId: accountId});
                } else {
                    dispatch({type: NO_MATCH, matchType: 'Buy Bid'});
                }
                client.deactivate();
            });

        }
    });
    client.activate();
}

export function retrieveBuyBids(accountId, pageNumber, pageSize, orderBy, direction) {
    return async (dispatch) => {
        let url = "/spark-exchange/account/" + accountId + "/buy-bids?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
        if (orderBy) {
            url = url + "&orderBy=" + orderBy;
        }
        if (direction) {
            url = url + "&direction=" + direction;
        }
        await trackPromise(axios.get(url).then(response => {
            return dispatch(retrieveBuyBidsSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    };
}

export function searchBuyBids(buyBidSearch, pageNumber, pageSize, orderBy, direction) {
    let queryParams = '?pageNumber=' + pageNumber + '&pageSize=' + pageSize;
    let url = '/spark-exchange/buy-bids/search' + queryParams;
    if (orderBy) {
        url = url + "&orderBy=" + orderBy;
    }
    if (direction) {
        url = url + "&direction=" + direction;
    }
    return async (dispatch) => {
        await trackPromise(axios.post(url, JSON.stringify(buyBidSearch)).then(response => {
            return dispatch(searchBuyBidsSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    };
}

export function retrieveBuyBid(accountId, buyBidId) {
    return async (dispatch) => {
        await trackPromise(axios.get("/spark-exchange/account/" + accountId + "/buy-bids/" + buyBidId).then(response => {
            return dispatch(retrieveBuyBidSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    };
}

export function updateBuyBid(accountId, buyBidId, buyBid) {
    return async (dispatch) => {
        await trackPromise(axios.put("/spark-exchange/account/" + accountId + "/buy-bids/" + buyBidId, JSON.stringify(buyBid)).then(response => {
            if (buyBid.published) {
                openBuyBidWebSocket(accountId, response.data.id, dispatch);
            }
            dispatch(updateBuyBidSuccess("Buy Bid saved"));
            return dispatch(retrieveBuyBids(accountId, 0, 10));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }
}

export function retrieveMatchedSellOffer(accountId, buyBidId) {
    return async (dispatch) => {
        await trackPromise(axios.get("/spark-exchange/account/" + accountId + "/buy-bids/" + buyBidId + "/match").then(response => {
            dispatch(retrieveMatchedSellOfferSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    };
}

export function createBuyBidForSellOffer(accountId, sellOffer) {
    return async (dispatch) => {
        await trackPromise(axios.post("/spark-exchange/account/" + accountId + "/buy-bids/match", JSON.stringify(sellOffer)).then(response => {
            dispatch(onSuccess("Buy Bid created successfully"));
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }
}

export function retrievePotentialMatches(accountId, buyBidId) {
    return async (dispatch) => {
        await trackPromise(axios.get("/spark-exchange/account/" + accountId + "/buy-bids/" + buyBidId + "/match/potential").then(response => {
            dispatch(retrievePotentialMatchesSuccess(response.data))
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }
}

export function retrieveBuyBidMatchesForAccount(accountId) {
    return async (dispatch) => {
        await trackPromise(axios.get("/spark-exchange/account/" + accountId + "/buy-bids/match").then(response => {
            dispatch({type: RETRIEVE_BUY_BID_MATCHES_FOR_ACCOUNT, matches: response.data})
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }
}

export function retrievePotentialBuyBidMatchesForAccount(accountId) {
    return async (dispatch) => {
        await trackPromise(axios.get("/spark-exchange/account/" + accountId + "/buy-bids/match/potential").then(response => {
            dispatch({type: RETRIEVE_POTENTIAL_BUY_BID_MATCHES_FOR_ACCOUNT, potentialMatches: response.data});
        }).catch(error => {
            dispatch(onError(error.response));
        }))
    }
}