import React, {useEffect, useState} from 'react';
import axios from "../../../axios/AxiosInterceptors";
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {Collapse, FormHelperText} from "@material-ui/core";
import moment from "moment/moment";

import GeneratorAttributes from "../GeneratorAttributes/GeneratorAttributes";
import EightySevenSixtyProfile from "../EightySevenSixtyProfile/EightySevenSixtyProfile";
import EnvironmentAttributes from "./EnvironmentAttributes";
import {cleanStringInput} from "../../../Utility/StringUtil";
import {useHistory, useParams} from "react-router";
import Button from "@material-ui/core/Button";
import {saveGenerator, updateGenerator} from "../../../store/spark-exchange/actions/generatorActions";
import {useDispatch, useSelector} from "react-redux";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import {trackPromise} from "react-promise-tracker";
import {onError} from "../../../store/actions/popupActions";
import {retrieveRequiredMilestones} from "../../../store/spark-exchange/actions/sparkExchangeActions";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
}));

const GeneratorComponent = (props) => {

    const classes = useStyles();
    const {accountId} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [generatorAttributesOpen, setGeneratorAttributesOpen] = useState(true);
    const [environmentalAttributesOpen, setEnvironmentalAttributesOpen] = useState(true);
    const [eightySevenSixtyProfileOpen, setEightySevenSixtyProfileOpen] = useState(false);
    const requiredMilestones = useSelector(state => state.sparkExchange.requiredMilestones);
    const [isEdit, setIsEdit] = useState(false);
    const [generator, setGenerator] = useState({id: undefined, generatorType: '', requiredMilestones: []});
    const [validation, setValidation] = useState({});
    const [uploadedIsaWmpa, setUploadedIsaWmpa] = useState('');
    const [uploadedRequiredMilestone, setUploadedRequiredMilestone] = useState('');

    useEffect(() => {
        if (props.generator) {
            setGenerator(props.generator);
            if (props.generator.isaWmpaFileName) {
                setUploadedIsaWmpa(props.generator.isaWmpaFileName);
            }
        }
        if (!requiredMilestones || requiredMilestones.length < 1) {
            dispatch(retrieveRequiredMilestones());
        }
        setIsEdit(props.isEdit !== undefined ? props.isEdit : false);
        // eslint-disable-next-line
    }, [props]);

    const generatorTypes = ['Bio-Mass', 'Coal', 'Fuel Cell', 'Gas', 'Hydro', 'Nuclear', 'Pumped Hydro', 'Solar', 'Storage', 'Waste Energy', 'Wind'];
    const pjmStates = ['DE', 'DC', 'IL', 'IN', 'KY', 'MD', 'MI', 'NJ', 'NC', 'OH', 'PA', 'TN', 'VA', 'WV'];

    const onGeneratorTypeChange = (e) => {
        generator.generatorType = e.target.value;
        generator.nuclearWasteEmissions = null;
        generator.carbonDioxideEmissions = null;
        generator.nitrogenOxideEmissions = null;
        generator.sulfurDioxideEmissions = null;
        generator.particulatesEmissions = null;
        generator.heatRate = null;
        generator.fuelRate = null;
        generator.primaryFuelType = null;
        generator.secondaryFuelType = null;
        generator.registeredRecs = [];
        if (generator.generatorType) {
            validation.generatorType = false;
            validation.generatorTypeMessage = '';
            setValidation({...validation});
        }
        setGenerator({...generator});
    }

    const renderGeneratorAttributes = () => {
        switch (generator.generatorType) {
            case 'Bio-Mass':
            case 'Coal':
            case 'Fuel Cell':
            case 'Gas':
            case 'Waste Energy':
            case 'Nuclear':
                return true;
            case 'Hydro':
            case 'Pumped Hydro':
            case 'Storage':
            case 'Wind':
            case 'Solar':
                return false;
            default:
                return false;
        }
    }

    const renderEnvironmentalAttributes = () => {
        return generator.generatorType;
    }

    const render8760Profile = () => {
        return generator.generatorType;
    }

    const onInputChangeHandler = (e) => {
        if (e.target.type === "number") {
            if (e.target.value) {
                generator[e.target.name] = Number.parseFloat(e.target.value);
            } else {
                generator[e.target.name] = e.target.value;
            }
        } else {
            generator[e.target.name] = e.target.value;
        }
        setGenerator({...generator});
    };

    const onIsaWmpChosen = (e) => {
        const formData = new FormData();
        formData.append("file", e.target.files[0], e.target.files[0].name);
        setUploadedIsaWmpa(e.target.files[0].name)
        generator.isaWmpa = formData;
        setGenerator({...generator});
    }

    const componentInputChangeHandler = (field, value) => {
        generator[field] = value;
        setGenerator({...generator});
    }

    const renderGeneratorType = () => {
        if (isEdit) {
            return (<FormControl fullWidth required>
                <InputLabel>Generator Type</InputLabel>
                <Select value={cleanStringInput(generator.generatorType)} onChange={onGeneratorTypeChange} name="generatorType" fullWidth>
                    {generatorTypes.map(generatorType => <MenuItem value={generatorType} key={generatorType}>{generatorType}</MenuItem>)}
                </Select>
                <FormHelperText error={validation.generatorType}>{validation.generatorTypeMessage}</FormHelperText>
            </FormControl>);
        } else {
            return (<TextField value={cleanStringInput(generator.generatorType)} label="Generator Type" name="generatorType" disabled={true} fullWidth/>);
        }
    }

    const renderGeneratorState = () => {
        if(isEdit) {
            return (
                <FormControl fullWidth required>
                    <InputLabel>State</InputLabel>
                    <Select value={cleanStringInput(generator.state)} onChange={onInputChangeHandler} name="state" fullWidth>
                        {pjmStates.map(pjmState => <MenuItem value={pjmState} key={pjmState}>{pjmState}</MenuItem>)}
                    </Select>
                    <FormHelperText error={validation.state}>{validation.stateMessage}</FormHelperText>
                </FormControl>
            );
        } else {
            return (<TextField value={cleanStringInput(generator.state)} lable="State" name="generatorState" disabled={true} fullWidth/> );
        }
    }

    const saveButtonClick = () => {
        if (isValid()) {
            generator.accountId = accountId;
            if (generator.registeredRecs && generator.registeredRecs.length === 1 && generator.registeredRecs[0].state === '') {
                generator.registeredRecs = [];
            }
            if (generator.id) {
                dispatch(updateGenerator(generator.accountId, generator.id, generator));
            } else {
                dispatch(saveGenerator(generator));
            }
            history.push("/spark-exchange/account/" + accountId + "/generators");
        }
    }

    const editButtonClick = () => {
        setIsEdit(true);
    }

    const editBackButtonClick = () => {
        if (generator.id) {
            setIsEdit(false);
        } else {
            history.goBack();
        }
    }

    const viewBackButtonClick = () => {
        history.goBack();
    }

    const isValid = () => {
        let isValid = true;
        if (!generator.generatorName) {
            validation.generatorName = true;
            validation.generatorNameMessage = "Generator name is required";
            isValid = false;
        } else if (generator.generatorName && generator.generatorName.length < 2) {
            validation.generatorName = true;
            validation.generatorNameMessage = "Generator name must be at least 2 characters";
            isValid = false;
        } else {
            validation.generatorName = false;
            validation.generatorNameMessage = '';
        }
        if (!generator.addressLineOne) {
            validation.addressLineOne = true;
            validation.addressLineOneMessage = "Address is required";
            isValid = false;
        } else {
            validation.addressLineOne = false;
            validation.addressLineOneMessage = '';
        }
        if (!generator.city) {
            validation.city = true;
            validation.cityMessage = "City is required";
            isValid = false;
        } else {
            validation.city = false;
            validation.cityMessage = '';
        }
        if (!generator.state) {
            validation.state = true;
            validation.stateMessage = "State is required";
            isValid = false;
        } else {
            validation.state = false;
            validation.stateMessage = '';
        }
        if (!generator.zipCode) {
            validation.zipCode = true;
            validation.zipCodeMessage = "Zip Code is required";
            isValid = false;
        } else if (generator.zipCode && generator.zipCode.length !== 5 && generator.zipCode.length !== 10) {
            validation.zipCode = true;
            validation.zipCodeMessage = "Zip Code must be either 5 or 10 digits (with a dash)";
            isValid = false;
        } else {
            validation.zipCode = false;
            validation.zipCodeMessage = '';
        }
        if (!generator.maximumOutput) {
            validation.maximumOutput = true;
            validation.maximumOutputMessage = "Nameplate Capacity is required";
            isValid = false;
        } else {
            validation.maximumOutput = false;
            validation.maximumOutputMessage = '';
        }
        if (!generator.queueNumber) {
            validation.queueNumber = true;
            validation.queueNumberMessage = "Queue Number is required";
            isValid = false;
        } else {
            validation.queueNumber = false;
            validation.queueNumberMessage = "";
        }
        if (uploadedIsaWmpa) {
            if (uploadedIsaWmpa.length > 50) {
                validation.uploadedIsaWmpa = true;
                validation.uploadedIsaWmpaMessage = "Filename must be less than 50 characters";
                isValid = false;
            } else {
                validation.uploadedIsaWmpa = false;
                validation.uploadedIsaWmpaMessage = "";
            }
        }
        if (!generator.generatorType) {
            validation.generatorType = true;
            validation.generatorTypeMessage = "A Generator type must be selected";
            isValid = false;
        } else {
            validation.generatorType = false;
            validation.generatorTypeMessage = '';
        }
        if (generator.generatorType) {
            if (generator.generatorType === 'Bio-Mass' || generator.generatorType === 'Coal' || generator.generatorType === 'Fuel Cell' || generator.generatorType === 'Gas' ||
                generator.generatorType === 'Waste Energy') {
                if (!generator.primaryFuelType) {
                    validation.primaryFuelType = true;
                    validation.primaryFuelTypeMessage = "Primary fuel type is required";
                    isValid = false;
                } else {
                    validation.primaryFuelType = false;
                    validation.primaryFuelTypeMessage = '';
                }
                if (!generator.carbonDioxideEmissions) {
                    validation.carbonDioxideEmissions = true;
                    validation.carbonDioxideEmissionsMessage = "Carbon Dioxide emissions are required";
                    isValid = false;
                } else {
                    validation.carbonDioxideEmissions = false;
                    validation.carbonDioxideEmissionsMessage = '';
                }
                if (!generator.nitrogenOxideEmissions) {
                    validation.nitrogenOxideEmissions = true;
                    validation.nitrogenOxideEmissionsMessage = "Nitrogen Oxide emissions are required";
                    isValid = false;
                } else {
                    validation.nitrogenOxideEmissions = false;
                    validation.nitrogenOxideEmissionsMessage = '';
                }
                if (!generator.sulfurDioxideEmissions) {
                    validation.sulfurDioxideEmissions = true;
                    validation.sulfurDioxideEmissionsMessage = "Sulfur Dioxide emissions are required";
                    isValid = false;
                } else {
                    validation.sulfurDioxideEmissions = false;
                    validation.sulfurDioxideEmissionsMessage = '';
                }
                if (!generator.particulatesEmissions) {
                    validation.particulatesEmissions = true;
                    validation.particulatesEmissionsMessage = "Particulates emissions are required";
                    isValid = false;
                } else {
                    validation.particulatesEmissions = false;
                    validation.particulatesEmissionsMessage = '';
                }
            }
            if (generator.generatorType === 'Bio-Mass' || generator.generatorType === 'Coal' || generator.generatorType === 'Fuel Cell' || generator.generatorType === 'Gas' ||
                generator.generatorType === 'Waste Energy' || generator.generatorType === 'Nuclear') {
                if (!generator.heatRate) {
                    validation.heatRate = true;
                    validation.heatRateMessage = "Heat rate is required";
                    isValid = false;
                } else {
                    validation.heatRate = false;
                    validation.heatRateMessage = '';
                }
                if (!generator.fuelRate) {
                    validation.fuelRate = true;
                    validation.fuelRateMessage = "Fuel rate is required";
                    isValid = false;
                } else {
                    validation.fuelRate = false;
                    validation.fuelRateMessage = '';
                }
            }
            if (generator.generatorType === 'Nuclear') {
                if (!generator.nuclearWasteEmissions) {
                    validation.nuclearWasteEmissions = true;
                    validation.nuclearWasteEmissionsMessage = "Nuclear Waste emissions are required";
                    isValid = false;
                } else {
                    validation.nuclearWasteEmissions = false;
                    validation.nuclearWasteEmissionsMessage = '';
                }
            }
            if (generator.registeredRecs && generator.registeredRecs.length > 0) {
                let registeredRecs = [];
                for (let registeredRec of generator.registeredRecs) {
                    let valid = false;
                    let keys = Object.keys(registeredRec);
                    keys.forEach(key => {
                        if (key !== 'state' && registeredRec[key]) {
                            valid = true;
                        }
                    });
                    if (valid === false) {
                        registeredRecs.push(registeredRec.state);
                    }
                }
                if (registeredRecs.length > 0) {
                    isValid = false;
                }
                validation.registeredRecs = registeredRecs;
            }
        }
        setValidation({...validation});
        return isValid;
    }


    const renderButtons = () => {
        if (isEdit) {
            return (
                <Grid container spacing={6}>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={saveButtonClick}>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={editBackButtonClick}>Back</Button>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                </Grid>
            )
        } else {
            return (
                <React.Fragment>
                    <Grid container spacing={6}>
                        <Grid item xs={6} sm={2}/>
                        <Grid item xs={6} sm={3} align="center">
                            <Button variant="contained" onClick={editButtonClick}>Edit</Button>
                        </Grid>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={3} align="center">
                            <Button variant="contained" onClick={viewBackButtonClick}>Back</Button>
                        </Grid>
                        <Grid item xs={6} sm={2}/>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    const viewIsaWmpa = async () => {
        await trackPromise(axios.get("/spark-exchange/account/" + accountId + "/generators/" + generator.id + "/isaWmpa", {
            responseType: 'arraybuffer',
            headers: {'Accept': 'application/pdf'}
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a')
            link.href = url;
            link.setAttribute('download', uploadedIsaWmpa);
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    const viewRequiredMilestone = async (requiredMilestone) => {
        await trackPromise(axios.get("/spark-exchange/account/" + accountId + "/generators/" + generator.id + "/milestones/" + requiredMilestone.id + "/documentation", {
            responseType: 'arraybuffer'
        }).then(response => {
            setUploadedRequiredMilestone(requiredMilestone.documentationFilename);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a')
            link.href = url;
            link.setAttribute('download', uploadedRequiredMilestone);
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }

    const renderIsaWmpaField = () => {
        if (isEdit) {
            return (
                <label htmlFor="uploadIsaWmpa">
                    <TextField value={uploadedIsaWmpa} label="Executed ISA/WMPA" InputProps={{startAdornment: <Button component="span" disabled={!isEdit}>Browse</Button>}}
                               fullWidth disabled={!isEdit} helperText={validation.uploadedIsaWmpaMessage} error={validation.uploadedIsaWmpa}/>
                    <input style={{display: "none"}} id="uploadIsaWmpa" name="uploadIsaWmpa" type="file" onChange={onIsaWmpChosen} disabled={!isEdit}/>
                </label>
            );
        }
        if (generator.isaWmpaFileName) {
            return (<TextField value={uploadedIsaWmpa} label="Executed ISA/WMPA" fullWidth disabled={!isEdit}
                               InputProps={{startAdornment: <Button component="span" onClick={viewIsaWmpa}>View</Button>}}/>)
        }
        return (<TextField value={uploadedIsaWmpa} label="Executed ISA/WMPA" fullWidth disabled={!isEdit}/>)
    }

    const renderMilestoneDocumentationField = (requiredMilestone) => {
        if (isEdit) {
            return (
                <label htmlFor={"milestoneDocumentation" + requiredMilestone.id}>
                    <TextField value={findMilestoneDocumentation(requiredMilestone)} label="Milestone Documentation"
                               InputProps={{startAdornment: <Button component="span" disabled={!isEdit}>Browse</Button>}}
                               fullWidth disabled={!isEdit}/>
                    <input style={{display: "none"}} id={"milestoneDocumentation" + requiredMilestone.id} name={"milestoneDocumentation" + requiredMilestone.id} type="file"
                           onChange={(e) => onMilestoneDocumentationChosen(e, requiredMilestone)} disabled={!isEdit}/>
                </label>
            );
        }
        if (generator.requiredMilestones && generator.requiredMilestones.length > 0) {
            let generatorRequiredMilestone = generator.requiredMilestones.filter(r => r.milestoneName === requiredMilestone.milestoneName);
            if (generatorRequiredMilestone && generatorRequiredMilestone.length === 1 && generatorRequiredMilestone[0].documentationFilename) {
                return (
                    <TextField value={generatorRequiredMilestone[0].documentationFilename} label="Milestone Documentation" fullWidth disabled={!isEdit}
                               InputProps={{startAdornment: <Button component="span" onClick={() => viewRequiredMilestone(generatorRequiredMilestone[0])}>View</Button>}}/>
                )
            }
        }
        return (<TextField value={uploadedIsaWmpa} label="Milestone Documentation" fullWidth disabled={!isEdit}/>);
    }

    const findMilestoneDate = (requiredMilestone) => {
        if (generator.requiredMilestones && generator.requiredMilestones.length > 0) {
            for (let rm of generator.requiredMilestones) {
                if (rm.milestoneName === requiredMilestone.milestoneName) {
                    if (rm.milestoneDate) {
                        return moment(rm.milestoneDate);
                    }
                }
            }
        }
        return null;
    }

    const onMilestoneDateChange = (date, value, requiredMilestone) => {
        let found = false;
        for (let rm of generator.requiredMilestones) {
            if (rm.milestoneName === requiredMilestone.milestoneName) {
                rm.milestoneDate = date;
                found = true;
            }
        }
        if (!found) {
            generator.requiredMilestones.push({milestoneName: requiredMilestone.milestoneName, milestoneDate: date});
        }
        setGenerator({...generator});
    }

    const findMilestoneDocumentation = (requiredMilestone) => {
        if (generator.requiredMilestones && generator.requiredMilestones.length > 0) {
            for (let rm of generator.requiredMilestones) {
                if (rm.milestoneName === requiredMilestone.milestoneName) {
                    return rm.documentationFilename;
                }
            }
        }
        return '';
    }

    const onMilestoneDocumentationChosen = (e, requiredMilestone) => {
        let found = false;
        const formData = new FormData();
        formData.append("file", e.target.files[0], e.target.files[0].name);
        for (let rm of generator.requiredMilestones) {
            if (rm.milestoneName === requiredMilestone.milestoneName) {
                rm.documentationFilename = e.target.files[0].name;
                rm.documentation = formData;
                found = true;
            }
        }
        if (!found) {
            generator.requiredMilestones.push({milestoneName: requiredMilestone.milestoneName, documentationFilename: e.target.files[0].name, documentation: formData});
        }
        setGenerator({...generator});
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2}>
                <Grid item xs={12} align="center">
                    <Typography variant="h6">Generator Metadata</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Generator Name" name="generatorName" value={cleanStringInput(generator.generatorName)}
                               onChange={onInputChangeHandler} disabled={!isEdit} fullWidth autoFocus={true} required error={validation.generatorName}
                               helperText={validation.generatorNameMessage ? validation.generatorNameMessage : "Two characters minimum"}/>
                </Grid>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Address" name={"addressLineOne"} value={cleanStringInput(generator.addressLineOne)} onChange={onInputChangeHandler} disabled={!isEdit}
                               fullWidth required
                               error={validation.addressLineOne} helperText={validation.addressLineOneMessage}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="City" name="city" value={cleanStringInput(generator.city)} onChange={onInputChangeHandler} disabled={!isEdit} fullWidth required
                               error={validation.city} helperText={validation.cityMessage}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    {renderGeneratorState()}
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Zip Code" name="zipCode" value={cleanStringInput(generator.zipCode)} onChange={onInputChangeHandler} disabled={!isEdit} fullWidth required
                               error={validation.zipCode} helperText={validation.zipCodeMessage}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label={"Nameplate Capacity"} name={"maximumOutput"} value={cleanStringInput(generator.maximumOutput)}
                               onChange={onInputChangeHandler} disabled={!isEdit} fullWidth required
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWs</InputAdornment>, inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }}
                               error={validation.maximumOutput} helperText={validation.maximumOutputMessage}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label={"PNode Id"} name={"pnodeId"} value={cleanStringInput(generator.pnodeId)} onChange={onInputChangeHandler} disabled={!isEdit} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="PJM Queue Number" name="queueNumber" value={cleanStringInput(generator.queueNumber)} onChange={onInputChangeHandler} disabled={!isEdit}
                               fullWidth required error={validation.queueNumber} helperText={validation.queueNumberMessage}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    {renderIsaWmpaField()}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {requiredMilestones && requiredMilestones.map(r => {
                    return (
                        <React.Fragment key={r.id}>
                            <Grid item xs={6} sm={2}>
                                <Typography>{r.milestoneName}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Typography>{r.milestoneDescription}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker disableToolbar variant="inline" value={findMilestoneDate(r)} format="yyyy-MM-dd" label="Date" name="milestoneDate"
                                                        disabled={!isEdit} fullWidth
                                                        autoOk={true} onChange={(date, value) => onMilestoneDateChange(date, value, r)}/>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                {renderMilestoneDocumentationField(r)}
                            </Grid>
                        </React.Fragment>
                    );
                })
                }
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={4}>
                    {renderGeneratorType()}
                </Grid>
                <Grid item xs={6} sm={4}/>
            </Grid>
            {renderGeneratorAttributes() &&
                <React.Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <IconButton aria-label="expand row" size="small" onClick={() => setGeneratorAttributesOpen(!generatorAttributesOpen)}>
                                {generatorAttributesOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} align="center">
                            <Typography variant="h6">Generator Attributes</Typography>
                        </Grid>
                    </Grid>
                    <Collapse in={generatorAttributesOpen} timeout="auto" unmountOnExit>
                        <GeneratorAttributes generatorType={generator.generatorType} data={{
                            primaryFuelType: generator.primaryFuelType,
                            secondaryFuelType: generator.secondaryFuelType,
                            heatRate: generator.heatRate,
                            fuelRate: generator.fuelRate
                        }} canEdit={isEdit} inputChangeHandler={componentInputChangeHandler} key={generator.id} validation={validation}/>
                    </Collapse>
                </React.Fragment>
            }
            {render8760Profile() &&
                <React.Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <IconButton aria-label="expand row" size="small" onClick={() => setEightySevenSixtyProfileOpen(!eightySevenSixtyProfileOpen)}>
                                {eightySevenSixtyProfileOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} align="center">
                            <Typography variant="h6">8760 Profile</Typography>
                        </Grid>
                    </Grid>
                    <Collapse in={eightySevenSixtyProfileOpen} timeout="auto" unmountOnExit>
                        <EightySevenSixtyProfile generatorId={generator.id} accountId={accountId} canEdit={isEdit} inputChangeHandler={componentInputChangeHandler}
                                                 key={generator.id}/>
                    </Collapse>
                </React.Fragment>
            }
            {renderEnvironmentalAttributes() &&
                <React.Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <IconButton aria-label="expand row" size="small" onClick={() => setEnvironmentalAttributesOpen(!environmentalAttributesOpen)}>
                                {environmentalAttributesOpen ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} align="center">
                            <Typography variant="h6">Environmental Attributes</Typography>
                        </Grid>
                    </Grid>
                    <Collapse in={environmentalAttributesOpen} timeout="auto" unmountOnExit>
                        <EnvironmentAttributes generatorType={generator.generatorType} data={{
                            carbonDioxideEmissions: generator.carbonDioxideEmissions,
                            nitrogenOxideEmissions: generator.nitrogenOxideEmissions,
                            particulatesEmissions: generator.particulatesEmissions,
                            sulfurDioxideEmissions: generator.sulfurDioxideEmissions,
                            nuclearWasteEmissions: generator.nuclearWasteEmissions,
                            registeredRecs: generator.registeredRecs
                        }} canEdit={isEdit} key={generator.id} inputChangeHandler={componentInputChangeHandler} validation={validation}/>
                    </Collapse>
                </React.Fragment>
            }
            {props.showButtons && renderButtons()}
        </main>
    );
}

export default GeneratorComponent;