import {createStore, applyMiddleware, combineReducers, compose} from 'redux';
import thunk from 'redux-thunk';

import sellOfferReducer from './spark-exchange/reducers/SellOfferReducer';
import buyBidReducer from './spark-exchange/reducers/BuyBidReducer';
import generatorReducer from './spark-exchange/reducers/GeneratorReducer';
import authReducer from './reducers/AuthReducer';
import chosenAccountReducer from './reducers/ChosenAccountReducer';
import popupReducer from './reducers/PopupReducer';
import userReducer from './user-management/reducers/userReducer';
import chosenApplicationReducer from './reducers/ChosenApplicationReducer';
import {LOGOUT} from './actionTypes';
import accountRoleReducer from "./user-management/reducers/accountRoleReducer";
import applicationRoleReducer from "./user-management/reducers/applicationRoleReducer";
import buyBidProfileReducer from './spark-exchange/reducers/BuyBidProfileReducer';
import accountsReducer from "./account-management/reducers/accountReducer";
import capacityExchangeReducer from "./capacity-exchange/reducers/CapacityExchangeReducer";
import sellOfferSearchReducer from "./spark-exchange/reducers/SellOfferSearchReducer";
import buyBidSearchReducer from "./spark-exchange/reducers/BuyBidSearchReducer";
import matchAlertReducer from "./spark-exchange/reducers/MatchAlertReducer";
import matchReducer from "./spark-exchange/reducers/MatchReducer";
import sparkExchangeReducer from "./spark-exchange/reducers/SparkExchangeReducer";

const appReducers = combineReducers({
    sellOffer: sellOfferReducer,
    buyBid: buyBidReducer,
    generator: generatorReducer,
    auth: authReducer,
    chosenAccount: chosenAccountReducer,
    popup: popupReducer,
    users:userReducer,
    chosenApplication: chosenApplicationReducer,
    accountRoles: accountRoleReducer,
    applicationRoles: applicationRoleReducer,
    buyBidProfiles: buyBidProfileReducer,
    accounts: accountsReducer,
    capacity: capacityExchangeReducer,
    sellOfferSearch: sellOfferSearchReducer,
    buyBidSearch: buyBidSearchReducer,
    matchAlert: matchAlertReducer,
    match: matchReducer,
    sparkExchange: sparkExchangeReducer
});

const rootReducer = (state, action) => {
    if(action.type === LOGOUT ) {
        state = undefined;
    }
    return appReducers(state, action);
};

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;