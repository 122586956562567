import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {retrieveDeliveryYearAuctions} from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import {trackPromise} from "react-promise-tracker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import {TableCell} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import axios from "../../../axios/AxiosInterceptors";
import {onError} from "../../../store/actions/popupActions";
import Typography from "@material-ui/core/Typography";
import {useParams} from "react-router";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const CapacityCommitment = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const params = useParams();
    const deliveryYearAuctions = useSelector(state => state.capacity.deliveryYearAuctions);
    const [deliveryYear, setDeliveryYear] = useState('');
    const [deliveryYearPosition, setDeliveryYearPosition] = useState();

    useEffect(() => {
        if (!deliveryYearAuctions) {
            dispatch(retrieveDeliveryYearAuctions());
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const retrieveDeliveryYearPosition = async (deliveryYear) => {
        await trackPromise(axios.get("/capacity/account/" + params.accountId + "/deliveryYear/" + deliveryYear.replace("/", "-") + "/commitment").then(response => {
            setDeliveryYearPosition(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const renderDeliveryYearSelectBox = () => {
        if (deliveryYearAuctions) {
            return (
                <Autocomplete value={deliveryYear} options={['', ...Object.keys(deliveryYearAuctions).sort((c1, c2) => c2.localeCompare(c1))]}
                              getOptionLabel={(option) => option} id="deliveryYear"
                              renderInput={(params) => <TextField {...params} label="Delivery Year"/>}
                              onChange={(event, value) => onDeliveryYearSelectChange(event, value)}/>
            );
        }
    }

    const onDeliveryYearSelectChange = (event, value) => {
        setDeliveryYearPosition(null);
        if (value) {
            setDeliveryYear(value);
            retrieveDeliveryYearPosition(value);
        } else {
            setDeliveryYear('');
        }
    }

    const renderAuctionTable = () => {
        return (
            <Table className={classes.table} stickyHeader>
                <TableHead>
                    <TableRow key="headers">
                        <TableCell align="center">Auction</TableCell>
                        <TableCell align="center">Unit Name</TableCell>
                        <TableCell align="center">Max Offer (MWs)</TableCell>
                        <TableCell align="center">Min Offer (MWs)</TableCell>
                        <TableCell align="center">Max Offer EFORd</TableCell>
                        <TableCell align="center">Offer EFORd</TableCell>
                        <TableCell align="center">Bid (MWs)</TableCell>
                        <TableCell align="center">Offer/Bid Price ($/MW-day)</TableCell>
                        <TableCell align="center">Cleared MWs</TableCell>
                        <TableCell align="center">Clearing Price ($/MW-day)</TableCell>
                        <TableCell align="center">Credit ($/day)</TableCell>
                        <TableCell align="center">Charge ($/day)</TableCell>
                        <TableCell align="center">Net credit/(charge) ($/day)</TableCell>
                    </TableRow>
                </TableHead>
                {renderAuctionTableBody()}
            </Table>
        );
    }

    const renderAuctionTableBody = () => {
        let renderedRows = [];
        if (deliveryYearPosition) {
            let keys = Object.keys(deliveryYearPosition.auctionTransactionsByAuction).sort((c1, c2) => c1.localeCompare(c2));
            renderedRows.push(keys.map(key => renderAuctionRows(deliveryYearPosition.auctionTransactionsByAuction[key], key)));
        }
        return (
            <TableBody>
                {renderedRows}
            </TableBody>
        )
    }

    const renderAuctionRows = (auctionTransactions, auctionName) => {
        if (auctionTransactions) {
            let sellOfferCreditTotal = auctionTransactions.filter(r => r.credit !== null).map(r => r.credit).reduce((r1, r2) => r1 + r2, 0);
            let buyBidChargeTotal = auctionTransactions.filter(r => r.charge !== null).map(r => r.charge).reduce((r1, r2) => r1 + r2, 0)
            let netChargeCredit = sellOfferCreditTotal - buyBidChargeTotal;
            return (
                <React.Fragment key={auctionName}>
                    <TableRow key={auctionName}>
                        <TableCell rowSpan={auctionTransactions.length + 2} align="center">{auctionName}</TableCell>
                    </TableRow>
                    {auctionTransactions && auctionTransactions.length > 0 && auctionTransactions.map(auctionTransaction => {
                        return (
                            <TableRow key={auctionTransaction.auctionName + auctionTransaction.resourceName + auctionTransaction.offerBidPrice}>
                                <TableCell align="center">{auctionTransaction.resourceName}</TableCell>
                                <TableCell align="center">{auctionTransaction.maxOfferMws ?
                                    <NumberFormat value={auctionTransaction.maxOfferMws} displayType={'text'} fixedDecimalScale={true} decimalScale={1}
                                                  thousandSeparator={true}/> : '-'}</TableCell>
                                <TableCell align="center">{auctionTransaction.minOfferMws ?
                                    <NumberFormat value={auctionTransaction.minOfferMws} displayType={'text'} fixedDecimalScale={true} decimalScale={1}
                                                  thousandSeparator={true}/> : '-'}</TableCell>
                                <TableCell align="center">{auctionTransaction.maxEford ?
                                    <NumberFormat value={auctionTransaction.maxEford} displayType={'text'} fixedDecimalScale={true}
                                                  decimalScale={5}/> : '-'}</TableCell>
                                <TableCell align="center">{auctionTransaction.enteredEford ?
                                    <NumberFormat value={auctionTransaction.enteredEford} displayType={'text'} fixedDecimalScale={true}
                                                  decimalScale={5}/> : '-'}</TableCell>
                                <TableCell align="center">{auctionTransaction.bidMws ?
                                    <NumberFormat value={auctionTransaction.bidMws} displayType={'text'} fixedDecimalScale={true} decimalScale={1}
                                                  thousandSeparator={true}/> : '-'}</TableCell>
                                <TableCell align="center">{auctionTransaction.offerBidPrice ?
                                    <NumberFormat value={auctionTransaction.offerBidPrice} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                                  prefix="$" thousandSeparator={true}/> : '-'}</TableCell>
                                <TableCell align="center">{auctionTransaction.clearedMws ?
                                    <NumberFormat value={auctionTransaction.clearedMws} displayType={'text'} fixedDecimalScale={true} decimalScale={1}
                                                  thousandSeparator={true}/> : '-'}</TableCell>
                                <TableCell align="center">{auctionTransaction.clearingPrice ?
                                    <NumberFormat value={auctionTransaction.clearingPrice} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                                  prefix="$" thousandSeparator={true}/> : '-'}</TableCell>
                                <TableCell align="center">{auctionTransaction.credit ?
                                    <NumberFormat value={auctionTransaction.credit} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$"
                                                  thousandSeparator={true}/> : '-'}</TableCell>
                                <TableCell align="center">{auctionTransaction.charge ?
                                    <NumberFormat value={auctionTransaction.charge} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$"
                                                  thousandSeparator={true}/> : '-'}</TableCell>
                                <TableCell align="center">{'-'}</TableCell>
                            </TableRow>
                        )
                    })}
                    <TableRow key={auctionName + "Totals"}>
                        <TableCell align="center">Auction Total</TableCell>
                        <TableCell colSpan={6}/>
                        <TableCell align="center"><NumberFormat value={auctionTransactions.map(r => r.clearedMws).reduce((r1, r2) => r1 + r2, 0)} displayType={'text'}
                                                                fixedDecimalScale={true}
                                                                decimalScale={1} thousandSeparator={true}/></TableCell>
                        <TableCell/>
                        <TableCell align="center"><NumberFormat value={sellOfferCreditTotal} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$"
                                                                thousandSeparator={true}/></TableCell>
                        <TableCell align="center"><NumberFormat value={buyBidChargeTotal} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$"
                                                                thousandSeparator={true}/></TableCell>
                        <TableCell align="center"><NumberFormat value={netChargeCredit} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$"
                                                                thousandSeparator={true}/></TableCell>
                    </TableRow>
                    <TableRow key={auctionName + "Blank"}>
                        <TableCell colSpan={13}/>
                    </TableRow>
                </React.Fragment>
            )
        }
    }

    const renderTotalsTable = () => {
        return (
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={13}>Totals</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deliveryYearPosition && deliveryYearPosition.deliveryYearTotals &&
                    <React.Fragment>
                        <TableRow>
                            <TableCell>Credit ($/MW-day)</TableCell>
                            <TableCell><NumberFormat value={deliveryYearPosition.deliveryYearTotals.credit} displayType={"text"} fixedDecimalScale={true} decimalScale={2}
                                                     prefix={"$"} thousandSeparator={true}/></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Charge ($/MW-day)</TableCell>
                            <TableCell><NumberFormat value={deliveryYearPosition.deliveryYearTotals.charge} displayType={"text"} fixedDecimalScale={true} decimalScale={2}
                                                     prefix={"$"} thousandSeparator={true}/></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Net ($/MW-day)</TableCell>
                            <TableCell><NumberFormat value={deliveryYearPosition.deliveryYearTotals.net} displayType={"text"} fixedDecimalScale={true} decimalScale={2} prefix={"$"}
                                                     thousandSeparator={true}/></TableCell>
                        </TableRow>
                    </React.Fragment>
                    }
                </TableBody>
            </Table>
        )
    }

    const renderPositionTable = () => {
        return (
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Unit Name</TableCell>
                        <TableCell align="center">ICAP (MWs)</TableCell>
                        <TableCell align="center">EFORd</TableCell>
                        <TableCell align="center">UCAP (MWs)</TableCell>
                        <TableCell align="center">Total Cleared (MWs)</TableCell>
                        <TableCell align="center">Excess (MWs)</TableCell>
                        <TableCell align="center">Replacements (MWs)</TableCell>
                        <TableCell align="center">Commitment (MWs)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deliveryYearPosition && deliveryYearPosition.deliveryYearSummary && deliveryYearPosition.deliveryYearSummary.length > 0 &&
                    deliveryYearPosition.deliveryYearSummary.map(summary => {
                        return (
                            <TableRow key={summary.resourceName}>
                                <TableCell align="center">{summary.resourceName}</TableCell>
                                <TableCell align="center"><NumberFormat value={summary.icap ? summary.icap : '-'} displayType='text' fixedDecimalScale={true} decimalScale={1}
                                                                        thousandSeparator={true}/></TableCell>
                                <TableCell align="center"><NumberFormat value={summary.eford ? summary.eford : '-'} displayType='text' fixedDecimalScale={true} decimalScale={5}
                                                                        thousandSeparator={true}/></TableCell>
                                <TableCell align="center"><NumberFormat value={summary.ucap ? summary.ucap : '-'} displayType='text' fixedDecimalScale={true} decimalScale={1}
                                                                        thousandSeparator={true}/></TableCell>
                                <TableCell align="center"><NumberFormat value={summary.totalCleared ? summary.totalCleared : '-'} displayType='text' fixedDecimalScale={true}
                                                                        decimalScale={1}
                                                                        thousandSeparator={true}/></TableCell>
                                <TableCell align="center"><NumberFormat value={summary.excess ? summary.excess : '-'} displayType='text' fixedDecimalScale={true} decimalScale={1}
                                                                        thousandSeparator={true}/></TableCell>
                                <TableCell align="center"><NumberFormat value={summary.replacements} displayType='text' fixedDecimalScale={true} decimalScale={1}
                                                                        thousandSeparator={true}/></TableCell>
                                <TableCell align="center"><NumberFormat value={summary.commitment} displayType='text' fixedDecimalScale={true} decimalScale={1}
                                                                        thousandSeparator={true}/></TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        )
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={4}>
                    {deliveryYearAuctions && renderDeliveryYearSelectBox()}
                </Grid>
                <Grid item xs={6} sm={4}/>
            </Grid>
            <Typography variant="h3" align="center">Summary</Typography>
            {renderPositionTable()}
            <Typography variant="h3" align="center">Totals</Typography>
            {renderTotalsTable()}
            <Typography variant="h3" align="center">Auctions</Typography>
            {renderAuctionTable()}
        </main>
    )

}

export default CapacityCommitment;