import React from 'react';
import Grid from "@material-ui/core/Grid";
import RenewableEnergyCredits from "../BuyBid/RenewableEnergyCredits";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const IncludedRecSearch = (props) => {

    const search = useSelector(props.selector);
    const dispatch = useDispatch();

    const onInputChange = (recs) => {
        dispatch({type: props.searchDispatch, value: recs});
    }

    const onRemoveIconClick = () => {
        dispatch({type: props.searchDispatch, value: []});
        dispatch({type: props.removeDispatch, value: props.name});
    }

    return (
        <Grid container item xs={6} sm={12}>
            <Grid item xs={6} sm={10}>
                <RenewableEnergyCredits inputChangeHandler={onInputChange} registeredRecs={search.includedRecs} canEdit={true}/>
            </Grid>
            <Grid item xs={6} sm={2}>
                <IconButton onClick={onRemoveIconClick}>
                    <RemoveCircleOutlineIcon/>
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default IncludedRecSearch;