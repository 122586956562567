import React, {Component} from "react";
import {connect} from 'react-redux';

import {withStyles, withTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import CreateRole from './CreateRole';
import {createApplicationRole} from '../../../store/user-management/actions/applicationRoleActions';



const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

class CreateApplicationRole extends Component {

    onSave= (role) => {
        this.props.createApplicationRole(role);
        this.props.history.goBack();
    };

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <Typography variant="h3" align="center">Create new Application Role</Typography>
                <CreateRole onSaveFunction={this.onSave}/>
            </main>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createApplicationRole: (role) => dispatch(createApplicationRole(role))
    };
};

export default withTheme(withStyles(styles)(connect(null, mapDispatchToProps)(CreateApplicationRole)));