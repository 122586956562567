import React, {useState} from 'react';

import {Document, Page} from "react-pdf/dist/esm/entry.webpack";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const TermSheetComponent = (props) => {

    const [numPages, setNumPages] = useState(null);
    const {termSheet} = props;

    const onDocumentLoadSuccess = (numPages) => {
        setNumPages(numPages.numPages);
    }

    const downloadTermSheet = () => {
        const url = window.URL.createObjectURL(new Blob([termSheet]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'term-sheet.pdf');
        document.body.appendChild(link);
        link.click();
    }

    const renderBuyBidTermSheet = () => {
        if(termSheet) {
            return (
                <React.Fragment>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={3} />
                        <Grid item xs={6} sm={6}>
                            <Document file={termSheet} onLoadSuccess={onDocumentLoadSuccess} options={{cMapUrl: 'cmaps/', cMapPacked: true}}>
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index +1}/>
                                ))}
                            </Document>
                        </Grid>
                        <Grid item xs={6} sm={3} />
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={4} align="center">
                            <Button variant="contained" onClick={downloadTermSheet}>Download Term Sheet</Button>
                        </Grid>
                        <Grid item xs={6} sm={4} />
                    </Grid>
                </React.Fragment>
            );
        }
    }
    return (
        <React.Fragment>
        {renderBuyBidTermSheet()}
        </React.Fragment>
    )
}

export default TermSheetComponent;