import React, {useEffect, useState} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TableCell, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {trackPromise} from "react-promise-tracker";
import {onError} from "../../../store/actions/popupActions";
import axios from "../../../axios/AxiosInterceptors"
import {retrieveDeliveryYearAuctions} from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import {useHistory} from "react-router-dom";
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const ViewRpmParameter = (props) => {

    const classes = useStyles(props);
    const deliveryYearAuctions = useSelector(state => state.capacity.deliveryYearAuctions);
    const dispatch = useDispatch();
    const history = useHistory();
    const roles = useSelector(state => state.auth.roles)
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const [search, setSearch] = useState({deliveryYear: ''});
    const [rpmParameters, setRpmParameters] = useState();
    const [hasModify, setHasModify] = useState(false);

    useEffect(() => {
        if (!deliveryYearAuctions) {
            dispatch(retrieveDeliveryYearAuctions());
        }
        setHasModify(hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_rpm_parameter') ||
            hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_delete_rpm_parameter'))
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const retrieveRpmParameters = async () => {
        let url = undefined;
        if (search.deliveryYear) {
            url = "/capacity/parameters/deliveryYear/" + search.deliveryYear.replace("/", "-");
        }
        if (url) {
            await trackPromise(axios.get(url).then(response => {
                setRpmParameters(response.data);
            }).catch(error => {
                dispatch(onError(error));
            }));
        }
    }

    const renderDeliveryYearSelectBox = () => {
        if (deliveryYearAuctions) {
            return (
                <Autocomplete value={search.deliveryYear} options={['', ...Object.keys(deliveryYearAuctions).sort((c1, c2) => c2.localeCompare(c1))]}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => <TextField {...params} label="Delivery Year"/>}
                              onChange={(event, value) => onDeliveryYearSelectChange(event, value)}/>
            );
        }
    }

    const onDeliveryYearSelectChange = (event, value) => {
        if (value) {
            search.deliveryYear = value;
        } else {
            search.deliveryYear = '';
        }
        setSearch({...search});
        retrieveRpmParameters();
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={4}>
                    {renderDeliveryYearSelectBox()}
                </Grid>
                <Grid item xs={6} sm={4}/>
            </Grid>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Delivery Year</TableCell>
                        <TableCell align="center">Auction</TableCell>
                        <TableCell align="center">IRM</TableCell>
                        <TableCell align="center">Pool Average EFORd (%)</TableCell>
                        <TableCell align="center">FPR</TableCell>
                        <TableCell align="center">RTO Reliability Requirement (MWs)</TableCell>
                        <TableCell align="center">Obligation Peak Load Scaling Factor</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rpmParameters && rpmParameters.map(rpmParameter => {
                        return (
                            <TableRow key={rpmParameter.id} className={hasModify ? classes.tableRow : undefined}
                                      onClick={hasModify ? (() => history.push("/capacity/rpmParameter/" + rpmParameter.id)) : undefined}>
                                <TableCell align="center">{rpmParameter.deliveryYear}</TableCell>
                                <TableCell align="center">{rpmParameter.auction}</TableCell>
                                <TableCell align="center"><NumberFormat value={rpmParameter.installedReserveMargin} displayType='text' fixedDecimalScale={true}
                                                                        decimalScale={1}/></TableCell>
                                <TableCell align="center"><NumberFormat value={rpmParameter.poolAverageEford} displayType='text' fixedDecimalScale={true}
                                                                        decimalScale={2}/></TableCell>
                                <TableCell align="center"><NumberFormat value={rpmParameter.forecastPoolRequirement} displayType='text' fixedDecimalScale={true} decimalScale={5}/></TableCell>
                                <TableCell align="center"><NumberFormat value={rpmParameter.rtoReliabilityRequirement} displayType='text' fixedDecimalScale={true}
                                                                        decimalScale={3} thousandSeparator/></TableCell>
                                <TableCell align="center"><NumberFormat value={rpmParameter.obligationPeakLoadScalingFactor} displayType='text' fixedDecimalScale={true}
                                                                        decimalScale={10}/></TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </main>
    )

}

export default ViewRpmParameter;