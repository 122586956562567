import React from 'react';
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const GeneratorStateSearch = (props) => {

    const dispatch = useDispatch();
    const availableStates = ['DE', 'DC', 'IL', 'IN', 'KY', 'MD', 'MI', 'NJ', 'NC', 'OH', 'PA', 'TN', 'VA', 'WV'];

    const onInputChange = (event, value) => {
        dispatch({type: props.searchDispatch, value});
    }

    const onRemoveIconClick = () => {
        dispatch({type: props.searchDispatch, value: []})
        dispatch({type: props.removeDispatch, value: props.name});
    }

    return (
        <Grid container item xs={6} sm={4}>
            <div style={{width: 300}}>
                <Autocomplete multiple options={availableStates} renderInput={(params) => <TextField {...params} placeholder="States"/>}
                              onChange={(event, value) => onInputChange(event, value)} />
            </div>
            <div>
                <IconButton onClick={onRemoveIconClick}>
                    <RemoveCircleOutlineIcon />
                </IconButton>
            </div>
        </Grid>
    )

}

export default GeneratorStateSearch;