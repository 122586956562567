import {ROLES_SUCCESS, AUTO_LOGIN_SUCCESS, AUTHENTICATION_FAILED, RESPONSE_RECEIVED} from '../actionTypes';

const initialState = {
    jwt: null,
    username: null,
    accounts: [{
        customerAccount: ''
    }],
    issuedAt: null,
    roles: [],
    superUser: false,
    authenticationFailed: false,
    lastRequestTime: null
};

const updateUserRoles = (state, action) => {
    return {...state, roles: action.roles};
};

const autoLogin = (state, action) => {
    let updatedState = {
        jwt: action.jwt,
        username: action.username,
        accounts: action.accounts,
        issuedAt: action.issuedAt,
        roles: action.roles,
        superUser: action.superUser,
        authenticationFailed: false,
        lastRequestTime: new Date()
    };
    return {...state, ...updatedState};
};

const authenticationFailed = (state, action) => {
    let updatedState = initialState;
    updatedState.authenticationFailed = true;
    return {...state, state};
}

const responseReceived = (state, action) => {
    let updatedState = state;
    updatedState.lastRequestTime = new Date();
    return {...state, ...updatedState};
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case ROLES_SUCCESS:
            return updateUserRoles(state, action);
        case AUTO_LOGIN_SUCCESS:
            return autoLogin(state, action);
        case AUTHENTICATION_FAILED:
            return authenticationFailed(state, action);
        case RESPONSE_RECEIVED:
            return responseReceived(state, action);
        default:
            return state;
    }
};

export default authReducer;