import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import Typography from '@material-ui/core/Typography';

import GeneratorTable from '../Generator/GeneratorTable';
import {findServicePermissionsForCurrentAccount, hasPermission} from '../../../Utility/PermissionsUtil';
import SellOfferTable from "../SellOffer/SellOfferTable";
import BuyBidTable from "../BuyBid/BuyBidTable";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
}));

const Dashboard = () => {

    const classes = useStyles();
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const [servicePermissions, setServicePermissions] = useState();

    useEffect(() => {
        if (chosenAccount && roles) {
            setServicePermissions(findServicePermissionsForCurrentAccount(roles, chosenAccount.id));
        }
    }, [chosenAccount, roles])

    const renderBuyBids = () => {
        if (servicePermissions) {
            if (hasPermission(servicePermissions, 'ROLE_get_buy_bid')) {
                return (
                    <React.Fragment>
                        <Typography variant="h4" align="center">My Buy Bids</Typography>
                        <BuyBidTable accountId={chosenAccount.id}/>
                    </React.Fragment>
                );
            }
        }
    }

    const renderGenerators = () => {
        if (servicePermissions) {
            if (hasPermission(servicePermissions, 'ROLE_get_generator')) {
                return (
                    <React.Fragment>
                        <Typography variant="h4" align="center">My Generators</Typography>
                        <GeneratorTable accountId={chosenAccount.id}/>
                    </React.Fragment>
                );
            }
        }
    }

    const renderSellOffers = () => {
        if (servicePermissions) {
            if (hasPermission(servicePermissions, 'ROLE_get_sell_offer')) {
                return (
                    <React.Fragment>
                        <Typography variant="h4" align="center">My Sell Offers</Typography>
                        <SellOfferTable accountId={chosenAccount.id}/>
                    </React.Fragment>
                );
            }
        }
    }

    return (
        <main className={classes.layout}>
            <Typography align="center" variant="h3">Welcome to Spark Exchange</Typography>
            {renderBuyBids()}
            {renderGenerators()}
            {renderSellOffers()}
        </main>
    );
}

export default Dashboard;