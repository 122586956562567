import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {retrieveDeliveryYearAuctions} from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {cleanStringInput} from "../../../Utility/StringUtil";
import InputAdornment from "@material-ui/core/InputAdornment";
import {trackPromise} from "react-promise-tracker";
import {onError} from "../../../store/actions/popupActions";
import axios from "../../../axios/AxiosInterceptors";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";

const RpmParameterComponent = (props) => {

    const dispatch = useDispatch();
    const {isEdit} = props;
    const deliveryYearAuctions = useSelector(state => state.capacity.deliveryYearAuctions);
    const {rpmParameterId} = props;
    const [rpmParameter, setRpmParameter] = useState({deliveryYear: '', auction: ''});

    useEffect(() => {
        if (!deliveryYearAuctions) {
            dispatch(retrieveDeliveryYearAuctions());
        }
        if (rpmParameterId) {
            retrieveRpmParameter(rpmParameterId);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const retrieveRpmParameter = async (rpmParameterId) => {
        await trackPromise(axios.get("/capacity/parameters/" + rpmParameterId).then(response => setRpmParameter(response.data)).catch(error => dispatch(onError(error))));
    }

    const renderDeliveryYearSelectBox = () => {
        if (deliveryYearAuctions) {
            return (
                <Autocomplete value={rpmParameter.deliveryYear} options={['', ...Object.keys(deliveryYearAuctions).sort((c1, c2) => c2.localeCompare(c1))]}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => <TextField {...params} label="Delivery Year"/>}
                              onChange={(event, value) => onDeliveryYearSelectChange(event, value)} disabled={!isEdit}/>
            );
        }
    }

    const renderAuctionSelectBox = () => {
        if (rpmParameter.deliveryYear) {
            return (
                <Autocomplete value={rpmParameter.auction}
                              options={['', ...deliveryYearAuctions[rpmParameter.deliveryYear].map(r => r.auction)]}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => <TextField {...params} label="Auction"/>}
                              onChange={(event, value) => onAuctionSelectChange(event, value)} disabled={!isEdit}/>
            );
        }
        return (
            <Autocomplete value={rpmParameter.auction} options={['']} getOptionLabel={() => ''}
                          renderInput={(params) => <TextField {...params} label="Auction" disabled={!isEdit}/>}/>
        );
    }

    const onDeliveryYearSelectChange = (event, value) => {
        if (value) {
            rpmParameter.deliveryYear = value;
        } else {
            rpmParameter.deliveryYear = '';
            rpmParameter.auction = '';
        }
        setRpmParameter({...rpmParameter});
        if (props.onChangeHandler) {
            props.onChangeHandler(rpmParameter);
        }
    }

    const onAuctionSelectChange = (event, value) => {
        if (value) {
            rpmParameter.auction = value;
        } else {
            rpmParameter.auction = '';
        }
        setRpmParameter({...rpmParameter});
        if (props.onChangeHandler) {
            props.onChangeHandler(rpmParameter);
        }
    }

    const onInputChange = (e) => {
        if (e.target.value) {
            rpmParameter[e.target.name] = Number.parseFloat(e.target.value);
        } else {
            rpmParameter[e.target.name] = '';
        }
        setRpmParameter({...rpmParameter});
        if (props.onChangeHandler) {
            props.onChangeHandler(rpmParameter);
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    {renderDeliveryYearSelectBox()}
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    {renderAuctionSelectBox()}
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="IRM" name="installedReserveMargin" value={cleanStringInput(rpmParameter.installedReserveMargin)} onChange={onInputChange} type="number"
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">%</InputAdornment>, inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 1, allowNegative: false, isEdit: isEdit}
                               }} fullWidth disabled={!isEdit} InputLabelProps={{shrink: !!rpmParameter.installedReserveMargin}}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Pool Average EFORd" name="poolAverageEford" value={cleanStringInput(rpmParameter.poolAverageEford)} onChange={onInputChange} type="number"
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">%</InputAdornment>, inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} fullWidth disabled={!isEdit} InputLabelProps={{shrink: !!rpmParameter.poolAverageEford}}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="FPR" name="forecastPoolRequirement" value={cleanStringInput(rpmParameter.forecastPoolRequirement)} onChange={onInputChange} type="number"
                               InputProps={{inputComponent: GenericNumberFormat, inputProps: {fixedDecimalScale: true, decimalScale: 5, allowNegative: false, isEdit: isEdit}}}
                               fullWidth disabled={!isEdit} InputLabelProps={{shrink: !!rpmParameter.forecastPoolRequirement}}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="RTO Reliability Requirement" name="rtoReliabilityRequirement" value={cleanStringInput(rpmParameter.rtoReliabilityRequirement)} type="number"
                               onChange={onInputChange} InputProps={{
                        endAdornment: <InputAdornment position="start">MW</InputAdornment>, inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 3, allowNegative: false, isEdit: isEdit}
                    }} fullWidth disabled={!isEdit} InputLabelProps={{shrink: !!rpmParameter.rtoReliabilityRequirement}}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Obligation Peak Load Scaling Factor" name="obligationPeakLoadScalingFactor" onChange={onInputChange}
                               value={cleanStringInput(rpmParameter.obligationPeakLoadScalingFactor)}
                               type="number"
                               InputProps={{inputComponent: GenericNumberFormat, inputProps: {fixedDecimalScale: true, decimalScale: 10, allowNegative: false, isEdit: isEdit}}}
                               fullWidth disabled={!isEdit} InputLabelProps={{shrink: !!rpmParameter.obligationPeakLoadScalingFactor}}/>
                </Grid>
                <Grid item xs={6} sm={7}/>
            </Grid>
        </React.Fragment>
    );


}

export default RpmParameterComponent;