import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {TableCell, TableSortLabel, TextField, Tooltip} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import axios from "../../../axios/AxiosInterceptors";
import {trackPromise} from 'react-promise-tracker';
import {onError} from "../../../store/actions/popupActions";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import PaginatedTableActions from "../../Table/PaginatedTableActions";
import TablePagination from "@material-ui/core/TablePagination";
import {calculateRowsPerPage} from "../../Table/PaginatedTableUtils";
import {useHistory} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import {CallSplit, Details} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const ViewProjectStatus = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const history = useHistory();
    const [projectStatuses, setProjectStatuses] = useState();
    const [search, setSearch] = useState({upgradeId: ''});
    const [orderBy, setOrderBy] = useState();
    const [direction, setDirection] = useState();
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        retrieveProjectStatus(0, pageSize, orderBy, direction);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const retrieveProjectStatus = async (pageNumber, pageSize, orderBy, direction) => {
        let url = "/capacity/project/status?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
        if (search.upgradeId) {
            url = url + "&upgradeId=" + search.upgradeId;
        }
        if (orderBy) {
            url = url + "&orderBy=" + orderBy;
        }
        if (direction) {
            url = url + "&direction=" + direction;
        }
        await (trackPromise(axios.get(url).then(response => {
            setProjectStatuses(response.data);
        }).catch(error => {
            dispatch(onError(error));
        })));
    }


    const handlePageChange = (pageNumber, pageSize, orderBy, direction) => {
        retrieveProjectStatus(pageNumber, pageSize);
    }

    const handleRowsPerPageChange = (e) => {
        setPageSize(e.target.value);
        retrieveProjectStatus(0, e.target.value, orderBy, direction);
    }

    const handleSubmit = (e) => {
        retrieveProjectStatus(0, pageSize, orderBy, direction);
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        setOrderBy(column);
        setDirection(sortDirection);
        retrieveProjectStatus(0, pageSize, column, sortDirection);
    }

    const onUpgradeIdChange = (e) => {
        search.upgradeId = e.target.value;
        setSearch({...search});
    }

    return (
        <main className={classes.layout}>
            <form onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e)
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={6} sm={3}>
                        <TextField id="upgradeId" name="upgradeId" label="Upgrade ID" value={search.upgradeId} onChange={onUpgradeIdChange} fullWidth/>
                    </Grid>
                    <Grid item xs={6} sm={1}>
                        <Button type="submit">Search</Button>
                    </Grid>
                    <Grid item xs={6} sm={4}/>
                </Grid>
            </form>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'upgradeId'} direction={orderBy === 'upgradeId' ? direction : 'asc'}
                                            onClick={() => handleSort("upgradeId")}>
                                Upgrade ID
                                {orderBy === 'upgradeId' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'description'} direction={orderBy === 'description' ? direction : 'asc'}
                                            onClick={() => handleSort("description")}>
                                Description
                                {orderBy === 'description' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'subRegion'} direction={orderBy === 'subRegion' ? direction : 'asc'}
                                            onClick={() => handleSort("subRegion")}>
                                Sub-Region
                                {orderBy === 'subRegion' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'transmissionOwner'} direction={orderBy === 'transmissionOwner' ? direction : 'asc'}
                                            onClick={() => handleSort("transmissionOwner")}>
                                Transmission Owner
                                {orderBy === 'transmissionOwner' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'state'} direction={orderBy === 'state' ? direction : 'asc'} onClick={() => handleSort("state")}>
                                State
                                {orderBy === 'state' ? (<span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'projectType'} direction={orderBy === 'projectType' ? direction : 'asc'}
                                            onClick={() => handleSort("projectType")}>
                                Project Type
                                {orderBy === 'projectType' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'status'} direction={orderBy === 'status' ? direction : 'asc'} onClick={() => handleSort("status")}>
                                Status
                                {orderBy === 'status' ? (<span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'projectedInServiceDate'} direction={orderBy === 'projectedInServiceDate' ? direction : 'asc'}
                                            onClick={() => handleSort("projectedInServiceDate")}>
                                Projected In-Service Date
                                {orderBy === 'projectedInServiceDate' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'actualInServiceDate'} direction={orderBy === 'actualInServiceDate' ? direction : 'asc'}
                                            onClick={() => handleSort("actualInServiceDate")}>
                                Actual In-Service Date
                                {orderBy === 'actualInServiceDate' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projectStatuses && projectStatuses.results && projectStatuses.results.length > 0 &&
                    projectStatuses.results.map(projectStatus => {
                        return (
                            <TableRow key={projectStatus.id}>
                                <TableCell align="center">{projectStatus.upgradeId}</TableCell>
                                <TableCell title={projectStatus.description} align="center">{projectStatus.description.substring(0, 25) + "..."}</TableCell>
                                <TableCell align="center">{projectStatus.subRegion}</TableCell>
                                <TableCell align="center">{projectStatus.transmissionOwner}</TableCell>
                                <TableCell align="center">{projectStatus.state}</TableCell>
                                <TableCell align="center">{projectStatus.projectType}</TableCell>
                                <TableCell align="center">{projectStatus.status}</TableCell>
                                <TableCell align="center">{projectStatus.projectedInServiceDate}</TableCell>
                                <TableCell align="center">{projectStatus.actualInServiceDate}</TableCell>
                                <TableCell align="center">
                                    <Tooltip title="View Details"><IconButton
                                        onClick={() => history.push("/capacity/projectStatus/" + projectStatus.upgradeId)}><Details/></IconButton></Tooltip>
                                    <Tooltip title="View Cost Allocation"><IconButton
                                        onClick={() => history.push("/capacity/projectStatus/" + projectStatus.upgradeId + "/costAllocation")}><CallSplit/></IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                {projectStatuses &&
                <TableFooter>
                    <TableRow>
                        <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={9}
                                         count={projectStatuses.totalCount ? projectStatuses.totalCount : 0}
                                         rowsPerPage={calculateRowsPerPage(projectStatuses.pageSize)}
                                         page={projectStatuses.pageNumber ? projectStatuses.pageNumber : 0}
                                         onChangePage={handlePageChange} onChangeRowsPerPage={handleRowsPerPageChange} ActionsComponent={PaginatedTableActions}/>
                    </TableRow>
                </TableFooter>
                }
            </Table>
        </main>
    )

}

export default ViewProjectStatus;