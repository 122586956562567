import React, {useState, useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {useDispatch} from "react-redux";
import {onError} from "../../../store/actions/popupActions";
import {InputAdornment, TextField} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {cleanStringInput} from "../../../Utility/StringUtil";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import TwoDigitCurrency from "../../NumberFormat/TwoDigitCurrency";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const ViewProjectStatusDetail = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const [projectStatus, setProjectStatus] = useState();

    useEffect(() => {
        retrieveProjectStatus();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retrieveProjectStatus = async () => {
        await (trackPromise(axios.get("/capacity/project/status/" + props.match.params.upgradeId).then(response => {
            setProjectStatus(response.data);
        }).catch(error => {
            dispatch(onError(error));
        })));
    }

    return (
        <main className={classes.layout}>
            {projectStatus &&
            <Grid container spacing={2}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Upgrade ID" value={cleanStringInput(projectStatus.upgradeId)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Sub-Region" value={cleanStringInput(projectStatus.subRegion)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Transmission Owner" value={cleanStringInput(projectStatus.transmissionOwner)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Location" value={cleanStringInput(projectStatus.location)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="State" value={cleanStringInput(projectStatus.state)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Project Type" value={cleanStringInput(projectStatus.projectType)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    {
                        !isNaN(projectStatus.voltage) ? <TextField label="Voltage" value={cleanStringInput(projectStatus.voltage)} disabled={true} fullWidth
                                                                   InputProps={{
                                                                       endAdornment: <InputAdornment position="start">kV</InputAdornment>, inputComponent: GenericNumberFormat,
                                                                       inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: false}
                                                                   }}
                                                                   InputLabelProps={{shrink: !!projectStatus.voltage}}/> :
                            <TextField label="Voltage" value={cleanStringInput(projectStatus.voltage)} disabled={true} fullWidth/>
                    }

                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Cost Estimate" value={cleanStringInput(projectStatus.costEstimate)} disabled={true} fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">MM</InputAdornment>,
                        inputComponent: TwoDigitCurrency, inputProps: {isEdit: false}
                    }} InputLabelProps={{shrink: !!projectStatus.costEstimate}}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="TEAC Cost" value={cleanStringInput(projectStatus.teacCost)} disabled={true} fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">MM</InputAdornment>,
                        inputComponent: TwoDigitCurrency, inputProps: {isEdit: false}
                    }} InputLabelProps={{shrink: !!projectStatus.teacCost}}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Task" value={cleanStringInput(projectStatus.task)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Required Date" value={cleanStringInput(projectStatus.requiredDate)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Projected In-Service Date" value={cleanStringInput(projectStatus.projectedInServiceDate)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Actual In-Service Date" value={cleanStringInput(projectStatus.actualInServiceDate)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Initial TEAC Date" value={cleanStringInput(projectStatus.initialTeacDate)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Latest TEAC Date" value={cleanStringInput(projectStatus.latestTeacDate)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="ISA In-Service Date" value={cleanStringInput(projectStatus.isaInServiceDate)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Revised In-Service Date" value={cleanStringInput(projectStatus.revisedInServiceDate)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Percent Complete" value={cleanStringInput(projectStatus.percentComplete ? projectStatus.percentComplete * 100 : '')} disabled={true}
                               fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        inputComponent: GenericNumberFormat, inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: false}
                    }} InputLabelProps={{shrink: !!projectStatus.percentComplete}}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Status" value={cleanStringInput(projectStatus.status)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Immediate Need" value={cleanStringInput(projectStatus.immediateNeed)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={11}>
                    <TextField label="Description" value={cleanStringInput(projectStatus.description)} multiline disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={11}>
                    <TextField label="Revised Description" value={cleanStringInput(projectStatus.revisedDescription)} multiline disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={11}>
                    <TextField label="Status Detail" value={cleanStringInput(projectStatus.statusDetail)} multiline disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={11}>
                    <TextField label="Driver" value={cleanStringInput(projectStatus.driver)} multiline disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={11}>
                    <TextField label="Equipment" value={cleanStringInput(projectStatus.equipment)} multiline disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={11}>
                    <TextField label="Proposal IDs" value={cleanStringInput(projectStatus.proposalIds)} multiline disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={11}>
                    <TextField label="Units" value={cleanStringInput(projectStatus.units)} multiline disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={11}>
                    <TextField label="Related Upgrades" value={cleanStringInput(projectStatus.relatedUpgrades)} multiline disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={11}>
                    <TextField label="Schedule 12 Methodology" value={cleanStringInput(projectStatus.schedule12Methodology)} multiline disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={11}>
                    <TextField label="Applicable Rating" value={cleanStringInput(projectStatus.applicableRating)} multiline disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={11}>
                    <TextField label="TEAC Materials" value={cleanStringInput(projectStatus.teacMaterials)} multiline disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Last Updated" value={cleanStringInput(projectStatus.lastUpdated)} disabled={true} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={4}/>
            </Grid>
            }
        </main>
    );

}

export default ViewProjectStatusDetail;