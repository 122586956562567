import React, {Component} from "react";
import {withStyles, withTheme} from '@material-ui/core/styles';
import AccountComponent from "./AccountComponent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {updateAccount} from '../../../store/account-management/actions/accountActions';


const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

class ViewAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            canEdit: false,
            account: {}
        }
    }

    onAccountChange = (account) => {
        this.setState({account: account});
    }

    goBack = () => {
        this.props.history.goBack();
    }

    editAccount = () => {
        this.setState({canEdit: true});
    }

    saveAccount = () => {
        this.props.updateAccount(this.state.account.id, this.state.account);
        this.props.history.goBack();
    }

    renderButtons = () => {
        if (this.state.canEdit) {
            return (<React.Fragment>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={this.saveAccount} fullWidth>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={this.goBack} fullWidth>Back</Button>
                    </Grid>
                </React.Fragment>
            )
        }
        return (<React.Fragment>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <Button onClick={this.editAccount} fullWidth>Edit</Button>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <Button onClick={this.goBack} fullWidth>Back</Button>
                </Grid>
            </React.Fragment>
        )
    }


    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <AccountComponent canEdit={this.state.canEdit} accountId={this.props.match.params.accountId} onChange={this.onAccountChange}/>
                <Grid container spacing={2}>
                    {this.renderButtons()}
                </Grid>
            </main>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateAccount: (accountId, account) => dispatch(updateAccount(accountId, account))
    }
}

export default withTheme(withStyles(styles)(connect(null,mapDispatchToProps)(ViewAccount)));