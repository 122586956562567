import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from '@material-ui/core/styles';

import {retrieveSellOffersForGenerator, retrieveSellOffers} from '../../../store/spark-exchange/actions/sellOfferActions';
import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import PublishIcon from "@material-ui/icons/Publish";
import {TableFooter, TableSortLabel} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import PaginatedTableActions from "../../Table/PaginatedTableActions";
import {useHistory} from "react-router";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const SellOfferTable = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {accountId, generatorId} = props;
    const [pageSize, setPageSize] = useState(10);
    const [orderBy, setOrderBy] = useState();
    const [direction, setDirection] = useState();
    const sellOffers = useSelector(state => state.sellOffer.sellOffers);

    useEffect(() => {
        doRetrieve(0, orderBy, direction);
        // eslint-disable-next-line
    }, [accountId, generatorId])

    const formatOfferType = (offerType) => {
        if (offerType === "unitContingent") {
            return "Unit Contingent";
        }
        return "Firm";
    }

    const handlePageChange = (pageNumber) => {
        doRetrieve(pageNumber, orderBy, direction);
    }

    const handleRowsPerPageChange = (e) => {
        setPageSize(e.target.value);
        doRetrieve(0, orderBy, direction);
    }

    const doRetrieve = (pageNumber, orderByColumn, sortDirection) => {
        if (generatorId) {
            dispatch(retrieveSellOffersForGenerator(accountId, generatorId, pageNumber, pageSize, orderByColumn, sortDirection));
        } else {
            dispatch(retrieveSellOffers(accountId, pageNumber, pageSize, orderByColumn, sortDirection));
        }
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc';
        setOrderBy(column);
        setDirection(sortDirection);
        doRetrieve(0, column, sortDirection);
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'generatorName'} direction={orderBy === 'generatorName' ? direction : 'asc'}
                                        onClick={() => handleSort("generator_generatorName")}>
                            Generator Name
                            {orderBy === 'generatorName' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'generatorType'} direction={orderBy === 'generatorType' ? direction : 'asc'}
                                        onClick={() => handleSort("generator_generatorType")}>
                            Generator Type
                            {orderBy === 'generatorType' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'quantityMw'} direction={orderBy === 'quantityMw' ? direction : 'asc'}
                                        onClick={() => handleSort("quantityMw")}>
                            Quantity (MWs)
                            {orderBy === 'quantityMw' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'termLengthMonth'} direction={orderBy === 'termLengthMonth' ? direction : 'asc'}
                                        onClick={() => handleSort("termLengthMonth")}>
                            Term Length (Months)
                            {orderBy === 'termLengthMonth' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'fixedPaymentMwday'} direction={orderBy === 'fixedPaymentMwday' ? direction : 'asc'}
                                        onClick={() => handleSort("fixedPaymentMwday")}>
                            Capacity Payment ($/MW-day)
                            {orderBy === 'fixedPaymentMwday' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'variablePaymentMwh'} direction={orderBy === 'variablePaymentMwh' ? direction : 'asc'}
                                        onClick={() => handleSort("variablePaymentMwh")}>
                            Energy Payment ($/MWh)
                            {orderBy === 'variablePaymentMwh' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'deliveryPoint'} direction={orderBy === 'deliveryPoint' ? direction : 'asc'}
                                        onClick={() => handleSort("deliveryPoint")}>
                            Delivery Point
                            {orderBy === 'deliveryPoint' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'offerType'} direction={orderBy === 'offerType' ? direction : 'asc'}
                                        onClick={() => handleSort("offerType")}>
                            Offer Type
                            {orderBy === 'offerType' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'includePhysicalAttributes'} direction={orderBy === 'includePhysicalAttributes' ? direction : 'asc'}
                                        onClick={() => handleSort("includePhysicalAttributes")}>
                            Include Physical Attributes
                            {orderBy === 'includePhysicalAttributes' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'includeEnvironmentalAttributes'} direction={orderBy === 'includeEnvironmentalAttributes' ? direction : 'asc'}
                                        onClick={() => handleSort("includeEnvironmentalAttributes")}>
                            Include Environmental Attributes
                            {orderBy === 'includeEnvironmentalAttributes' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'published'} direction={orderBy === 'published' ? direction : 'asc'}
                                        onClick={() => handleSort("published")}>
                            Published
                            {orderBy === 'published' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'matched'} direction={orderBy === 'matched' ? direction : 'asc'}
                                        onClick={() => handleSort("published")}>
                            Matched
                            {orderBy === 'matched' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sellOffers.results &&
                sellOffers.results.map(sellOffer => {
                    return (
                        <TableRow key={sellOffer.id} className={classes.tableRow}
                                  onClick={() => history.push("/spark-exchange/account/" + sellOffer.accountId + "/generators/" + sellOffer.generator.id + "/sell-offers/" + sellOffer.id)}>
                            <TableCell align="center">{sellOffer.generator.generatorName}</TableCell>
                            <TableCell align="center">{sellOffer.generator.generatorType}</TableCell>
                            <TableCell align="center"><NumberFormat value={sellOffer.quantityMw} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/></TableCell>
                            <TableCell align="center"><NumberFormat value={sellOffer.termLengthMonth} displayType={'text'} fixedDecimalScale={true} decimalScale={0}/></TableCell>
                            <TableCell align="center"><NumberFormat value={sellOffer.fixedPaymentMwday} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$"/>
                            </TableCell>
                            <TableCell align="center"><NumberFormat value={sellOffer.variablePaymentMwh} displayType={'text'} fixedDecimalScale={true} decimalScale={2} prefix="$"/>
                            </TableCell>
                            <TableCell align="center">{sellOffer.deliveryPoint}</TableCell>
                            <TableCell align="center">{formatOfferType(sellOffer.offerType)}</TableCell>
                            <TableCell align="center">{sellOffer.includePhysicalAttributes ? <CheckIcon/> : <BlockIcon/>}</TableCell>
                            <TableCell align="center">{sellOffer.includeEnvironmentalAttributes ? <CheckIcon/> : <BlockIcon/>}</TableCell>
                            <TableCell align="center">{sellOffer.published && <PublishIcon/>}</TableCell>
                            <TableCell align="center">{sellOffer.matched && <CheckIcon/>}</TableCell>
                        </TableRow>);
                })}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={11} count={sellOffers.totalCount ? sellOffers.totalCount : 0}
                                     rowsPerPage={calculateRowsPerPage(sellOffers.pageSize)}
                                     page={sellOffers.pageNumber ? sellOffers.pageNumber : 0} onChangePage={handlePageChange}
                                     onChangeRowsPerPage={handleRowsPerPageChange}
                                     ActionsComponent={PaginatedTableActions}/>
                </TableRow>
            </TableFooter>
        </Table>
    );
}

export default SellOfferTable;