import React, {useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import {cleanStringInput} from "../../../Utility/StringUtil";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
}));

const BuyBidProfile = (props) => {

    const classes = useStyles();
    const [buyBidProfile, setBuyBidProfile] = useState({});
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (props.buyBidProfile) {
            setBuyBidProfile(props.buyBidProfile);
            setIsEdit(props.isEdit !== undefined ? props.isEdit : false);
        } else {
            setIsEdit(props.isEdit !== undefined ? props.isEdit : false);
        }
    }, [props]);

    const onInputChangeHandler = (e) => {
        if (e.target.type === "number") {
            if (e.target.value) {
                buyBidProfile[e.target.name] = Number.parseFloat(e.target.value);
            } else {
                buyBidProfile[e.target.name] = e.target.value;
            }
        } else {
            buyBidProfile[e.target.name] = e.target.value;
        }
        setBuyBidProfile(buyBidProfile);
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBidProfile);
        }
    };

    return (
        <main className={classes.layout}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Name" name="name" value={cleanStringInput(buyBidProfile.name)} onChange={onInputChangeHandler}
                               disabled={!isEdit} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={12}>
                    <TextField label="Description" name="description" value={cleanStringInput(buyBidProfile.description)}
                               onChange={onInputChangeHandler}
                               disabled={!isEdit} fullWidth multiline rows={3}/>
                </Grid>
            </Grid>
            <Typography variant="h6" align="center">Generator Attributes</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label={"Heat Rate (HHV)"} name={"heatRate"} value={cleanStringInput(buyBidProfile.heatRate)}
                               onChange={onInputChangeHandler}
                               fullWidth disabled={!isEdit}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">BTU/MWh</InputAdornment>, inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}
                               }}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label={"Fuel Rate"} name={"fuelRate"} value={cleanStringInput(buyBidProfile.fuelRate)}
                               onChange={onInputChangeHandler}
                               fullWidth disabled={!isEdit}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">BTU/MWh</InputAdornment>, inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}}}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
            </Grid>
            <Typography variant="h6" align="center">Environmental Attributes</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Carbon Dioxide Emissions" name="carbonDioxideEmissions"
                               value={cleanStringInput(buyBidProfile.carbonDioxideEmissions)}
                               InputProps={{endAdornment: <InputAdornment position="start">lbs/MWh</InputAdornment>, inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}}}
                               onChange={onInputChangeHandler} disabled={!isEdit} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Nitrogen Oxide Emissions" name="nitrogenOxideEmissions"
                               value={cleanStringInput(buyBidProfile.nitrogenOxideEmissions)}
                               onChange={onInputChangeHandler} disabled={!isEdit} fullWidth
                               InputProps={{endAdornment: <InputAdornment position="start">lbs/MWh</InputAdornment>, inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}}}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Sulfur Dioxide Emissions" name="sulfurDioxideEmissions"
                               value={cleanStringInput(buyBidProfile.sulfurDioxideEmissions)}
                               onChange={onInputChangeHandler} disabled={!isEdit} fullWidth
                               InputProps={{endAdornment: <InputAdornment position="start">lbs/MWh</InputAdornment>, inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}}}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Particulates Emissions" name="particulatesEmissions"
                               value={cleanStringInput(buyBidProfile.particulatesEmissions)}
                               onChange={onInputChangeHandler} disabled={!isEdit} fullWidth
                               InputProps={{endAdornment: <InputAdornment position="start">lbs/MWh</InputAdornment>, inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}}}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
            </Grid>
        </main>
    );
}

export default BuyBidProfile;