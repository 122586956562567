import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {cleanStringInput} from "../../../Utility/StringUtil";
import {useSelector} from 'react-redux';
import {Checkbox} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";


const UserComponent = (props) => {

    const [user, setUser] = useState({
        username: '',
        emailAddress: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        accountIds: [],
        userAccountRoles: [],
        userApplicationRoles: [],
        superUser: false
    });
    const superUser = useSelector(state => state.auth.superUser);

    useEffect(() => {
        if (props.user) {
            setUser(props.user);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.user])

    const onInputChangeHandler = (e) => {
        user[e.target.name] = e.target.value;
        setUser(user);
        if (props.inputChangeHandler) {
            props.inputChangeHandler(user);
        }
    }

    const superUserChangeHandler = (e) => {
        user[e.target.name] = !user[e.target.name];
        setUser(user);
        if (props.inputChangeHandler) {
            props.inputChangeHandler(user);
        }
    }

    const renderUsername = () => {
        if (props.displayUsername) {
            return (<TextField label="User Name" name="username" value={cleanStringInput(user.username)} fullWidth disabled={!props.canEdit}
                               onChange={onInputChangeHandler} required/>);
        }
        return null;
    }

    const renderSuperUser = () => {
        if (superUser) {
            return (<FormControlLabel control={<Checkbox name="superUser" checked={user.superUser}
                                                         onChange={superUserChangeHandler} disabled={!props.canEdit}/>} label="Super User"/>);
        }
        return null;
    }


    return (
        <React.Fragment>
            <Typography variant="h6" align="center">User Details</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="First Name" name="firstName" value={cleanStringInput(user.firstName)} fullWidth disabled={!props.canEdit}
                               onChange={onInputChangeHandler} required/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Last Name" name="lastName" value={cleanStringInput(user.lastName)} fullWidth disabled={!props.canEdit}
                               onChange={onInputChangeHandler} required/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="E-Mail Address" name="emailAddress" value={cleanStringInput(user.emailAddress)} fullWidth disabled={!props.canEdit}
                               onChange={onInputChangeHandler} required/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Phone Number" name="phoneNumber" value={cleanStringInput(user.phoneNumber)} fullWidth disabled={!props.canEdit}
                               onChange={onInputChangeHandler} required/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    {renderUsername()}
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    {renderSuperUser()}
                </Grid>
                <Grid item xs={6} sm={1}/>
            </Grid>
        </React.Fragment>
    )

}

export default UserComponent;