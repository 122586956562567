import React from 'react';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const SellOfferTypeSearch = (props) => {

    const search = useSelector(props.selector);
    const dispatch = useDispatch();

    const onInputChange = (e) => {
        dispatch({type: props.searchDispatch, value: e.target.value});
    }

    const onRemoveIconClick = () => {
        dispatch({type: props.searchDispatch, value: ''});
        dispatch({type: props.removeDispatch, value: props.name})
    }

    return (
        <Grid item xs={6} sm={9}>
            <Box display="flex" flexWrap="nowrap">
                <Box>
                    <FormControl component="fieldset">
                        <FormLabel>Firm Offer Type</FormLabel>
                        <RadioGroup name="firmOfferType" value={search.sellOfferType} onChange={onInputChange} row>
                            <FormControlLabel control={<Radio/>} label="Monthly Shape" value="monthlyShape"/>
                            <FormControlLabel control={<Radio/>} label="Standard Product" value="standardProduct"/>
                            <FormControlLabel control={<Radio/>} label="On Peak" value="onPeak"/>
                            <FormControlLabel control={<Radio/>} label="Off Peak" value="offPeak"/>
                            <FormControlLabel control={<Radio/>} label="Around the Clock" value="aroundTheClock"/>
                            <FormControlLabel control={<Radio/>} label="7x16" value="sevenBySixteen"/>
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Box>
                    <IconButton onClick={onRemoveIconClick}>
                        <RemoveCircleOutlineIcon/>
                    </IconButton>
                </Box>
            </Box>
        </Grid>
    )

}

export default SellOfferTypeSearch;