import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {makeStyles} from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {CLEAR_ERROR, CLEAR_SUCCESS} from "../../store/actionTypes";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(10),
        },
    },
    errorSnackbar: {
        background: 'red',
        width: '100%',
    },
    successSnackbar: {
        background: 'green',
        width: '100%'
    }
}));

const WpecAlert = (props) => {

    const dispatch = useDispatch();

    const {error, success} = useSelector(state => state.popup);

    const classes = useStyles();

    const createErrorCloseButton = () => {
        return (
            <IconButton aria-label="close" onClick={errorClose}>
                <CloseIcon/>
            </IconButton>
        );
    };

    const createSuccessCloseButton = () => {
        return (
            <IconButton aria-label="close" onClick={successClose}>
                <CloseIcon/>
            </IconButton>
        );
    }

    const errorClose = () => {
        dispatch({type: CLEAR_ERROR});
    };

    const successClose = () => {
        dispatch({type: CLEAR_SUCCESS});
    }

    return (
        <div className={classes.root}>
            <Snackbar ContentProps={{classes: {root: classes.errorSnackbar}}} open={Boolean(error)} onClose={errorClose}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                      action={createErrorCloseButton()} message={error}/>
            <Snackbar ContentProps={{classes: {root: classes.successSnackbar}}} open={Boolean(success)} onClose={successClose}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                      action={createSuccessCloseButton()} message={success}/>
        </div>
    );

};

export default WpecAlert;