import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import axios from '../../../axios/AxiosInterceptors';
import {onError} from '../../../store/actions/popupActions';
import {trackPromise} from "react-promise-tracker";
import Button from "@material-ui/core/Button";
import BuyBidProfile from "./BuyBidProfile";
import {updateBuyBidProfile} from '../../../store/spark-exchange/actions/buyBidProfileActions';
import {useHistory, useParams} from "react-router";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
}));

const ViewSellOffer = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const [buyBidProfile, setBuyBidProfile] = useState({});
    const [isEdit, setIsEdit] = useState();

    useEffect(() => {
        retrieveBuyBidProfile();
        // eslint-disable-next-line
    }, [params]);

    const retrieveBuyBidProfile = async () => {
        await trackPromise(axios.get("/spark-exchange/buy-bids/profiles/" + params.buyBidProfileId).then(response => {
            setBuyBidProfile(response.data);
        }).catch((error) => {
            dispatch(onError(error.response));
        }));
    }

    const backButtonClick = () => {
        history.goBack();
    };

    const saveButtonClick = () => {
        dispatch(updateBuyBidProfile(buyBidProfile.id, buyBidProfile));
        this.props.history.goBack();
    }

    const editButtonClick = () => {
        setIsEdit(true);
    }

    const inputChangeHandler = (buyBidProfile) => {
        setBuyBidProfile(buyBidProfile);
    }

    const renderViewBuyBidProfileButtons = () => {
        if (isEdit) {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={saveButtonClick}>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={backButtonClick}>Back</Button>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={editButtonClick}>Edit</Button>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={backButtonClick}>Back</Button>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                </React.Fragment>
            );
        }
    }

        return (
            <main className={classes.layout}>
                <Typography variant="h3" align="center">{buyBidProfile && buyBidProfile.name ? buyBidProfile.name : "Buy Bid Profile"}</Typography>
                {buyBidProfile &&
                <BuyBidProfile buyBidProfile={buyBidProfile} isEdit={isEdit} inputChangeHandler={inputChangeHandler}/>
                }
                <Grid container spacing={2}>
                    {renderViewBuyBidProfileButtons()}
                </Grid>
            </main>
        );
}

export default ViewSellOffer;