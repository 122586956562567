import {trackPromise} from "react-promise-tracker";

import {CREATE_APPLICATION_ROLE_SUCCESS, RETRIEVE_APPLICATION_ROLES_SUCCESS, UPDATE_APPLICATION_ROLE_SUCCESS} from "../userManagementActionTypes";
import axios from '../../../axios/AxiosInterceptors';
import {onError} from '../../actions/popupActions';

export const createRoleSuccess = (role) => {
    return {
        type: CREATE_APPLICATION_ROLE_SUCCESS,
        role: role
    }
}

export const retrieveRolesSuccess = (roles) => {
    return {
        type: RETRIEVE_APPLICATION_ROLES_SUCCESS,
        roles: roles
    }
}

export const updateRoleSuccess = (role) => {
    return {
        type: UPDATE_APPLICATION_ROLE_SUCCESS,
        role: role
    }
}

export function createApplicationRole(role) {
    return async (dispatch) => {
        await trackPromise(axios.post("/auth/application/roles", JSON.stringify(role)).then(response => {
            return dispatch(createRoleSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }
}

export function retrieveApplicationRoles() {
    return async (dispatch) => {
        await trackPromise(axios.get("/auth/application/roles").then(response => {
            return dispatch(retrieveRolesSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }
}

export function updateApplicationRoles(roleId, role) {
    return async (dispatch) => {
        await trackPromise(axios.put("/auth/application/roles/" + roleId, JSON.stringify(role)).then(response => {
            return dispatch(updateRoleSuccess(role));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    }
}

