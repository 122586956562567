import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";

import {retrieveBuyBidProfiles} from '../../../store/spark-exchange/actions/buyBidProfileActions';
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import RenewableEnergyCredits from "./RenewableEnergyCredits";
import MonthlyOutput from "./MonthlyOutput";
import FirmMonthlyShapeOutput from "./FirmMonthlyShapeOutput";
import {useHistory, useParams} from "react-router";
import {cleanStringInput} from "../../../Utility/StringUtil";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {createBuyBid, updateBuyBid} from "../../../store/spark-exchange/actions/buyBidActions";
import {FormHelperText} from "@material-ui/core";
import TwoDigitCurrency from "../../NumberFormat/TwoDigitCurrency";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import {lessThanOneNotAllowed} from "../../../Utility/NumberFormatUtil";
import moment from "moment";
import {cloneDeep} from "lodash";
import {retrieveDeliveryPoints, retrieveRequiredMilestones} from "../../../store/spark-exchange/actions/sparkExchangeActions";
import {Autocomplete} from "@material-ui/lab";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {onError} from "../../../store/actions/popupActions";
import TermSheetComponent from "../TermSheet/TermSheetComponent";
import UnitContingentMonthlyOutput from "../UnitContingentMonthlyOutput/UnitContingentMonthlyOutput";

const BuyBidComponent = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const {accountId} = useParams();
    const {showButtons} = props;
    const buyBidProfiles = useSelector(state => state.buyBidProfiles.buyBidProfiles);
    const deliveryPoints = useSelector(state => state.sparkExchange.deliveryPoints);
    const requiredMilestones = useSelector(state => state.sparkExchange.requiredMilestones);
    const [buyBid, setBuyBid] = useState({
        market: '',
        includePhysicalAttributes: true,
        includeCapacity: true,
        includeEnergyEscalation: false,
        includeCapacityEscalation: false,
        includeEnvironmentalAttributes: true,
        includeEnvironmentalEscalation: false,
        includeComplianceRecs: true,
        includedRecs: [],
        buyBidType: '',
        firmType: '',
        standardProductType: '',
        startDate: null,
        generatorType: '',
        includeMilestoneRequirements: false,
        states: [],
        includeRecEscalation: false,
        recEscalationPercentage: '',
        recEscalationDelay: '',
        recEscalationFrequency: ''
    });
    const [isEdit, setIsEdit] = useState(true);
    const [isWaitingForConfirm, setIsWaitingForConfirm] = useState(false);
    const [validation, setValidation] = useState({});
    const [buyBidProfileId, setBuyBidProfileId] = useState('');
    const [termSheet, setTermSheet] = useState('');

    const generatorTypes = ['Bio-Mass', 'Coal', 'Fuel Cell', 'Gas', 'Hydro', 'Nuclear', 'Pumped Hydro', 'Solar', 'Storage', 'Waste Energy', 'Wind'];
    const pjmStates = ['DE', 'DC', 'IL', 'IN', 'KY', 'MD', 'MI', 'NJ', 'NC', 'OH', 'PA', 'TN', 'VA', 'WV'];

    useEffect(() => {
        dispatch(retrieveBuyBidProfiles());
        setIsEdit(props.isEdit);
        if (props.buyBid) {
            let bid = cloneDeep(props.buyBid);
            if (bid.startDate) {
                bid.startDate = moment(bid.startDate);
            }
            setBuyBid({...bid});
        }
        if (!deliveryPoints || deliveryPoints.length < 1) {
            dispatch(retrieveDeliveryPoints());
        }
        if (!requiredMilestones || requiredMilestones.length < 1) {
            dispatch(retrieveRequiredMilestones());
        }
        // eslint-disable-next-line
    }, [accountId]);

    const onStateSelectChange = (event, value) => {
        buyBid.states = [...value];
        setBuyBid({...buyBid});
    }

    const onInputChangeHandler = (e) => {
        if (e.target.type === "Number") {
            if (e.target.type) {
                if (e.target.type === "termLength") {
                    buyBid[e.target.name] = Number.parseInt(e.target.value);
                } else {
                    buyBid[e.target.name] = Number.parseFloat(e.target.value);
                }
            } else {
                buyBid[e.target.name] = e.target.value;
            }
        } else if (e.target.type === "checkbox") {
            buyBid[e.target.name] = !buyBid[e.target.name];
        } else {
            buyBid[e.target.name] = e.target.value;
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    };

    const startDateChangeHandler = (date, value) => {
        buyBid.startDate = date;
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    };

    const onIncludeMileRequirementsChangeHandler = () => {
        buyBid.includeMilestoneRequirements = !buyBid.includeMilestoneRequirements;
        if (!buyBid.requiredMilestones || buyBid.requiredMilestones.length === 0) {
            buyBid.requiredMilestones = [];
            requiredMilestones.forEach(r => {
                let rm = {};
                rm.includeMilestone = false;
                rm.milestoneName = r.milestoneName;
                rm.milestoneDescription = r.milestoneDescription;
                rm.requiredDate = null;
                buyBid.requiredMilestones.push(rm);
            });
            buyBid.milestoneCurePeriod = '';
        } else if (!buyBid.includeMilestoneRequirements) {
            buyBid.requiredMilestones.forEach(r => {
                r.includeMilestone = false;
                r.requiredDate = null
            });
            buyBid.milestoneCurePeriod = '';
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    }

    const onIncludeEnergyChangeHandler = () => {
        buyBid.includePhysicalAttributes = !buyBid.includePhysicalAttributes;
        if (!buyBid.includePhysicalAttributes) {
            buyBid.variablePaymentMwh = null;
            buyBid.includeEnergyEscalation = false;
            buyBid.energyEscalationPercentage = null;
            buyBid.energyEscalationDelay = null;
            buyBid.energyEscalationFrequency = null;
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    };

    const onIncludeEnergyEscalationChangeHandler = () => {
        buyBid.includeEnergyEscalation = !buyBid.includeEnergyEscalation;
        if (!buyBid.includeEnergyEscalation) {
            buyBid.energyEscalationPercentage = null;
            buyBid.energyEscalationDelay = null;
            buyBid.energyEscalationFrequency = null;
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    };

    const onIncludeCapacityChangeHandler = () => {
        buyBid.includeCapacity = !buyBid.includeCapacity;
        if (!buyBid.includeCapacity) {
            buyBid.fixedPaymentMwday = null;
            buyBid.includeCapacityEscalation = false;
            buyBid.capacityEscalationPercentage = null;
            buyBid.capacityEscalationDelay = null;
            buyBid.capacityEscalationFrequency = null;
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    };

    const onIncludeCapacityEscalationChangeHandler = () => {
        buyBid.includeCapacityEscalation = !buyBid.includeCapacityEscalation;
        if (!buyBid.includeCapacityEscalation) {
            buyBid.capacityEscalationPercentage = null;
            buyBid.capacityEscalationDelay = null;
            buyBid.capacityEscalationFrequency = null;
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    };

    const onIncludeEnvironmentalAttributesChangeHandler = () => {
        buyBid.includeEnvironmentalAttributes = !buyBid.includeEnvironmentalAttributes;
        if (!buyBid.includeEnvironmentalAttributes) {
            buyBid.environmentalAttributesMwh = null;
            buyBid.includeEnvironmentalEscalation = false;
            buyBid.environmentalEscalationPercentage = null;
            buyBid.environmentalEscalationDelay = null;
            buyBid.environmentalEscalationFrequency = null;
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    };

    const onIncludeEnvironmentalEscalationChangeHandler = () => {
        buyBid.includeEnvironmentalEscalation = !buyBid.includeEnvironmentalEscalation;
        if (!buyBid.includeEnvironmentalEscalation) {
            buyBid.environmentalEscalationPercentage = null;
            buyBid.environmentalEscalationDelay = null;
            buyBid.environmentalEscalationFrequency = null;
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    }

    const buyBidProfileChange = (e) => {
        let buyBidProfile = findBuyBidProfile(e.target.value);
        buyBid.carbonDioxideEmissions = buyBidProfile.carbonDioxideEmissions;
        buyBid.nitrogenOxideEmissions = buyBidProfile.nitrogenOxideEmissions;
        buyBid.sulfurDioxideEmissions = buyBidProfile.sulfurDioxideEmissions;
        buyBid.particulatesEmissions = buyBidProfile.particulatesEmissions;
        setBuyBid({...buyBid});
        setBuyBidProfileId(e.target.value);
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    };

    const onIncludeComplianceRecsChangeHandler = () => {
        buyBid.includeComplianceRecs = !buyBid.includeComplianceRecs;
        buyBid.includeRecEscalation = false;
        buyBid.recEscalationPercentage = null;
        buyBid.recEscalationDelay = null;
        buyBid.recEscalationFrequency = null;
        buyBid.recPayment = null;
        if (!buyBid.includeComplianceRecs && buyBid.includedRecs && buyBid.includedRecs.length > 0) {
            buyBid.includeComplianceRecs = false;
            buyBid.includedRecs = [];
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    };

    const onIncludeRecEscalationChangeHandler = () => {
        buyBid.includeRecEscalation = !buyBid.includeRecEscalation;
        if (!buyBid.includeRecEscalation) {
            buyBid.recEscalationPercentage = null;
            buyBid.recEscalationDelay = null;
            buyBid.recEscalationFrequency = null;
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    }

    const onRecInputChangeHandler = (registeredRecs) => {
        buyBid.includedRecs = registeredRecs;
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    };

    const onBuyBidTypeChange = (e) => {
        buyBid.buyBidType = e.target.value;
        if (e.target.value === "unitContingent") {
            buyBid.firmType = '';
            buyBid.standardProductType = '';
        } else if (e.target.value === "firm") {
            buyBid.monthlyOutputs = null;
            buyBid.unitContingentMonthlyOutputs = [];
            buyBid.monthlyDeviation = '';
            ;
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    };

    const onFirmTypeChange = (e) => {
        buyBid.firmType = e.target.value;
        let monthlyOutputs = [];
        for (let i = 1; i < 13; i++) {
            monthlyOutputs.push({month: i, output: 0});
        }
        buyBid.monthlyOutputs = monthlyOutputs;
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    };

    const onMonthlyOutputsChange = (fieldName, value) => {
        if (fieldName === "firmStandardProductMonthlyOutputs") {
            buyBid.monthlyOutputs = value;
        } else if (fieldName === "firmShapeMonthlyOutputs") {
            buyBid.monthlyShapeOutputs = value;
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    };

    const onUnitContingentOutputChange = (value) => {
        setBuyBid({...value});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(value);
        }
    }

    const onGeneratorTypeChange = (e) => {
        buyBid.generatorType = e.target.value;
        buyBid.nuclearWasteEmissions = null;
        buyBid.carbonDioxideEmissions = null;
        buyBid.nitrogenOxideEmissions = null;
        buyBid.sulfurDioxideEmissions = null;
        buyBid.particulatesEmissions = null;
        buyBid.heatRate = null;
        buyBid.fuelRate = null;
        buyBid.primaryFuelType = null;
        buyBid.secondaryFuelType = null;
        if (buyBid.generatorType) {
            validation.generatorType = false;
            validation.generatorTypeMessage = '';
            setValidation({...validation});
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    }

    const handleDeliveryPointChange = (event, value) => {
        if (value) {
            buyBid.deliveryPoint = value.pnodeName;
            buyBid.pnodeId = value.pnodeId;
        } else {
            buyBid.deliveryPoint = null;
            buyBid.pnodeId = null;
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    }

    const onSaveBuyBid = (isPublished) => {
        buyBid.published = isPublished;
        setBuyBid(buyBid);
        if (buyBid.id) {
            dispatch(updateBuyBid(accountId, buyBid.id, buyBid));
        } else {
            dispatch(createBuyBid(accountId, buyBid));
        }
        history.push("/spark-exchange/account/" + accountId + "/buy-bids");
    }

    const onConfirmBuyBid = async () => {
        if (isValid()) {
            setIsEdit(false);
            setIsWaitingForConfirm(true);
            await trackPromise(axios.post("/spark-exchange/account/" + accountId + "/buy-bids/" + buyBid.id, JSON.stringify(buyBid), {
                headers: {
                    'Accept': 'application/pdf'
                },
                responseType: "arraybuffer"
            }).then(response => {
                setTermSheet(response.data);
            }).catch(error => {
                dispatch(onError(error.response));
            }))
        }
    }

    const onEdit = () => {
        setIsEdit(true);
        setIsWaitingForConfirm(false);
    }

    const onBack = () => {
        history.goBack();
    }

    const onIncludedMilestoneChange = (requiredMilestone) => {
        for (let rm of buyBid.requiredMilestones) {
            if (rm.milestoneName === requiredMilestone.milestoneName) {
                rm.includeMilestone = !rm.includeMilestone;
                if (!rm.includeMilestone) {
                    rm.requiredDate = null;
                }
            }
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    }

    const onMilestoneDateChange = (date, value, requiredMilestone) => {
        for (let rm of buyBid.requiredMilestones) {
            if (rm.milestoneName === requiredMilestone.milestoneName) {
                rm.includeMilestone = true;
                rm.requiredDate = date;
            }
        }
        setBuyBid({...buyBid});
        if (props.inputChangeHandler) {
            props.inputChangeHandler(buyBid);
        }
    }

    const findMilestoneIncludedValue = (requiredMilestone) => {
        for (let rm of buyBid.requiredMilestones) {
            if (rm.milestoneName === requiredMilestone.milestoneName) {
                return rm.includeMilestone;
            }
        }
        return false;
    }

    const findMilestoneDate = (requiredMilestone) => {
        for (let rm of buyBid.requiredMilestones) {
            if (rm.milestoneName === requiredMilestone.milestoneName) {
                if (rm.requiredDate) {
                    return moment(rm.requiredDate);
                }
            }
        }
        return null;
    }

    const isValid = () => {
        let validationError = true;
        if (!buyBid.market) {
            validation.market = true;
            validation.marketMessage = "Market type must be selected";
            validationError = false;
        } else {
            validation.market = false;
            validation.marketMessage = "";
        }
        if (!buyBid.startDate) {
            validation.startDate = true;
            validation.startDateMessage = "Start date is required";
            validationError = false;
        } else {
            if (buyBid.startDate instanceof Date) {
                if (buyBid.startDate.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
                    validation.startDate = true;
                    validation.startDateMessage = "Start date must be after today.";
                    validationError = false;
                }
            } else {
                validation.startDate = false;
                validation.startDateMessage = "";
            }
        }
        if (!buyBid.termLengthMonth) {
            validation.termLengthMonth = true;
            validation.termLengthMonthMessage = "Term Length is required";
            validationError = false;
        } else {
            validation.termLengthMonth = false;
            validation.termLengthMonthMessage = "";
        }
        if (!buyBid.deliveryPoint || buyBid.deliveryPoint.length < 3) {
            validation.deliveryPoint = true;
            validation.deliveryPointMessage = "Delivery Point is required and must be at least 3 characters";
            validationError = false;
        } else {
            validation.deliveryPoint = false;
            validation.deliveryPointMessage = "";
        }
        if (buyBid.includePhysicalAttributes && !buyBid.variablePaymentMwh) {
            validation.variablePaymentMwh = true;
            validation.variablePaymentMwhMessage = "Energy payment is required if physical energy box is checked";
            validationError = false;
        } else {
            validation.variablePaymentMwh = false;
            validation.variablePaymentMwhMessage = "";
        }
        if (buyBid.includeEnergyEscalation === true) {
            if (!buyBid.energyEscalationPercentage) {
                validation.energyEscalationPercentage = true;
                validation.energyEscalationPercentageMessage = "Required if include energy escalation is checked";
                validationError = false;
            } else {
                validation.energyEscalationPercentage = false;
                validation.energyEscalationPercentageMessage = "";
            }
            if (!buyBid.energyEscalationDelay) {
                validation.energyEscalationDelay = true;
                validation.energyEscalationDelayMessage = "Required if include energy escalation is checked";
                validationError = false;
            } else {
                validation.energyEscalationDelay = false;
                validation.energyEscalationDelayMessage = "";
            }
            if (!buyBid.energyEscalationFrequency) {
                validation.energyEscalationFrequency = true;
                validation.energyEscalationFrequencyMessage = "Required if include energy escalation is checked";
                validationError = false;
            } else {
                validation.energyEscalationFrequency = false;
                validation.energyEscalationFrequencyMessage = "";
            }
        }
        if (buyBid.includeCapacity && !buyBid.fixedPaymentMwday) {
            validation.fixedPaymentMwday = true;
            validation.fixedPaymentMwdayMessage = "Capacity payment is required if capacity box is checked";
            validationError = false;
        } else {
            validation.fixedPaymentMwday = false;
            validation.fixedPaymentMwdayMessage = "";
        }
        if (buyBid.includeCapacityEscalation === true) {
            if (!buyBid.capacityEscalationPercentage) {
                validation.capacityEscalationPercentage = true;
                validation.capacityEscalationPercentageMessage = "Required if include capacity escalation is checked";
                validationError = false;
            } else {
                validation.capacityEscalationPercentage = false;
                validation.capacityEscalationPercentageMessage = "";
            }
            if (!buyBid.capacityEscalationDelay) {
                validation.capacityEscalationDelay = true;
                validation.capacityEscalationDelayMessage = "Required if include capacity escalation is checked";
                validationError = false;
            } else {
                validation.capacityEscalationDelay = false;
                validation.capacityEscalationDelayMessage = "";
            }
            if (!buyBid.capacityEscalationFrequency) {
                validation.capacityEscalationFrequency = true;
                validation.capacityEscalationFrequencyMessage = "Required if include capacity escalation is checked";
                validationError = false;
            } else {
                validation.capacityEscalationFrequency = false;
                validation.capacityEscalationFrequencyMessage = "";
            }
        }
        if (buyBid.includeComplianceRecs) {
            if (!buyBid.includedRecs || buyBid.includedRecs.length === 0) {
                validation.includedRecs = true;
                validation.includedRecsMessage = "Recs must be selected if Compliance Recs is checked";
                validationError = false;
            } else {
                validation.includedRecs = false;
                validation.includedRecsMessage = "";
            }
            let registeredRecs = [];
            for (let includedRec of buyBid.includedRecs) {
                if(!includedRec.recClass) {
                    registeredRecs.push(includedRec.state);
                }
            }
            if (registeredRecs.length > 0) {
                validationError = false;
            }
            validation.registeredRecs = registeredRecs;
        }
        if (buyBid.includeEnvironmentalEscalation === true) {
            if (!buyBid.environmentalEscalationPercentage) {
                validation.environmentalEscalationPercentage = true;
                validation.environmentalEscalationPercentageMessage = "Required if include environmental escalation is checked";
                validationError = false;
            } else {
                validation.environmentalEscalationPercentage = false;
                validation.environmentalEscalationPercentageMessage = "";
            }
            if (!buyBid.environmentalEscalationDelay) {
                validation.environmentalEscalationDelay = true;
                validation.environmentalEscalationDelayMessage = "Required if include environmental escalation is checked";
                validationError = false;
            } else {
                validation.environmentalEscalationDelay = true;
                validation.environmentalEscalationDelayMessage = "";
            }
            if (!buyBid.environmentalEscalationFrequency) {
                validation.environmentalEscalationFrequency = true;
                validation.environmentalEscalationFrequencyMessage = "Required if include environmental escalation is checked";
                validationError = false;
            } else {
                validation.environmentalEscalationFrequency = false;
                validation.environmentalEscalationFrequencyMessage = "";
            }
        }
        if (!buyBid.buyBidType) {
            validation.buyBidType = true;
            validation.buyBidTypeMessage = "A buy bid type must be selected";
            validationError = false;
        } else {
            validation.buyBidType = false;
            validation.buyBidTypeMessage = "";
        }
        if (buyBid.buyBidType === 'firm') {
            if (!buyBid.firmType) {
                validation.firmType = true;
                validation.firmTypeMessage = "A firm type must be selected";
                validationError = false;
            } else {
                validation.firmType = false;
                validation.firmTypeMessage = "";
            }
        }
        if (buyBid.buyBidType === 'unitContingent') {
            validation.unitContingentMonthlyOutputs = [];
            if (!buyBid.unitContingentMonthlyOutputs || buyBid.unitContingentMonthlyOutputs.length === 0) {
                for (let i = 0; i < 12; i++) {
                    validation.unitContingentMonthlyOutputs.push({month: i + 1, error: true, message: 'Target, Minimum, and Maximum MWhs must be provided'});
                }
                validationError = false;
            } else {
                let isValid = true;
                for (let output of buyBid.unitContingentMonthlyOutputs) {
                    if (!output.minMwhs || !output.targetMwhs || !output.maxMwhs) {
                        validation.unitContingentMonthlyOutputs.push({month: output.month, error: true, message: 'Target, Minimum, and Maximum MWhs must be provided'});
                        isValid = false;
                    } else {
                        validation.unitContingentMonthlyOutputs.push({month: output.month, error: false, message: ''});
                    }
                }
                if (!isValid) {
                    validationError = false;
                } else {
                    validation.unitContingentMonthlyOutputs = [];
                }
            }
        }
        if (buyBid.firmType === 'standardProduct') {
            if (!buyBid.standardProductType) {
                validation.standardProductType = true;
                validation.standardProductTypeMessage = "A standard product type must be selected";
                validationError = false;
            } else {
                validation.standardProductType = false;
                validation.standardProductTypeMessage = "";
            }
            if (!buyBid.quantityMw) {
                validation.quantityMw = true;
                validation.quantityMwMessage = "A quantity must be provided";
                validationError = false;
            } else {
                validation.quantityMw = false;
                validation.quantityMwMessage = "";
            }
        }
        if (buyBid.firmType === 'monthlyShape') {
            if (!buyBid.quantityMw) {
                validation.quantityMw = true;
                validation.quantityMwMessage = "A quantity must be provided";
                validationError = false;
            } else {
                validation.quantityMw = false;
                validation.quantityMwMessage = "";
            }
        }
        setValidation({...validation});
        return validationError;
    }

    const findBuyBidProfile = (id) => {
        for (let buyBidProfile of buyBidProfiles) {
            if (buyBidProfile.id === id) {
                return buyBidProfile;
            }
        }
    };

    const renderStateSelect = () => {
        return (
            <Autocomplete multiple options={pjmStates} value={buyBid.states ? buyBid.states : []} renderInput={(params) => <TextField {...params} label="State(s)"/>}
                          onChange={(event, value) => onStateSelectChange(event, value)} disabled={!isEdit}/>
        );
    }

    const renderBuyBidProfiles = () => {
        if (isEdit) {
            let profiles = buyBidProfiles;
            profiles.sort((p1, p2) => {
                return p1.name.localeCompare(p2.name);
            })
            return (<FormControl fullWidth>
                <InputLabel>Emissions Profile</InputLabel>
                <Select value={buyBidProfileId} onChange={buyBidProfileChange} name="buyBidProfile" fullWidth>
                    {profiles.map(buyBidProfile => <MenuItem value={buyBidProfile.id} key={buyBidProfile.id}>{buyBidProfile.name}</MenuItem>)}
                </Select>
            </FormControl>);
        }
        return null;
    };

    const renderMonthlyOutput = () => {
        if (buyBid.buyBidType === "unitContingent") {
            return (<UnitContingentMonthlyOutput isEdit={isEdit} inputChangeHandler={onUnitContingentOutputChange} bidOffer={buyBid} validation={validation}/>);
        } else if (buyBid.buyBidType === "firm" && buyBid.firmType === "standardProduct") {
            return (<React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={6} sm={4}>
                        <FormControl>
                            <FormLabel>MW Quantity</FormLabel>
                            <TextField label="Quantity" name="quantityMw" value={cleanStringInput(buyBid.quantityMw)} onChange={onInputChangeHandler}
                                       fullWidth InputProps={{
                                endAdornment: <InputAdornment position="start">MW</InputAdornment>,
                                inputComponent: GenericNumberFormat,
                                inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                            }} disabled={!isEdit} required error={validation.quantityMw} helperText={validation.quantityMwMessage}
                                       InputLabelProps={{shrink: !!buyBid.quantityMw}}/>
                            <FormHelperText error={buyBid.quantityMw && !buyBid.startDate}>A MW quantity and start date must be provided to calculate indicative
                                values.</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={4}/>
                </Grid>
                <Grid container spacing={2}>
                    <MonthlyOutput onInputChangeHandler={onMonthlyOutputsChange} quantity={buyBid.quantityMw}
                                   standardProductType={buyBid.standardProductType} startDate={buyBid.startDate} isEdit={isEdit}
                                   monthlyOutputs={buyBid.monthlyOutputs}/>
                </Grid>
            </React.Fragment>);
        } else if (buyBid.buyBidType === "firm" && buyBid.firmType === "monthlyShape") {
            return (<React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={6} sm={4}>
                        <FormControl>
                            <FormLabel>MW Quantity</FormLabel>
                            <TextField label="Quantity" name="quantityMw" value={cleanStringInput(buyBid.quantityMw)} onChange={onInputChangeHandler}
                                       fullWidth InputProps={{
                                endAdornment: <InputAdornment position="start">MW</InputAdornment>,
                                inputComponent: GenericNumberFormat,
                                inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                            }} disabled={!isEdit} required error={validation.quantityMw} helperText={validation.quantityMwMessage}
                                       InputLabelProps={{shrink: !!buyBid.quantityMw}}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={12}/>
                </Grid>
                <Grid container spacing={2}>
                    <FirmMonthlyShapeOutput onInputChangeHandler={onMonthlyOutputsChange} quantity={buyBid.quantityMw} isEdit={isEdit}
                                            monthlyOutputs={buyBid.monthlyShapeOutputs}/>
                </Grid>
            </React.Fragment>);
        }
    }

    const renderButtons = () => {
        if (isEdit) {
            return (<React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={() => onSaveBuyBid(false)}>Save Draft</Button>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={onConfirmBuyBid}>Publish</Button>
                    </Grid>
                </Grid>
            </React.Fragment>)
        } else if (isWaitingForConfirm) {
            return (<React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={12}>
                        <Typography align="center">
                            By click Submit below the buy bid will be created and will be immediately matched against existing sell offers. If a match is found, you will be
                            given a
                            brief window to confirm. If not match is found the sell offer will remain active for comparison against sell offers. During this time you may modify
                            your buy bid. When a sell offer is created that matches this buy bid, as a buyer you will not receive a confirmation window.
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={onEdit}>Edit</Button>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={() => onSaveBuyBid(true)}>Publish</Button>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                </Grid>
            </React.Fragment>)
        } else if (!buyBid.published) {
            return (<React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={onEdit}>Edit</Button>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3} align="center">
                        <Button variant="contained" onClick={onBack}>Back</Button>
                    </Grid>
                </Grid>
            </React.Fragment>)
        } else {
            return (<React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={6} sm={4} align="center">
                        <Button variant="contained" onClick={onBack}>Back</Button>
                    </Grid>
                    <Grid item xs={6} sm={4}/>
                </Grid>
            </React.Fragment>)
        }
    }

    const renderGeneratorType = () => {
        if (isEdit) {
            return (<FormControl fullWidth>
                <InputLabel>Generator Type</InputLabel>
                <Select value={cleanStringInput(buyBid.generatorType)} onChange={onGeneratorTypeChange} name="generatorType" fullWidth>
                    {generatorTypes.map(generatorType => <MenuItem value={generatorType} key={generatorType}>{generatorType}</MenuItem>)}
                </Select>
                <FormHelperText error={validation.generatorType}>{validation.generatorTypeMessage}</FormHelperText>
            </FormControl>);
        } else {
            return (<TextField value={cleanStringInput(buyBid.generatorType)} label="Generator Type" name="generatorType" disabled={true} fullWidth/>);
        }
    }

    const renderRequiredMilestones = () => {
        if (buyBid.includeMilestoneRequirements) {
            return (
                <React.Fragment>
                    {requiredMilestones.map(r => {
                        return (<React.Fragment key={r.id}>
                            <Grid item xs={6} sm={1}/>
                            <Grid item xs={6} sm={3}>
                                <FormControlLabel
                                    control={<Checkbox name="milestoneName" checked={findMilestoneIncludedValue(r)} disabled={!isEdit}/>} label={r.milestoneName}
                                    onChange={() => onIncludedMilestoneChange(r)}/>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Typography>{r.milestoneDescription}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Date" name="milestoneDate" value={findMilestoneDate(r)} disabled={!isEdit} fullWidth
                                                        autoOk={true} onChange={(date, value) => onMilestoneDateChange(date, value, r)}/>
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </React.Fragment>)
                    })}
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3}>
                        <TextField label="Milestone Cure Period" name="milestoneCurePeriod" value={buyBid.milestoneCurePeriod} onChange={onInputChangeHandler}
                                   fullWidth InputProps={{
                            endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                            inputComponent: GenericNumberFormat,
                            inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}
                        }} disabled={!isEdit} InputLabelProps={{shrink: !!buyBid.milestoneCurePeriod}}/>
                    </Grid>
                    <Grid item xs={6} sm={8}/>
                </React.Fragment>
            );
        }
    }

    const getOptionLabel = (option) => {
        if (!option.pnodeName) {
            return "";
        }
        return option.pnodeName;
    }

    const renderBuyBidScreen = () => {
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}/>
                    <Grid item xs={6} sm={6}>
                        <FormControl component="fieldset" required error={validation.market}>
                            <FormLabel>Market</FormLabel>
                            <RadioGroup name="market" value={cleanStringInput(buyBid.market)} onChange={onInputChangeHandler} row>
                                <FormControlLabel control={<Radio required={true}/>} label="Day Ahead" value="dayAhead" disabled={!isEdit}/>
                                <FormControlLabel control={<Radio required={true}/>} label="Real Time" value="realTime" disabled={!isEdit}/>
                            </RadioGroup>
                            <FormHelperText>{validation.marketMessage}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3}/>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate" onChange={startDateChangeHandler}
                                                value={buyBid.startDate} disabled={!isEdit} fullWidth error={validation.startDate} autoOk={true}
                                                disablePast helperText={validation.startDateMessage}/>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={4}>
                        <TextField label="Term Length" name="termLengthMonth" value={cleanStringInput(buyBid.termLengthMonth)}
                                   onChange={onInputChangeHandler} fullWidth
                                   InputProps={{
                                       endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                       inputComponent: GenericNumberFormat,
                                       inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                                   }}
                                   disabled={!isEdit} required error={validation.termLengthMonth}
                                   helperText={validation.termLengthMonthMessage ? validation.termLengthMonthMessage : 'Must be a whole number greater than 0'}
                                   InputLabelProps={{shrink: !!buyBid.termLengthMonth}}/>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={4}>
                        {deliveryPoints && deliveryPoints.length > 0 &&
                            <Autocomplete options={deliveryPoints.sort((a, b) => a.pnodeSubType.localeCompare(b.pnodeSubType) || a.pnodeName.localeCompare(b.pnodeName))}
                                          value={{pnodeId: buyBid.pnodeId, pnodeName: buyBid.deliveryPoint}}
                                          getOptionSelected={(option, value) => value.pnodeId === option.pnodeId}
                                          getOptionLabel={(option) => getOptionLabel(option)} groupBy={(option) => option.pnodeSubType}
                                          renderInput={(params) => <TextField {...params} label="Delivery Point" error={validation.deliveryPoint}
                                                                              helperText={validation.deliveryPointMessage}/>}
                                          onChange={(event, value) => handleDeliveryPointChange(event, value)} disabled={!isEdit}/>}
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={4}>
                        {renderStateSelect()}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel control={<Checkbox name="includeMilestoneRequirements" checked={buyBid.includeMilestoneRequirements}
                                                             onChange={onIncludeMileRequirementsChangeHandler} disabled={!isEdit}/>} label="Include Milestones"/>
                    </Grid>
                    <Grid item xs={6} sm={9}/>
                    {renderRequiredMilestones()}
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel
                            control={<Checkbox name="includePhysicalAttributes" checked={buyBid.includePhysicalAttributes} onChange={onIncludeEnergyChangeHandler}
                                               disabled={!isEdit}/>} label="Physical Energy"/>
                    </Grid>
                    {buyBid.includePhysicalAttributes && <React.Fragment>
                        <Grid item xs={6} sm={3}>
                            <TextField label="Energy Payment" name="variablePaymentMwh" value={cleanStringInput(buyBid.variablePaymentMwh)}
                                       onChange={onInputChangeHandler} fullWidth
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">$/MWh</InputAdornment>, inputComponent: TwoDigitCurrency, inputProps: {isEdit: isEdit}
                                       }}
                                       disabled={!isEdit} required error={validation.variablePaymentMwh} helperText={validation.variablePaymentMwhMessage}
                                       InputLabelProps={{shrink: !!buyBid.variablePaymentMwh}}/>
                        </Grid>
                        <Grid item xs={6} sm={6}/>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox name="includeEnergyEscalation" checked={buyBid.includeEnergyEscalation} onChange={onIncludeEnergyEscalationChangeHandler}
                                                   disabled={!isEdit}/>} label="Include Energy Escalation"/>
                        </Grid>
                        {buyBid.includeEnergyEscalation && <React.Fragment>
                            <Grid item xs={6} sm={2}>
                                <TextField label="Percentage" name="energyEscalationPercentage" value={cleanStringInput(buyBid.energyEscalationPercentage)}
                                           onChange={onInputChangeHandler} fullWidth error={validation.energyEscalationPercentage} disabled={!isEdit} required
                                           InputProps={{
                                               endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                               inputComponent: GenericNumberFormat,
                                               inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                                           }}
                                           helperText={validation.energyEscalationPercentageMessage ? validation.energyEscalationPercentageMessage : 'Must be greater than 0'}
                                           InputLabelProps={{shrink: !!buyBid.energyEscalationPercentage}}/>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <TextField label="Delay" name="energyEscalationDelay" value={cleanStringInput(buyBid.energyEscalationDelay)} onChange={onInputChangeHandler}
                                           fullWidth disabled={!isEdit} required error={validation.energyEscalationDelay}
                                           InputProps={{
                                               endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                               inputComponent: GenericNumberFormat,
                                               inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                                           }}
                                           helperText={validation.energyEscalationDelayMessage ? validation.energyEscalationDelayMessage : 'Must be a whole number greater than 0'}
                                           InputLabelProps={{shrink: !!buyBid.energyEscalationDelay}}/>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <TextField label="Frequency" name="energyEscalationFrequency" value={cleanStringInput(buyBid.energyEscalationFrequency)}
                                           onChange={onInputChangeHandler}
                                           fullWidth disabled={!isEdit} required error={validation.energyEscalationFrequency}
                                           InputProps={{
                                               endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                               inputComponent: GenericNumberFormat,
                                               inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                                           }}
                                           helperText={validation.energyEscalationFrequencyMessage ? validation.energyEscalationFrequencyMessage : 'Must be a whole number greater than 0'}
                                           InputLabelProps={{shrink: !!buyBid.energyEscalationFrequency}}/>
                            </Grid>
                        </React.Fragment>}
                    </React.Fragment>}
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel control={<Checkbox name="includeCapacity" checked={buyBid.includeCapacity} onChange={onIncludeCapacityChangeHandler} disabled={!isEdit}/>}
                                          label="Capacity"/>
                    </Grid>
                    {buyBid.includeCapacity && <React.Fragment>
                        <Grid item xs={6} sm={3}>
                            <TextField label="Capacity Payment" name="fixedPaymentMwday" value={cleanStringInput(buyBid.fixedPaymentMwday)} onChange={onInputChangeHandler}
                                       fullWidth InputProps={{
                                endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>, inputComponent: TwoDigitCurrency, inputProps: {isEdit: isEdit}
                            }} disabled={!isEdit || !buyBid.includeCapacity} required error={validation.fixedPaymentMwday} helperText={validation.fixedPaymentMwdayMessage}
                                       InputLabelProps={{shrink: !!buyBid.fixedPaymentMwday}}/>
                        </Grid>
                        <Grid item xs={6} sm={6}/>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox name="includeCapacityEscalation" checked={buyBid.includeCapacityEscalation} onChange={onIncludeCapacityEscalationChangeHandler}
                                                   disabled={!isEdit}/>} label="Include Capacity Escalation"/>
                        </Grid>
                        {buyBid.includeCapacityEscalation && <React.Fragment>
                            <Grid item xs={6} sm={2}>
                                <TextField label="Percentage" name="capacityEscalationPercentage" value={cleanStringInput(buyBid.capacityEscalationPercentage)}
                                           onChange={onInputChangeHandler} type="number" fullWidth disabled={!isEdit} required
                                           InputProps={{
                                               endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                               inputComponent: GenericNumberFormat,
                                               inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                                           }}
                                           error={validation.capacityEscalationPercentage}
                                           helperText={validation.capacityEscalationPercentageMessage ? validation.capacityEscalationPercentageMessage : "Must be greater than 0"}
                                           InputLabelProps={{shrink: !!buyBid.capacityEscalationPercentage}}/>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <TextField label="Delay" name="capacityEscalationDelay" value={cleanStringInput(buyBid.capacityEscalationDelay)} onChange={onInputChangeHandler}
                                           type="number" fullWidth disabled={!isEdit} required
                                           InputProps={{
                                               endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                               inputComponent: GenericNumberFormat,
                                               inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                                           }}
                                           error={validation.capacityEscalationDelay}
                                           helperText={validation.capacityEscalationDelayMessage ? validation.capacityEscalationDelayMessage : "Must be a whole number greater than 0"}
                                           InputLabelProps={{shrink: !!buyBid.capacityEscalationDelay}}/>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <TextField label="Frequency" name="capacityEscalationFrequency" value={cleanStringInput(buyBid.capacityEscalationFrequency)}
                                           onChange={onInputChangeHandler} type="number" fullWidth disabled={!isEdit} required
                                           InputProps={{
                                               endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                               inputComponent: GenericNumberFormat,
                                               inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                                           }}
                                           error={validation.capacityEscalationFrequency}
                                           helperText={validation.capacityEscalationFrequencyMessage ? validation.capacityEscalationFrequencyMessage : "Must be a whole number greater than 0"}
                                           InputLabelProps={{shrink: !!buyBid.capacityEscalationFrequency}}/>
                            </Grid>
                        </React.Fragment>}
                    </React.Fragment>}
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel control={<Checkbox name="includeEnvironmentalAttributes" checked={buyBid.includeEnvironmentalAttributes}
                                                             onChange={onIncludeEnvironmentalAttributesChangeHandler} disabled={!isEdit}/>}
                                          label="Environmental Attributes"/>
                    </Grid>
                    {buyBid.includeEnvironmentalAttributes && <React.Fragment>
                        <Grid item xs={6} sm={3}>
                            <TextField label="Payment" name="environmentalAttributesMwh" value={cleanStringInput(buyBid.environmentalAttributesMwh)} onChange={onInputChangeHandler}
                                       fullWidth disabled={!isEdit}
                                       InputProps={{
                                           endAdornment: <InputAdornment position="start">$/MWh</InputAdornment>, inputComponent: TwoDigitCurrency, inputProps: {isEdit: isEdit}
                                       }} InputLabelProps={{shrink: !!buyBid.environmentalAttributesMwh}}/>
                        </Grid>
                        <Grid item xs={6} sm={6}/>
                        <Grid item xs={6} sm={1}/>
                        <Grid item xs={6} sm={3}>
                            <FormControlLabel
                                control={<Checkbox name="includeEnvironmentalEscalation" checked={buyBid.includeEnvironmentalEscalation}
                                                   onChange={onIncludeEnvironmentalEscalationChangeHandler}
                                                   disabled={!isEdit}/>} label="Include Environmental Escalation"/>
                        </Grid>
                        {buyBid.includeEnvironmentalEscalation && <React.Fragment>
                            <Grid item xs={6} sm={2}>
                                <TextField label="Percentage" name="environmentalEscalationPercentage"
                                           value={cleanStringInput(buyBid.environmentalEscalationPercentage)}
                                           onChange={onInputChangeHandler} fullWidth
                                           InputProps={{
                                               endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                               inputComponent: GenericNumberFormat,
                                               inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                                           }} disabled={!isEdit} required error={validation.environmentalEscalationPercentage}
                                           helperText={validation.environmentalEscalationPercentageMessage}
                                           InputLabelProps={{shrink: !!buyBid.environmentalEscalationPercentage}}/>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <TextField label="Delay" name="environmentalEscalationDelay"
                                           value={cleanStringInput(buyBid.environmentalEscalationDelay)}
                                           onChange={onInputChangeHandler} fullWidth
                                           InputProps={{
                                               endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                               inputComponent: GenericNumberFormat,
                                               inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                                           }} disabled={!isEdit} required error={validation.environmentalEscalationDelay}
                                           helperText={validation.environmentalEscalationDelayMessage}
                                           InputLabelProps={{shrink: !!buyBid.environmentalEscalationDelay}}/>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                                <TextField label="Frequency" name="environmentalEscalationFrequency"
                                           value={cleanStringInput(buyBid.environmentalEscalationFrequency)}
                                           onChange={onInputChangeHandler} fullWidth
                                           InputProps={{
                                               endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                               inputComponent: GenericNumberFormat,
                                               inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                                           }}
                                           disabled={!isEdit} required error={validation.environmentalEscalationFrequency}
                                           helperText={validation.environmentalEscalationFrequencyMessage}
                                           InputLabelProps={{shrink: !!buyBid.environmentalEscalationFrequency}}/>
                            </Grid>
                        </React.Fragment>}
                        <Grid item xs={6} sm={buyBid.includeEnvironmentalEscalation ? 2 : 8}/>
                        <Grid item xs={6} sm={1}/>
                    </React.Fragment>}
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel control={<Checkbox name="includeComplianceRecs" checked={buyBid.includeComplianceRecs}
                                                             onChange={onIncludeComplianceRecsChangeHandler} disabled={!isEdit}/>} label="Compliance RECs"/>
                    </Grid>
                    {buyBid.includeComplianceRecs &&
                        <React.Fragment>
                            <Grid item xs={6} sm={3}>
                                <TextField label="REC Payment" name="recPayment" value={cleanStringInput(buyBid.recPayment)} onChange={onInputChangeHandler} fullWidth
                                           InputProps={{
                                               endAdornment: <InputAdornment position="start">$/MWh</InputAdornment>,
                                               inputComponent: TwoDigitCurrency, inputProps: {isEdit: isEdit}
                                           }} InputLabelProps={{shrink: !!buyBid.recPayment}}/>
                            </Grid>
                            <Grid item xs={6} sm={6}/>
                            <Grid item xs={6} sm={1}/>
                            <Grid item xs={6} sm={3}>
                                <FormControlLabel control={<Checkbox name="includeRecEscalation" checked={buyBid.includeRecEscalation}
                                                                     onChange={onIncludeRecEscalationChangeHandler} disabled={!isEdit}/>} label="Include REC Escalation"/>
                            </Grid>
                            {buyBid.includeRecEscalation && <React.Fragment>
                                <Grid item xs={6} sm={2}>
                                    <TextField label="Percentage" name="recEscalationPercentage" value={cleanStringInput(buyBid.recEscalationPercentage)}
                                               onChange={onInputChangeHandler} fullWidth InputProps={{
                                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        inputComponent: GenericNumberFormat,
                                        inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                                    }} disabled={!isEdit} required InputLabelProps={{shrink: !!buyBid.recEscalationPercentage}}/>
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                    <TextField label="Delay" name="recEscalationDelay" value={cleanStringInput(buyBid.recEscalationDelay)}
                                               onChange={onInputChangeHandler} fullWidth InputProps={{
                                        endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                        inputComponent: GenericNumberFormat,
                                        inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                                    }} disabled={!isEdit} required InputLabelProps={{shrink: !!buyBid.recEscalationDelay}}/>
                                </Grid>
                                <Grid item xs={6} sm={2}>
                                    <TextField label="Frequency" name="recEscalationFrequency" value={cleanStringInput(buyBid.recEscalationFrequency)}
                                               onChange={onInputChangeHandler} fullWidth InputProps={{
                                        endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                                        inputComponent: GenericNumberFormat,
                                        inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: isEdit}
                                    }}
                                               disabled={!isEdit} required InputLabelProps={{shrink: !!buyBid.recEscalationFrequency}}/>
                                </Grid>
                            </React.Fragment>
                            }
                            <RenewableEnergyCredits canEdit={isEdit} inputChangeHandler={onRecInputChangeHandler} registeredRecs={buyBid.includedRecs} validation={validation}/>
                        </React.Fragment>}
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={6} sm={4}>
                        {renderGeneratorType()}
                    </Grid>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={6} sm={4}>
                        {renderBuyBidProfiles()}
                    </Grid>
                    <Grid item xs={6} sm={4}/>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={4}>
                        <TextField label="Carbon Dioxide Emissions" name="carbonDioxideEmissions" value={cleanStringInput(buyBid.carbonDioxideEmissions)}
                                   type="number" InputProps={{
                            endAdornment: <InputAdornment position="start">lbs/MWH</InputAdornment>,
                            inputComponent: GenericNumberFormat,
                            inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}
                        }} onChange={onInputChangeHandler} disabled={!isEdit} fullWidth InputLabelProps={{shrink: !!buyBid.carbonDioxideEmissions}}/>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={4}>
                        <TextField label="Nitrogen Oxide Emissions" name="nitrogenOxideEmissions" value={cleanStringInput(buyBid.nitrogenOxideEmissions)}
                                   type="number" onChange={onInputChangeHandler} disabled={!isEdit} fullWidth
                                   InputProps={{
                                       endAdornment: <InputAdornment position="start">lbs/MWH</InputAdornment>,
                                       inputComponent: GenericNumberFormat,
                                       inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}
                                   }} InputLabelProps={{shrink: !!buyBid.nitrogenOxideEmissions}}/>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={4}>
                        <TextField label="Sulfur Dioxide Emissions" name="sulfurDioxideEmissions" value={cleanStringInput(buyBid.sulfurDioxideEmissions)}
                                   type="number" onChange={onInputChangeHandler} disabled={!isEdit} fullWidth
                                   InputProps={{
                                       endAdornment: <InputAdornment position="start">lbs/MWH</InputAdornment>,
                                       inputComponent: GenericNumberFormat,
                                       inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}
                                   }} InputLabelProps={{shrink: !!buyBid.sulfurDioxideEmissions}}/>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={4}>
                        <TextField label="Particulates Emissions" name="particulatesEmissions" value={cleanStringInput(buyBid.particulatesEmissions)}
                                   type="number" onChange={onInputChangeHandler} disabled={!isEdit} fullWidth
                                   InputProps={{
                                       endAdornment: <InputAdornment position="start">lbs/MWH</InputAdornment>,
                                       inputComponent: GenericNumberFormat,
                                       inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}
                                   }} InputLabelProps={{shrink: !!buyBid.particulatesEmissions}}/>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}/>
                    <Grid item xs={6} sm={6}>
                        <FormControl component="fieldset" required>
                            <FormLabel>Buy Bid Type</FormLabel>
                            <RadioGroup name="offerType" value={cleanStringInput(buyBid.buyBidType)} onChange={onBuyBidTypeChange} row>
                                <FormControlLabel control={<Radio/>} label="Unit Contingent" value="unitContingent" disabled={!isEdit}/>
                                <FormControlLabel control={<Radio/>} label="Firm" value="firm" disabled={!isEdit}/>
                            </RadioGroup>
                            <FormHelperText error={validation.buyBidType}>{validation.buyBidTypeMessage}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3}/>
                </Grid>
                {buyBid.buyBidType === "firm" && <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}/>
                    <Grid item xs={6} sm={6}>
                        <FormControl component="fieldset">
                            <FormLabel>Firm Type</FormLabel>
                            <RadioGroup name="firmOfferType" value={cleanStringInput(buyBid.firmType)} onChange={onFirmTypeChange} row>
                                <FormControlLabel control={<Radio/>} label="Monthly Shape" value="monthlyShape" disabled={!isEdit}/>
                                <FormControlLabel control={<Radio/>} label="Standard Product" value="standardProduct" disabled={!isEdit}/>
                            </RadioGroup>
                            <FormHelperText error={validation.firmType}>{validation.firmTypeMessage}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3}/>
                </Grid>}
                {(buyBid.firmType && buyBid.firmType === "standardProduct") && <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}/>
                    <Grid item xs={6} sm={6}>
                        <FormControl component="fieldset">
                            <FormLabel>Standard Product Type</FormLabel>
                            <RadioGroup name="standardProductType" value={cleanStringInput(buyBid.standardProductType)} onChange={onInputChangeHandler} row>
                                <FormControlLabel control={<Radio/>} label="On Peak" value="onPeak" disabled={!isEdit}/>
                                <FormControlLabel control={<Radio/>} label="Off Peak" value="offPeak" disabled={!isEdit}/>
                                <FormControlLabel control={<Radio/>} label="Around the Clock" value="aroundTheClock" disabled={!isEdit}/>
                                <FormControlLabel control={<Radio/>} label="7x16" value="sevenBySixteen" disabled={!isEdit}/>
                            </RadioGroup>
                            <FormHelperText error={validation.standardProductType}>{validation.standardProductTypeMessage}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={3}/>
                </Grid>}
                {renderMonthlyOutput()}
            </React.Fragment>
        )
    }

    const renderBuyBid = () => {
        if (isWaitingForConfirm) {
            return (<TermSheetComponent termSheet={termSheet}/>);
        } else if (buyBid.matched) {
            onConfirmBuyBid();
            return (<TermSheetComponent termSheet={termSheet}/>);
        }
        return renderBuyBidScreen();
    }

    return (
        <React.Fragment>
            {renderBuyBid()}
            {showButtons && <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12}/>
                </Grid>
                {renderButtons()}
            </React.Fragment>}
        </React.Fragment>
    );

}

export default BuyBidComponent;
