import React from 'react';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import {lessThanOneNotAllowed} from "../../../Utility/NumberFormatUtil";
import {cleanStringInput} from "../../../Utility/StringUtil";

const IncludeEnergyEscalationSearch = (props) => {

    const search = useSelector(props.selector);
    const dispatch = useDispatch();

    const onIncludeEscalationEscalationChangeHandler = (e) => {
        dispatch({type: props.includeEnergyEscalationDispatch, value: !search.includeEnergyEscalation});
    }

    const onInputChange = (e) => {
        if (e.target.name === 'energyEscalationPercentage') {
            dispatch({type: props.energyEscalationPercentageDispatch, value: e.target.value});
        }
        if (e.target.name === 'energyEscalationDelay') {
            dispatch({type: props.energyEscalationDelayDispatch, value: e.target.value});
        }
        if (e.target.name === 'energyEscalationFrequency') {
            dispatch({type: props.energyEscalationFrequencyDispatch, value: e.target.value});
        }
    }

    const onRemoveIconClick = () => {
        dispatch({type: props.includeEnergyEscalationDispatch, value: undefined});
        dispatch({type: props.energyEscalationPercentageDispatch, value: ''});
        dispatch({type: props.energyEscalationDelayDispatch, value: ''});
        dispatch({type: props.energyEscalationFrequencyDispatch, value: ''});
        dispatch({type: props.removeDispatch, value: props.name})
    }

    return (
        <Grid item xs={6} sm={8} key="includeEnergyEscalationSearch">
            <Box display="flex" flexWrap="nowrap">
                <Box>
                    <FormControlLabel
                        control={<Checkbox name="includeEnergyEscalation" value={search.includeEnergyEscalation} onChange={onIncludeEscalationEscalationChangeHandler}/>}
                        label="Include Energy Escalation"/>
                </Box>
                <Box mr={2}>
                    <TextField label="Percentage" name="energyEscalationPercentage" value={cleanStringInput(search.energyEscalationPercentage)} onChange={onInputChange}
                               type="number"
                               fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: true}
                    }} helperText="Less than or Equal"/>
                </Box>
                <Box mr={2}>
                    <TextField label="Delay" name="energyEscalationDelay" value={cleanStringInput(search.energyEscalationDelay)} onChange={onInputChange} type="number" fullWidth
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">Months</InputAdornment>, inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: true}
                               }} helperText="Greater than or Equal"/>
                </Box>
                <Box mr={2}>
                    <TextField label="Frequency" name="energyEscalationFrequency" value={cleanStringInput(search.energyEscalationFrequency)} onChange={onInputChange} type="number"
                               fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">Months</InputAdornment>, inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: true}
                    }} helperText="Greater than or Equal"/>
                </Box>
                <Box>
                    <IconButton onClick={onRemoveIconClick}>
                        <RemoveCircleOutlineIcon/>
                    </IconButton>
                </Box>
            </Box>
        </Grid>
    )

}

export default IncludeEnergyEscalationSearch;