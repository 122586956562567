import {RETRIEVE_ACCOUNT_ROLES_SUCCESS, UPDATE_ACCOUNT_ROLE_SUCCESS, CREATE_ACCOUNT_ROLE_SUCCESS} from "../userManagementActionTypes";

const initialState = {
    roles: []
}

const retrieveRoles = (state, action) => {
    return {...state, roles: action.roles};
}

const updateRole = (state, action) => {
    let roles = state.roles;
    let role = action.role;
    let index = -1;
    for (let i = 0; i < roles.length; i++) {
        if (roles[i].id === role.id) {
            index = i;
        }
    }
    if (index !== -1) {
        roles.splice(index, 1);
    }
    roles.push(role);
    return {...state, roles: roles};
}

const createRole = (state, action) => {
    return {...state, roles: [...state.roles, action.role]};
}

const accountRoleReducer = (state = initialState, action) => {
    switch (action.type) {
        case RETRIEVE_ACCOUNT_ROLES_SUCCESS:
            return retrieveRoles(state, action);
        case UPDATE_ACCOUNT_ROLE_SUCCESS:
            return updateRole(state, action);
        case CREATE_ACCOUNT_ROLE_SUCCESS:
            return createRole(state, action);
        default:
            return state;
    }
}

export default accountRoleReducer;