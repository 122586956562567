import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, withTheme} from '@material-ui/core/styles';
import UserComponent from "../User/UserComponent";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {onError} from "../../../store/actions/popupActions";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

class UserProfile extends Component {

    constructor(props) {
        super();
        this.state = {};
    }

    componentDidMount() {
        if (this.props.username) {
            this.retrieveUser();
        }
    }

    retrieveUser = async () => {
        await trackPromise(axios.get("/auth/users/" + this.props.username).then(response => {
            this.setState({user: response.data});
        }).catch(error => {
            this.props.onError(error);
        }));
    }

    onInputChangeHandler = (user) => {
        this.setState({user: user});
    }

    save = async () => {
        await trackPromise(axios.put("/auth/users/" + this.props.username, JSON.stringify(this.state.user)).then(response => {
            this.setState({user: response.data});
        }).catch(error => {
            this.props.onError(error);
        }));
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                {this.state.user &&
                <UserComponent canEdit={true} inputChangeHandler={this.onInputChangeHandler} user={this.state.user} showUsername={false}/>
                }
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={this.save} fullWidth>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={() => this.props.history.push("/user-management/users/" + this.props.username + "/password")} fullWidth>Change Password</Button>
                    </Grid>
                    <Grid item xs={6} sm={4}/>
                </Grid>
            </main>
        )
    }

}

const mapStateToProps = state => {
    return {
        username: state.auth.username
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onError: (error) => dispatch(onError(error))
    };
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UserProfile)));