import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {trackPromise} from "react-promise-tracker";
import {onError} from "../../../store/actions/popupActions";
import axios from "../../../axios/AxiosInterceptors";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {TableCell, TableSortLabel} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import PaginatedTableActions from "../../Table/PaginatedTableActions";
import {calculateRowsPerPage} from "../../Table/PaginatedTableUtils";
import Grid from "@material-ui/core/Grid";
import {retrieveDeliveryYearAuctions, retrieveLdas} from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import {useHistory} from "react-router-dom";
import NumberFormat from "react-number-format";
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const ViewResourceClearingPrices = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const history = useHistory();
    const ldas = useSelector(state => state.capacity.ldas);
    const deliveryYearAuctions = useSelector(state => state.capacity.deliveryYearAuctions);
    const roles = useSelector(state => state.auth.roles)
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const [search, setSearch] = useState({deliveryYear: '', auction: '', lda: ''});
    const [resourceClearingPrices, setResourceClearingPrices] = useState();
    const [orderBy, setOrderBy] = useState();
    const [direction, setDirection] = useState();
    const [pageSize, setPageSize] = useState(10);
    const [hasModifyPermissions, setHasModifyPermissions] = useState(false);

    useEffect(() => {
        if (!deliveryYearAuctions) {
            dispatch(retrieveDeliveryYearAuctions());
        }
        if (!ldas) {
            dispatch(retrieveLdas());
        }
        retrieveResourceClearingPrices(search, 0, pageSize, orderBy, direction);
        setHasModifyPermissions(hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_resource_clearing_price') ||
            hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_delete_resource_clearing_price'))
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderLdaSelectBox = () => {
        if (ldas && ldas.length > 0) {
            return (
                <Autocomplete value={search.lda} options={['', ...ldas.map(r => r.ldaName)]} getOptionLabel={(option) => option} id="lda"
                              renderInput={(params) => <TextField {...params} label="LDA"/>}
                              onChange={(event, value) => onLdaSelectChange(event, value)}/>
            );
        }
    }

    const renderDeliveryYearSelectBox = () => {
        if (deliveryYearAuctions) {
            return (
                <Autocomplete value={search.deliveryYear} options={['', ...Object.keys(deliveryYearAuctions).sort((c1, c2) => c2.localeCompare(c1))]}
                              getOptionLabel={(option) => option} id="deliveryYear"
                              renderInput={(params) => <TextField {...params} label="Delivery Year"/>}
                              onChange={(event, value) => onDeliveryYearSelectChange(event, value)}/>
            );
        }
    }

    const renderAuctionSelectBox = () => {
        return (
            <Autocomplete value={search.auction} options={['', 'BRA', 'FIRST', 'SECOND', 'THIRD']} getOptionLabel={(option) => option} id="auction"
                          renderInput={(params) => <TextField {...params} label="Auction"/>}
                          onChange={(event, value) => onAuctionSelectChange(event, value)}/>
        );
    }

    const onLdaSelectChange = (event, value) => {
        if (value) {
            search.lda = value;
        } else {
            search.lda = '';
        }
        setSearch({...search});
        retrieveResourceClearingPrices(search, 0, pageSize, orderBy, direction);
    }

    const onDeliveryYearSelectChange = (event, value) => {
        if (value) {
            search.deliveryYear = value;
        } else {
            search.deliveryYear = '';
        }
        setSearch({...search});
        retrieveResourceClearingPrices(search, 0, pageSize, orderBy, direction);
    }

    const onAuctionSelectChange = (event, value) => {
        if (value) {
            search.auction = value;
        } else {
            search.auction = '';
        }
        setSearch({...search});
        retrieveResourceClearingPrices(search, 0, pageSize, orderBy, direction);
    }

    const retrieveResourceClearingPrices = async (search, pageNumber, pageSize, orderBy, direction) => {
        let url = undefined;
        if (search.deliveryYear && search.auction && search.lda) {
            url = "/capacity/deliveryYear/" + search.deliveryYear.replace("/", "-") + "/auction/" + search.auction + "/lda/" + search.lda + "/resourceClearingPrice";
        } else if (search.deliveryYear && search.auction && !search.lda) {
            url = "/capacity/deliveryYear/" + search.deliveryYear.replace("/", "-") + "/auction/" + search.auction + "/resourceClearingPrice";
        } else if (search.deliveryYear && !search.auction && search.lda) {
            url = "/capacity/deliveryYear/" + search.deliveryYear.replace("/", "-") + "/lda/" + search.lda + "/resourceClearingPrice";
        } else if (search.deliveryYear && !search.auction && !search.lda) {
            url = "/capacity/deliveryYear/" + search.deliveryYear.replace("/", "-") + "/resourceClearingPrice";
        } else if (!search.deliveryYear && search.auction && search.lda) {
            url = "/capacity/auction/" + search.auction + "/lda/" + search.lda + "/resourceClearingPrice";
        } else if (!search.deliveryYear && search.auction && !search.lda) {
            url = "/capacity/auction/" + search.auction + "/resourceClearingPrice";
        } else if (!search.deliveryYear && !search.auction && search.lda) {
            url = "/capacity/lda/" + search.lda + "/resourceClearingPrice";
        }
        if (!url) {
            url = "/capacity/resourceClearingPrice";
        }
        url = url + "?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
        if (orderBy) {
            url = url + "&orderBy=" + orderBy;
        }
        if (direction) {
            url = url + "&direction=" + direction;
        }
        await trackPromise(axios.get(url).then(response => setResourceClearingPrices(response.data))
            .catch(error => dispatch(onError(error))));
    }

    const handlePageChange = (pageNumber, pageSize) => {
        retrieveResourceClearingPrices(search, pageNumber, pageSize, orderBy, direction);
    }

    const handleRowsPerPageChange = (e) => {
        setPageSize(e.target.value);
        retrieveResourceClearingPrices(search, 0, e.target.value, orderBy, direction);
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        setOrderBy(column);
        setDirection(sortDirection);
        retrieveResourceClearingPrices(search, 0, pageSize, column, sortDirection);
    }

    const renderResourceClearingPriceTable = () => {
        if (search.deliveryYear && search.deliveryYear >= '2020/2021') {
            return renderPostCPTable();
        }
        return renderPreCPTable();
    }

    const renderPreCPTable = () => {
        let table = (
            <TableHead>
                <TableRow>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'deliveryYear'} direction={orderBy === 'deliveryYear' ? direction : 'asc'} onClick={() => handleSort("deliveryYear")}>
                            Delivery Year
                            {orderBy === 'deliveryYear' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'auction'} direction={orderBy === 'auction' ? direction : 'asc'} onClick={() => handleSort("auction")}>
                            Auction
                            {orderBy === 'auction' ? (<span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'ldaName'} direction={orderBy === 'ldaName' ? direction : 'asc'} onClick={() => handleSort("ldaName")}>
                            LDA
                            {orderBy === 'ldaName' ? (<span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'systemMarginalPrice'} direction={orderBy === 'systemMarginalPrice' ? direction : 'asc'}
                                        onClick={() => handleSort("systemMarginalPrice")}>
                            System Marginal Price ($/MW-Day)
                            {orderBy === 'systemMarginalPrice' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'locationalPriceAdder'} direction={orderBy === 'locationalPriceAdder' ? direction : 'asc'}
                                        onClick={() => handleSort("locationalPriceAdder")}>
                            Locational Price Adder ($/MW-Day)
                            {orderBy === 'locationalPriceAdder' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'capacityPerformanceResourceClearingPrice'}
                                        direction={orderBy === 'capacityPerformanceResourceClearingPrice' ? direction : 'asc'}
                                        onClick={() => handleSort("capacityPerformanceResourceClearingPrice")}>
                            CP Resource Clearing Price ($/MW-Day)
                            {orderBy === 'capacityPerformanceResourceClearingPrice' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'baseCapacityResourceClearingPrice'} direction={orderBy === 'baseCapacityResourceClearingPrice' ? direction : 'asc'}
                                        onClick={() => handleSort("baseCapacityResourceClearingPrice")}>
                            Base Resource Clearing Price ($/MW-Day)
                            {orderBy === 'baseCapacityResourceClearingPrice' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'baseCapacityDrEeClearingPrice'} direction={orderBy === 'baseCapacityDrEeClearingPrice' ? direction : 'asc'}
                                        onClick={() => handleSort("baseCapacityDrEeClearingPrice")}>
                            Base DR/EE Resource Clearing Price ($/MW-Day)
                            {orderBy === 'baseCapacityDrEeClearingPrice' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
        if (resourceClearingPrices) {
            if (Array.isArray(resourceClearingPrices.results)) {
                return <Table className={classes.table}>
                    {table}
                    <TableBody>
                        {resourceClearingPrices.results.map(resourceClearingPrice => {
                            return (
                                <TableRow key={resourceClearingPrice.id} className={hasModifyPermissions ? classes.tableRow : undefined}
                                          onClick={hasModifyPermissions ? (() => history.push("/capacity/resourceClearingPrice/" + resourceClearingPrice.id)) : undefined}>
                                    <TableCell align="center">{resourceClearingPrice.deliveryYear}</TableCell>
                                    <TableCell align="center">{resourceClearingPrice.auction}</TableCell>
                                    <TableCell align="center">{resourceClearingPrice.ldaName}</TableCell>
                                    <TableCell align="center"><NumberFormat value={resourceClearingPrice.systemMarginalPrice} displayType='text' fixedDecimalScale={true}
                                                                            decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                                    <TableCell align="center"><NumberFormat value={resourceClearingPrice.locationalPriceAdder} displayType='text' fixedDecimalScale={true}
                                                                            decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                                    <TableCell align="center"><NumberFormat value={resourceClearingPrice.capacityPerformanceResourceClearingPrice} displayType='text'
                                                                            fixedDecimalScale={true}
                                                                            decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                                    <TableCell align="center"><NumberFormat value={resourceClearingPrice.baseCapacityResourceClearingPrice} displayType='text'
                                                                            fixedDecimalScale={true}
                                                                            decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                                    <TableCell align="center"><NumberFormat value={resourceClearingPrice.baseCapacityDrEeClearingPrice} displayType='text'
                                                                            fixedDecimalScale={true}
                                                                            decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                                </TableRow>
                            );
                        })
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={8}
                                             count={resourceClearingPrices.totalCount ? resourceClearingPrices.totalCount : 0}
                                             rowsPerPage={calculateRowsPerPage(resourceClearingPrices.pageSize)}
                                             page={resourceClearingPrices.pageNumber ? resourceClearingPrices.pageNumber : 0}
                                             onChangePage={handlePageChange} onChangeRowsPerPage={handleRowsPerPageChange}
                                             ActionsComponent={PaginatedTableActions}/>
                        </TableRow>
                    </TableFooter>
                </Table>
            }
            return (
                <Table className={classes.table}>
                    {table}
                    <TableBody>
                        <TableRow key={resourceClearingPrices.id} className={hasModifyPermissions ? classes.tableRow : undefined}
                                  onClick={hasModifyPermissions ? (() => history.push("/capacity/resourceClearingPrice/" + resourceClearingPrices.id)) : undefined}>
                            <TableCell align="center">{resourceClearingPrices.deliveryYear}</TableCell>
                            <TableCell align="center">{resourceClearingPrices.auction}</TableCell>
                            <TableCell align="center">{resourceClearingPrices.ldaName}</TableCell>
                            <TableCell align="center"><NumberFormat value={resourceClearingPrices.systemMarginalPrice} displayType='text' fixedDecimalScale={true}
                                                                    decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                            <TableCell align="center"><NumberFormat value={resourceClearingPrices.locationalPriceAdder} displayType='text' fixedDecimalScale={true}
                                                                    decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                            <TableCell align="center"><NumberFormat value={resourceClearingPrices.capacityPerformanceResourceClearingPrice} displayType='text'
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                            <TableCell align="center"><NumberFormat value={resourceClearingPrices.baseCapacityResourceClearingPrice} displayType='text'
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                            <TableCell align="center"><NumberFormat value={resourceClearingPrices.baseCapacityDrEeClearingPrice} displayType='text'
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                        </TableRow>
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={8} count={1} rowsPerPage={10} page={0}
                                             onChangePage={handlePageChange} onChangeRowsPerPage={handleRowsPerPageChange}
                                             ActionsComponent={PaginatedTableActions}/>
                        </TableRow>
                    </TableFooter>
                </Table>
            )
        }
    }

    const renderPostCPTable = () => {
        let table = (
            <TableHead>
                <TableRow>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'deliveryYear'} direction={orderBy === 'deliveryYear' ? direction : 'asc'} onClick={() => handleSort("deliveryYear")}>
                            Delivery Year
                            {orderBy === 'deliveryYear' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'auction'} direction={orderBy === 'auction' ? direction : 'asc'} onClick={() => handleSort("auction")}>
                            Auction
                            {orderBy === 'auction' ? (<span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'ldaName'} direction={orderBy === 'ldaName' ? direction : 'asc'} onClick={() => handleSort("ldaName")}>
                            LDA
                            {orderBy === 'ldaName' ? (<span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'systemMarginalPrice'} direction={orderBy === 'systemMarginalPrice' ? direction : 'asc'}
                                        onClick={() => handleSort("systemMarginalPrice")}>
                            System Marginal Price ($/MW-Day)
                            {orderBy === 'systemMarginalPrice' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'locationalPriceAdder'} direction={orderBy === 'locationalPriceAdder' ? direction : 'asc'}
                                        onClick={() => handleSort("locationalPriceAdder")}>
                            Locational Price Adder ($/MW-Day)
                            {orderBy === 'locationalPriceAdder' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'capacityPerformanceResourceClearingPrice'}
                                        direction={orderBy === 'capacityPerformanceResourceClearingPrice' ? direction : 'asc'}
                                        onClick={() => handleSort("capacityPerformanceResourceClearingPrice")}>
                            CP Resource Clearing Price ($/MW-Day)
                            {orderBy === 'capacityPerformanceResourceClearingPrice' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
        if (resourceClearingPrices) {
            if (Array.isArray(resourceClearingPrices.results)) {
                return (
                    <Table className={classes.table}>
                        {table}
                        <TableBody>
                            {resourceClearingPrices.results.map(resourceClearingPrice => {
                                return (
                                    <TableRow key={resourceClearingPrice.id} className={hasModifyPermissions ? classes.tableRow : undefined}
                                              onClick={hasModifyPermissions ? (() => history.push("/capacity/resourceClearingPrice/" + resourceClearingPrice.id)) : undefined}>
                                        <TableCell align="center">{resourceClearingPrice.deliveryYear}</TableCell>
                                        <TableCell align="center">{resourceClearingPrice.auction}</TableCell>
                                        <TableCell align="center">{resourceClearingPrice.ldaName}</TableCell>
                                        <TableCell align="center"><NumberFormat value={resourceClearingPrice.systemMarginalPrice} displayType='text' fixedDecimalScale={true}
                                                                                decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                                        <TableCell align="center"><NumberFormat value={resourceClearingPrice.locationalPriceAdder} displayType='text' fixedDecimalScale={true}
                                                                                decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                                        <TableCell align="center"><NumberFormat value={resourceClearingPrice.capacityPerformanceResourceClearingPrice} displayType='text'
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                                    </TableRow>
                                );
                            })
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={6}
                                                 count={resourceClearingPrices.totalCount ? resourceClearingPrices.totalCount : 0}
                                                 rowsPerPage={calculateRowsPerPage(resourceClearingPrices.pageSize)}
                                                 page={resourceClearingPrices.pageNumber ? resourceClearingPrices.pageNumber : 0}
                                                 onChangePage={handlePageChange} onChangeRowsPerPage={handleRowsPerPageChange} ActionsComponent={PaginatedTableActions}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                );
            } else {
                return (
                    <Table className={classes.table}>
                        {table}
                        <TableBody>
                            <TableRow key={resourceClearingPrices.id} className={hasModifyPermissions ? classes.tableRow : undefined}
                                      onClick={hasModifyPermissions ? (() => history.push("/capacity/resourceClearingPrice/" + resourceClearingPrices.id)) : undefined}>
                                <TableCell align="center">{resourceClearingPrices.deliveryYear}</TableCell>
                                <TableCell align="center">{resourceClearingPrices.auction}</TableCell>
                                <TableCell align="center">{resourceClearingPrices.ldaName}</TableCell>
                                <TableCell align="center"><NumberFormat value={resourceClearingPrices.systemMarginalPrice} displayType='text' fixedDecimalScale={true}
                                                                        decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                                <TableCell align="center"><NumberFormat value={resourceClearingPrices.locationalPriceAdder} displayType='text' fixedDecimalScale={true}
                                                                        decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                                <TableCell align="center"><NumberFormat value={resourceClearingPrices.capacityPerformanceResourceClearingPrice} displayType='text'
                                                                        fixedDecimalScale={true}
                                                                        decimalScale={2} thousandSeparator disabled={true} prefix="$"/></TableCell>
                            </TableRow>
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={6} count={1} rowsPerPage={10} page={0}
                                                 onChangePage={handlePageChange} onChangeRowsPerPage={handleRowsPerPageChange} ActionsComponent={PaginatedTableActions}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                );
            }
        }
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    {renderDeliveryYearSelectBox()}
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    {renderAuctionSelectBox()}
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    {renderLdaSelectBox()}
                </Grid>
            </Grid>
            {renderResourceClearingPriceTable()}
        </main>
    );

}

export default ViewResourceClearingPrices;