import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {FormHelperText, Radio, RadioGroup} from "@material-ui/core";
import {cleanStringInput} from "../../../Utility/StringUtil";

const RenewableEnergyCredits = (props) => {

    const [registeredRecs, setRegisteredRecs] = useState([])
    const [validationRecs, setValidationRecs] = useState([]);
    const [validationIncludedRecs, setValidationIncludedRecs] = useState({});
    const recStates = ['Delaware', 'District of Columbia', 'Illinois', 'Maryland', 'New Jersey', 'Pennsylvania', 'Ohio', 'Virginia', 'West Virginia'];

    useEffect(
        () => {
            if (props.registeredRecs) {
                setRegisteredRecs(props.registeredRecs);
            }
            if (props.validation) {
                setValidationRecs(props.validation.registeredRecs);
                setValidationIncludedRecs({error: props.validation.includedRecs, message: props.validation.includedRecsMessage});
            }
            //eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.registeredRecs, props.validation])

    const onRecRegisteredStateChange = (e) => {
        let regRecs = registeredRecs;
        let index = -1;
        if (regRecs && regRecs.length === 0) {
            regRecs = [{state: e.target.value}];
        } else {
            for (let i = 0; i < regRecs.length; i++) {
                if (regRecs[i].state === e.target.name) {
                    index = i;
                }
            }
            if (index !== -1) {
                regRecs.splice(index, 1, {state: e.target.value});
            } else {
                regRecs.push({state: e.target.value});
            }
        }
        setRegisteredRecs(regRecs);
        if (props.inputChangeHandler) {
            props.inputChangeHandler(regRecs);
        }
    }

    const onRecInputChangeHandler = (e, registeredState) => {
        let regRecs = registeredRecs;
        if (regRecs.length === 0) {
            regRecs.push({state: registeredState, recClass: e.target.value});
        } else {
            for (let rec of regRecs) {
                if (rec.state === registeredState) {
                    rec.recClass = e.target.value;
                }
            }
        }
        setRegisteredRecs(regRecs);
        if (props.inputChangeHandler) {
            props.inputChangeHandler(regRecs);
        }
    }

    const addIconClick = () => {
        let regRecs = registeredRecs;
        regRecs.push({state: ""});
        setRegisteredRecs(regRecs);
        if (props.inputChangeHandler) {
            props.inputChangeHandler(regRecs);
        }
    }

    const removeIconClick = (recState) => {
        let regRecs = registeredRecs;
        let index = -1;
        for (let i = 0; i < regRecs.length; i++) {
            if (regRecs[i].state === recState) {
                index = i;
            }
        }
        if (index !== -1) {
            regRecs.splice(index, 1);
        }
        setRegisteredRecs(regRecs);
        if (props.inputChangeHandler) {
            props.inputChangeHandler(regRecs);
        }
    }

    const displayRegisteredRecs = () => {
        let displayedRecs = [];
        let regRecs = registeredRecs;
        if (props.canEdit) {
            if (!regRecs || regRecs.length === 0) {
                regRecs = [{state: ''}];
            }
        }
        for (let i = 0; i < regRecs.length; i++) {
            displayedRecs.push(displayRecs(regRecs[i], regRecs.length - 1, i));
        }
        if (displayedRecs.length > 0) {
            return displayedRecs;
        }
        return null;
    }

    const displayRecs = (registeredRec, totalCount, index) => {
        if (props.canEdit) {
            return (
                <Grid container spacing={2} key={registeredRec.state}>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={2}>
                        <FormControl fullWidth>
                            <InputLabel>Registered State</InputLabel>
                            <Select value={registeredRec.state} key={registeredRec.state}
                                    onChange={(e) => onRecRegisteredStateChange(e)} name={registeredRec.state} fullWidth>
                                {recStates.map(recState => <MenuItem value={recState} key={recState}>{recState}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormHelperText error={validationIncludedRecs.error}>{validationIncludedRecs.message}</FormHelperText>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={5}>
                        {renderRecCheckBoxes(registeredRec)}
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        {renderAddIcon(registeredRec, totalCount, index)}
                        <IconButton onClick={() => removeIconClick(registeredRec.state)}>
                            <RemoveIcon/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={6} sm={12}/>
                </Grid>
            );
        } else {
            return (
                <Grid container spacing={2} key={registeredRec.state}>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={2}>
                        <TextField key={registeredRec.state} value={registeredRec.state} name={registeredRec.state} fullWidth disabled={!props.canEdit}/>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={5}>
                        {renderRecCheckBoxes(registeredRec)}
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                </Grid>
            );
        }
    }

    const renderAddIcon = (registeredRec, totalCount, index) => {
        if (registeredRec.state && props.canEdit && index === totalCount) {
            return (
                <IconButton onClick={addIconClick}>
                    <AddIcon/>
                </IconButton>
            );
        }
    }


    const renderRecCheckBoxes = (registeredRec) => {
        let recState = registeredRec.state;
        switch (recState) {
            case 'Delaware':
                return (
                    <React.Fragment>
                        <FormControl component="fieldset">
                            <RadioGroup row name="recClass" value={cleanStringInput(isRecChecked(registeredRec))}
                                        onChange={(e) => onRecInputChangeHandler(e, recState)}>
                                <FormControlLabel control={<Radio/>} label="Solar" value="solar" disabled={!props.canEdit}/>
                                <FormControlLabel control={<Radio/>} label="Eligible" value="eligible" disabled={!props.canEdit}/>
                                <FormControlLabel control={<Radio/>} label="New Eligible" value="newEligible" disabled={!props.canEdit}/>
                            </RadioGroup>
                            {validationRecs && validationRecs.filter(r => r === recState).length >= 1 &&
                                <FormHelperText error={true}>REC type must be selected</FormHelperText>
                            }
                        </FormControl>
                    </React.Fragment>
                );
            case 'District of Columbia':
            case 'Maryland':
            case 'Pennsylvania':
                return (
                    <React.Fragment>
                        <FormControl component="fieldset">
                            <RadioGroup row name="recClass" value={cleanStringInput(isRecChecked(registeredRec))}
                                        onChange={(e) => onRecInputChangeHandler(e, recState)}>
                                <FormControlLabel control={<Radio/>} value="solar" disabled={!props.canEdit} label="Solar"/>
                                <FormControlLabel control={<Radio/>} value="tierI" disabled={!props.canEdit} label="Tier I"/>
                                <FormControlLabel control={<Radio/>} value="tierII" disabled={!props.canEdit} label="Tier II"/>
                            </RadioGroup>
                            {validationRecs && validationRecs.filter(r => r === recState).length >= 1 &&
                                <FormHelperText error={true}>REC type must be selected</FormHelperText>
                            }
                        </FormControl>
                    </React.Fragment>
                );
            case 'Illinois':
                return (
                    <React.Fragment>
                        <FormControl component="fieldset">
                            <RadioGroup row name="recClass" value={cleanStringInput(isRecChecked(registeredRec))}
                                        onChange={(e) => onRecInputChangeHandler(e, recState)}>
                                <FormControlLabel control={<Radio/>} value="solar" disabled={!props.canEdit} label="Solar"/>
                                <FormControlLabel control={<Radio/>} value="renewable" disabled={!props.canEdit} label="Renewable"/>
                                <FormControlLabel control={<Radio/>} value="utilityOnly" disabled={!props.canEdit} label="Utility Only"/>
                            </RadioGroup>
                            {validationRecs && validationRecs.filter(r => r === recState).length >= 1 &&
                                <FormHelperText error={true}>REC type must be selected</FormHelperText>
                            }
                        </FormControl>
                    </React.Fragment>
                );
            case 'New Jersey':
                return (
                    <React.Fragment>
                        <FormControl component="fieldset">
                            <RadioGroup row name="recClass" value={cleanStringInput(isRecChecked(registeredRec))}
                                        onChange={(e) => onRecInputChangeHandler(e, recState)}>
                                <FormControlLabel control={<Radio/>} value="solar" disabled={!props.canEdit} label="Solar"/>
                                <FormControlLabel control={<Radio/>} value="classI" disabled={!props.canEdit} label="Class I"/>
                                <FormControlLabel control={<Radio/>} value="classII" disabled={!props.canEdit} label="Class II"/>
                                <FormControlLabel control={<Radio/>} value="transition" disabled={!props.canEdit} label="Transition"/>
                            </RadioGroup>
                            {validationRecs && validationRecs.filter(r => r === recState).length >= 1 &&
                                <FormHelperText error={true}>REC type must be selected</FormHelperText>
                            }
                        </FormControl>
                    </React.Fragment>
                );
            case 'Ohio':
                return (
                    <React.Fragment>
                        <FormControl component="fieldset">
                            <RadioGroup row name="recClass" value={cleanStringInput(isRecChecked(registeredRec))}
                                        onChange={(e) => onRecInputChangeHandler(e, recState)}>
                                <FormControlLabel control={<Radio/>} nvalue="solar" disabled={!props.canEdit} label="Solar"/>
                                <FormControlLabel control={<Radio/>} value="renewable" disabled={!props.canEdit} label="Renewable"/>
                            </RadioGroup>
                            {validationRecs && validationRecs.filter(r => r === recState).length >= 1 &&
                                <FormHelperText error={true}>REC type must be selected</FormHelperText>
                            }
                        </FormControl>
                    </React.Fragment>
                );
            case 'West Virginia':
                return (
                    <React.Fragment>
                        <FormControl component="fieldset">
                            <RadioGroup row name="recClass" value={cleanStringInput(isRecChecked(registeredRec))}
                                        onChange={(e) => onRecInputChangeHandler(e, recState)}>
                                <FormControlLabel control={<Radio/>} value="renewableEnergyResource" disabled={!props.canEdit} label="Renewable Energy Resource"/>
                                <FormControlLabel control={<Radio/>} value="alternativeEnergyResource" disabled={!props.canEdit} label="Alternative Energy Resource"/>
                            </RadioGroup>
                            {validationRecs && validationRecs.filter(r => r === recState).length >= 1 &&
                                <FormHelperText error={true}>REC type must be selected</FormHelperText>
                            }
                        </FormControl>
                    </React.Fragment>
                );
            case 'Virginia':
                return (
                    <React.Fragment>
                        <FormControl component="fieldset">
                            <RadioGroup row name="recClass" value={cleanStringInput(isRecChecked(registeredRec))}
                                        onChange={(e) => onRecInputChangeHandler(e, recState)}>
                                <FormControlLabel control={<Radio/>} value="renewable" disabled={!props.canEdit} label="Renewable"/>
                            </RadioGroup>
                            {validationRecs && validationRecs.filter(r => r === recState).length >= 1 &&
                                <FormHelperText error={true}>REC type must be selected</FormHelperText>}
                        </FormControl>
                    </React.Fragment>
                );
            default:
                return;
        }
    }

    const isRecChecked = (registeredRec) => {
        if (registeredRec.recClass) {
            return registeredRec.recClass;
        }
        return undefined;
    }

    return (
        displayRegisteredRecs()
    );

}

export default RenewableEnergyCredits;