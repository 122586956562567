import React, {useState, useEffect} from 'react';
import {trackPromise} from "react-promise-tracker";
import {makeStyles} from '@material-ui/core';
import {useHistory} from 'react-router-dom';


import axios from "../../../axios/AxiosInterceptors";
import {onError} from "../../../store/actions/popupActions";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import EditableServicePermissions from "../ServicePermissions/EditableServicePermissions";
import Button from "@material-ui/core/Button";

const initialState = {
    role: {
        roleName: '',
        servicePermissions: []
    }
};

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const CreateRole = (props) => {

    const [availableServicePermissions, setAvailableServicePermissions] = useState([]);
    const [role, setRole] = useState(initialState);
    const {onSaveFunction} = props;
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        async function retrievePermissions() {
            await trackPromise((axios.get("/auth/service/permissions")).then(response => {
                setAvailableServicePermissions(response.data);
            }).catch((dispatch, error) => {
                dispatch(onError(error.response));
            }));
        }
        retrievePermissions();
    },[]);

    const onInputChangeHandler = (e) => {
        let updatedRole = role;
        updatedRole.role.roleName = e.target.value;
        setRole(updatedRole);
    };

    const updateServicePermissions = (selectedPermissions) => {
        let updatedRole = role;
        let servicePermissions = [];
        selectedPermissions.forEach((selectedPermission) => {
            servicePermissions.push({id: selectedPermission});
        });
        updatedRole.role.servicePermissions = servicePermissions;
        setRole(updatedRole);
    }

    const onBack = () => {
        history.goBack();
    }

    const onSave = () => {
        onSaveFunction(role.role);
    }


    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={6} sm={3}>
                        <TextField label="Role Name" name="roleName" value={role.roleName} onChange={onInputChangeHandler} fullWidth/>
                    </Grid>
                    <Grid item xs={6} sm={4}/>
                </Grid>
            </Paper>
            <Paper className={classes.paper}>
                <EditableServicePermissions servicePermissions={availableServicePermissions} updateSelectedPermissions={updateServicePermissions}/>
            </Paper>
            <Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={onSave}>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={onBack}>Back</Button>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );

}

export default CreateRole;