import {trackPromise} from 'react-promise-tracker';

import {EIGHTY_SEVEN_SIXTY_RETRIEVED, RETRIEVE_GENERATOR, RETRIEVE_GENERATORS} from '../sparkExchangeActionTypes';
import {SUCCESS} from '../../actionTypes';
import axios from '../../../axios/AxiosInterceptors';
import {onError} from "../../actions/popupActions";
import _ from "lodash";

export const retrieveGeneratorsSuccess = (generators) => {
    return {
        type: RETRIEVE_GENERATORS,
        generators: generators
    };
};

export const saveGeneratorSuccess = (message) => {
    return {
        type: SUCCESS,
        message: message
    };
};

export const retrieveGeneratorSuccess = (generator) => {
    return {
        type: RETRIEVE_GENERATOR,
        generator: generator
    }
};

export const updateGeneratorSuccess = (message) => {
    return {
        type: SUCCESS,
        message: message
    }
}

export const eightySevenSixtyRetrievalSuccess = (eightSevenSixtyProfile) => {
    return {
        type: EIGHTY_SEVEN_SIXTY_RETRIEVED,
        eightSevenSixtyProfile: eightSevenSixtyProfile
    }
}

export function saveGenerator(generator) {
    let generatorProfile = null;
    let isaWmpa = null;
    let milestoneDocumentations = [];
    if (generator.profile) {
        generatorProfile = generator.profile;
        generator.profile = null;
    }
    if (generator.isaWmpa) {
        isaWmpa = generator.isaWmpa;
        generator.isaWmpa = null;
    }
    if (generator.requiredMilestones && generator.requiredMilestones.length > 0) {
        milestoneDocumentations = _.cloneDeep(generator.requiredMilestones);
        generator.requiredMilestones.forEach(r => {
            r.documentation = null;
        })
    }
    return async dispatch => {
        let generatorResponse = undefined;
        await trackPromise(axios.post("/spark-exchange/account/" + generator.accountId + "/generators", JSON.stringify(generator)).then(response => {
            generatorResponse = response.data;
            dispatch(saveGeneratorSuccess(generator.generatorName + " Saved"));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
        await Promise.all([
            uploadGeneratorProfile(generator.accountId, generatorResponse.id, generatorProfile, dispatch),
            uploadIsaWmpa(generator.accountId, generatorResponse.id, isaWmpa, dispatch),
            uploadMilestoneDocumentation(generator.accountId, generatorResponse.id, generatorResponse.requiredMilestones, milestoneDocumentations, dispatch)
        ]);
        return dispatch(retrieveGenerators(generator.accountId, 0, 10));
    };
}

export function updateGenerator(accountId, generatorId, generator) {
    let generatorProfile = null;
    let isaWmpa = null;
    let milestoneDocumentations = [];
    if (generator.profile) {
        generatorProfile = generator.profile;
        generator.profile = null;
    }
    if (generator.isaWmpa) {
        isaWmpa = generator.isaWmpa;
        generator.isaWmpa = null;
    }
    if (generator.requiredMilestones && generator.requiredMilestones.length > 0) {
        milestoneDocumentations = _.cloneDeep(generator.requiredMilestones);
        generator.requiredMilestones.forEach(r => {
            r.documentation = null;
        })
    }
    return async dispatch => {
        let generatorResponse = undefined;
        await trackPromise(axios.put("/spark-exchange/account/" + accountId + "/generators/" + generatorId, JSON.stringify(generator)).then(response => {
            generatorResponse = response.data;
            dispatch(updateGeneratorSuccess(generator.generatorName + " Saved"));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
        await Promise.all([
            uploadGeneratorProfile(accountId, generatorId, generatorProfile, dispatch),
            uploadIsaWmpa(accountId, generatorId, isaWmpa, dispatch),
            uploadMilestoneDocumentation(generator.accountId, generatorId, generatorResponse.requiredMilestones, milestoneDocumentations, dispatch)
        ]);
        return dispatch(retrieveGenerators(generator.accountId, 0, 10));
    };
}

async function uploadGeneratorProfile(accountId, generatorId, generatorProfile, dispatch) {
    if (generatorProfile) {
        await trackPromise(axios.put("/spark-exchange/account/" + accountId + "/generators/" + generatorId + "/profile", generatorProfile).catch(error => {
            dispatch(onError(error.response));
        }));
    }
}

async function uploadIsaWmpa(accountId, generatorId, isaWmpa, dispatch) {
    if (isaWmpa) {
        await trackPromise(axios.put("/spark-exchange/account/" + accountId + "/generators/" + generatorId + "/isaWmpa", isaWmpa).catch(error => {
            dispatch(onError(error.response));
        }));
    }
}

async function uploadMilestoneDocumentation(accountId, generatorId, requiredMilestones, milestoneDocumentations, dispatch) {
    if (milestoneDocumentations && milestoneDocumentations.length > 0) {
        for (let milestoneDocumentation of milestoneDocumentations) {
            if (milestoneDocumentation.documentation) {
                let filteredMilestones = requiredMilestones.filter(r => r.milestoneName === milestoneDocumentation.milestoneName);
                if (filteredMilestones && filteredMilestones.length === 1) {
                    await trackPromise(axios.put("/spark-exchange/account/" + accountId + "/generators/" + generatorId + "/milestones/" + filteredMilestones[0].id + "/documentation",
                        milestoneDocumentation.documentation).catch(error => {
                        dispatch(onError(error.response));
                    }))
                }
            }
        }
    }
}

export function retrieveGenerators(accountId, pageNumber, pageSize, orderBy, direction) {
    let url = "/spark-exchange/account/" + accountId + "/generators?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
    if (orderBy) {
        url = url + "&orderBy=" + orderBy;
    }
    if (direction) {
        url = url + "&direction=" + direction;
    }
    return async (dispatch) => {
        await trackPromise(axios.get(url).then(response => {
            return dispatch(retrieveGeneratorsSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    };
}

export function retrieveGenerator(accountId, generatorId) {
    return async dispatch => {
        await trackPromise(axios.get("/spark-exchange/account/" + accountId + "/generators/" + generatorId).then(response => {
            return dispatch(retrieveGeneratorSuccess(response.data));
        }).catch(error => {
            dispatch(onError(error.response));
        }));
    };
}

export function retrieveGeneratorProfile(generatorId, accountId, pageNumber, pageSize) {
    return async (dispatch) => {
        await trackPromise(axios.get("/spark-exchange/account/" + accountId + "/generators/" + generatorId + "/profile?pageNumber=" + pageNumber + "&pageSize=" + pageSize)
            .then(response => {
                return dispatch(eightySevenSixtyRetrievalSuccess(response.data));
            }).catch(error => {
                dispatch(onError(error.response));
            }));
    }
}

export function retrieveGeneratorProfileNoError(generatorId, accountId, pageNumber, pageSize) {
    return async (dispatch) => {
        await trackPromise(axios.get("/spark-exchange/account/" + accountId + "/generators/" + generatorId + "/profile?pageNumber=" + pageNumber + "&pageSize=" + pageSize)
            .then(response => {
                return dispatch(eightySevenSixtyRetrievalSuccess(response.data));
            }).catch(error => {
                //don't care about the error.
            }));
    }
}
