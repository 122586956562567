import React from 'react';

import {makeStyles} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const ViewRoles = (props) => {

    const [roleToggles, setRoleToggles] = React.useState([]);

    const classes = useStyles();
    const roles = props.roles;
    const roleClickFunction = props.roleClickFunction;

    const isToggled = (roleId) => {
        if (roleToggles.length === 0) {
            return false;
        }
        for (let roleToggle of roleToggles) {
            if (roleToggle.roleId === roleId) {
                return roleToggle.open;
            }
        }
        return false;
    };

    const toggleVisibility = (roleId) => {
        let toggles = _.clone(roleToggles);
        if (toggles.length === 0) {
            toggles.push({roleId: roleId, open: true});
        } else {
            let index = -1;
            for (let i = 0; i < toggles.length; i++) {
                if (toggles[i].roleId === roleId) {
                    toggles[i].open = !toggles[i].open;
                    index = i;
                }
            }
            if (index === -1) {
                toggles.push({roleId: roleId, open: true});
            }
        }
        setRoleToggles(toggles);
    }

    const renderList = (role) => {
        if (roleClickFunction) {
            return (<React.Fragment key={role.id}>
                <ListItem key={role.id} onClick={() => roleClickFunction(role.id)}  button component="a">
                    <ListItemText>{role.roleName}</ListItemText>
                    <ListItemSecondaryAction onClick={() => toggleVisibility(role.id)}>
                        {isToggled(role.id) ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={isToggled(role.id)} timeout="auto" unmountOnExit>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Permission Name</TableCell>
                                <TableCell>Permission Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {role.servicePermissions.map((servicePermission) => (
                                <TableRow key={servicePermission.id}>
                                    <TableCell>{servicePermission.permissionPrettyName}</TableCell>
                                    <TableCell>{servicePermission.permissionDescription}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Collapse>
            </React.Fragment>);
        }
        return (<React.Fragment key={role.id}>
            <ListItem key={role.id}>
                <ListItemText>{role.roleName}</ListItemText>
                <ListItemSecondaryAction onClick={() => toggleVisibility(role.id)}>
                    {isToggled(role.id) ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={isToggled(role.id)} timeout="auto" unmountOnExit>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Permission Name</TableCell>
                            <TableCell>Permission Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {role.servicePermissions.map((servicePermission) => (
                            <TableRow key={servicePermission.id}>
                                <TableCell>{servicePermission.permissionPrettyName}</TableCell>
                                <TableCell>{servicePermission.permissionDescription}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Collapse>
        </React.Fragment>);
    }

    return (
        <Paper className={classes.layout}>
            {roles &&
            <List dense={true} component="nav">
                {
                    roles.map((role) => renderList(role))
                }
            </List>
            }
        </Paper>
    );

}

export default ViewRoles;