import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, withTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";
import {cleanStringInput} from "../../../Utility/StringUtil";
import {
    deleteCapacityTransferRight,
    retrieveDeliveryYearAuctions,
    retrieveLdas,
    updateCapacityTransferRight
} from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import Button from "@material-ui/core/Button";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {onError} from "../../../store/actions/popupActions";
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
});

class ViewCapacityTransferRight extends Component {

    constructor(props) {
        super(props);
        this.state = {
            capacityTransferRight: {
                deliveryYear: '',
                auction: '',
                lda: '',
                remainingCtrs: 0
            },
            isEdit: false
        }
    }

    componentDidMount = async () => {
        this.props.retrieveLdas();
        this.props.retrieveDeliveryYearAuction();
        await trackPromise(axios.get("/capacity/transferRight/" + this.props.match.params.capacityTransferRightId).then(response => {
            this.setState({capacityTransferRight: response.data});
        }).catch(error => {
            this.props.onError(error);
        }));
        if (this.props.roles) {
            this.setState({isEdit: hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, 'ROLE_update_capacity_transfer_right')});
        }
    }

    renderLdaSelectBox = () => {
        if (this.props.ldas && this.props.ldas.length > 0 && this.state.capacityTransferRight.lda !== '') {
            return (
                <Autocomplete value={this.findLdaForLdaName(this.state.capacityTransferRight.lda)} options={["", ...this.props.ldas]} getOptionLabel={(option) => option.ldaName}
                              renderInput={(params) => <TextField {...params} label="LDA"/>}
                              onChange={(event, value) => this.onLdaSelectChange(event, value)} disabled={!this.state.isEdit}/>
            );
        }
    }

    findLdaForLdaName = (ldaName) => {
        for (let lda of this.props.ldas) {
            if (lda.ldaName === ldaName) {
                return lda;
            }
        }
    }

    renderDeliveryYearSelectBox = () => {
        if (this.props.deliveryYearAuctions && this.state.capacityTransferRight.deliveryYear !== '') {
            return (
                <Autocomplete value={this.state.capacityTransferRight.deliveryYear ? this.state.capacityTransferRight.deliveryYear : ''}
                              options={['', ...Object.keys(this.props.deliveryYearAuctions)]} getOptionLabel={(option) => option}
                              renderInput={(params) => <TextField {...params} label="Delivery Year"/>}
                              onChange={(event, value) => this.onDeliveryYearSelectChange(event, value)} disabled={!this.state.isEdit}/>
            );
        }
    }

    renderAuctionSelectBox = () => {
        if (this.props.deliveryYearAuctions && this.state.capacityTransferRight.auction !== '') {
            return (
                <Autocomplete value={this.state.capacityTransferRight.auction ? this.state.capacityTransferRight.auction : ''}
                              options={['', ...this.props.deliveryYearAuctions[this.state.capacityTransferRight.deliveryYear].map(r => r.auction)]}
                              getOptionLabel={(option) => option} getOptionSelected={(value, option) => value === option}
                              renderInput={(params) => <TextField {...params} label="Auction"/>}
                              onChange={(event, value) => this.onAuctionSelectChange(event, value)} disabled={!this.state.isEdit}/>
            );
        }
        return (
            <Autocomplete value={this.state.capacityTransferRight.auction} options={['']} getOptionLabel={(option) => ''}
                          renderInput={(params) => <TextField {...params} label="Auction"/>} disabled={!this.state.isEdit}/>
        );
    }

    onDeliveryYearSelectChange = (event, value) => {
        let capacityTransferRight = {...this.state.capacityTransferRight};
        if (value) {
            capacityTransferRight.deliveryYear = value;
        } else {
            capacityTransferRight.deliveryYear = '';
            capacityTransferRight.auction = '';
        }
        this.setState({capacityTransferRight: capacityTransferRight});
    }

    onAuctionSelectChange = (event, value) => {
        let capacityTransferRight = {...this.state.capacityTransferRight};
        if (value) {
            capacityTransferRight.auction = value;
        } else {
            capacityTransferRight.auction = '';
        }
        this.setState({capacityTransferRight: capacityTransferRight});
    }

    onSave = () => {
        this.props.updateCapacityTransferRight(this.props.match.params.capacityTransferRightId, this.state.capacityTransferRight);
        this.props.history.push("/capacity/capacityTransferRights/view?deliveryYear=" + this.state.capacityTransferRight.deliveryYear + "&auction=" +
            this.state.capacityTransferRight.auction + "&lda=" + this.state.capacityTransferRight.lda);
    }

    onDelete = () => {
        this.props.deleteCapacityTransferRight(this.props.match.params.capacityTransferRightId);
        this.props.history.push("/capacity/capacityTransferRights/view?deliveryYear=" + this.state.capacityTransferRight.deliveryYear + "&auction=" +
            this.state.capacityTransferRight.auction);
    }

    onCtrInputChange = (event) => {
        let capacityTransferRight = {...this.state.capacityTransferRight};
        if (event.target.value) {
            capacityTransferRight[event.target.name] = Number.parseFloat(event.target.value)
        } else {
            capacityTransferRight[event.target.name] = event.target.value;
        }
        this.setState({capacityTransferRight: capacityTransferRight});
    }

    onLdaSelectChange = (event, value) => {
        let capacityTransferRight = {...this.state.capacityTransferRight};
        capacityTransferRight.lda = value.ldaName;
        this.setState({capacityTransferRight: capacityTransferRight})
    }

    renderButtons = () => {
        if (this.state.isEdit && hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, 'ROLE_delete_capacity_transfer_right')) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3}>
                        <Button onClick={this.onSave} fullWidth>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={3}>
                        <Button onClick={this.onDelete} fullWidth>Delete</Button>
                    </Grid>
                </Grid>
            )
        }
        if (!this.state.isEdit && hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, 'ROLE_delete_capacity_transfer_right')) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={6} sm={2}>
                        <Button onClick={this.onDelete} fullWidth>Delete</Button>
                    </Grid>
                </Grid>
            )
        }
        if (this.state.isEdit && !hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, 'ROLE_delete_capacity_transfer_right')) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={6} sm={2}>
                        <Button onClick={this.onSave} fullWidth>Save</Button>
                    </Grid>
                </Grid>
            )
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3}>
                        {this.renderDeliveryYearSelectBox()}
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={3}>
                        {this.renderAuctionSelectBox()}
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3}>
                        {this.renderLdaSelectBox()}
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={3}>
                        <TextField label="Remaining CTRs" name="remainingCtrs" type="number" value={cleanStringInput(this.state.capacityTransferRight.remainingCtrs)}
                                   onChange={(event) => this.onCtrInputChange(event)} fullWidth
                                   InputProps={{endAdornment: <InputAdornment position="start">MWs</InputAdornment>, inputProps: {step: 0.1}}} disabled={!this.state.isEdit}/>
                    </Grid>
                </Grid>
                {this.renderButtons()}
            </main>
        );
    }

}

const mapStateToProps = state => {
    return {
        ldas: state.capacity.ldas,
        deliveryYearAuctions: state.capacity.deliveryYearAuctions,
        chosenAccount: state.chosenAccount.account,
        roles: state.auth.roles,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateCapacityTransferRight: (id, capacityTransferRight) => dispatch(updateCapacityTransferRight(id, capacityTransferRight)),
        deleteCapacityTransferRight: (id) => dispatch(deleteCapacityTransferRight(id)),
        retrieveLdas: () => dispatch(retrieveLdas()),
        retrieveDeliveryYearAuction: () => dispatch(retrieveDeliveryYearAuctions()),
        onError: (error) => dispatch(onError(error))
    };
}

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ViewCapacityTransferRight)));