import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {retrieveDeliveryYearAuctions} from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import {cleanStringInput} from "../../../Utility/StringUtil";
import InputAdornment from "@material-ui/core/InputAdornment";
import RemoveIcon from "@material-ui/icons/Remove";
import {trackPromise} from "react-promise-tracker";
import {onError, onSuccess} from "../../../store/actions/popupActions";
import Button from "@material-ui/core/Button";
import axios from "../../../axios/AxiosInterceptors";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import TwoDigitCurrency from "../../NumberFormat/TwoDigitCurrency";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const CreateZonalAuctionResult = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const history = useHistory();
    const deliveryYearAuctions = useSelector(state => state.capacity.deliveryYearAuctions);
    const [zonalAuctionResults, setZonalAuctionResults] = useState({deliveryYear: '', auction: '', zonalResults: []})

    useEffect(() => {
        if (!deliveryYearAuctions) {
            dispatch(retrieveDeliveryYearAuctions());
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderDeliveryYearSelectBox = () => {
        if (deliveryYearAuctions) {
            return (
                <Autocomplete value={zonalAuctionResults.deliveryYear} options={['', ...Object.keys(deliveryYearAuctions).sort((c1, c2) => c2.localeCompare(c1))]}
                              getOptionLabel={(option) => option} renderInput={(params) => <TextField {...params} label="Delivery Year"/>}
                              onChange={(event, value) => onDeliveryYearSelectChange(event, value)}/>
            );
        }
    }

    const renderAuctionSelectBox = () => {
        if (zonalAuctionResults.deliveryYear) {
            return (
                <Autocomplete value={zonalAuctionResults.auction}
                              options={['', ...deliveryYearAuctions[zonalAuctionResults.deliveryYear].map(r => r.auction)]}
                              getOptionLabel={(option) => option} renderInput={(params) => <TextField {...params} label="Auction"/>}
                              onChange={(event, value) => onAuctionSelectChange(event, value)}/>
            );
        }
        return (
            <Autocomplete value={zonalAuctionResults.auction} options={['']} getOptionLabel={() => ''}
                          renderInput={(params) => <TextField {...params} label="Auction"/>}/>
        );
    }

    const onDeliveryYearSelectChange = (event, value) => {
        if (value) {
            zonalAuctionResults.deliveryYear = value;
        } else {
            zonalAuctionResults.deliveryYear = '';
            zonalAuctionResults.auction = '';
        }
        setZonalAuctionResults({...zonalAuctionResults});
    }

    const onAuctionSelectChange = (event, value) => {
        if (value) {
            zonalAuctionResults.auction = value;
        } else {
            zonalAuctionResults.auction = '';
        }
        setZonalAuctionResults({...zonalAuctionResults});
    }

    const addIconClick = () => {
        zonalAuctionResults.zonalResults.push({zone: ""});
        setZonalAuctionResults({...zonalAuctionResults});
    }

    const removeIconClick = (index) => {
        zonalAuctionResults.zonalResults.splice(index, 1);
        setZonalAuctionResults({...zonalAuctionResults});
    }

    const renderAddIcon = (totalCount, index) => {
        if (index === totalCount) {
            return (
                <IconButton onClick={addIconClick}>
                    <AddIcon/>
                </IconButton>
            )
        }
    }

    const onInputChange = (e, index) => {
        if (e.target.value) {
            if (e.target.type === "number") {
                zonalAuctionResults.zonalResults[index][e.target.name] = Number.parseFloat(e.target.value);
            } else {
                zonalAuctionResults.zonalResults[index][e.target.name] = e.target.value;
            }
        } else {
            zonalAuctionResults.zonalResults[index][e.target.name] = '';
        }
        setZonalAuctionResults({...zonalAuctionResults});
    }

    const renderZonalAuctionResults = () => {
        if (zonalAuctionResults.zonalResults.length === 0) {
            zonalAuctionResults.zonalResults.push({"zone": ''});
        }
        let renderedZonalResults = [];
        for (let i = 0; i < zonalAuctionResults.zonalResults.length; i++) {
            renderedZonalResults.push(renderZonalResult(zonalAuctionResults.zonalResults[i], zonalAuctionResults.zonalResults.length - 1, i));
        }
        return renderedZonalResults;
    }

    const renderZonalResult = (zonalResult, totalCount, index) => {
        return (
            <Grid container spacing={2} key={index}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField name="zone" label="Zone Name" onChange={(e) => onInputChange(e, index)}
                               value={cleanStringInput(zonalResult.zone)} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField name="coincidentPeakYear" label="Coincident Peak Year" onChange={(e) => onInputChange(e, index)}
                               value={cleanStringInput(zonalResult.coincidentPeakYear)} fullWidth
                               InputProps={{
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: true, thousandSeparator: false}
                               }}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField name="coincidentPeakLoad" label="Coincident Peak Load" onChange={(e) => onInputChange(e, index)}
                               value={cleanStringInput(zonalResult.coincidentPeakLoad)}
                               type="number" fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">MWs</InputAdornment>, inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 1, allowNegative: false, isEdit: true}
                    }}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField name="peakLoadScalingFactor" label="Peak Load Scaling Factor" onChange={(e) => onInputChange(e, index)}
                               value={cleanStringInput(zonalResult.peakLoadScalingFactor)} type="number" fullWidth InputProps={{
                        inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 10, allowNegative: false, isEdit: true}
                    }}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField name="zonalPeakLoadForecast" label="Zonal Peak Load Forecast" onChange={(e) => onInputChange(e, index)}
                               value={cleanStringInput(zonalResult.zonalPeakLoadForecast)}
                               type="number" fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">MWs</InputAdornment>, inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 1, allowNegative: false, isEdit: true}
                    }}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField name="ucapObligation" label="UCAP Obligation" onChange={(e) => onInputChange(e, index)}
                               value={cleanStringInput(zonalResult.ucapObligation)}
                               type="number" fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">MWs</InputAdornment>, inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 1, allowNegative: false, isEdit: true}
                    }}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField name="zonalCapacityPrice" label="Zonal Capacity Price" onChange={(e) => onInputChange(e, index)}
                               value={cleanStringInput(zonalResult.zonalCapacityPrice)}
                               type="number" fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>, inputComponent: TwoDigitCurrency,
                        inputProps: {isEdit: true}
                    }}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField name="netLoadPrice" label="Net Load Price" onChange={(e) => onInputChange(e, index)}
                               value={cleanStringInput(zonalResult.netLoadPrice)}
                               type="number" fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>, inputComponent: TwoDigitCurrency,
                        inputProps: {isEdit: true}
                    }}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField name="ctrCreditRate" label="CTR Credit Rate" onChange={(e) => onInputChange(e, index)}
                               value={cleanStringInput(zonalResult.ctrCreditRate)}
                               type="number" fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>, inputComponent: TwoDigitCurrency,
                        inputProps: {isEdit: true}
                    }}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField name="ctrSettlementRate" label="CTR Settlement Rate" onChange={(e) => onInputChange(e, index)}
                               value={cleanStringInput(zonalResult.ctrSettlementRate)} type="number" fullWidth
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">$/MW-Day</InputAdornment>,
                                   inputComponent: TwoDigitCurrency,
                                   inputProps: {isEdit: true}
                               }}/>
                </Grid>
                <Grid item xs={6} sm={1}>
                    {renderAddIcon(totalCount, index)}
                    <IconButton onClick={() => removeIconClick(index)}>
                        <RemoveIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        );
    }

    const onSave = async () => {
        await trackPromise(axios.post("/capacity/deliveryYear/" + zonalAuctionResults.deliveryYear.replace("/", "-") + "/auction/" + zonalAuctionResults.auction + "/zonalResult", JSON.stringify(zonalAuctionResults.zonalResults)).then(() => {
            dispatch(onSuccess("Saved Zonal Results for delivery year " + zonalAuctionResults.deliveryYear + " and auction " + zonalAuctionResults.auction));
            history.push("/capacity/zonalResult/view");
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={3}>
                    {deliveryYearAuctions && renderDeliveryYearSelectBox()}
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    {renderAuctionSelectBox()}
                </Grid>
            </Grid>
            {renderZonalAuctionResults()}
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={4}>
                    <Button onClick={onSave} fullWidth>Save</Button>
                </Grid>
            </Grid>
        </main>
    )

}

export default CreateZonalAuctionResult;