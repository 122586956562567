import React from 'react';

import GeneratorComponent from "./GeneratorComponent";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
}));

const CreateGenerator = () => {

    const classes = useStyles();

    return (
        <main className={classes.layout}>
            <Typography variant="h3" align="center">Create Generator</Typography>
            <GeneratorComponent isEdit={true} showButtons={true}/>
        </main>
    );
}

export default CreateGenerator;