import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {onError, onSuccess} from "../../../store/actions/popupActions";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import RpmParameterComponent from "./RpmParameterComponent";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const CreateRpmParameter = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const history = useHistory();
    const [rpmParameter, setRpmParameter] = useState({});


    const onSave = async () => {
        await trackPromise(axios.post("/capacity/parameters/deliveryYear/" + rpmParameter.deliveryYear.replace("/", "-") +
            "/auction/" + rpmParameter.auction, JSON.stringify(rpmParameter)).then(response => {
            dispatch(onSuccess("Saved RPM Parameters for delivery year " + rpmParameter.deliveryYear + " and auction " + rpmParameter.auction));
        }).catch(error => {
            dispatch(onError(error));
        }));
        history.push("/capacity/rpmParameter/view");
    }

    const onChangeHandler = (rpmParameter) => {
        setRpmParameter({...rpmParameter});
    }

    return (
        <main className={classes.layout}>
            <RpmParameterComponent onChangeHandler={onChangeHandler} isEdit={true}/>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={4}>
                    <Button onClick={onSave} fullWidth>Save</Button>
                </Grid>
                <Grid item xs={6} sm={4}/>
            </Grid>
        </main>
    );

}

export default CreateRpmParameter;