import React, {Component} from 'react';

import {withStyles, withTheme} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import BuyBidTable from "./BuyBidTable";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
});

class MyBuyBids extends Component {

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <Typography variant="h3" align="center">Buy Bids</Typography>
                <BuyBidTable accountId={this.props.match.params.accountId}/>
            </main>
        );
    }

}

export default withTheme(withStyles(styles)(MyBuyBids));