import React, {useState, useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {useDispatch, useSelector} from "react-redux";
import {retrieveDeliveryYearAuctions, retrieveLdas} from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import {cleanStringInput} from "../../../Utility/StringUtil";
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {onSuccess, onError} from "../../../store/actions/popupActions";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import TwoDigitCurrency from "../../NumberFormat/TwoDigitCurrency";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const CreateIncrementalCapacityTransferRight = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const deliveryYearAuctions = useSelector(state => state.capacity.deliveryYearAuctions);
    const ldas = useSelector(state => state.capacity.ldas);
    const history = useHistory();
    const [incrementalCtr, setIncrementalCtr] = useState({auction: '', iCtrs: []});

    useEffect(() => {
        if (!deliveryYearAuctions) {
            dispatch(retrieveDeliveryYearAuctions());
        }
        if (!ldas) {
            dispatch(retrieveLdas());
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderDeliveryYearSelectBox = () => {
        if (deliveryYearAuctions) {
            return (
                <Autocomplete options={Object.keys(deliveryYearAuctions).sort((c1, c2) => c2.localeCompare(c1))} getOptionLabel={(option) => option}
                              renderInput={(params) => <TextField {...params} label="Delivery Year"/>}
                              onChange={(event, value) => onDeliveryYearSelectChange(event, value)}/>
            );
        }
    }

    const renderAuctionSelectBox = () => {
        if (incrementalCtr.deliveryYear) {
            return (
                <Autocomplete value={incrementalCtr.auction}
                              options={['', ...deliveryYearAuctions[incrementalCtr.deliveryYear].map(r => r.auction)]}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => <TextField {...params} label="Auction"/>}
                              onChange={(event, value) => onAuctionSelectChange(event, value)}/>
            );
        }
        return (
            <Autocomplete value={incrementalCtr.auction} options={['']} getOptionLabel={(option) => ''}
                          renderInput={(params) => <TextField {...params} label="Auction"/>}/>
        );
    }

    const renderLdaSelectBox = (iCtr, index) => {
        if (ldas && ldas.length > 0) {
            return (
                <Autocomplete value={findLdaForLdaName(iCtr.lda)} options={ldas} getOptionLabel={(option) => option.ldaName}
                              renderInput={(params) => <TextField {...params} label="LDA"/>}
                              onChange={(event, value) => onLdaSelectChange(event, value, index)}/>
            );
        }
    }

    const onLdaSelectChange = (event, value, index) => {
        let iCtrs = incrementalCtr.iCtrs;
        iCtrs[index].ldaName = value.ldaName;
        incrementalCtr.iCtrs = iCtrs;
        setIncrementalCtr({...incrementalCtr});
    }

    const findLdaForLdaName = (ldaName) => {
        for (let lda of ldas) {
            if (lda.ldaName === ldaName) {
                return lda;
            }
        }
    }

    const onDeliveryYearSelectChange = (event, value) => {
        if (value) {
            incrementalCtr.deliveryYear = value;
        } else {
            incrementalCtr.deliveryYear = '';
            incrementalCtr.auction = '';
        }
        setIncrementalCtr({...incrementalCtr});
    }

    const onAuctionSelectChange = (event, value) => {
        if (value) {
            incrementalCtr.auction = value;
        } else {
            incrementalCtr.auction = '';
        }
        setIncrementalCtr({...incrementalCtr});
    }

    const removeIconClick = (index) => {
        let iCtrs = incrementalCtr.iCtrs;
        iCtrs.splice(index, 1);
        incrementalCtr.iCtrs = iCtrs;
        setIncrementalCtr({...incrementalCtr});
    }

    const renderAddIcon = (totalCount, index) => {
        if (index === totalCount) {
            return (
                <IconButton onClick={addIconClick}>
                    <AddIcon/>
                </IconButton>
            )
        }
    }

    const addIconClick = () => {
        let iCtrs = incrementalCtr.iCtrs;
        iCtrs.push({ldaName: ""});
        incrementalCtr.iCtrs = iCtrs;
        setIncrementalCtr({...incrementalCtr});
    }

    const onInputChange = (e, index) => {
        let iCtrs = incrementalCtr.iCtrs;
        if (e.target.type === "number") {
            if (e.target.value) {
                iCtrs[index][e.target.name] = Number.parseFloat(e.target.value);
            } else {
                iCtrs[index][e.target.name] = e.target.value;
            }
        } else {
            iCtrs[index][e.target.name] = e.target.value;
        }
        incrementalCtr.iCtrs = iCtrs;
        setIncrementalCtr({...incrementalCtr});
    }

    const renderIncrementalCtrs = () => {
        let renderedICtrs = [];
        let iCtrs = incrementalCtr.iCtrs;
        if (!iCtrs || iCtrs.length === 0) {
            iCtrs.push({ldaName: ""});
        }
        for (let i = 0; i < iCtrs.length; i++) {
            renderedICtrs.push(renderIncrementalCtr(iCtrs[i], iCtrs.length - 1, i));
        }
        return renderedICtrs;
    }

    const onSave = async () => {
        await trackPromise(axios.post("/capacity/deliveryYear/" + incrementalCtr.deliveryYear.replace("/", "-") + "/auction/" + incrementalCtr.auction + "/project/status/ictr", JSON.stringify(incrementalCtr.iCtrs)).then(response => {
            dispatch(onSuccess("Saved ICTRs for delivery year " + incrementalCtr.deliveryYear + " and auction " + incrementalCtr.auction));
        }).catch((error => {
            dispatch(onError(error));
        })));
        history.push("/capacity/projectStatus/ictr/view");
    }

    const renderIncrementalCtr = (iCtr, totalCount, index) => {
        return (
            <Grid container spacing={2} key={index}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    {renderLdaSelectBox(iCtr, index)}
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel id="type-label">Type</InputLabel>
                        <Select labelId="type-label" name="type" value={iCtr.type ? iCtr.type : ''} onChange={(e) => onInputChange(e, index)} fullWidth>
                            <MenuItem value={"REGIONAL"}>Regional</MenuItem>
                            <MenuItem value={"Customer Funded"}>Customer Funded</MenuItem>
                            <MenuItem value={"Lower Voltage"}>Lower Voltage</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Upgrade ID" name="upgradeId" value={cleanStringInput(iCtr.upgradeId)}
                               onChange={(e) => onInputChange(e, index)} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Parent Upgrade ID" name="parentUpgradeId" value={cleanStringInput(iCtr.parentUpgradeId)}
                               onChange={(e) => onInputChange(e, index)} fullWidth/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Certified ICTR" name="certifiedIctr" type="number" value={cleanStringInput(iCtr.certifiedIctr)}
                               onChange={(e) => onInputChange(e, index)} fullWidth
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWs</InputAdornment>, inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 1, allowNegative: false, isEdit: true}
                               }}/>
                </Grid>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Adjusted ICTR" name="adjustedIctr" type="number" value={cleanStringInput(iCtr.adjustedIctr)}
                               onChange={(e) => onInputChange(e, index)} fullWidth
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWs</InputAdornment>, inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 1, allowNegative: false, isEdit: true}
                               }}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={4}>
                    <TextField label="Economic Value" name="economicValue" type="number" value={cleanStringInput(iCtr.economicValue)}
                               onChange={(e) => onInputChange(e, index)} fullWidth
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">$/MW-day</InputAdornment>,
                                   inputComponent: TwoDigitCurrency,
                                   inputProps: {isEdit: true}
                               }}/>
                </Grid>
                <Grid item xs={6} sm={3}/>
                <Grid item xs={6} sm={2}>
                    {renderAddIcon(totalCount, index)}
                    <IconButton onClick={() => removeIconClick(index)}>
                        <RemoveIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        );
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={3}>
                    {deliveryYearAuctions && renderDeliveryYearSelectBox()}
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    {ldas && renderAuctionSelectBox()}
                </Grid>
                <Grid item xs={6} sm={1}/>
            </Grid>
            {renderIncrementalCtrs()}
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={4}>
                    <Button onClick={onSave} fullWidth>Save</Button>
                </Grid>
            </Grid>
        </main>
    )

}

export default CreateIncrementalCapacityTransferRight;