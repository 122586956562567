export const RETRIEVE_GENERATORS = 'RETRIEVE_GENERATORS';
export const RETRIEVE_GENERATOR = 'RETRIEVE_GENERATOR';
export const RETRIEVE_SELL_OFFERS = 'RETRIEVE_SELL_OFFERS';
export const SEARCH_SELL_OFFERS = 'SEARCH_SELL_OFFERS';
export const CLEAR_SELL_OFFERS = 'CLEAR_SELL_OFFERS';
export const SAVE_BUY_BID = 'SAVE_BUY_BID';
export const RETRIEVE_BUY_BIDS = 'RETRIEVE_BUY_BIDS';
export const SEARCH_BUY_BIDS = 'SEARCH_BUY_BIDS';
export const RETRIEVE_SELL_OFFER = 'RETRIEVE_SELL_OFFER';
export const RETRIEVE_BUY_BID = 'RETRIEVE_BUY_BID';
export const RETRIEVE_BUY_BID_FOR_SELL_OFFER = 'RETRIEVE_BUY_BID_FOR_SELL_OFFER';
export const RETRIEVE_SELL_OFFER_FOR_BUY_BID = 'RETRIEVE_SELL_OFFER_FOR_BUY_BID';
export const SAVE_BUY_BID_PROFILE_SUCCESS = 'SAVE_BUY_BID_PROFILE';
export const RETRIEVE_BUY_BID_PROFILES_SUCCESS = 'RETRIEVE_BUY_BID_PROFILES_SUCCESS';
export const UPDATE_BUY_BID_PROFILE_SUCCESS = 'UPDATE_BUY_BID_PROFILE_SUCCESS';
export const STORE_GENERATOR_CONFIRM = 'STORE_GENERATOR_FOR_CONFIRM';
export const STORE_SELL_OFFER_CONFIRM = 'STORE_SELL_OFFER_FOR_CONFIRM';
export const EIGHTY_SEVEN_SIXTY_RETRIEVED = 'EIGHTY_SEVEN_SIXTY_RETRIEVED';
export const SELL_OFFER_SEARCH_QUANTITY_MW = 'SELL_OFFER_SEARCH_QUANTITY_MW';
export const SELL_OFFER_SEARCH_VARIABLE_PAYMENT = 'SELL_OFFER_SEARCH_VARIABLE_PAYMENT';
export const SELL_OFFER_SEARCH_FIXED_PAYMENT = 'SELL_OFFER_SEARCH_FIXED_PAYMENT';
export const SELL_OFFER_SEARCH_ENVIRONMENTAL_ATTRIBUTES = 'SELL_OFFER_SEARCH_ENVIRONMENTAL_ATTRIBUTES';
export const SELL_OFFER_SEARCH_INCLUDE_CAPACITY_ESCALATION = 'SELL_OFFER_SEARCH_INCLUDED_CAPACITY_ESCALATION';
export const SELL_OFFER_SEARCH_CAPACITY_ESCALATION_PERCENTAGE = 'SELL_OFFER_SEARCH_CAPACITY_ESCALATION_PERCENTAGE';
export const SELL_OFFER_SEARCH_CAPACITY_ESCALATION_DELAY = 'SELL_OFFER_SEARCH_CAPACITY_ESCALATION_DELAY';
export const SELL_OFFER_SEARCH_CAPACITY_ESCALATION_FREQUENCY = 'SELL_OFFER_SEARCH_CAPACITY_ESCALATION_FREQUENCY';
export const SELL_OFFER_SEARCH_INCLUDE_ENERGY_ESCALATION = 'SELL_OFFER_SEARCH_INCLUDE_ENERGY_ESCALATION';
export const SELL_OFFER_SEARCH_ENERGY_ESCALATION_PERCENTAGE = 'SELL_OFFER_SEARCH_ENERGY_ESCALATION_PERCENTAGE';
export const SELL_OFFER_SEARCH_ENERGY_ESCALATION_DELAY = 'SELL_OFFER_SEARCH_ENERGY_ESCALATION_DELAY';
export const SELL_OFFER_SEARCH_ENERGY_ESCALATION_FREQUENCY = 'SELL_OFFER_SEARCH_ENERGY_ESCALATION_FREQUENCY';
export const SELL_OFFER_SEARCH_INCLUDE_ENVIRONMENTAL_ESCALATION = 'SELL_OFFER_SEARCH_INCLUDE_ENVIRONMENTAL_ESCALATION';
export const SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_PERCENTAGE = 'SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_PERCENTAGE';
export const SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_DELAY = 'SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_DELAY';
export const SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_FREQUENCY = 'SELL_OFFER_SEARCH_ENVIRONMENTAL_ESCALATION_FREQUENCY';
export const SELL_OFFER_SEARCH_DELIVERY_POINT = 'SELL_OFFER_SEARCH_DELIVERY_POINT';
export const SELL_OFFER_SEARCH_TERM_LENGTH = 'SELL_OFFER_SEARCH_TERM_LENGTH';
export const SELL_OFFER_SEARCH_MARKET = 'SELL_OFFER_SEARCH_MARKET';
export const SELL_OFFER_SEARCH_OFFER_TYPE = 'SELL_OFFER_SEARCH_OFFER_TYPE';
export const SELL_OFFER_SEARCH_SELL_OFFER_TYPE = 'SELL_OFFER_SEARCH_SELL_OFFER_TYPE';
export const SELL_OFFER_SEARCH_START_DATE = 'SELL_OFFER_SEARCH_START_DATE';
export const SELL_OFFER_SEARCH_INCLUDED_RECS = 'SELL_OFFER_SEARCH_INCLUDED_RECS';
export const SELL_OFFER_SEARCH_GENERATOR_TYPE = 'SELL_OFFER_SEARCH_GENERATOR_TYPE';
export const SELL_OFFER_SEARCH_STATE = 'SELL_OFFER_SEARCH_STATE';
export const SELL_OFFER_SEARCH_ADDITIONAL_SEARCH_FIELDS = 'SELL_OFFER_SEARCH_ADDITIONAL_SEARCH_FIELDS';
export const SELL_OFFER_SEARCH_REMOVE_SEARCH_FIELD = 'SELL_OFFER_SEARCH_REMOVE_SEARCH_FIELD';
export const BUY_BID_SEARCH_QUANTITY_MW = 'BUY_BID_SEARCH_QUANTITY_MW';
export const BUY_BID_SEARCH_VARIABLE_PAYMENT = 'BUY_BID_SEARCH_VARIABLE_PAYMENT';
export const BUY_BID_SEARCH_FIXED_PAYMENT = 'BUY_BID_SEARCH_FIXED_PAYMENT';
export const BUY_BID_SEARCH_ENVIRONMENTAL_ATTRIBUTES = 'BUY_BID_SEARCH_ENVIRONMENTAL_ATTRIBUTES';
export const BUY_BID_SEARCH_INCLUDE_CAPACITY_ESCALATION = 'BUY_BID_SEARCH_INCLUDED_CAPACITY_ESCALATION';
export const BUY_BID_SEARCH_CAPACITY_ESCALATION_PERCENTAGE = 'BUY_BID_SEARCH_CAPACITY_ESCALATION_PERCENTAGE';
export const BUY_BID_SEARCH_CAPACITY_ESCALATION_DELAY = 'BUY_BID_SEARCH_CAPACITY_ESCALATION_DELAY';
export const BUY_BID_SEARCH_CAPACITY_ESCALATION_FREQUENCY = 'BUY_BID_SEARCH_CAPACITY_ESCALATION_FREQUENCY';
export const BUY_BID_SEARCH_INCLUDE_ENERGY_ESCALATION = 'BUY_BID_SEARCH_INCLUDE_ENERGY_ESCALATION';
export const BUY_BID_SEARCH_ENERGY_ESCALATION_PERCENTAGE = 'BUY_BID_SEARCH_ENERGY_ESCALATION_PERCENTAGE';
export const BUY_BID_SEARCH_ENERGY_ESCALATION_DELAY = 'BUY_BID_SEARCH_ENERGY_ESCALATION_DELAY';
export const BUY_BID_SEARCH_ENERGY_ESCALATION_FREQUENCY = 'BUY_BID_SEARCH_ENERGY_ESCALATION_FREQUENCY';
export const BUY_BID_SEARCH_INCLUDE_ENVIRONMENTAL_ESCALATION = 'BUY_BID_SEARCH_INCLUDE_ENVIRONMENTAL_ESCALATION';
export const BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_PERCENTAGE = 'BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_PERCENTAGE';
export const BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_DELAY = 'BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_DELAY';
export const BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_FREQUENCY = 'BUY_BID_SEARCH_ENVIRONMENTAL_ESCALATION_FREQUENCY';
export const BUY_BID_SEARCH_DELIVERY_POINT = 'BUY_BID_SEARCH_DELIVERY_POINT';
export const BUY_BID_SEARCH_TERM_LENGTH = 'BUY_BID_SEARCH_TERM_LENGTH';
export const BUY_BID_SEARCH_MARKET = 'BUY_BID_SEARCH_MARKET';
export const BUY_BID_SEARCH_OFFER_TYPE = 'BUY_BID_SEARCH_OFFER_TYPE';
export const BUY_BID_SEARCH_SELL_OFFER_TYPE = 'BUY_BID_SEARCH_BUY_BID_TYPE';
export const BUY_BID_SEARCH_START_DATE = 'BUY_BID_SEARCH_START_DATE';
export const BUY_BID_SEARCH_INCLUDED_RECS = 'BUY_BID_SEARCH_INCLUDED_RECS';
export const BUY_BID_SEARCH_GENERATOR_TYPE = 'BUY_BID_SEARCH_GENERATOR_TYPE';
export const BUY_BID_SEARCH_ADDITIONAL_SEARCH_FIELDS = 'BUY_BID_SEARCH_ADDITIONAL_SEARCH_FIELDS';
export const BUY_BID_SEARCH_MILESTONE = 'BUY_BID_SEARCH_MILESTONE';
export const BUY_BID_SEARCH_STATE = 'BUY_BID_SEARCH_STATE';
export const BUY_BID_SEARCH_REMOVE_SEARCH_FIELD = 'BUY_BID_SEARCH_REMOVE_SEARCH_FIELD';
export const CLEAR_MATCH_ALERT = 'CLEAR_MATCH_ALERT';
export const EXACT_MATCH = "EXACT_MATCH";
export const MULTIPLE_MATCHES = "MULTIPLE_MATCHES";
export const NO_MATCH = "NO_MATCH";
export const RETRIEVE_POTENTIAL_SELL_OFFERS_FOR_BUY_BID = 'RETRIEVE_POTENTIAL_SELL_OFFERS_FOR_BUY_BID';
export const RETRIEVE_SELL_OFFER_MATCHES_FOR_ACCOUNT = 'RETRIEVE_SELL_OFFER_MATCHES_FOR_ACCOUNT';
export const RETRIEVE_POTENTIAL_SELL_OFFER_MATCHES_FOR_ACCOUNT = 'RETRIEVE_POTENTIAL_SELL_OFFER_MATCHES_FOR_ACCOUNT';
export const RETRIEVE_BUY_BID_MATCHES_FOR_ACCOUNT = 'RETRIEVE_BUY_BID_MATCHES_FOR_ACCOUNT';
export const RETRIEVE_POTENTIAL_BUY_BID_MATCHES_FOR_ACCOUNT = 'RETRIEVE_POTENTIAL_BUY_BID_MATCHES_FOR_ACCOUNT';
export const RETRIEVE_DELIVERY_POINTS = 'RETRIEVE_DELIVERY_POINTS';
export const RETRIEVE_REQUIRED_MILESTONES = 'RETRIEVE_REQUIRED_MILESTONES';