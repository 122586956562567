import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import PaginatedTableActions from "../../Table/PaginatedTableActions";
import {useDispatch, useSelector} from "react-redux";
import {retrieveGenerators} from '../../../store/spark-exchange/actions/generatorActions';
import NumberFormat from "react-number-format";
import {TableSortLabel} from "@material-ui/core";

const useStyles = makeStyles({
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
});

const GeneratorTable = (props) => {

    const {accountId} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const generators = useSelector(state => state.generator.generators);
    const history = useHistory();
    const [orderBy, setOrderBy] = useState();
    const [direction, setDirection] = useState();
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        dispatch(retrieveGenerators(accountId, 0, 10));
        // eslint-disable-next-line
    }, [accountId])


    const handleRowClick = (e, id, accountId) => {
        history.push('/spark-exchange/account/' + accountId + '/generators/' + id);
    };

    const handlePageChange = (pageNumber) => {
        dispatch(retrieveGenerators(accountId, pageNumber, pageSize, orderBy, direction));
    }

    const handleRowsPerPageChange = (e) => {
        setPageSize(e.target.value);
        dispatch(retrieveGenerators(accountId, 0, e.target.value, orderBy, direction));
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        setOrderBy(column);
        setDirection(sortDirection);
        dispatch(retrieveGenerators(accountId, 0, pageSize, column, sortDirection));
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'generatorName'} direction={orderBy === 'generatorName' ? direction : 'asc'}
                                        onClick={() => handleSort("generatorName")}>
                            Generator Name
                            {orderBy === 'generatorName' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'city'} direction={orderBy === 'city' ? direction : 'asc'}
                                        onClick={() => handleSort("city")}>
                            City
                            {orderBy === 'city' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'state'} direction={orderBy === 'state' ? direction : 'asc'}
                                        onClick={() => handleSort("state")}>
                            State
                            {orderBy === 'state' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'generatorType'} direction={orderBy === 'generatorType' ? direction : 'asc'}
                                        onClick={() => handleSort("generatorType")}>
                            Generator Type
                            {orderBy === 'generatorType' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'maximumOutput'} direction={orderBy === 'maximumOutput' ? direction : 'asc'}
                                        onClick={() => handleSort("maximumOutput")}>
                            Nameplate Capacity (MWs)
                            {orderBy === 'maximumOutput' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'primaryFuelType'} direction={orderBy === 'primaryFuelType' ? direction : 'asc'}
                                        onClick={() => handleSort("primaryFuelType")}>
                            Primary Fuel Type
                            {orderBy === 'primaryFuelType' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {generators && generators.results &&
                generators.results.map((row) => (
                    <TableRow className={classes.tableRow} onClick={(e) => handleRowClick(e, row.id, row.accountId)} key={row.id}>
                        <TableCell align="center">{row.generatorName}</TableCell>
                        <TableCell align="center">{row.city}</TableCell>
                        <TableCell align="center">{row.state}</TableCell>
                        <TableCell align="center">{row.generatorType}</TableCell>
                        <TableCell align="center"><NumberFormat value={row.maximumOutput} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/></TableCell>
                        <TableCell align="center">{row.primaryFuelType}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={6} count={generators.totalCount ? generators.totalCount : 0}
                                     rowsPerPage={calculateRowsPerPage(generators.pageSize)} page={generators.pageNumber ? generators.pageNumber : 0}
                                     onChangePage={handlePageChange} onChangeRowsPerPage={handleRowsPerPageChange} ActionsComponent={PaginatedTableActions}/>
                </TableRow>
            </TableFooter>
        </Table>
    );

}

export default GeneratorTable;