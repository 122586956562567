import React, {Component} from 'react';
import DeliveryYearAuctionComponent from "./DeliveryYearAuctionComponent";
import {withStyles, withTheme} from "@material-ui/core/styles";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {onError, onSuccess} from "../../../store/actions/popupActions";
import {connect} from "react-redux";
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
});

class EditDeliveryYearAuction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deliveryYearAuction: {
                deliveryYear: '',
                deliveryYearAuctions: []
            }
        }
    }

    onChangeHandler = (deliveryYearAuction) => {
        this.setState({deliveryYearAuction: deliveryYearAuction});
    }

    onSave = async () => {
        await trackPromise(axios.put("/capacity/deliveryYearAuction/deliveryYear/" + this.state.deliveryYearAuction.deliveryYear.replace("/", "-"),
            JSON.stringify(this.state.deliveryYearAuction.deliveryYearAuctions)).then(response => {
            this.props.onSuccess("Successfully saved auctions for delivery year " + this.state.deliveryYearAuction.deliveryYear);
        }).catch(error => {
            this.props.onError(error);
        }));
        this.props.history.push("/capacity/deliveryYearAuctions/view")
    }

    onDelete = async () => {
        await trackPromise(axios.delete("/capacity/deliveryYearAuction/deliveryYear/" + this.state.deliveryYearAuction.deliveryYear.replace("/", "-")).then(response => {
            this.props.onSuccess("Successfully delete auctions for delivery year " + this.state.deliveryYearAuction.deliveryYear);
        }).catch(error => {
            this.props.onError(error);
        }));
        this.props.history.push("/capacity/deliveryYearAuctions/view")
    }

    renderButtons = () => {
        if (hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, 'ROLE_update_delivery_year_auction') &&
            hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, 'ROLE_delete_delivery_year_auction')) {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={3}/>
                    <Grid item xs={6} sm={2}>
                        <Button onClick={this.onSave} fullWidth>Save</Button>
                    </Grid>
                    <Grid item xs={6} sm={2}/>
                    <Grid item xs={6} sm={3}>
                        <Button onClick={this.onDelete} fullWidth>Delete</Button>
                    </Grid>
                </React.Fragment>
            );
        }
        if (hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, 'ROLE_update_delivery_year_auction') &&
            !hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, 'ROLE_delete_delivery_year_auction')) {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={5}/>
                    <Grid item xs={6} sm={3}>
                        <Button onClick={this.onSave} fullWidth>Save</Button>
                    </Grid>
                </React.Fragment>
            );
        }
        if (!hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, 'ROLE_update_delivery_year_auction') &&
            hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, 'ROLE_delete_delivery_year_auction')) {
            return (
                <React.Fragment>
                    <Grid item xs={6} sm={5}/>
                    <Grid item xs={6} sm={3}>
                        <Button onClick={this.onDelete} fullWidth>Delete</Button>
                    </Grid>
                </React.Fragment>
            );
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <DeliveryYearAuctionComponent deliveryYear={this.props.match.params.deliveryYear.replace("-", "/")} onChangeHandler={this.onChangeHandler}
                                              isEdit={hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, 'ROLE_update_delivery_year_auction')}/>
                <Grid container spacing={2}>
                    {this.renderButtons()}
                </Grid>
            </main>
        )
    }
}

const mapStateToProps = state => {
    return {
        chosenAccount: state.chosenAccount.account,
        roles: state.auth.roles
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onError: (error) => dispatch(onError(error)),
        onSuccess: (message) => dispatch(onSuccess(message))
    };
}

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EditDeliveryYearAuction)));