import React, {useState, useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {TableCell, TableSortLabel} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import {calculateRowsPerPage} from "../../Table/PaginatedTableUtils";
import PaginatedTableActions from "../../Table/PaginatedTableActions";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {onError} from "../../../store/actions/popupActions";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {retrieveDeliveryYearAuctions, retrieveLdas} from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import {useHistory} from "react-router-dom";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const ViewIncrementalCapacityTransferRights = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const deliveryYearAuctions = useSelector(state => state.capacity.deliveryYearAuctions);
    const ldas = useSelector(state => state.capacity.ldas);
    const history = useHistory();
    const [incrementalCtrs, setIncrementalCtrs] = useState();
    const [search, setSearch] = useState({deliveryYear: '', auction: ''});
    const [orderBy, setOrderBy] = useState();
    const [direction, setDirection] = useState();
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        if (!deliveryYearAuctions) {
            dispatch(retrieveDeliveryYearAuctions());
        }
        if (!ldas) {
            dispatch(retrieveLdas());
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const retrieveIncrementalCapacityTransferRights = async (pageNumber, pageSize, column, sortDirection) => {
        let url = undefined;
        if (search.deliveryYear && search.auction) {
            url = "/capacity/deliveryYear/" + search.deliveryYear.replace("/", "-") + "/auction/" + search.auction + "/project/status/ictr";
        } else if (search.deliveryYear && !search.auction) {
            url = "/capacity/deliveryYear/" + search.deliveryYear.replace("/", "-") + "/project/status/ictr";
        }
        if (url) {
            url = url + "?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
            if (column) {
                url = url + "&orderBy=" + column;
            }
            if (sortDirection) {
                url = url + "&direction=" + sortDirection;
            }
            await trackPromise(axios.get(url).then(response => {
                setIncrementalCtrs({...response.data});
            }).catch(error => {
                dispatch(onError(error));
            }));
        }
    }

    const renderDeliveryYearSelectBox = () => {
        if (deliveryYearAuctions) {
            return (
                <Autocomplete value={search.deliveryYear} options={['', ...Object.keys(deliveryYearAuctions).sort((c1, c2) => c2.localeCompare(c1))]}
                              getOptionLabel={(option) => option} id="deliveryYear" renderInput={(params) => <TextField {...params} label="Delivery Year"/>}
                              onChange={(event, value) => onDeliveryYearSelectChange(event, value)}/>
            );
        }
    }

    const renderAuctionSelectBox = () => {
        return (
            <Autocomplete value={search.auction} options={['', 'BRA', 'FIRST', 'SECOND', 'THIRD']} getOptionLabel={(option) => option} id="auction"
                          renderInput={(params) => <TextField {...params} label="Auction"/>}
                          onChange={(event, value) => onAuctionSelectChange(event, value)}/>
        );
    }

    const onDeliveryYearSelectChange = (event, value) => {
        if (value) {
            search.deliveryYear = value;
        } else {
            search.deliveryYear = '';
        }
        setSearch({...search});
        retrieveIncrementalCapacityTransferRights(0, pageSize, orderBy, direction);
    }

    const onAuctionSelectChange = (event, value) => {
        if (value) {
            search.auction = value;
        } else {
            search.auction = '';
        }
        setSearch({...search});
        retrieveIncrementalCapacityTransferRights(0, pageSize, orderBy, direction);
    }

    const handlePageChange = (pageNumber, pageSize) => {
        retrieveIncrementalCapacityTransferRights(pageNumber, pageSize, orderBy, direction);
    }

    const handleRowsPerPageChange = (e) => {
        setPageSize(e.target.value);
        retrieveIncrementalCapacityTransferRights(0, e.target.value, orderBy, direction);
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        setOrderBy(column);
        setDirection(sortDirection);
        retrieveIncrementalCapacityTransferRights(0, pageSize, column, sortDirection);
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={2}/>
                <Grid item xs={6} sm={3}>
                    {renderDeliveryYearSelectBox()}
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    {renderAuctionSelectBox()}
                </Grid>
                <Grid item s={6} sm={2}/>
            </Grid>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'deliveryYear'} direction={orderBy === 'deliveryYear' ? direction : 'asc'}
                                            onClick={() => handleSort("deliveryYear")}>
                                Delivery Year
                                {orderBy === 'deliveryYear' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'auction'} direction={orderBy === 'auction' ? direction : 'asc'}
                                            onClick={() => handleSort("auction")}>
                                Auction
                                {orderBy === 'auction' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'upgradeId'} direction={orderBy === 'upgradeId' ? direction : 'asc'}
                                            onClick={() => handleSort("upgradeId")}>
                                Upgrade ID
                                {orderBy === 'upgradeId' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'parentUpgradeId'} direction={orderBy === 'parentUpgradeId' ? direction : 'asc'}
                                            onClick={() => handleSort("parentUpgradeId")}>
                                Related Upgrade ID
                                {orderBy === 'parentUpgradeId' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'ldaName'} direction={orderBy === 'ldaName' ? direction : 'asc'}
                                            onClick={() => handleSort("ldaName")}>
                                LDA
                                {orderBy === 'ldaName' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'type'} direction={orderBy === 'type' ? direction : 'asc'}
                                            onClick={() => handleSort("type")}>
                                Type
                                {orderBy === 'type' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'certifiedIctr'} direction={orderBy === 'certifiedIctr' ? direction : 'asc'}
                                            onClick={() => handleSort("certifiedIctr")}>
                                Certified ICTR (MWs)
                                {orderBy === 'certifiedIctr' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'adjustedIctr'} direction={orderBy === 'adjustedIctr' ? direction : 'asc'}
                                            onClick={() => handleSort("adjustedIctr")}>
                                Adjusted ICTR (MWs)
                                {orderBy === 'adjustedIctr' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === 'economicValue'} direction={orderBy === 'economicValue' ? direction : 'asc'}
                                            onClick={() => handleSort("economicValue")}>
                                Economic Value ($/MW-day)
                                {orderBy === 'economicValue' ? (
                                    <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {incrementalCtrs && incrementalCtrs.results && incrementalCtrs.results.length > 0 &&
                    incrementalCtrs.results.map(iCtr => {
                        return (<TableRow key={iCtr.id} className={classes.tableRow} onClick={() => history.push("/capacity/projectStatus/ictr/" + iCtr.id)}>
                                <TableCell align="center">{iCtr.deliveryYear}</TableCell>
                                <TableCell align="center">{iCtr.auction}</TableCell>
                                <TableCell align="center">{iCtr.upgradeId}</TableCell>
                                <TableCell align="center">{iCtr.parentUpgradeId ? iCtr.parentUpgradeId : '-'}</TableCell>
                                <TableCell align="center">{iCtr.ldaName}</TableCell>
                                <TableCell align="center">{iCtr.type}</TableCell>
                                <TableCell align="center">{iCtr.certifiedIctr ?
                                    <NumberFormat value={iCtr.certifiedIctr} displayType='text' fixedDecimalScale={true} decimalScale={1} thousandSeparator/> : '-'}</TableCell>
                                <TableCell align="center">{iCtr.adjustedIctr ?
                                    <NumberFormat value={iCtr.certifiedIctr} displayType='text' fixedDecimalScale={true} decimalScale={1} thousandSeparator/> : '-'}</TableCell>
                                <TableCell align="center">{iCtr.economicValue ?
                                    <NumberFormat value={iCtr.economicValue} displayType='text' fixedDecimalScale={true} decimalScale={2} thousandSeparator
                                                  prefix="$"/> : '-'}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        {incrementalCtrs && incrementalCtrs.results && incrementalCtrs.results.length > 0 &&
                        <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={9}
                                         count={incrementalCtrs.totalCount ? incrementalCtrs.totalCount : 0}
                                         rowsPerPage={calculateRowsPerPage(incrementalCtrs.pageSize)}
                                         page={incrementalCtrs.pageNumber ? incrementalCtrs.pageNumber : 0}
                                         onChangePage={handlePageChange} onChangeRowsPerPage={handleRowsPerPageChange} ActionsComponent={PaginatedTableActions}/>
                        }
                    </TableRow>
                </TableFooter>
            </Table>
        </main>
    );

}

export default ViewIncrementalCapacityTransferRights;