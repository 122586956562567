import React, {useEffect} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {usePromiseTracker} from 'react-promise-tracker';
import Loader from 'react-promise-loader';

import WPECAppBar from "./components/AppBar/WPECAppBar";
import MyGenerators from './components/spark-exchange/Generator/MyGenerators';
import ViewGenerator from './components/spark-exchange/Generator/ViewGenerator';
import CreateGenerator from './components/spark-exchange/Generator/CreateGenerator';
import Dashboard from './components/spark-exchange/Dashboard/Dashboard';
import MyBuyBids from './components/spark-exchange/BuyBid/MyBuyBids';
import CreateBuyBid from './components/spark-exchange/BuyBid/CreateBuyBid';
import Login from './components/Login/Login';
import {doAutoLogin} from './store/actions/authActions';
import SearchSellOffers from './components/spark-exchange/SellOffer/SearchSellOffers';
import SearchBuyBids from './components/spark-exchange/BuyBid/SearchBuyBids';
import MySellOffers from './components/spark-exchange/SellOffer/MySellOffers';
import WpecAlert from './components/Alert/WpecAlert';
import CreateSellOffer from './components/spark-exchange/SellOffer/CreateSellOffer';
import ViewSellOffer from './components/spark-exchange/SellOffer/ViewSellOffer';
import ViewBuyBid from './components/spark-exchange/BuyBid/ViewBuyBid';
import ViewUsers from './components/user-management/ViewUsers/ViewUsers';
import ViewUser from "./components/user-management/ViewUsers/ViewUser";
import CreateUser from "./components/user-management/CreateUser/CreateUser";
import ViewAccountRoles from "./components/user-management/ViewRoles/ViewAccountRoles";
import ViewAccountRole from "./components/user-management/ViewRoles/ViewAccountRole";
import CreateAccountRole from "./components/user-management/CreateRoles/CreateAccountRole";
import CreateApplicationRole from "./components/user-management/CreateRoles/CreateApplicationRole";
import ViewApplicationRoles from "./components/user-management/ViewRoles/ViewApplicationRoles";
import ViewApplicationRole from "./components/user-management/ViewRoles/ViewApplicationRole";
import ViewBuyBidProfile from "./components/spark-exchange/BuyBidProfile/ViewBuyBidProfile";
import BuyBidProfiles from "./components/spark-exchange/BuyBidProfile/BuyBidProfiles";
import CreateBuyBidProfile from "./components/spark-exchange/BuyBidProfile/CreateBuyBidProfile";
import LogoutTimer from "./components/LogoutTimer/LogoutTimer";
import ResetPassword from "./components/user-management/ResetPassword/ResetPassword";
import UserProfile from "./components/user-management/UserProfile/UserProfile";
import ChangePassword from "./components/user-management/UserProfile/ChangePassword";
import SearchAccounts from "./components/account-management/SearchAccounts/SearchAccounts";
import ViewAccount from "./components/account-management/Account/ViewAccount";
import CreateAccount from "./components/account-management/Account/CreateAccount";
import CreateCapacityTransferRight from "./components/capacity-exchange/CapacityTransferRight/CreateCapacityTransferRight";
import ViewCapacityTransferRights from "./components/capacity-exchange/CapacityTransferRight/ViewCapacityTransferRights";
import ViewCapacityTransferRight from "./components/capacity-exchange/CapacityTransferRight/ViewCapacityTransferRight";
import CreateDeliveryYearAuction from "./components/capacity-exchange/DeliveryYearAuction/CreateDeliveryYearAuction";
import ViewDeliveryYearAuctions from "./components/capacity-exchange/DeliveryYearAuction/ViewDeliveryYearAuctions";
import EditDeliveryYearAuctions from "./components/capacity-exchange/DeliveryYearAuction/EditDeliveryYearAuction";
import ViewProjectStatus from "./components/capacity-exchange/ProjectStatus/ViewProjectStatus";
import ViewProjectStatusDetail from "./components/capacity-exchange/ProjectStatus/ViewProjectStatusDetail";
import ViewCostAllocation from "./components/capacity-exchange/ProjectStatus/ViewCostAllocation";
import CreateIncrementalCapacityTransferRight from "./components/capacity-exchange/IncrementalCapacityTransferRight/CreateIncrementalCapacityTransferRight";
import ViewIncrementalCapacityTransferRights from "./components/capacity-exchange/IncrementalCapacityTransferRight/ViewIncrementalCapacityTransferRights";
import ViewIncrementalCapacityTransferRight from "./components/capacity-exchange/IncrementalCapacityTransferRight/ViewIncrementalCapacityTransferRight";
import CreateRpmParameter from "./components/capacity-exchange/RpmParameter/CreateRpmParameter";
import ViewRpmParameters from "./components/capacity-exchange/RpmParameter/ViewRpmParameters";
import EditRpmParameter from "./components/capacity-exchange/RpmParameter/EditRpmParameter";
import CreateResourceClearingPrices from "./components/capacity-exchange/ResourceClearingPrice/CreateResourceClearingPrices";
import ViewResourceClearingPrices from "./components/capacity-exchange/ResourceClearingPrice/ViewResourceClearingPrices";
import EditResourceClearingPrice from "./components/capacity-exchange/ResourceClearingPrice/EditResourceClearingPrice";
import CreateZonalAuctionResult from "./components/capacity-exchange/ZonalAuctionResult/CreateZonalAuctionResult";
import ViewZonalAuctionResults from "./components/capacity-exchange/ZonalAuctionResult/ViewZonalAuctionResults";
import EditZonalResult from "./components/capacity-exchange/ZonalAuctionResult/EditZonalResult";
import CapacityCommitment from "./components/capacity-exchange/CapacityCommitment/CapacityCommitment";
import MatchAlert from "./components/spark-exchange/MatchAlert/MatchAlert";
import CapacityObligation from "./components/capacity-exchange/CapacityObligation/CapacityObligation";
import CreatePeakDaySignal from "./components/peak-day-signal/PeakDaySignal/CreatePeakDaySignal";
import MatchesView from "./components/spark-exchange/MatchView/MatchesView";

const App = (props) => {

    const [currentUrl, setCurrentUrl] = React.useState();

    useEffect(() => {
        if (props.location.pathname !== '/login') {
            setCurrentUrl(props.location.pathname);
        }
        if (props.location.pathname !== '/user-management/account/users/resetPassword') {
            props.autoLogin();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let routes = (
        <Switch>
            <Route exact path="/user-management/account/users/resetPassword" component={ResetPassword}/>
            <Route path="/login" component={Login}/>
            <Redirect to="/login"/>
        </Switch>
    );

    if (props.isAuthenticated) {
        routes = (
            <Switch>
                <Route exact path="/logout" component={Login}/>
                <Route path="/spark-exchange/sell-offers" component={SearchSellOffers}/>
                <Route exact path="/spark-exchange/buy-bids/profiles/create" component={CreateBuyBidProfile}/>
                <Route path="/spark-exchange/buy-bids/profiles/:buyBidProfileId" component={ViewBuyBidProfile}/>
                <Route exact path="/spark-exchange/buy-bids/profiles" component={BuyBidProfiles}/>
                <Route path="/spark-exchange/buy-bids" component={SearchBuyBids}/>
                <Route path="/spark-exchange/account/:accountId/generators/create" component={CreateGenerator}/>
                <Route path="/spark-exchange/account/:accountId/generators/:generatorId/sell-offers/:sellOfferId" component={ViewSellOffer}/>
                <Route path="/spark-exchange/account/:accountId/generators/:generatorId/sell-offers" component={MySellOffers}/>
                <Route path="/spark-exchange/account/:accountId/generators/:generatorId" component={ViewGenerator}/>
                <Route exact path="/spark-exchange/account/:accountId/generators" component={MyGenerators}/>
                <Route path="/spark-exchange/account/:accountId/matches" component={MatchesView}/>
                <Route path="/spark-exchange/account/:accountId/sell-offers/create" component={CreateSellOffer}/>
                <Route path="/spark-exchange/account/:accountId/sell-offers" component={MySellOffers}/>
                <Route path="/spark-exchange/account/:accountId/buy-bids/create" component={CreateBuyBid}/>
                <Route path="/spark-exchange/account/:accountId/buy-bids/:buyBidId" component={ViewBuyBid}/>
                <Route path="/spark-exchange/account/:accountId/buy-bids" component={MyBuyBids}/>
                <Route exact path="/spark-exchange" component={Dashboard}/>
                <Route path="/user-management/account/:accountId/users/create" component={CreateUser}/>
                <Route path="/user-management/account/:accountId/users/:userId" component={ViewUser}/>
                <Route exact path="/user-management/account/:accountId/users" component={ViewUsers}/>
                <Route exact path="/user-management/account/:accountId/roles/create" component={CreateAccountRole}/>
                <Route path="/user-management/account/:accountId/roles/:roleId" component={ViewAccountRole}/>
                <Route path="/user-management/account/:accountId/roles" component={ViewAccountRoles}/>
                <Route exact path="/user-management/application/roles/create" component={CreateApplicationRole}/>
                <Route path="/user-management/application/roles/:roleId" component={ViewApplicationRole}/>
                <Route path="/user-management/application/roles" component={ViewApplicationRoles}/>
                <Route path="/user-management/users/:username/password" component={ChangePassword}/>
                <Route path="/user-management/users/:username" component={UserProfile}/>
                <Route path="/user-management/superuser/users/create" component={CreateUser}/>
                <Route path="/user-management/superuser/users/:userId" component={ViewUser}/>
                <Route exact path="/user-management/superuser/users" component={ViewUsers}/>
                <Route exact path="/user-management" component={ViewUsers}/>
                <Route exact path="/account-management/accounts/create" component={CreateAccount}/>
                <Route path="/account-management/accounts/:accountId" component={ViewAccount}/>
                <Route path="/account-management/accounts" component={SearchAccounts}/>
                <Route exact path="/account-management" component={SearchAccounts}/>
                <Route exact path="/capacity/capacityTransferRights/create" component={CreateCapacityTransferRight}/>
                <Route exact path="/capacity/capacityTransferRights/view" component={ViewCapacityTransferRights}/>
                <Route path="/capacity/capacityTransferRights/:capacityTransferRightId" component={ViewCapacityTransferRight}/>
                <Route exact path="/capacity/deliveryYearAuctions/create" component={CreateDeliveryYearAuction}/>
                <Route exact path="/capacity/deliveryYearAuctions/view" component={ViewDeliveryYearAuctions}/>
                <Route path="/capacity/deliveryYearAuctions/:deliveryYear" component={EditDeliveryYearAuctions}/>
                <Route exact path="/capacity/projectStatus" component={ViewProjectStatus}/>
                <Route path="/capacity/projectStatus/:upgradeId/costAllocation" component={ViewCostAllocation}/>
                <Route exact path="/capacity/projectStatus/ictr/create" component={CreateIncrementalCapacityTransferRight}/>
                <Route exact path="/capacity/projectStatus/ictr/view" component={ViewIncrementalCapacityTransferRights}/>
                <Route path="/capacity/projectStatus/ictr/:iCtrId" component={ViewIncrementalCapacityTransferRight}/>
                <Route path="/capacity/projectStatus/:upgradeId" component={ViewProjectStatusDetail}/>
                <Route exact path="/capacity/rpmParameter/create" component={CreateRpmParameter}/>
                <Route exact path="/capacity/rpmParameter/view" component={ViewRpmParameters}/>
                <Route path="/capacity/rpmParameter/:rpmParameterId" component={EditRpmParameter}/>
                <Route path="/capacity/resourceClearingPrice/create" component={CreateResourceClearingPrices}/>
                <Route exact path="/capacity/resourceClearingPrice/view" component={ViewResourceClearingPrices}/>
                <Route exact path="/capacity/resourceClearingPrice/:resourceClearingPriceId" component={EditResourceClearingPrice}/>
                <Route exact path="/capacity/zonalResult/create" component={CreateZonalAuctionResult}/>
                <Route exact path="/capacity/zonalResult/view" component={ViewZonalAuctionResults}/>
                <Route exact path="/capacity/zonalResult/:zonalResultId" component={EditZonalResult}/>
                <Route exact path="/capacity/account/:accountId/commitment" component={CapacityCommitment}/>
                <Route exact path="/capacity/account/:accountId/obligation" component={CapacityObligation}/>
                <Route path="/pds" component={CreatePeakDaySignal}/>
                <Route exact path="/" component={Dashboard}/>
                <Redirect to={currentUrl ? currentUrl : '/spark-exchange'}/>
            </Switch>
        );
    }

    return (
        <div>
            <WpecAlert/>
            <MatchAlert/>
            <Loader promiseTracker={usePromiseTracker}/>
            {props.isAuthenticated &&
                <React.Fragment>
                    <LogoutTimer/>
                    <WPECAppBar isAuthenticated={props.isAuthenticated} key={props.chosenAccount.id}/>
                </React.Fragment>
            }
            <div>
                {routes}
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.jwt !== null,
        chosenAccount: state.chosenAccount.account
    };
};

const mapDispatchToProps = dispatch => {
    return {
        autoLogin: () => dispatch(doAutoLogin()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));