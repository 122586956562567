import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import Typography from "@material-ui/core/Typography";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";

const FirmStandardProductOutput = (props) => {

    const [monthlyOutputs, setMonthlyOutputs] = useState([]);
    const [quantity, setQuantity] = useState(null);
    const [standardProductType, setStandardProductType] = useState(null);
    const [startDate, setStartDate] = useState(null);

    useEffect(() => {
        if (props.quantity && props.quantity !== quantity) {
            setQuantity(props.quantity);
        }
        if (props.standardProductType && props.standardProductType !== standardProductType) {
            setStandardProductType(props.standardProductType)
        }
        if (props.startDate && props.startDate !== startDate) {
            if (Object.prototype.toString.call(props.startDate) === '[object Date]') {
                setStartDate(props.startDate);
            } else {
                setStartDate(new Date(Date.parse(props.startDate)));
            }
        }
        if (props.monthlyOutputs) {
            setMonthlyOutputs(props.monthlyOutputs);
        }
        if ((props.quantity && props.standardProductType && props.startDate) && (props.isEdit) &&
            (props.quantity !== quantity || props.standardProductType !== standardProductType || props.startDate !== startDate)) {
            calculateMonthlyOutputs(props.quantity, props.standardProductType, props.startDate.getFullYear());
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const calculateMonthlyOutputs = async (quantityMw, standardProductType, startYear) => {
        await trackPromise(axios.get("/spark-exchange/calculator/standard-product?quantityMw=" + quantityMw + "&standardProductType=" + standardProductType + "&startYear=" + startYear).then(response => {
            let monthlyOutputs = [];
            response.data.forEach(monthlyOutput => monthlyOutputs.push({month: monthlyOutput.month, output: monthlyOutput.output}));
            setMonthlyOutputs([...monthlyOutputs]);
            if (props.onInputChangeHandler) {
                props.onInputChangeHandler('firmStandardProductMonthlyOutputs', monthlyOutputs);
            }
        }).catch(error => {

        }));
    }

    return (
        <React.Fragment>
            <Grid item xs={6} sm={12}>
                {startDate ? <Typography
                        align="center">{'The below monthly MWhs amounts are indicative values calculated based on the year ' + startDate.getFullYear() + '. Actual amounts will vary.'}</Typography> :
                    <Typography align="center">{'The below monthly MWhs amounts are indicative values. Actual amounts will vary.'}</Typography>}
            </Grid>
            <Grid item xs={6} sm={1}/>
            <Grid item xs={6} sm={2}>
                <TextField label="January" name="january" value={monthlyOutputs[0] ? monthlyOutputs[0].output : 0} fullWidth
                           disabled={true}
                           InputProps={{
                               endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                               inputComponent: GenericNumberFormat,
                               inputProps: {fixedDecimalScale: 2, decimalScale: 2, isEdit: false}
                           }} InputLabelProps={{shrink: true}}/>
            </Grid>
            <Grid item xs={6} sm={2}>
                <TextField label="February" name="february" value={monthlyOutputs[1] ? monthlyOutputs[1].output : 0} fullWidth
                           disabled={true}
                           InputProps={{
                               endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                               inputComponent: GenericNumberFormat,
                               inputProps: {fixedDecimalScale: 2, decimalScale: 2, isEdit: false}
                           }} InputLabelProps={{shrink: true}}/>
            </Grid>
            <Grid item xs={6} sm={2}>
                <TextField label="March" name="march" value={monthlyOutputs[2] ? monthlyOutputs[2].output : 0} fullWidth
                           disabled={true}
                           InputProps={{
                               endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                               inputComponent: GenericNumberFormat,
                               inputProps: {fixedDecimalScale: 2, decimalScale: 2, isEdit: false}
                           }} InputLabelProps={{shrink: true}}/>
            </Grid>
            <Grid item xs={6} sm={2}>
                <TextField label="April" name="april" value={monthlyOutputs[3] ? monthlyOutputs[3].output : 0} fullWidth
                           disabled={true}
                           InputProps={{
                               endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                               inputComponent: GenericNumberFormat,
                               inputProps: {fixedDecimalScale: 2, decimalScale: 2, isEdit: false}
                           }} InputLabelProps={{shrink: true}}/>
            </Grid>
            <Grid item xs={6} sm={2}>
                <TextField label="May" name="may" value={monthlyOutputs[4] ? monthlyOutputs[4].output : 0} fullWidth
                           disabled={true}
                           InputProps={{
                               endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                               inputComponent: GenericNumberFormat,
                               inputProps: {fixedDecimalScale: 2, decimalScale: 2, isEdit: false}
                           }} InputLabelProps={{shrink: true}}/>
            </Grid>
            <Grid item xs={6} sm={1}/>
            <Grid item xs={6} sm={1}/>
            <Grid item xs={6} sm={2}>
                <TextField label="June" name="june" value={monthlyOutputs[5] ? monthlyOutputs[5].output : 0} fullWidth
                           disabled={true}
                           InputProps={{
                               endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                               inputComponent: GenericNumberFormat,
                               inputProps: {fixedDecimalScale: 2, decimalScale: 2, isEdit: false}
                           }} InputLabelProps={{shrink: true}}/>
            </Grid>
            <Grid item xs={6} sm={2}>
                <TextField label="July" name="july" value={monthlyOutputs[6] ? monthlyOutputs[6].output : 0} fullWidth
                           disabled={true}
                           InputProps={{
                               endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                               inputComponent: GenericNumberFormat,
                               inputProps: {fixedDecimalScale: 2, decimalScale: 2, isEdit: false}
                           }} InputLabelProps={{shrink: true}}/>
            </Grid>
            <Grid item xs={6} sm={2}>
                <TextField label="August" name="august" value={monthlyOutputs[7] ? monthlyOutputs[7].output : 0} fullWidth
                           disabled={true}
                           InputProps={{
                               endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                               inputComponent: GenericNumberFormat,
                               inputProps: {fixedDecimalScale: 2, decimalScale: 2, isEdit: false}
                           }} InputLabelProps={{shrink: true}}/>
            </Grid>
            <Grid item xs={6} sm={2}>
                <TextField label="September" name="september" value={monthlyOutputs[8] ? monthlyOutputs[8].output : 0} fullWidth
                           disabled={true}
                           InputProps={{
                               endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                               inputComponent: GenericNumberFormat,
                               inputProps: {fixedDecimalScale: 2, decimalScale: 2, isEdit: false}
                           }} InputLabelProps={{shrink: true}}/>
            </Grid>
            <Grid item xs={6} sm={2}>
                <TextField label="October" name="october" value={monthlyOutputs[9] ? monthlyOutputs[9].output : 0} fullWidth
                           disabled={true}
                           InputProps={{
                               endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                               inputComponent: GenericNumberFormat,
                               inputProps: {fixedDecimalScale: 2, decimalScale: 2, isEdit: false}
                           }} InputLabelProps={{shrink: true}}/>
            </Grid>
            <Grid item xs={6} sm={1}/>
            <Grid item xs={6} sm={4}/>
            <Grid item xs={6} sm={2}>
                <TextField label="November" name="november" value={monthlyOutputs[10] ? monthlyOutputs[10].output : 0} fullWidth
                           disabled={true}
                           InputProps={{
                               endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                               inputComponent: GenericNumberFormat,
                               inputProps: {fixedDecimalScale: 2, decimalScale: 2, isEdit: false}
                           }} InputLabelProps={{shrink: true}}/>
            </Grid>
            <Grid item xs={6} sm={2}>
                <TextField label="December" name="december" value={monthlyOutputs[11] ? monthlyOutputs[11].output : 0} fullWidth
                           disabled={true}
                           InputProps={{
                               endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                               inputComponent: GenericNumberFormat,
                               inputProps: {fixedDecimalScale: 2, decimalScale: 2, isEdit: false}
                           }} InputLabelProps={{shrink: true}}/>
            </Grid>
            <Grid item xs={6} sm={4}/>
        </React.Fragment>
    );

};

export default FirmStandardProductOutput;