import {
    RETRIEVE_SELL_OFFERS,
    SEARCH_SELL_OFFERS,
    CLEAR_SELL_OFFERS,
    RETRIEVE_SELL_OFFER,
    RETRIEVE_BUY_BID_FOR_SELL_OFFER, STORE_SELL_OFFER_CONFIRM
} from '../sparkExchangeActionTypes';
import {ACCOUNT_CHANGED} from '../../actionTypes';

const initialState = {
    sellOffers: [],
    sellOffer: {},
    matchedBuyBid: {},
    pagedSellOffersSearchResult: null
};

const sellOfferReducer = (state = initialState, action) => {
    switch (action.type) {
        case RETRIEVE_SELL_OFFERS:
            return retrieveSellOffers(state, action);
        case SEARCH_SELL_OFFERS:
            return searchSellOffers(state, action);
        case CLEAR_SELL_OFFERS:
            return clearSellOffers(state, action);
        case RETRIEVE_SELL_OFFER:
            return retrieveSellOffer(state, action);
        case RETRIEVE_BUY_BID_FOR_SELL_OFFER:
            return matchedBuyBidsFound(state, action);
        case STORE_SELL_OFFER_CONFIRM:
            return storeForConfirm(state, action);
        case ACCOUNT_CHANGED:
            return initialState;
        default:
            return state;
    }
};

const storeForConfirm = (state, action) => {
    return {...state, sellOffer: action.sellOffer};
}

const retrieveSellOffers = (state, action) => {
    return {...state, sellOffers: action.sellOffers};
};

const searchSellOffers = (state, action) => {
    return {...state, pagedSellOffersSearchResult: action.sellOffersResult};
};

const clearSellOffers = (state, action) => {
    return {...state, pagedSellOffersSearchResult: undefined};
};

const retrieveSellOffer = (state, action) => {
    return {...state, sellOffer: action.sellOffer};
};

const matchedBuyBidsFound = (state, action) => {
    return {...state, matchedBuyBid: action.matchedBuyBid.buyBid};
};


export default sellOfferReducer;