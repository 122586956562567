import {ACCOUNT_CHOSEN, ACCOUNT_CHANGED} from '../actionTypes';

export const setChosenAccount = (account, isDefault) => {
    return {
        type: ACCOUNT_CHOSEN,
        account: account,
        isDefault: isDefault
    };
};

export const accountChange = () => {
    return {
        type: ACCOUNT_CHANGED
    };
};

export function chooseAccount(account, isDefault = false) {
    return (dispatch, getState) => {
        let currentState = getState().chosenAccount.account;
        dispatch(setChosenAccount(account, isDefault));
        let newState = getState().chosenAccount.account;
        if(currentState && newState.id !== currentState.id) {
            dispatch(accountChange());
        }
    }
}