import {
    RETRIEVE_BUY_BID_MATCHES_FOR_ACCOUNT,
    RETRIEVE_POTENTIAL_BUY_BID_MATCHES_FOR_ACCOUNT,
    RETRIEVE_POTENTIAL_SELL_OFFER_MATCHES_FOR_ACCOUNT,
    RETRIEVE_SELL_OFFER_MATCHES_FOR_ACCOUNT
} from "../sparkExchangeActionTypes";
import {ACCOUNT_CHANGED} from "../../actionTypes";


const initialState = {
    sellOfferMatches: undefined,
    buyBidMatches: undefined,
    potentialSellOfferMatches: undefined,
    potentialBuyBidMatches: undefined
}

const matchReducer = (state = initialState, action) => {
    switch (action.type) {
        case RETRIEVE_SELL_OFFER_MATCHES_FOR_ACCOUNT:
            return {...state, sellOfferMatches: action.matches};
        case RETRIEVE_POTENTIAL_SELL_OFFER_MATCHES_FOR_ACCOUNT:
            return {...state, potentialSellOfferMatches: action.potentialMatches};
        case RETRIEVE_BUY_BID_MATCHES_FOR_ACCOUNT:
            return {...state, buyBidMatches: action.matches};
        case RETRIEVE_POTENTIAL_BUY_BID_MATCHES_FOR_ACCOUNT:
            return {...state, potentialBuyBidMatches: action.matches};
        case ACCOUNT_CHANGED:
            return initialState;
        default:
            return state;
    }
}

export default matchReducer;