import React, {Component} from 'react';
import {connect} from 'react-redux';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {withStyles, withTheme} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CheckBox from '@material-ui/core/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import axios from '../../../axios/AxiosInterceptors';
import {saveUser, saveUserAdmin} from '../../../store/user-management/actions/userActions';
import UserComponent from "../User/UserComponent";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

const initialState = {
    user: {
        username: '',
        emailAddress: '',
        firstName: '',
        lastName: '',
        password: '',
        phoneNumber: '',
        accountIds: [],
        userAccountRoles: [],
        userApplicationRoles: []
    },
    accountRoles: [],
    applicationRoles: [],
    applicationRoleToggles: [],
    accountRoleToggles: []
}

class CreateUser extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        axios.get("/auth/account/" + this.props.match.params.accountId + "/roles").then(response => {
            let accountRoleToggles = this.state.accountRoleToggles;
            response.data.forEach((role) => {
                accountRoleToggles.push({roleId: role.id, open: false})
            });
            this.setState({accountRoleToggles: accountRoleToggles, accountRoles: response.data});
        });
        axios.get("/auth/application/roles").then(response => {
            let applicationRoleToggles = this.state.applicationRoleToggles;
            response.data.forEach((role) => {
                applicationRoleToggles.push({roleId: role.id, open: false})
            });
            this.setState({applicationRoleToggles: applicationRoleToggles, applicationRoles: response.data});
        });
    }

    inputChangeHandler = (user) => {
        this.setState({user});
    };

    clearInput = () => {
        let user = initialState.user;
        user.userAccountRoles = [];
        user.userApplicationRoles = [];
        this.setState({user});
    };

    saveUser = () => {
        if(this.props.superUser) {
            this.props.saveUserAdmin(this.state.user);
        } else {
            let user = {...this.state.user};
            user.accountIds = [this.props.match.params.accountId];
            this.setState({user});
            this.props.saveUser(this.props.match.params.accountId, user)
        }
        this.props.history.goBack();
    };

    handleAccountRoleToggle = (e) => {
        let user = {...this.state.user};
        user.userAccountRoles = this.handleRoleToggle(user.userAccountRoles, Number.parseInt(e.target.value));
        this.setState({user});
    }

    handleApplicationRoleToggle = (e) => {
        let user = {...this.state.user};
        user.userApplicationRoles = this.handleRoleToggle(user.userApplicationRoles, Number.parseInt(e.target.value));
        this.setState({user: user});
    };

    handleRoleToggle = (roles, roleId) => {
        let index = -1;
        for (let i = 0; i < roles.length; i++) {
            if (roles[i].roleId === roleId) {
                index = i;
            }
        }
        if (index !== -1) {
            roles.splice(index, 1);
        } else {
            roles.push({roleId: roleId, accountId: Number.parseInt(this.props.match.params.accountId)});
        }
        return roles;
    }

    toggleAccountRoleVisibility = (roleId) => {
        let accountRoleToggles = this.state.accountRoleToggles;
        accountRoleToggles = this.toggleRoleVisibility(accountRoleToggles, roleId);
        this.setState({accountRoleToggles});
    };

    toggleApplicationRoleVisibility = (roleId) => {
        let applicationRoleToggles = this.state.applicationRoleToggles;
        applicationRoleToggles = this.toggleRoleVisibility(applicationRoleToggles, roleId);
        this.setState({applicationRoleToggles});
    };

    toggleRoleVisibility = (toggles, roleId) => {
        for (let toggle of toggles) {
            if (toggle.roleId === roleId) {
                toggle.open = !toggle.open;
            }
        }
        return toggles
    }

    isAccountRoleVisible = (roleId) => {
        return this.isRoleVisible(this.state.accountRoleToggles, roleId);
    };

    isApplicationRoleVisible = (roleId) => {
        return this.isRoleVisible(this.state.applicationRoleToggles, roleId);
    };

    isRoleVisible = (toggles, roleId) => {
        for (let toggle of toggles) {
            if (toggle.roleId === roleId) {
                return toggle.open;
            }
        }
        return false;
    }

    isAccountRoleChecked = (roleId) => {
        return this.isRoleChecked(this.state.user.userAccountRoles, roleId);
    }

    isApplicationRoleChecked = (roleId) => {
        return this.isRoleChecked(this.state.user.userApplicationRoles, roleId);
    }

    isRoleChecked = (roles, roleId) => {
        for (let role of roles) {
            if (role.roleId === roleId) {
                return true;
            }
        }
        return false;
    }

    renderListRow = (role, visibilityToggle, isVisible, roleCheckedToggle, isRoleChecked) => {
        const {classes} = this.props;
        return (
            <React.Fragment key={role.id}>
                <ListItem key={role.id} onClick={() => visibilityToggle(role.id)}>
                    <ListItemText>{role.roleName}</ListItemText>
                    {isVisible(role.id) ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    <ListItemSecondaryAction>
                        <CheckBox edge="end" disableRipple value={role.id} onChange={roleCheckedToggle} checked={isRoleChecked(role.id)}/>
                    </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={isVisible(role.id)} timeout="auto" unmountOnExit>
                    <List className={classes.nested} component="div" disablePadding dense={true}>
                        {role.servicePermissions.map((servicePermission) => (
                            <ListItem key={servicePermission.id}>
                                <ListItemText>{servicePermission.permissionPrettyName}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
            </React.Fragment>);
    };

    renderRoles(roles, visibilityToggle, isVisible, roleCheckedToggle, isRoleChecked) {
        if (roles) {
            return (
                <List dense={true}>{
                    roles.map((role) => (
                        this.renderListRow(role, visibilityToggle, isVisible, roleCheckedToggle, isRoleChecked)
                    ))
                }</List>
            )
        } else {
            return (<List/>);
        }
    }

    render() {
        const {classes} = this.props;
        const availableAccountRoles = this.renderRoles(this.state.accountRoles, this.toggleAccountRoleVisibility, this.isAccountRoleVisible, this.handleAccountRoleToggle, this.isAccountRoleChecked);
        const availableApplicationRoles = this.renderRoles(this.state.applicationRoles, this.toggleApplicationRoleVisibility, this.isApplicationRoleVisible, this.handleApplicationRoleToggle, this.isApplicationRoleChecked);
        return (
            <main className={classes.layout}>
                <Typography variant="h3" align="center">Create New User</Typography>
                <UserComponent inputChangeHandler={this.inputChangeHandler} canEdit={true} displayUsername={true}/>
                <Paper className={classes.paper}>
                    <Typography variant="h5" align="center">Roles</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6}>
                            <Typography variant="h6" align="center">Account Roles</Typography>
                            {availableAccountRoles}
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Typography variant="h6" align="center">Application Roles</Typography>
                            {availableApplicationRoles}
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container spacing={6}>
                    <Grid item xs={4} align="center">
                        <Button onClick={this.saveUser}>Save</Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                        <Button onClick={this.clearInput}>Clear</Button>
                    </Grid>
                    <Grid item xs={4} align="center">
                        <Button onClick={() => {
                            this.props.history.goBack()
                        }}>Back</Button>
                    </Grid>
                </Grid>
            </main>
        );
    }
}

const mapStateToProps = state => {
    return {
        superUser: state.auth.superUser
    }
}


const mapDispatchToProps = dispatch => {
    return {
        saveUser: (accountId, user) => dispatch(saveUser(accountId, user)),
        saveUserAdmin: (user) => dispatch(saveUserAdmin(user))
    };
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CreateUser)));