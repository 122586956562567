import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Button, Grid, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Typography} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {useDispatch} from "react-redux";
import {useParams} from "react-router";
import {onError} from "../../../store/actions/popupActions";
import moment from "moment";
import NumberFormat from "react-number-format";
import PaginatedTableActions from "../../Table/PaginatedTableActions";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    divPadding: {
        height: '50px',
    }
}));

const CapacityObligation = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const [search, setSearch] = useState({startDate: null, endDate: null});
    const [validation, setValidation] = useState({});
    const [capacityObligation, setCapacityObligation] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [direction, setDirection] = useState();
    const [orderBy, setOrderBy] = useState();

    const dateChangeHandler = (date, name) => {
        search[name] = date;
        setSearch({...search});
    }

    const doSearch = async (pageNumber, pageSize, orderBy, direction) => {
        if (isValid()) {
            let url = "/capacity/account/" + params.accountId + "/load/obligation?startDate=" + moment(search.startDate).format("YYYY-MM-DD") +
                "&endDate=" + moment(search.endDate).format("YYYY-MM-DD") + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber;
            if (orderBy) {
                url = url + "&orderBy=" + orderBy;
            }
            if (direction) {
                url = url + "&direction=" + direction;
            }
            await trackPromise(axios.get(url).then(response => {
                setCapacityObligation({...response.data});
            }).catch(error => {
                dispatch(onError(error))
            }))
        }
    }

    const isValid = () => {
        let isValid = true;
        if (!search.startDate) {
            validation.startDate = true;
            validation.startDateMessage = "Start Date is required";
            isValid = false;
        } else {
            validation.startDate = false;
            validation.startDateMessage = "";
        }
        if (!search.endDate) {
            validation.endDate = true;
            validation.endDateMessage = "End Date is required";
            isValid = false;
        } else {
            validation.endDate = false;
            validation.endDateMessage = "";
        }
        if (search.startDate && search.endDate) {
            if (search.endDate < search.startDate) {
                validation.endDate = true;
                validation.endDateMessage = "End Date must be after Start Date";
                isValid = false;
            } else {
                validation.endDate = false;
                validation.endDateMessage = "";
            }
        }
        setValidation({...validation});
        return isValid;
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        setOrderBy(column);
        setDirection(sortDirection);
        doSearch(0, pageSize, column, sortDirection);
    }

    const handlePageChange = (pageNumber, pageSize) => {
        setPageSize(pageSize);
        doSearch(pageNumber, pageSize, orderBy, direction);
    }

    const handleRowsPerPageChange = (e) => {
        setPageSize(e.target.value);
        doSearch(0, e.target.value, orderBy, direction);
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 30) {
            return 30;
        }
        if (rowsPerPage <= 31) {
            return 31;
        }
        return rowsPerPage;
    }

    return (
        <main className={classes.layout}>
            <Typography variant="h4" align="center">Capacity Obligation</Typography>
            <form onSubmit={(e) => {
                e.preventDefault();
                doSearch(0, pageSize)
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate"
                                                onChange={(date) => dateChangeHandler(date, "startDate")} value={search.startDate} fullWidth error={validation.startDate}
                                                autoOk={true} helperText={validation.startDateMessage} maxDate={search.endDate ? search.endDate : null}/>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate"
                                                onChange={(date) => dateChangeHandler(date, "endDate")} value={search.endDate} fullWidth error={validation.endDate}
                                                autoOk={true} helperText={validation.endDateMessage} minDate={search.startDate ? search.startDate : null}/>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6} sm={1}/>
                    <Grid item xs={6} sm={3}>
                        <Button type="submit" variant="outlined">Search</Button>
                    </Grid>
                </Grid>
            </form>
            <div className={classes.divPadding}/>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="center" style={{whiteSpace: 'nowrap', width: '90px'}}>
                            <TableSortLabel active={orderBy === "date"} direction={orderBy === "date" ? direction : 'asc'} onClick={() => handleSort("date")}>
                                Date
                                {orderBy === "date" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === "lse"} direction={orderBy === "lse" ? direction : 'asc'} onClick={() => handleSort("lse")}>
                                LSE
                                {orderBy === "lse" ? (<span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === "zoneName"} direction={orderBy === "zoneName" ? direction : 'asc'} onClick={() => handleSort("zoneName")}>
                                Zone
                                {orderBy === "zoneName" ? (
                                    <span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === "areaName"} direction={orderBy === "areaName" ? direction : 'asc'} onClick={() => handleSort("areaName")}>
                                Area
                                {orderBy === "areaName" ? (
                                    <span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === "uploadMw"} direction={orderBy === "uploadMw" ? direction : 'asc'} onClick={() => handleSort("uploadMw")}>
                                PLC (MWs)
                                {orderBy === "uploadMw" ? (
                                    <span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === "scalingFactor"} direction={orderBy === "scalingFactor" ? direction : 'asc'}
                                            onClick={() => handleSort("scalingFactor")}>
                                Scaling Factor
                                {orderBy === "scalingFactor" ? (
                                    <span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === "obligationPeakLoad"} direction={orderBy === "obligationPeakLoad" ? direction : 'asc'}
                                            onClick={() => handleSort("obligationPeakLoad")}>
                                Obligation Peak Load (MWs)
                                {orderBy === "obligationPeakLoad" ? (
                                    <span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === "finalZonalScalingFactor"} direction={orderBy === "finalZonalScalingFactor" ? direction : 'asc'}
                                            onClick={() => handleSort("finalZonalScalingFactor")}>
                                Zonal Scaling Factor
                                {orderBy === "finalZonalScalingFactor" ? (
                                    <span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === "forecastPoolRequirement"} direction={orderBy === "forecastPoolRequirement" ? direction : 'asc'}
                                            onClick={() => handleSort("forecastPoolRequirement")}>
                                Forecast Pool Requirement
                                {orderBy === "forecastPoolRequirement" ? (
                                    <span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell align="center">
                            <TableSortLabel active={orderBy === "dailyUcapObligation"} direction={orderBy === "dailyUcapObligation" ? direction : 'asc'}
                                            onClick={() => handleSort("dailyUcapObligation")}>
                                UCAP Obligation (MWs)
                                {orderBy === "dailyUcapObligation" ? (
                                    <span className={classes.visuallyHidden}>{direction === "desc" ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {capacityObligation && capacityObligation.results &&
                    capacityObligation.results.map(r => {
                        return (
                            <TableRow key={r.id}>
                                <TableCell align="center">{r.date}</TableCell>
                                <TableCell align="center">{r.lse}</TableCell>
                                <TableCell align="center">{r.zoneName}</TableCell>
                                <TableCell align="center">{r.areaName}</TableCell>
                                <TableCell align="center"><NumberFormat value={r.uploadMw} displayType='text' fixedDecimalScale={true} decimalScale={1}
                                                                        thousandSeparator/></TableCell>
                                <TableCell align="center"><NumberFormat value={r.scalingFactor} displayType='text' fixedDecimalScale={true} decimalScale={6}/></TableCell>
                                <TableCell align="center"><NumberFormat value={r.obligationPeakLoad} displayType='text' fixedDecimalScale={true} decimalScale={4}
                                                                        thousandSeparator/></TableCell>
                                <TableCell align="center"><NumberFormat value={r.finalZonalScalingFactor} displayType='text' fixedDecimalScale={true}
                                                                        decimalScale={6}/></TableCell>
                                <TableCell align="center"><NumberFormat value={r.forecastPoolRequirement} displayType='text' fixedDecimalScale={true}
                                                                        decimalScale={6}/></TableCell>
                                <TableCell align="center"><NumberFormat value={r.dailyUcapObligation} displayType='text' fixedDecimalScale={true} decimalScale={2}
                                                                        thousandSeparator/></TableCell>
                            </TableRow>
                        )
                    })
                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination count={capacityObligation.totalCount ? capacityObligation.totalCount : 0} onChangePage={handlePageChange}
                                         page={capacityObligation.pageNumber ? capacityObligation.pageNumber : 0}
                                         rowsPerPage={calculateRowsPerPage(capacityObligation.pageSize)} onChangeRowsPerPage={handleRowsPerPageChange}
                                         rowsPerPageOptions={[10, 30, 31]}
                                         colSpan={10} ActionsComponent={PaginatedTableActions}/>
                    </TableRow>
                </TableFooter>
            </Table>
        </main>
    );
}

export default CapacityObligation;