import {APPLICATION_CHOSEN} from "../actionTypes";

const initialState = {
    application: undefined
};

const chosenApplicationReducer = (state = initialState, action) => {
    switch(action.type) {
        case APPLICATION_CHOSEN:
            return {...state, application: action.application};
        default:
            return state;
    }
};

export default chosenApplicationReducer;