import React, {Component} from 'react';
import {retrieveDeliveryYearAuctions} from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import {withStyles, withTheme} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
});

class ViewDeliveryYearAuctions extends Component {

    componentDidMount() {
        if (!this.props.deliveryYearAuctions) {
            this.props.retrieveDeliveryYearAuctions();
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Delivery Year</TableCell>
                            <TableCell align="center">Auction Name</TableCell>
                            <TableCell align="center">Auction Open</TableCell>
                            <TableCell align="center">Auction Close</TableCell>
                            <TableCell align="center">Results Posted</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.deliveryYearAuctions &&
                        Object.keys(this.props.deliveryYearAuctions).sort((c1, c2) => c2.localeCompare(c1)).map(deliveryYear => {
                            return (
                                <React.Fragment key={deliveryYear}>
                                    <TableRow className={classes.tableRow} key={deliveryYear}
                                              onClick={() => this.props.history.push("/capacity/deliveryYearAuctions/" + deliveryYear.replace("/", "-"))}>
                                        <TableCell align="center" rowSpan={this.props.deliveryYearAuctions[deliveryYear].length + 1}>{deliveryYear}</TableCell>
                                    </TableRow>
                                    {this.props.deliveryYearAuctions[deliveryYear].map(auction => {
                                        return (
                                            <TableRow key={auction.id}>
                                                <TableCell align="center">{auction.auction}</TableCell>
                                                <TableCell align="center">{auction.auctionOpenDate}</TableCell>
                                                <TableCell align="center">{auction.auctionCloseDate}</TableCell>
                                                <TableCell align="center">{auction.resultsPostedDate}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </main>
        );
    }

}

const mapStateToProps = state => {
    return {
        deliveryYearAuctions: state.capacity.deliveryYearAuctions
    };
}

const mapDispatchToProps = dispatch => {
    return {
        retrieveDeliveryYearAuctions: () => dispatch(retrieveDeliveryYearAuctions())
    };
}

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ViewDeliveryYearAuctions)));