import {CLEAR_MATCH_ALERT, EXACT_MATCH, MULTIPLE_MATCHES, NO_MATCH} from "../sparkExchangeActionTypes";
import {ACCOUNT_CHANGED} from "../../actionTypes";

const initialState = {
    exactMatch: false,
    multipleMatches: false,
    noMatch: false,
    matchType: undefined,
    accountId: undefined,
}

const matchAlertReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXACT_MATCH:
            return {
                ...state,
                exactMatch: true,
                matchType: action.matchType,
                accountId: action.accountId
            };
        case MULTIPLE_MATCHES:
            return {
                ...state,
                multipleMatches: true,
                matchType: action.matchType,
                accountId: action.accountId
            };
        case NO_MATCH:
            return {...state, noMatch: true, matchType: action.matchType};
        case CLEAR_MATCH_ALERT:
            return initialState;
        case ACCOUNT_CHANGED:
            return initialState;
        default:
            return state;
    }
}

export default matchAlertReducer;