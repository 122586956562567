import jwt_decode from 'jwt-decode';
import moment from 'moment';
import {trackPromise} from 'react-promise-tracker';

import axios from '../../axios/AxiosInterceptors';
import {LOGOUT, AUTO_LOGIN_SUCCESS, AUTHENTICATION_FAILED} from '../actionTypes';
import {onError} from "./popupActions";

export const loginSuccess = (jwt, username, accounts, issuedAt, roles, superUser) => {
    return {
        type: AUTO_LOGIN_SUCCESS,
        jwt: jwt,
        username: username,
        accounts: accounts,
        issuedAt: issuedAt,
        roles: roles,
        superUser: superUser
    }
};

export const logoutSuccess = () => {
    return {
        type: LOGOUT
    };
};

export const authenticationFailed = () => {
    return {
        type: AUTHENTICATION_FAILED
    };
};

export const doAuthenticate = (usernamePassword) => {
    return async dispatch => {

        await trackPromise(axios.post("/auth/user/authenticate", JSON.stringify(usernamePassword)).then(async response => {
            let jwt = response.data;
            let decodedJwt = jwt_decode(jwt);
            let accountIds = decodedJwt.accountIds;
            let accounts = await findAccounts(accountIds, jwt);
            localStorage.setItem('jwt', jwt);
            let user = await findUser(decodedJwt.username, jwt);
            let roles = [...user.userAccountRoles, ...user.userApplicationRoles];
            return dispatch(loginSuccess(jwt, decodedJwt.username, accounts, decodedJwt.iat, roles, user.superUser));
        }).catch(
            error => {
                dispatch(authenticationFailed());
            }
        ));
    };
};

export const doAutoLogin = () => {
    return dispatch => {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            let decodedJwt = jwt_decode(jwt);
            if (moment.duration(moment().diff(moment(decodedJwt.iat * 1000))).minutes() > 60) {
                return dispatch(doLogout());
            }
            (async () => {
                let accounts = await findAccounts(decodedJwt.accountIds, jwt);
                await trackPromise(axios.get("/auth/users/" + decodedJwt.username).then(async response => {
                    let roles = [...response.data.userAccountRoles, ...response.data.userApplicationRoles];
                    let superUser = response.data.superUser;
                    return dispatch(loginSuccess(jwt, decodedJwt.username, accounts, decodedJwt.iat, roles, superUser));
                }));
            })();
        }
    };
};

export const stayLoggedIn = (username) => {
    return async dispatch => {
        await trackPromise(axios.get("/auth/users/" + username).then (response => {
            //Nothing to do here.
        }).catch(error => {
            dispatch(onError(error));
            dispatch(doLogout());
        }))
    }
}

export const doLogout = () => {
    return async dispatch => {
        localStorage.removeItem('jwt');
        await trackPromise(axios.post("/auth/user/logout"));
        return dispatch(logoutSuccess());
    };
};


async function findAccounts(accountIds, jwt) {
    let promises = [];
    for (let accountId of accountIds) {
        promises.push(axios.get("/account-service/v3/account/" + accountId));
    }
    let results = await Promise.all(promises);
    let accounts = [];
    for (let result of results) {
        accounts.push(result.data);
    }
    return accounts;
}

async function findUser(username, jwt) {
    let promise = axios.get("/auth/users/" + username);
    let result = await Promise.resolve(promise);
    return result.data;
}