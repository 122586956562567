import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {retrieveRequiredMilestones} from "../../../store/spark-exchange/actions/sparkExchangeActions";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const IncludeMilestoneSearch = (props) => {

    const search = useSelector(props.selector);
    const milestones = useSelector(state => state.sparkExchange.requiredMilestones)

    const dispatch = useDispatch();

    useEffect(() => {
        if (!milestones || milestones.length < 1) {
            dispatch(retrieveRequiredMilestones());
        }
        // eslint-disable-next-line
    }, [])

    const onMilestoneSearchChange = (date, value, milestone) => {
        dispatch({type: props.searchDispatch, value: {milestoneName: milestone.milestoneName, requiredDate: date}});
    }

    const findMilestoneValue = (milestone) => {
        const ms = search.requiredMilestones.filter(r => r.milestoneName === milestone.milestoneName);
        if(ms && ms.length === 1) {
            return moment(ms[0].requiredDate);
        }
        return null;
    }

    const onRemoveIconClick = () => {
        dispatch({type: props.searchDispatch});
        dispatch({type: props.removeDispatch, value: props.name});
    }


    return (
        <Grid item xs={6} sm={10} key="includeMilestoneSearch">
            <Box display="flex" flexWrap="nowrap">
                {milestones.map((milestone) => {
                    return (
                        <Box mr={4} key={milestone.id}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label={milestone.milestoneName} value={findMilestoneValue(milestone)}
                                                    onChange={(date, value) => onMilestoneSearchChange(date, value, milestone)} fullWidth autoOk />
                            </MuiPickersUtilsProvider>
                        </Box>
                    )
                })}
                <Box>
                    <IconButton onClick={onRemoveIconClick}>
                        <RemoveCircleOutlineIcon/>
                    </IconButton>
                </Box>
            </Box>
        </Grid>
    )
}

export default IncludeMilestoneSearch;