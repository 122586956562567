import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {retrieveDeliveryYearAuctions, retrieveZones} from "../../../store/capacity-exchange/actions/CapacityExchangeActions";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {onError} from "../../../store/actions/popupActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {TableCell, TableSortLabel} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import {calculateRowsPerPage} from "../../Table/PaginatedTableUtils";
import PaginatedTableActions from "../../Table/PaginatedTableActions";
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const ViewZonalAuctionResults = (props) => {

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const history = useHistory();
    const deliveryYearAuctions = useSelector(state => state.capacity.deliveryYearAuctions);
    const zones = useSelector(state => state.capacity.zones);
    const roles = useSelector(state => state.auth.roles)
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const [search, setSearch] = useState({deliveryYear: '', auction: '', zone: ''});
    const [zonalResults, setZonalResults] = useState();
    const [orderBy, setOrderBy] = useState();
    const [direction, setDirection] = useState();
    const [pageSize, setPageSize] = useState(10);
    const [hasModify, setHasModify] = useState(false);

    useEffect(() => {
        if (!deliveryYearAuctions) {
            dispatch(retrieveDeliveryYearAuctions());
        }
        retrieveZonalResults(search, 0, pageSize, orderBy, direction);
        setHasModify(hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_update_zonal_auction_result') ||
            hasPermissionForAccount(roles, chosenAccount.id, 'ROLE_delete_zonal_auction_result'))
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retrieveZonalResults = async (search, pageNumber, pageSize, orderBy, direction) => {
        let url = undefined;
        if (search.deliveryYear && search.auction && search.zone) {
            url = "/capacity/deliveryYear/" + search.deliveryYear.replace("/", "-") + "/auction/" + search.auction + "/zone/" + search.zone + "/zonalResult";
        } else if (search.deliveryYear && search.auction && !search.zone) {
            url = "/capacity/deliveryYear/" + search.deliveryYear.replace("/", "-") + "/auction/" + search.auction + "/zonalResult";
        } else if (search.deliveryYear && !search.auction && search.zone) {
            url = "/capacity/deliveryYear/" + search.deliveryYear.replace("/", "-") + "/zone/" + search.zone + "/zonalResult";
        } else if (search.deliveryYear && !search.auction && !search.zone) {
            url = "/capacity/deliveryYear/" + search.deliveryYear.replace("/", "-") + "/zonalResult";
        } else if (!search.deliveryYear && search.auction && search.zone) {
            url = "/capacity/auction/" + search.auction + "/zone/" + search.zone + "/zonalResult";
        } else if (!search.deliveryYear && search.auction && !search.zone) {
            url = "/capacity/auction/" + search.auction + "/zonalResult";
        } else if (!search.deliveryYear && !search.auction && search.zone) {
            url = "/capacity/zone/" + search.zone + "/zonalResult";
        }
        if (!url) {
            url = "/capacity/zonalResult";
        }
        url = url + "?pageNumber=" + pageNumber + "&pageSize=" + pageSize;
        if (orderBy) {
            url = url + "&orderBy=" + orderBy;
        }
        if (direction) {
            url = url + "&direction=" + direction;
        }
        await trackPromise(axios.get(url).then(response => setZonalResults(response.data))
            .catch(error => dispatch(onError(error))));
    }

    const renderDeliveryYearSelectBox = () => {
        if (deliveryYearAuctions) {
            return (
                <Autocomplete value={search.deliveryYear} options={['', ...Object.keys(deliveryYearAuctions).sort((c1, c2) => c2.localeCompare(c1))]}
                              getOptionLabel={(option) => option} id="deliveryYear"
                              renderInput={(params) => <TextField {...params} label="Delivery Year"/>}
                              onChange={(event, value) => onDeliveryYearSelectChange(event, value)}/>
            );
        }
    }

    const renderAuctionSelectBox = () => {
        return (
            <Autocomplete value={search.auction} options={['', 'BRA', 'FIRST', 'SECOND', 'THIRD']} getOptionLabel={(option) => option} id="auction"
                          renderInput={(params) => <TextField {...params} label="Auction"/>}
                          onChange={(event, value) => onAuctionSelectChange(event, value)}/>
        );
    }

    const renderZoneSelectBox = () => {
        if (zones && search.deliveryYear && zones.get(search.deliveryYear) && zones.get(search.deliveryYear).length > 0) {
            return (<Autocomplete value={search.zone} options={['', ...zones.get(search.deliveryYear).map(zone => zone.zoneName)].sort((c1, c2) => c1.localeCompare(c2))}
                                  getOptionLabel={(option) => option} id="zone" renderInput={(params) => <TextField {...params} label="Zone"/>}
                                  onChange={(event, value) => onZoneSelectChange(event, value)}/>
            )
        }
        return (<Autocomplete value={search.zone} options={['']} getOptionLabel={(option) => option} id="zone"
                              renderInput={(params) => <TextField {...params} label="Zone"/>}
                              onChange={(event, value) => onZoneSelectChange(event, value)}/>
        )
    }

    const onDeliveryYearSelectChange = (event, value) => {
        if (value) {
            search.deliveryYear = value;
            dispatch(retrieveZones(search.deliveryYear));
        } else {
            search.deliveryYear = '';
        }
        setSearch({...search});
        retrieveZonalResults(search, 0, pageSize, orderBy, direction);
    }

    const onAuctionSelectChange = (event, value) => {
        if (value) {
            search.auction = value;
        } else {
            search.auction = '';
        }
        setSearch({...search});
        retrieveZonalResults(search, 0, pageSize, orderBy, direction);
    }

    const onZoneSelectChange = (e, value) => {
        if (value) {
            search.zone = value;
        } else {
            search.zone = '';
        }
        setSearch({...search});
        retrieveZonalResults(search, 0, pageSize, orderBy, direction);
    }

    const handlePageChange = (pageNumber, pageSize) => {
        retrieveZonalResults(search, pageNumber, pageSize, orderBy, direction);
    }

    const handleRowsPerPageChange = (e) => {
        setPageSize(e.target.value);
        retrieveZonalResults(search, 0, e.target.value, orderBy, direction);
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        setOrderBy(column);
        setDirection(sortDirection);
        retrieveZonalResults(search, 0, pageSize, column, sortDirection);
    }

    const renderTable = () => {
        if (zonalResults) {
            if (Array.isArray(zonalResults.results)) {
                return (
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    <TableSortLabel active={orderBy === 'deliveryYear'} direction={orderBy === 'deliveryYear' ? direction : 'asc'}
                                                    onClick={() => handleSort("deliveryYear")}>
                                        Delivery Year
                                        {orderBy === 'deliveryYear' ? (
                                            <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel active={orderBy === 'auction'} direction={orderBy === 'auction' ? direction : 'asc'} onClick={() => handleSort("auction")}>
                                        Auction
                                        {orderBy === 'auction' ? (
                                            <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel active={orderBy === 'zone'} direction={orderBy === 'zone' ? direction : 'asc'} onClick={() => handleSort("zone")}>
                                        Zone
                                        {orderBy === 'zone' ? (
                                            <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel active={orderBy === 'coincidentPeakYear'} direction={orderBy === 'coincidentPeakYear' ? direction : 'asc'}
                                                    onClick={() => handleSort("coincidentPeakYear")}>
                                        Coincident Peak Year
                                        {orderBy === 'coincidentPeakYear' ? (
                                            <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel active={orderBy === 'coincidentPeakLoad'} direction={orderBy === 'coincidentPeakLoad' ? direction : 'asc'}
                                                    onClick={() => handleSort("coincidentPeakLoad")}>
                                        Coincident Peak Load (MWs)
                                        {orderBy === 'coincidentPeakLoad' ? (
                                            <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel active={orderBy === 'zonalPeakLoadForecast'} direction={orderBy === 'zonalPeakLoadForecast' ? direction : 'asc'}
                                                    onClick={() => handleSort("zonalPeakLoadForecast")}>
                                        Coincident Peak Load (MWs)
                                        {orderBy === 'zonalPeakLoadForecast' ? (
                                            <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel active={orderBy === 'peakLoadScalingFactor'} direction={orderBy === 'peakLoadScalingFactor' ? direction : 'asc'}
                                                    onClick={() => handleSort("peakLoadScalingFactor")}>
                                        Peak Load Scaling Factor
                                        {orderBy === 'peakLoadScalingFactor' ? (
                                            <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel active={orderBy === 'ucapObligation'} direction={orderBy === 'ucapObligation' ? direction : 'asc'}
                                                    onClick={() => handleSort("ucapObligation")}>
                                        UCAP Obligation (MWs)
                                        {orderBy === 'ucapObligation' ? (
                                            <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel active={orderBy === 'zonalCapacityPrice'} direction={orderBy === 'zonalCapacityPrice' ? direction : 'asc'}
                                                    onClick={() => handleSort("zonalCapacityPrice")}>
                                        Capacity Price ($/MW-day)
                                        {orderBy === 'zonalCapacityPrice' ? (
                                            <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel active={orderBy === 'netLoadPrice'} direction={orderBy === 'netLoadPrice' ? direction : 'asc'}
                                                    onClick={() => handleSort("netLoadPrice")}>
                                        Net Load Price ($/MW-day)
                                        {orderBy === 'netLoadPrice' ? (
                                            <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel active={orderBy === 'ctrCreditRate'} direction={orderBy === 'ctrCreditRate' ? direction : 'asc'}
                                                    onClick={() => handleSort("ctrCreditRate")}>
                                        CTR Credit Rate ($/MW-day)
                                        {orderBy === 'ctrCreditRate' ? (
                                            <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel active={orderBy === 'ctrSettlementRate'} direction={orderBy === 'ctrSettlementRate' ? direction : 'asc'}
                                                    onClick={() => handleSort("ctrSettlementRate")}>
                                        CTR Settlement Rate ($/MW-day)
                                        {orderBy === 'ctrSettlementRate' ? (
                                            <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {zonalResults.results.map(zonalResult => {
                                return (
                                    <TableRow key={zonalResult.id} className={hasModify ? classes.tableRow : undefined}
                                              onClick={hasModify ? (() => history.push("/capacity/zonalResult/" + zonalResult.id)) : undefined}>
                                        <TableCell align="center">{zonalResult.deliveryYear}</TableCell>
                                        <TableCell align="center">{zonalResult.auction}</TableCell>
                                        <TableCell align="center">{zonalResult.zone}</TableCell>
                                        <TableCell align="center">{zonalResult.coincidentPeakYear ?
                                            <NumberFormat value={zonalResult.coincidentPeakYear} displayType='text' fixedDecimalScale={true}
                                                          decimalScale={0}/> : '-'}</TableCell>
                                        <TableCell align="center">{zonalResult.coincidentPeakLoad ?
                                            <NumberFormat value={zonalResult.coincidentPeakLoad} displayType='text' fixedDecimalScale={true} decimalScale={1}
                                                          thousandSeparator/> : '-'}</TableCell>
                                        <TableCell align="center">{zonalResult.zonalPeakLoadForecast ?
                                            <NumberFormat value={zonalResult.zonalPeakLoadForecast} displayType='text' fixedDecimalScale={true}
                                                          decimalScale={1} thousandSeparator/> : '-'}</TableCell>
                                        <TableCell align="center">{zonalResult.peakLoadScalingFactor ?
                                            <NumberFormat value={zonalResult.peakLoadScalingFactor} displayType='text' fixedDecimalScale={true}
                                                          decimalScale={10}/> : '-'}</TableCell>
                                        <TableCell align="center">{zonalResult.ucapObligation ?
                                            <NumberFormat value={zonalResult.ucapObligation} displayType='text' fixedDecimalScale={true} decimalScale={1}
                                                          thousandSeparator/> : '-'}</TableCell>
                                        <TableCell align="center">{zonalResult.zonalCapacityPrice ?
                                            <NumberFormat value={zonalResult.zonalCapacityPrice} displayType='text' fixedDecimalScale={true} decimalScale={2}
                                                          thousandSeparator prefix="$"/> : '-'}</TableCell>
                                        <TableCell align="center">{zonalResult.netLoadPrice ?
                                            <NumberFormat value={zonalResult.netLoadPrice} displayType='text' fixedDecimalScale={true} decimalScale={2}
                                                          thousandSeparator prefix="$"/> : '-'}</TableCell>
                                        <TableCell align="center">{zonalResult.ctrCreditRate ?
                                            <NumberFormat value={zonalResult.ctrCreditRate} displayType='text' fixedDecimalScale={true} decimalScale={2}
                                                          thousandSeparator prefix="$"/> : '-'}</TableCell>
                                        <TableCell align="center">{zonalResult.ctrSettlementRate ?
                                            <NumberFormat value={zonalResult.ctrSettlementRate} displayType='text' fixedDecimalScale={true} decimalScale={2}
                                                          thousandSeparator prefix="$"/> : '-'}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={12}
                                                 count={zonalResults.totalCount ? zonalResults.totalCount : 0}
                                                 rowsPerPage={calculateRowsPerPage(zonalResults.pageSize)}
                                                 page={zonalResults.pageNumber ? zonalResults.pageNumber : 0}
                                                 onChangePage={handlePageChange} onChangeRowsPerPage={handleRowsPerPageChange} ActionsComponent={PaginatedTableActions}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                )
            } else {
                return (
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Delivery Year</TableCell>
                                <TableCell align="center">Auction</TableCell>
                                <TableCell align="center">Zone</TableCell>
                                <TableCell align="center">Coincident Peak Year</TableCell>
                                <TableCell align="center">Coincident Peak Load (MWs)</TableCell>
                                <TableCell align="center">Peak Load Forecast (MWs)</TableCell>
                                <TableCell align="center">Peak Load Scaling Factor</TableCell>
                                <TableCell align="center">UCAP Obligation (MWs)</TableCell>
                                <TableCell align="center">Capacity Price ($/MW-day)</TableCell>
                                <TableCell align="center">Net Load Price ($/MW-day)</TableCell>
                                <TableCell align="center">CTR Credit Rate ($/MW-day)</TableCell>
                                <TableCell align="center">CTR Settlement Rate ($/MW-day)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key={zonalResults.id} className={hasModify ? classes.tableRow : undefined}
                                      onClick={hasModify ? (() => history.push("/capacity/zonalResult/" + zonalResults.id)) : undefined}>
                                <TableCell>{zonalResults.deliveryYear}</TableCell>
                                <TableCell>{zonalResults.auction}</TableCell>
                                <TableCell>{zonalResults.zone}</TableCell>
                                <TableCell align="center">{zonalResults.coincidentPeakYear ?
                                    <NumberFormat value={zonalResults.coincidentPeakYear} displayType='text' fixedDecimalScale={true}
                                                  decimalScale={0}/> : '-'}</TableCell>
                                <TableCell align="center">{zonalResults.coincidentPeakLoad ?
                                    <NumberFormat value={zonalResults.coincidentPeakLoad} displayType='text' fixedDecimalScale={true} decimalScale={1}
                                                  thousandSeparator/> : '-'}</TableCell>
                                <TableCell align="center">{zonalResults.zonalPeakLoadForecast ?
                                    <NumberFormat value={zonalResults.zonalPeakLoadForecast} displayType='text' fixedDecimalScale={true}
                                                  decimalScale={1} thousandSeparator/> : '-'}</TableCell>
                                <TableCell align="center">{zonalResults.peakLoadScalingFactor ?
                                    <NumberFormat value={zonalResults.peakLoadScalingFactor} displayType='text' fixedDecimalScale={true}
                                                  decimalScale={10}/> : '-'}</TableCell>
                                <TableCell align="center">{zonalResults.ucapObligation ?
                                    <NumberFormat value={zonalResults.ucapObligation} displayType='text' fixedDecimalScale={true} decimalScale={1}
                                                  thousandSeparator/> : '-'}</TableCell>
                                <TableCell align="center">{zonalResults.zonalCapacityPrice ?
                                    <NumberFormat value={zonalResults.zonalCapacityPrice} displayType='text' fixedDecimalScale={true} decimalScale={2}
                                                  thousandSeparator prefix="$"/> : '-'}</TableCell>
                                <TableCell align="center">{zonalResults.netLoadPrice ?
                                    <NumberFormat value={zonalResults.netLoadPrice} displayType='text' fixedDecimalScale={true} decimalScale={2}
                                                  thousandSeparator prefix="$"/> : '-'}</TableCell>
                                <TableCell align="center">{zonalResults.ctrCreditRate ?
                                    <NumberFormat value={zonalResults.ctrCreditRate} displayType='text' fixedDecimalScale={true} decimalScale={2}
                                                  thousandSeparator prefix="$"/> : '-'}</TableCell>
                                <TableCell align="center">{zonalResults.ctrSettlementRate ?
                                    <NumberFormat value={zonalResults.ctrSettlementRate} displayType='text' fixedDecimalScale={true} decimalScale={2}
                                                  thousandSeparator prefix="$"/> : '-'}</TableCell>
                            </TableRow>
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination rowsPerPageOptions={[10, 25, 50]} colSpan={12} count={1} rowsPerPage={10} page={0}
                                                 onChangePage={handlePageChange} onChangeRowsPerPage={handleRowsPerPageChange} ActionsComponent={PaginatedTableActions}/>
                            </TableRow>
                        </TableFooter>
                    </Table>
                )
            }
        }
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                    {renderDeliveryYearSelectBox()}
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    {renderAuctionSelectBox()}
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    {renderZoneSelectBox()}
                </Grid>
            </Grid>
            {renderTable()}
        </main>
    )


}

export default ViewZonalAuctionResults;