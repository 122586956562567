import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles, withTheme} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";
import {cleanStringInput} from "../../../Utility/StringUtil";
import {trackPromise} from "react-promise-tracker";
import axios from "../../../axios/AxiosInterceptors";
import {onError, onSuccess} from "../../../store/actions/popupActions";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPassword: '',
            password: '',
            passwordConfirm: ''
        }
    }

    onInputChangeHandler = (e) => {
        let state = {...this.state};
        state[e.target.name] = e.target.value;
        this.setState(state);
    }

    save = async () => {
        if (this.state.password === this.state.passwordConfirm) {
            let udpatedPassword = {currentPassword: this.state.currentPassword, password: this.state.password};
            await trackPromise(axios.put("/auth/users/" + this.props.username + "/password", JSON.stringify(udpatedPassword)).then(response => {
                this.props.onSuccess("Password updated successfully");
                this.props.history.push("/user-management/users/" + this.props.username);
            }).catch(error => {
                this.props.onError(error);
            }));
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    this.save()
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="Current Password" name="currentPassword" value={cleanStringInput(this.state.currentPassword)} type='password'
                                       onChange={this.onInputChangeHandler}
                                       fullWidth/>
                        </Grid>
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="Password" name="password" value={cleanStringInput(this.state.password)} type='password' onChange={this.onInputChangeHandler}
                                       fullWidth/>
                        </Grid>
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={4}>
                            <TextField label="Confirm Password" name="passwordConfirm" value={cleanStringInput(this.state.passwordConfirm)} type='password'
                                       onChange={this.onInputChangeHandler}
                                       fullWidth/>
                        </Grid>
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={4}/>
                        <Grid item xs={6} sm={4}>
                            <Button type="submit" fullWidth>Submit</Button>
                        </Grid>
                        <Grid item xs={6} sm={4}/>
                    </Grid>
                </form>
            </main>
        );
    }

}

const mapStateToProps = state => {
    return {
        username: state.auth.username
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onError: (error) => dispatch(onError(error)),
        onSuccess: (message) => dispatch(onSuccess(message))
    };
}

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ChangePassword)));