import React, {Component} from "react";
import {connect} from 'react-redux';

import {withStyles, withTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import {createRole} from '../../../store/user-management/actions/accountRoleActions';
import CreateRole from "./CreateRole";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

class CreateAccountRole extends Component {

    onSave = (role) => {
        let accountId = this.props.match.params.accountId;
        role.accountId = accountId;
        this.props.createAccountRole(accountId, role);
        this.props.history.goBack();
    }

    onBack = () => {
        this.props.history.goBack();
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <Typography variant="h3" align="center">Create New Account Role</Typography>
                <CreateRole onSaveFunction={this.onSave} />
            </main>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        createAccountRole: (accountId, role) => dispatch(createRole(accountId, role))
    };
};

export default withTheme(withStyles(styles)(connect(null, mapDispatchToProps)(CreateAccountRole)));