import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import {List, SwipeableDrawer} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";

import {APPLICATION_CHOSEN} from "../../store/actionTypes";
import {hasApplicationAccessForCurrentAccount} from "../../Utility/PermissionsUtil";

const ApplicationDrawer = (props) => {

    const [drawerOpen, setDrawerOpen] = useState(false);
    const roles = useSelector(state => state.auth.roles);
    const chosenAccount = useSelector(state => state.chosenAccount.account);


    const dispatch = useDispatch();

    const classes = (props);
    const history = useHistory();

    const handleDrawerClick = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <IconButton onClick={handleDrawerClick}>
            <MenuIcon/>
            <SwipeableDrawer anchor='left' onOpen={handleDrawerClick} onClose={handleDrawerClose} open={drawerOpen}>
                <List className={classes.list}>
                    {hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, "account-service") &&
                    <ListItem button key='AccountManagement' name="account-management" onClick={() => {
                        dispatch({type: APPLICATION_CHOSEN, application: 'account-management'});
                        history.push("/account-management");
                    }}>
                        <ListItemText>Account Management</ListItemText>
                    </ListItem>
                    }
                    {hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, "capacity-exchange") &&
                    <ListItem button key='CapacityExchange' name="capacity-exchange" onClick={() => {
                        dispatch({type: APPLICATION_CHOSEN, application: 'capacity-exchange'});
                        history.push("/capacity/capacityTransferRights/create");
                    }}>
                        <ListItemText>Capacity Exchange</ListItemText>
                    </ListItem>
                    }
                    {hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, 'pds-service') &&
                    <ListItem button key='PeakDaySignalService' name="pds-service" onClick={() => {
                        dispatch({type: APPLICATION_CHOSEN, application: 'pds-service'});
                        history.push("/pds");
                    }}>
                        <ListItemText>Peak Day Signal</ListItemText>
                    </ListItem>
                    }
                    {hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, "spark-exchange") &&
                    <ListItem button key='SparkExchange' name='spark-exchange' onClick={() => {
                        dispatch({type: APPLICATION_CHOSEN, application: 'spark-exchange'});
                        history.push("/spark-exchange");
                    }}>
                        <ListItemText>Spark Exchange</ListItemText>
                    </ListItem>
                    }
                    {hasApplicationAccessForCurrentAccount(roles, chosenAccount.id, "user-service") &&
                    <ListItem button key='UserManagement' name='user-management' onClick={() => {
                        dispatch({type: APPLICATION_CHOSEN, application: 'user-management'});
                        history.push("/user-management");
                    }}>
                        <ListItemText>User Management</ListItemText>
                    </ListItem>
                    }
                </List>
            </SwipeableDrawer>
        </IconButton>
    );
};

export default ApplicationDrawer;