import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import {FormGroup, FormHelperText} from "@material-ui/core";

const UnitContingentMonthlyOutput = (props) => {

    const {bidOffer, isEdit} = props;
    const [monthlyQuantity, setMonthlyQuantity] = useState('');
    const [monthlyDeviation, setMonthlyDeviation] = useState('')
    const [curePeriod, setCurePeriod] = useState(12);
    const [monthlyOutputs, setMonthlyOutputs] = useState([{month: 1}, {month: 2}, {month: 3}, {month: 4}, {month: 5}, {month: 6}, {month: 7}, {month: 8}, {month: 9}, {month: 10}, {month: 11}, {month: 12}]);
    const [validation, setValidation] = useState([]);

    useEffect(() => {
        if (props.validation) {
            setValidation(props.validation);
        }
        if (props.bidOffer && props.bidOffer.unitContingentMonthlyOutputs) {
            setMonthlyOutputs([...props.bidOffer.unitContingentMonthlyOutputs]);
        }
        if (props.bidOffer && props.bidOffer.quantityMw) {
            setMonthlyQuantity(props.bidOffer.quantityMw);
        }
        if (props.bidOffer && props.bidOffer.monthlyDeviation) {
            setMonthlyDeviation(props.bidOffer.monthlyDeviation);
        }
        if(props.bidOffer && props.bidOffer.unitDeviationCurePeriod) {
            setCurePeriod(props.bidOffer.unitDeviationCurePeriod);
        }
        bidOffer.unitDeviationCurePeriod = curePeriod;
        if(props.inputChangeHandler) {
            props.inputChangeHandler(bidOffer);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.validation])


    const onQuantityChangeHandler = (e) => {
        setMonthlyQuantity(e.target.value);
        let outputs = monthlyOutputs;
        if(outputs && outputs.length === 12) {
            for (let output of outputs) {
                output.targetMwhs = e.target.value;
                if (monthlyDeviation) {
                    output.minMwhs = (1 - (monthlyDeviation / 100)) * e.target.value;
                    output.maxMwhs = (1 + (monthlyDeviation / 100)) * e.target.value;
                }
            }
        } else if(outputs && outputs.length === 0) {
            for(let i = 0; i < 12; i++) {
                let output = {month: i + 1, targetMwhs: e.target.value};
                if (monthlyDeviation) {
                    output.minMwhs = (1 - (monthlyDeviation / 100)) * e.target.value;
                    output.maxMwhs = (1 + (monthlyDeviation / 100)) * e.target.value;
                }
                outputs.push(output);
            }
        }
        bidOffer.quantityMw = e.target.value;
        bidOffer.unitContingentMonthlyOutputs = outputs;
        setMonthlyOutputs([...outputs]);
        if (props.inputChangeHandler) {
            props.inputChangeHandler(bidOffer);
        }
    }

    const onDeviationChangeHandler = (e) => {
        setMonthlyDeviation(e.target.value);
        if (monthlyQuantity) {
            let outputs = monthlyOutputs;
            for (let output of outputs) {
                output.minMwhs = (1 - (e.target.value / 100)) * output.targetMwhs;
                output.maxMwhs = (1 + (e.target.value / 100)) * output.targetMwhs;
            }
            setMonthlyOutputs([...outputs]);
            bidOffer.unitContingentMonthlyOutputs = outputs;
        }
        bidOffer.monthlyDeviation = e.target.value;
        if (props.inputChangeHandler) {
            props.inputChangeHandler(bidOffer);
        }
    }

    const onCurePeriodChangeHandler = (e) => {
        setCurePeriod(e.target.value);
        bidOffer.unitDeviationCurePeriod = e.target.value;
        if (props.inputChangeHandler) {
            props.inputChangeHandler(bidOffer);
        }
    }

    const onMonthlyChangeHandler = (e, month) => {
        let outputs = monthlyOutputs;
        if (outputs.length === 0) {
            let output = {};
            output.month = month;
            output[e.target.name] = e.target.value;
            outputs.push(output);
        } else {
            for (let output of outputs) {
                if (output.month === month) {
                    output[e.target.name] = e.target.value;
                    if (e.target.name === 'targetMwhs' && monthlyDeviation) {
                        output.minMwhs = (1 - (monthlyDeviation / 100)) * e.target.value;
                        output.maxMwhs = (1 + (monthlyDeviation / 100)) * e.target.value;
                    }
                }
            }
        }
        bidOffer.unitContingentMonthlyOutputs = monthlyOutputs;
        setMonthlyOutputs([...outputs]);
        if (props.inputChangeHandler) {
            props.inputChangeHandler(bidOffer);
        }
    }

    const findMonthlyValue = (month) => {
        for (let monthlyOutput of monthlyOutputs) {
            if (monthlyOutput.month === month) {
                return monthlyOutput;
            }
        }
        return {};
    }

    const isValidationError = (month) => {
        if (!validation || !validation.unitContingentMonthlyOutputs || validation.unitContingentMonthlyOutputs.length === 0) {
            return false;
        }
        for (let error of validation.unitContingentMonthlyOutputs) {
            if (month === error.month) {
                return error.error;
            }
        }
        return false;
    }

    const findValidationErrorMessage = (month) => {
        if (!validation || !validation.unitContingentMonthlyOutputs || validation.unitContingentMonthlyOutputs.length === 0) {
            return '';
        }
        for (let error of validation.unitContingentMonthlyOutputs) {
            if (month === error.month) {
                return error.message;
            }
        }
        return '';
    }


    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Monthly Quantity" name="monthlyQuantity" value={monthlyQuantity} onChange={onQuantityChangeHandler}
                               fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                    }} disabled={!isEdit}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <TextField label="Monthly Deviation" name="monthlyDeviation" value={monthlyDeviation} onChange={onDeviationChangeHandler}
                               fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                    }} disabled={!isEdit}/>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs-6 sm={3}>
                    <TextField label="Cure Period" name="curePeriod" value={curePeriod} onChange={onCurePeriodChangeHandler}
                               fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isEdit: isEdit}
                    }} disabled={!isEdit} InputLabelProps={{shrink: !!curePeriod}}/>
                </Grid>
            </Grid>
            <FormControl fullWidth variant="outlined" style={{paddingTop: 25, paddingLeft: 125}} required error={isValidationError(1)}>
                <FormLabel component="legend">January</FormLabel>
                <FormGroup row>
                    <TextField label="Min MWHs" name="minMwhs" value={findMonthlyValue(1).minMwhs}
                               onChange={(e) => onMonthlyChangeHandler(e, 1)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label=" Target MWHs" name="targetMwhs" value={findMonthlyValue(1).targetMwhs} onChange={(e) => onMonthlyChangeHandler(e, 1)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label="Max MWHs" name="maxMwhs" value={findMonthlyValue(1).maxMwhs} onChange={(e) => onMonthlyChangeHandler(e, 1)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                </FormGroup>
                <FormHelperText>{findValidationErrorMessage(1)}</FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined" style={{paddingTop: 25, paddingLeft: 125}} required error={isValidationError(2)}>
                <FormLabel component="legend">February</FormLabel>
                <FormGroup row>
                    <TextField label="Min MWHs" name="minMwhs" value={findMonthlyValue(2).minMwhs}
                               onChange={(e) => onMonthlyChangeHandler(e, 2)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label=" Target MWHs" name="targetMwhs" value={findMonthlyValue(2).targetMwhs} onChange={(e) => onMonthlyChangeHandler(e, 2)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label="Max MWHs" name="maxMwhs" value={findMonthlyValue(2).maxMwhs} onChange={(e) => onMonthlyChangeHandler(e, 2)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                </FormGroup>
                <FormHelperText>{findValidationErrorMessage(2)}</FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined" style={{paddingTop: 25, paddingLeft: 125}} required error={isValidationError(3)}>
                <FormLabel component="legend">March</FormLabel>
                <FormGroup row>
                    <TextField label="Min MWHs" name="minMwhs" value={findMonthlyValue(3).minMwhs}
                               onChange={(e) => onMonthlyChangeHandler(e, 3)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label=" Target MWHs" name="targetMwhs" value={findMonthlyValue(3).targetMwhs} onChange={(e) => onMonthlyChangeHandler(e, 3)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label="Max MWHs" name="maxMwhs" value={findMonthlyValue(3).maxMwhs} onChange={(e) => onMonthlyChangeHandler(e, 3)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                </FormGroup>
                <FormHelperText>{findValidationErrorMessage(3)}</FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined" style={{paddingTop: 25, paddingLeft: 125}} required error={isValidationError(4)}>
                <FormLabel component="legend">April</FormLabel>
                <FormGroup row>
                    <TextField label="Min MWHs" name="minMwhs" value={findMonthlyValue(4).minMwhs}
                               onChange={(e) => onMonthlyChangeHandler(e, 4)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label=" Target MWHs" name="targetMwhs" value={findMonthlyValue(4).targetMwhs} onChange={(e) => onMonthlyChangeHandler(e, 4)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label="Max MWHs" name="maxMwhs" value={findMonthlyValue(4).maxMwhs} onChange={(e) => onMonthlyChangeHandler(e, 4)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                </FormGroup>
                <FormHelperText>{findValidationErrorMessage(4)}</FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined" style={{paddingTop: 25, paddingLeft: 125}} required error={isValidationError(5)}>
                <FormLabel component="legend">May</FormLabel>
                <FormGroup row>
                    <TextField label="Min MWHs" name="minMwhs" value={findMonthlyValue(5).minMwhs}
                               onChange={(e) => onMonthlyChangeHandler(e, 5)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label=" Target MWHs" name="targetMwhs" value={findMonthlyValue(5).targetMwhs} onChange={(e) => onMonthlyChangeHandler(e, 5)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label="Max MWHs" name="maxMwhs" value={findMonthlyValue(5).maxMwhs} onChange={(e) => onMonthlyChangeHandler(e, 5)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                </FormGroup>
                <FormHelperText>{findValidationErrorMessage(5)}</FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined" style={{paddingTop: 25, paddingLeft: 125}} required error={isValidationError(6)}>
                <FormLabel component="legend">June</FormLabel>
                <FormGroup row>
                    <TextField label="Min MWHs" name="minMwhs" value={findMonthlyValue(6).minMwhs}
                               onChange={(e) => onMonthlyChangeHandler(e, 6)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label=" Target MWHs" name="targetMwhs" value={findMonthlyValue(6).targetMwhs} onChange={(e) => onMonthlyChangeHandler(e, 6)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label="Max MWHs" name="maxMwhs" value={findMonthlyValue(6).maxMwhs} onChange={(e) => onMonthlyChangeHandler(e, 6)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                </FormGroup>
                <FormHelperText>{findValidationErrorMessage(6)}</FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined" style={{paddingTop: 25, paddingLeft: 125}} required error={isValidationError(7)}>
                <FormLabel component="legend">July</FormLabel>
                <FormGroup row>
                    <TextField label="Min MWHs" name="minMwhs" value={findMonthlyValue(7).minMwhs}
                               onChange={(e) => onMonthlyChangeHandler(e, 7)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label=" Target MWHs" name="targetMwhs" value={findMonthlyValue(7).targetMwhs} onChange={(e) => onMonthlyChangeHandler(e, 7)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label="Max MWHs" name="maxMwhs" value={findMonthlyValue(7).maxMwhs} onChange={(e) => onMonthlyChangeHandler(e, 7)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                </FormGroup>
                <FormHelperText>{findValidationErrorMessage(7)}</FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined" style={{paddingTop: 25, paddingLeft: 125}} required error={isValidationError(8)}>
                <FormLabel component="legend">August</FormLabel>
                <FormGroup row>
                    <TextField label="Min MWHs" name="minMwhs" value={findMonthlyValue(8).minMwhs}
                               onChange={(e) => onMonthlyChangeHandler(e, 8)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label=" Target MWHs" name="targetMwhs" value={findMonthlyValue(8).targetMwhs} onChange={(e) => onMonthlyChangeHandler(e, 8)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label="Max MWHs" name="maxMwhs" value={findMonthlyValue(8).maxMwhs} onChange={(e) => onMonthlyChangeHandler(e, 8)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                </FormGroup>
                <FormHelperText>{findValidationErrorMessage(8)}</FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined" style={{paddingTop: 25, paddingLeft: 125}} required error={isValidationError(9)}>
                <FormLabel component="legend">September</FormLabel>
                <FormGroup row>
                    <TextField label="Min MWHs" name="minMwhs" value={findMonthlyValue(9).minMwhs}
                               onChange={(e) => onMonthlyChangeHandler(e, 9)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label=" Target MWHs" name="targetMwhs" value={findMonthlyValue(9).targetMwhs} onChange={(e) => onMonthlyChangeHandler(e, 9)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label="Max MWHs" name="maxMwhs" value={findMonthlyValue(9).maxMwhs} onChange={(e) => onMonthlyChangeHandler(e, 9)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                </FormGroup>
                <FormHelperText>{findValidationErrorMessage(9)}</FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined" style={{paddingTop: 25, paddingLeft: 125}} required error={isValidationError(10)}>
                <FormLabel component="legend">October</FormLabel>
                <FormGroup row>
                    <TextField label="Min MWHs" name="minMwhs" value={findMonthlyValue(10).minMwhs}
                               onChange={(e) => onMonthlyChangeHandler(e, 10)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label=" Target MWHs" name="targetMwhs" value={findMonthlyValue(10).targetMwhs} onChange={(e) => onMonthlyChangeHandler(e, 10)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label="Max MWHs" name="maxMwhs" value={findMonthlyValue(10).maxMwhs} onChange={(e) => onMonthlyChangeHandler(e, 10)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                </FormGroup>
                <FormHelperText>{findValidationErrorMessage(10)}</FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined" style={{paddingTop: 25, paddingLeft: 125}} required error={isValidationError(11)}>
                <FormLabel component="legend">November</FormLabel>
                <FormGroup row>
                    <TextField label="Min MWHs" name="minMwhs" value={findMonthlyValue(11).minMwhs}
                               onChange={(e) => onMonthlyChangeHandler(e, 11)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label=" Target MWHs" name="targetMwhs" value={findMonthlyValue(11).targetMwhs} onChange={(e) => onMonthlyChangeHandler(e, 11)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label="Max MWHs" name="maxMwhs" value={findMonthlyValue(11).maxMwhs} onChange={(e) => onMonthlyChangeHandler(e, 11)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                </FormGroup>
                <FormHelperText>{findValidationErrorMessage(11)}</FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined" style={{paddingTop: 25, paddingLeft: 125}} required error={isValidationError(12)}>
                <FormLabel component="legend">December</FormLabel>
                <FormGroup row>
                    <TextField label="Min MWHs" name="minMwhs" value={findMonthlyValue(12).minMwhs}
                               onChange={(e) => onMonthlyChangeHandler(e, 12)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label=" Target MWHs" name="targetMwhs" value={findMonthlyValue(12).targetMwhs} onChange={(e) => onMonthlyChangeHandler(e, 12)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                    <TextField label="Max MWHs" name="maxMwhs" value={findMonthlyValue(12).maxMwhs} onChange={(e) => onMonthlyChangeHandler(e, 12)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="start">MWhs</InputAdornment>,
                                   inputComponent: GenericNumberFormat,
                                   inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: isEdit}
                               }} style={{paddingRight: 25}} required/>
                </FormGroup>
                <FormHelperText>{findValidationErrorMessage(12)}</FormHelperText>
            </FormControl>
        </React.Fragment>
    );

}

export default UnitContingentMonthlyOutput;