import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import store from './store/store';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#388e3c' },
        secondary: { main: '#607d8b' },
        spacing: 4,
    }
});

const app = (
    <ThemeProvider theme={theme} >
        <Provider store={store}>
            <HashRouter>
                <App />
            </HashRouter>
        </Provider>
    </ThemeProvider>
);

ReactDOM.render(app, document.getElementById('root'));
registerServiceWorker();
