import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from '@material-ui/core/styles';

import CheckIcon from "@material-ui/icons/Check";
import BlockIcon from "@material-ui/icons/Block";
import PublishIcon from "@material-ui/icons/Publish";
import {TableFooter, TableSortLabel} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import PaginatedTableActions from "../../Table/PaginatedTableActions";
import NumberFormat from "react-number-format";
import {useHistory} from "react-router";
import {retrieveBuyBids} from "../../../store/spark-exchange/actions/buyBidActions";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const BuyBidTable = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const buyBids = useSelector(state => state.buyBid.buyBids);
    const history = useHistory();
    const [orderBy, setOrderBy] = useState();
    const [direction, setDirection] = useState();
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        doRetrieveBuyBids(0, pageSize);
        // eslint-disable-next-line
    }, [props.accountId]);

    const doRetrieveBuyBids = (pageNumber, pageSize) => {
        setPageSize(pageSize);
        dispatch(retrieveBuyBids(props.accountId, pageNumber, pageSize, orderBy, direction));
    }

    const handlePageChange = (pageNumber, pageSize) => {
        setPageSize(pageSize);
        doRetrieveBuyBids(pageNumber, pageSize);
    }

    const handleRowsPerPageChange = (e) => {
        setPageSize(e.target.value);
        doRetrieveBuyBids(0, e.target.value);
    }

    const calculateRowsPerPage = (rowsPerPage) => {
        if (!rowsPerPage) {
            return 10;
        }
        if (rowsPerPage <= 10) {
            return 10;
        }
        if (rowsPerPage <= 25) {
            return 25;
        }
        if (rowsPerPage <= 50) {
            return 50;
        }
        return rowsPerPage;
    }

    const handleSort = (column) => {
        let sortDirection = column === orderBy && direction === 'asc' ? 'desc' : 'asc'
        setOrderBy(column);
        setDirection(sortDirection);
        dispatch(retrieveBuyBids(props.accountId, 0, pageSize, column, sortDirection));
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'startDate'} direction={orderBy === 'startDate' ? direction : 'asc'} onClick={() => handleSort("startDate")}>
                            Start Date
                            {orderBy === 'startDate' ? (<span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'quantityMw'} direction={orderBy === 'quantityMw' ? direction : 'asc'} onClick={() => handleSort("quantityMw")}>
                            Quantity (MWs)
                            {orderBy === 'quantityMw' ? (<span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'termLengthMonth'} direction={orderBy === 'termLengthMonth' ? direction : 'asc'}
                                        onClick={() => handleSort("termLengthMonth")}>
                            Term Length (Months)
                            {orderBy === 'termLengthMonth' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'fixedPaymentMwday'} direction={orderBy === 'fixedPaymentMwday' ? direction : 'asc'}
                                        onClick={() => handleSort("fixedPaymentMwday")}>
                            Capacity Payment ($/MW-day)
                            {orderBy === 'fixedPaymentMwday' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'variablePaymentMwh'} direction={orderBy === 'variablePaymentMwh' ? direction : 'asc'}
                                        onClick={() => handleSort("variablePaymentMwh")}>
                            Energy Payment ($/MWh)
                            {orderBy === 'variablePaymentMwh' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'deliveryPoint'} direction={orderBy === 'deliveryPoint' ? direction : 'asc'}
                                        onClick={() => handleSort("deliveryPoint")}>
                            Delivery Point
                            {orderBy === 'deliveryPoint' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'includePhysicalAttributes'} direction={orderBy === 'includePhysicalAttributes' ? direction : 'asc'}
                                        onClick={() => handleSort("includePhysicalAttributes")}>
                            Include Physical Attributes
                            {orderBy === 'includePhysicalAttributes' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'includeEnvironmentalAttributes'} direction={orderBy === 'includeEnvironmentalAttributes' ? direction : 'asc'}
                                        onClick={() => handleSort("includeEnvironmentalAttributes")}>
                            Include Environmental Attributes
                            {orderBy === 'includeEnvironmentalAttributes' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'published'} direction={orderBy === 'published' ? direction : 'asc'}
                                        onClick={() => handleSort("published")}>
                            Published
                            {orderBy === 'published' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                        <TableSortLabel active={orderBy === 'matched'} direction={orderBy === 'matched' ? direction : 'asc'}
                                        onClick={() => handleSort("matched")}>
                            Matched
                            {orderBy === 'matched' ? (
                                <span className={classes.visuallyHidden}>{direction === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>) : null}
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {buyBids.results &&
                buyBids.results.map(buyBid => {
                    return (
                        <TableRow key={buyBid.id} className={classes.tableRow}
                                  onClick={() => history.push("/spark-exchange/account/" + buyBid.accountId + "/buy-bids/" + buyBid.id)}>
                            <TableCell align="center">{buyBid.startDate}</TableCell>
                            <TableCell align="center"><NumberFormat value={buyBid.quantityMw} displayType={'text'} fixedDecimalScale={true} decimalScale={2}/></TableCell>
                            <TableCell align="center"><NumberFormat value={buyBid.termLengthMonth} displayType={'text'} fixedDecimalScale={true} decimalScale={0}/></TableCell>
                            <TableCell align="center"><NumberFormat value={buyBid.fixedPaymentMwday} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                                                    prefix="$"/></TableCell>
                            <TableCell align="center"><NumberFormat value={buyBid.variablePaymentMwh} displayType={'text'} fixedDecimalScale={true} decimalScale={2}
                                                                    prefix="$"/></TableCell>
                            <TableCell align="center">{buyBid.deliveryPoint}</TableCell>
                            <TableCell align="center">{buyBid.includePhysicalAttributes ? <CheckIcon/> : <BlockIcon/>}</TableCell>
                            <TableCell align="center">{buyBid.includeEnvironmentalAttributes ? <CheckIcon/> : <BlockIcon/>}</TableCell>
                            <TableCell align="center">{buyBid.published && <PublishIcon/>}</TableCell>
                            <TableCell align="center">{buyBid.matched && <CheckIcon/>}</TableCell>
                        </TableRow>);
                })}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination count={buyBids.totalCount ? buyBids.totalCount : 0} onChangePage={handlePageChange}
                                     page={buyBids.pageNumber ? buyBids.pageNumber : 0}
                                     rowsPerPage={calculateRowsPerPage(buyBids.pageSize)} onChangeRowsPerPage={handleRowsPerPageChange}
                                     rowsPerPageOptions={[10, 25, 50]} colSpan={9} ActionsComponent={PaginatedTableActions}/>
                </TableRow>
            </TableFooter>
        </Table>
    )
}

export default BuyBidTable;