import {RETRIEVE_ACCOUNTS_SUCCESS} from "../accountManagementActionTypes";
import {ACCOUNT_CHANGED} from "../../actionTypes";

const initialState = {
    accounts: []
}

const retrieveAccounts = (state, action) => {
    return {...state, accounts: action.accounts};
}

const accountsReducer = (state = initialState, action) => {
    switch(action.type) {
        case RETRIEVE_ACCOUNTS_SUCCESS:
            return retrieveAccounts(state, action);
        case ACCOUNT_CHANGED:
            return initialState;
        default:
            return state;
    }
}

export default accountsReducer;