import {SAVE_BUY_BID_PROFILE_SUCCESS, RETRIEVE_BUY_BID_PROFILES_SUCCESS, UPDATE_BUY_BID_PROFILE_SUCCESS} from '../sparkExchangeActionTypes';

const initialState = {
    buyBidProfiles: []
}

const saveBuyBidProfile = (state, action) => {
    return {...state, buyBidProfiles: [...state.buyBidProfiles, action.buyBidProfile]}
}

const retrieveBuyBidProfiles = (state, action) => {
    return {...state, buyBidProfiles: action.buyBidProfiles};
}

const updateBuyBidProfiles = (state, action) => {
    let profiles = state.buyBidProfiles;
    let index = -1;
    for(let i = 0; i < profiles.length; i++) {
        if(profiles[i].id === action.buyBidProfile.id) {
            index = i;
        }
    }
    if(index !== -1) {
        profiles.splice(index, 1);
    }
    return {...state, buyBidProfiles: [...profiles, action.buyBidProfile]};
}

const buyBidProfileReducer = (state = initialState, action) => {
    switch(action.type) {
        case SAVE_BUY_BID_PROFILE_SUCCESS:
            return saveBuyBidProfile(state, action);
        case RETRIEVE_BUY_BID_PROFILES_SUCCESS:
            return retrieveBuyBidProfiles(state, action);
        case UPDATE_BUY_BID_PROFILE_SUCCESS:
            return updateBuyBidProfiles(state, action);
        default:
            return state;
    }
}

export default buyBidProfileReducer;