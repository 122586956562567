import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Grid from '@material-ui/core/Grid';
import {TextField} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Box from "@material-ui/core/Box";

const DeliveryPointSearch = (props) => {

    const dispatch = useDispatch();
    const search = useSelector(props.selector);

    const cleanStringInput = (input) => {
        if (input) {
            return input;
        } else {
            return '';
        }
    };

    const onInputChange = (e) => {
        dispatch({type: props.searchDispatch, value: e.target.value});
    }

    const onRemoveIconClick = () => {
        dispatch({type: props.searchDispatch, value: ''});
        dispatch({type: props.removeDispatch, value:props.name})
    }

    return (
        <Grid item xs={6} sm={3}>
            <Box display="flex" flexWrap="nowrap">
                <Box>
                    <TextField label="Delivery Point" name="deliveryPoint" value={cleanStringInput(search.deliveryPoint)} onChange={onInputChange} fullWidth/>
                </Box>
                <Box>
                    <IconButton onClick={onRemoveIconClick}>
                        <RemoveCircleOutlineIcon/>
                    </IconButton>
                </Box>
            </Box>
        </Grid>
    )

}

export default DeliveryPointSearch;