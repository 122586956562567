import React from 'react';
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";

const GeneratorTypeSearch = (props) => {

    const dispatch = useDispatch();
    const availableGeneratorTypes = ['Bio-Mass', 'Coal', 'Fuel Cell', 'Gas', 'Hydro', 'Nuclear', 'Pumped Hydro', 'Solar', 'Storage', 'Waste Energy', 'Wind'];

    const onInputChange = (event, value) => {
        dispatch({type: props.searchDispatch, value: value});
    }

    const onRemoveIconClick = () => {
        dispatch({type: props.searchDispatch, value: []});
        dispatch({type: props.removeDispatch, value: props.name})
    }

    return (
        <Grid container item xs={6} sm={4}>
            <div style={{width: 300}}>
                <Autocomplete multiple options={availableGeneratorTypes} renderInput={(params) => <TextField {...params} placeholder="Generator Types"/>}
                              onChange={(event, value) => onInputChange(event, value)}/>
            </div>
            <div>
                <IconButton onClick={onRemoveIconClick}>
                    <RemoveCircleOutlineIcon/>
                </IconButton>
            </div>
        </Grid>
    )
}

export default GeneratorTypeSearch;