import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {trackPromise} from "react-promise-tracker";
import {useDispatch, useSelector} from "react-redux";
import {onError, onSuccess} from "../../../store/actions/popupActions";
import axios from "../../../axios/AxiosInterceptors";
import {Button, Grid, IconButton, MenuItem, Select, TextField} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import Plot from 'react-plotly.js';
import RadioButtonCheckedTwoToneIcon from '@material-ui/icons/RadioButtonCheckedTwoTone';
import {RadioButtonUnchecked} from "@material-ui/icons";
import LoadForecastGraph from "../LoadForecastGraph";
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    minMaxPeakText: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
}));

const CreatePeakDaySignal = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const roles = useSelector(state => state.auth.roles)
    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const [hasEdit, setHasEdit] = useState(true);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [loadForecast, setLoadForecast] = useState([]);
    const [peakDaySignal, setPeakDaySignal] = useState({calledPeaks: [], action: '', outlook: ''});
    const [peakPeriods, setPeakPeriods] = useState([]);
    const [prevYearPeaks, setPrevYearPeaks] = useState([]);
    const [currentYearPeaks, setCurrentYearPeaks] = useState([]);
    const [iso, setIso] = useState('');
    const [zone, setZone] = useState('');
    const [weatherData, setWeatherData] = useState([]);


    useEffect(() => {
        const start = new Date();
        const end = new Date();
        end.setDate(end.getDate() + 6);
        setStartDate(start);
        setEndDate(end);
        retrievePeakPeriods();
        setHasEdit(hasPermissionForAccount(roles, chosenAccount.id, "ROLE_create_pds_peaks"));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const retrieveData = (startDate, endDate, iso, zone) => {
        retrieveLoadForecast(startDate, endDate, iso, zone);
        retrievePeakDaySignal(startDate, iso, zone);
        retrievePeaks(startDate, endDate, iso, zone);
        retrieveWeatherData(startDate, endDate, iso, zone);
    }

    const retrieveLoadForecast = async (startDate, endDate, iso, zone) => {
        let startEndParams = "startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD");
        let url = "/data/" + iso.toLowerCase();
        if (iso === 'PJM') {
            url = url + '/v1/load/forecast/area/';
            if (!zone) {
                url = url + "RTO";
            } else {
                url = url + zone;
            }
            url = url + "?" + startEndParams;
        } else if (iso === 'ISONE') {
            url = url + "/v1/load/forecast?" + startEndParams;
        }
        setLoadForecast({});
        await trackPromise(axios.get(url + "&version=LATEST&pageNumber=0&pageSize=200").then(response => {
            setLoadForecast(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    const retrievePeakDaySignal = async (date, iso, zone) => {
        let url = "/v1/pds/iso/" + iso;
        if (zone) {
            url = url + "/zone/" + zone;
        }
        await trackPromise(axios.get(url + "?date=" + moment(date).format("YYYY-MM-DD")).then(response => {
            setPeakDaySignal(response.data);
        }).catch(error => {
            if (error.status !== 404) {
                dispatch(onError(error));
            }
        }))
    }

    const retrievePeakPeriods = async () => {
        await trackPromise(axios.get("/v1/pds/peak/period").then(response => {
            setPeakPeriods(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const retrievePeaks = async (startDate, endDate, iso, zone) => {
        let peakPeriod = peakPeriods.filter(r => r.iso === iso && zone ? r.zone === zone : r.zone === null)[0];
        const peakStart = calculatePeakPeriodStartDate(startDate, peakPeriod);
        const peakEnd = calculatePeakPeriodEndDate(startDate, peakPeriod);
        retrieveCurrentYearPeaks(peakStart, peakEnd, iso, zone);
        let prevPeakStart = new Date(peakStart);
        prevPeakStart = prevPeakStart.setFullYear(prevPeakStart.getFullYear() - 1);
        let prevPeakEnd = new Date(peakEnd);
        prevPeakEnd = prevPeakEnd.setFullYear(prevPeakEnd.getFullYear() - 1);
        retrievePreviousYearPeaks(prevPeakStart, prevPeakEnd, iso, zone);
    }

    const retrieveCurrentYearPeaks = async (startDate, endDate, iso, zone) => {
        let url = "/v1/pds/iso/" + iso;
        if (zone) {
            url = url + "/zone/" + zone;
        }
        setCurrentYearPeaks([]);
        await trackPromise(axios.get(url + "/peaks?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD")).then(response => {
            setCurrentYearPeaks(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    const retrievePreviousYearPeaks = async (startDate, endDate, iso, zone) => {
        let url = "/v1/pds/iso/" + iso;
        if (zone) {
            url = url + "/zone/" + zone;
        }
        setPrevYearPeaks([]);
        await trackPromise(axios.get(url + "/peaks?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD")).then(response => {
            setPrevYearPeaks(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    const calculatePeakPeriodStartDate = (startDate, peakPeriod) => {
        if (startDate.getMonth() + 1 >= peakPeriod.startMonthInt) {
            let date = new Date();
            date.setFullYear(startDate.getFullYear());
            date.setMonth(peakPeriod.startMonthInt - 1);
            date.setDate(peakPeriod.startDay);
            return date;
        }
        let date = new Date();
        date.setFullYear(startDate.getFullYear() - 1);
        date.setMonth(peakPeriod.startMonthInt - 1);
        date.setDate(peakPeriod.startDay);
        return date;
    }

    const calculatePeakPeriodEndDate = (startDate, peakPeriod) => {
        if (startDate.getMonth() + 1 <= peakPeriod.endMonthInt) {
            let date = new Date();
            date.setFullYear(startDate.getFullYear());
            date.setMonth(peakPeriod.endMonthInt - 1);
            date.setDate(peakPeriod.endDay);
            return date;
        }
        let date = new Date();
        date.setFullYear(startDate.getFullYear() + 1);
        date.setMonth(peakPeriod.endMonthInt - 1);
        date.setDate(peakPeriod.endDay);
        return date;
    }

    const retrieveWeatherData = async (startDate, endDate, iso, zone) => {
        let url = "/v1/pds/weather/iso/" + iso;
        if (zone) {
            url = url + "/zone/" + zone;
        }
        setWeatherData([]);
        await trackPromise(axios.get(url + "?startDate=" + moment(startDate).format("YYYY-MM-DD") + "&endDate=" + moment(endDate).format("YYYY-MM-DD")).then(response => {
            setWeatherData(response.data);
        }).catch(error => {
            dispatch(onError(error));
        }));
    }

    const startDateChangeHandler = (date) => {
        setStartDate(date);
        let tempDate = new Date(date);
        tempDate.setDate(date.getDate() + 6);
        setEndDate(tempDate);
    }

    const actionChangeHandler = (e) => {
        peakDaySignal.action = e.target.value;
        setPeakDaySignal({...peakDaySignal});
    }

    const outlookChangeHandler = (e) => {
        peakDaySignal.outlook = e.target.value;
        setPeakDaySignal({...peakDaySignal});
    }

    const doSearch = () => {
        if (zone === '') {
            retrieveData(startDate, endDate, iso);
        } else {
            retrieveData(startDate, endDate, iso, zone);
        }
    }

    const generateSingleDayData = (loadForecast, date) => {
        if (loadForecast && loadForecast.results && loadForecast.results.length > 0) {
            let singleDayGraphData = {};
            const singleDay = loadForecast.results.filter(r => r.hourBeginningDateTime.hourBeginningDate === date);
            if (singleDay && singleDay.length > 0) {
                singleDayGraphData.x = singleDay.map(r => r.hourBeginningDateTime.hourBeginningDate + " " + r.hourBeginningDateTime.hourBeginning);
                singleDayGraphData.y = singleDay.map(r => r.load);
            }
            return singleDayGraphData;
        }
    }

    const findPeakColorForDate = (date) => {
        if (peakDaySignal && peakDaySignal.calledPeaks && peakDaySignal.calledPeaks.length > 0) {
            let peak = peakDaySignal.calledPeaks.filter(r => r.date === date);
            if (peak && peak.length > 0) {
                return peak[0].color;
            }
        }
    }

    const setPeakColorForDate = (date, color) => {
        let peak = {date: date, color: color}
        let index = -1;
        for (let i = 0; i < peakDaySignal.calledPeaks.length; i++) {
            if (peakDaySignal.calledPeaks[i].date === date) {
                index = i;
            }
        }
        if (index !== -1) {
            peak = peakDaySignal.calledPeaks[index];
            peak.color = color;
            peakDaySignal.calledPeaks.splice(index, 1, peak);
        } else {
            peakDaySignal.calledPeaks.push(peak);
        }
        setPeakDaySignal({...peakDaySignal});
    }

    const isDisabled = (date) => {
        let isPast = date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
        return !hasEdit || isPast;
    }

    const renderDailyForecasts = () => {
        let date = new Date(startDate);
        let plots = [];
        let width = '';
        let height = '';
        while (date <= endDate) {
            let prettyDate = moment(date).format("YYYY-MM-DD");
            let singleDay = generateSingleDayData(loadForecast, prettyDate);
            if (singleDay) {
                if (date.getTime() === startDate.getTime()) {
                    width = '100%';
                    height = 650;
                } else {
                    width = '50%';
                    height = 525;
                }
                plots.push(
                    <React.Fragment key={prettyDate}>
                        <div style={{width: width, height: height, display: 'flex', flexDirection: 'column', float: 'left'}}>
                            {generatePlot(date, prettyDate, singleDay)}
                            <Grid container>
                                <Grid item xs={6} sm={4}/>
                                <Grid item xs={6} sm={1}>
                                    <IconButton onClick={() => setPeakColorForDate(prettyDate, undefined)} disabled={isDisabled(date)}>{findPeakColorForDate(prettyDate) === null ||
                                    findPeakColorForDate(prettyDate) === undefined ? <RadioButtonCheckedTwoToneIcon style={{color: 'grey', height: 50, width: 50}}/> :
                                        <RadioButtonUnchecked style={{color: 'grey', height: 50, width: 50}}/>}</IconButton>
                                </Grid>
                                <Grid item xs={6} sm={1}>
                                    <IconButton onClick={() => setPeakColorForDate(prettyDate, 'green')} disabled={isDisabled(date)}>{findPeakColorForDate(prettyDate) === 'green' ?
                                        <RadioButtonCheckedTwoToneIcon style={{color: 'green', height: 50, width: 50}}/> :
                                        <RadioButtonUnchecked style={{color: 'green', height: 50, width: 50}}/>}</IconButton>
                                </Grid>
                                <Grid item xs={6} sm={1}>
                                    <IconButton onClick={() => setPeakColorForDate(prettyDate, 'yellow')}
                                                disabled={isDisabled(date)}>{findPeakColorForDate(prettyDate) === 'yellow' ?
                                        <RadioButtonCheckedTwoToneIcon style={{color: 'yellow', height: 50, width: 50}}/> :
                                        <RadioButtonUnchecked style={{color: 'yellow', height: 50, width: 50}}/>}</IconButton>
                                </Grid>
                                <Grid item xs={6} sm={1}>
                                    <IconButton onClick={() => setPeakColorForDate(prettyDate, 'red')} disabled={isDisabled(date)}>{findPeakColorForDate(prettyDate) === 'red' ?
                                        <RadioButtonCheckedTwoToneIcon style={{color: 'red', height: 50, width: 50}}/> :
                                        <RadioButtonUnchecked style={{color: 'red', height: 50, width: 50}}/>}</IconButton>
                                </Grid>
                            </Grid>
                        </div>
                    </React.Fragment>
                )
            }
            date.setDate(date.getDate() + 1);
        }
        return plots;
    }

    const generatePlot = (date, prettyDate, singleDayData) => {
        let localDate = new Date(startDate);
        localDate.setHours(0, 0, 0, 0);
        if (startDate.getTime() === date.getTime()) {
            return (
                <LoadForecastGraph loadForecast={loadForecast.results.filter(r => r.hourBeginningDateTime.hourBeginningDate === prettyDate)} prevYearPeaks={prevYearPeaks}
                                   currentYearPeaks={currentYearPeaks}
                                   weatherData={filterWeatherData(prettyDate)}/>
            )
        }
        return (
            <Plot data={[{x: singleDayData.x, y: singleDayData.y, type: 'line', name: 'Forecast for ' + prettyDate}]}
                  layout={{
                      autoSize: true,
                      title: 'Forecast for ' + prettyDate, showlegend: true, yaxis: {title: {text: 'MWs'}}, xaxis: {title: {text: 'Date (Hour Beginning)'}},
                      legend: {yanchor: 'top', y: 1.1, xanchor: 'left', x: .01}
                  }} config={{displayModeBar: false}}/>
        )
    }

    const filterWeatherData = (prettyDate) => {
        const keys = Object.keys(weatherData);
        let weather = {};
        keys.forEach(key => {
            weather[key] = weatherData[key].filter(r => r.hourBeginningDateTime.hourBeginningDate === prettyDate)
        });
        return weather;
    }

    const savePds = async () => {
        let url = "/v1/pds/iso/" + iso;
        if (zone) {
            url = url + "/zone/" + zone;
        }
        peakDaySignal.iso = iso;
        if (zone) {
            peakDaySignal.zone = zone;
        }
        peakDaySignal.date = moment(startDate).format("YYYY-MM-DD");
        await trackPromise(axios.post(url, JSON.stringify(peakDaySignal)).then(response => {
            setPeakDaySignal(response.data);
            dispatch(onSuccess("Saved PDS"));
        }).catch(error => {
            dispatch(onError(error));
        }))
    }

    const renderIsoMenuItems = () => {
        if (peakPeriods) {
            let isos = Array.from(new Set(peakPeriods.map(r => r.iso)));
            let menuItems = [''];
            isos.forEach(r => menuItems.push(<MenuItem key={r} value={r}>{r}</MenuItem>));
            return menuItems;
        }
    }

    const renderZoneMenuItems = () => {
        if (iso === 'PJM') {
            let menuItems = [''];
            peakPeriods.filter(r => r.iso === 'PJM').forEach(r => menuItems.push(<MenuItem key={r.zone} value={r.zone}>{r.zone}</MenuItem>));
            return menuItems;
        }
    }

    const handleIsoMenuChange = (e) => {
        setIso(e.target.value);
        setZone('');
        if (e.target.value === 'ISONE') {
            const end = new Date();
            end.setDate(end.getDate() + 2);
            setEndDate(end);
        } else if (e.target.value === 'PJM') {
            const end = new Date();
            end.setDate(end.getDate() + 6);
            setEndDate(end);
        }
    }

    const handleZoneMenuChange = (e) => {
        setZone(e.target.value);
    }

    const renderSaveButton = () => {
        let date = new Date(startDate).setHours(0, 0, 0, 0);
        if (date === new Date().setHours(0, 0, 0, 0) && hasEdit) {
            return (
                <Grid container>
                    <Grid item xs={6} sm={4}/>
                    <Grid item xs={6} sm={4}>
                        <Button onClick={savePds} fullWidth>Save</Button>
                    </Grid>
                </Grid>
            )
        }
    }

    return (
        <main className={classes.layout}>
            <Grid container spacing={2} alignItems={"center"} alignContent={"center"} justify={"center"}>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <Select fullWidth value={iso} onChange={handleIsoMenuChange}>
                        {renderIsoMenuItems()}
                    </Select>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <Select value={zone} fullWidth disabled={iso !== 'PJM'} onChange={handleZoneMenuChange}>
                        {renderZoneMenuItems()}
                    </Select>
                </Grid>
                <Grid item xs={6} sm={4}/>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="Start Date" name="startDate" onChange={startDateChangeHandler}
                                            value={startDate} disabled={!hasEdit} fullWidth autoOk={true}/>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker disableToolbar variant="inline" format="yyyy-MM-dd" label="End Date" name="endDate" value={endDate} disabled={true} fullWidth
                                            autoOk={true}/>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6} sm={1}/>
                <Grid item xs={6} sm={3}>
                    <Button onClick={doSearch}>Search</Button>
                </Grid>
            </Grid>
            {loadForecast && loadForecast.results && loadForecast.results.length > 0 &&
            <React.Fragment>
                <LoadForecastGraph loadForecast={loadForecast.results} prevYearPeaks={prevYearPeaks} currentYearPeaks={currentYearPeaks} weatherData={weatherData}/>
                <Grid container spacing={2} alignItems={"center"} alignContent={"center"} justify={"center"}>
                    <Grid item xs={6} sm={3}/>
                    <Grid item xs={6} sm={6}>
                        <TextField name="action" label="Today's Action" value={peakDaySignal.action} onChange={actionChangeHandler} fullWidth multiline rows={2}
                                   disabled={!hasEdit}/>
                    </Grid>
                    <Grid item xs={6} sm={3}/>
                    <Grid item xs={6} sm={3}/>
                    <Grid item xs={6} sm={6}>
                        <TextField name="outlook" label="Outlook" value={peakDaySignal.outlook} onChange={outlookChangeHandler} fullWidth multiline rows={2}
                                   disabled={!hasEdit}/>
                    </Grid>
                    <Grid item xs={6} sm={3}/>
                </Grid>
                {renderDailyForecasts()}
                {renderSaveButton()}
            </React.Fragment>
            }
        </main>
    )

}

export default CreatePeakDaySignal;