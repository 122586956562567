import React from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import {hasPermission} from '../../../Utility/PermissionsUtil';

const UserManagementAppBar = (props) => {

    const [userAnchorEl, setUserAnchorEl] = React.useState(null);
    const [rolesAnchorEl, setRolesAnchorEl] = React.useState(null);
    const [accountRolesAnchorEl, setAccountRolesAnchorEl] = React.useState(null);
    const [applicationRolesAnchorEl, setApplicationRolesAnchorEl] = React.useState(null);

    const chosenAccount = useSelector(state => state.chosenAccount.account);
    const roles = useSelector(state => state.auth.roles);
    const superUser = useSelector(state => state.auth.superUser);
    const history = useHistory();

    const handleUserMenuClick = (e) => {
        setUserAnchorEl(e.currentTarget);
    };

    const handleUserMenuClose = () => {
        setUserAnchorEl(null);
    };

    const handleRolesMenuClick = (e) => {
        setAccountRolesAnchorEl(null);
        setApplicationRolesAnchorEl(null);
        setRolesAnchorEl(e.currentTarget);
    };

    const handleRolesMenuClose = () => {
        setRolesAnchorEl(null);
    };

    const handleAccountRolesMenuClick = (e) => {
        setAccountRolesAnchorEl(e.currentTarget);
    };

    const handleAccountRolesMenuClose = () => {
        setAccountRolesAnchorEl(null);
        handleRolesMenuClose();
    };

    const handleApplicationRolesMenuClick = (e) => {
        setApplicationRolesAnchorEl(e.currentTarget);
    };

    const handleApplicationRolesMenuClose = () => {
        setApplicationRolesAnchorEl(null);
        handleRolesMenuClose();
    };

    const handleMenuItemClick = (path) => {
        history.push(path);
        setUserAnchorEl(null);
        setRolesAnchorEl(null);
    };

    const createUserMenu = () => {
        let menuItems = [];
        let userMenu = (
            <React.Fragment>
                <Button onClick={handleUserMenuClick}>Users</Button>
                <Menu open={Boolean(userAnchorEl)} onClose={handleUserMenuClose} anchorEl={userAnchorEl} getContentAnchorEl={null}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                    {menuItems}
                </Menu>
            </React.Fragment>
        );
        if (superUser) {
            menuItems.push(<MenuItem onClick={() => handleMenuItemClick("/user-management/superuser/users/create")}>Create User</MenuItem>);
            menuItems.push(<MenuItem onClick={() => handleMenuItemClick("/user-management/superuser/users")}>View Users</MenuItem>);
        } else {
            for (let role of roles) {
                if (role.accountId === chosenAccount.id) {
                    if (hasPermission(role.servicePermissions, 'ROLE_create_user')) {
                        menuItems.push(<MenuItem onClick={() => handleMenuItemClick("/user-management/account/" + chosenAccount.id + "/users/create")}>Create User</MenuItem>);
                    }
                    if (hasPermission(role.servicePermissions, 'ROLE_retrieve_user_role')) {
                        menuItems.push(<MenuItem onClick={() => handleMenuItemClick("/user-management/account/" + chosenAccount.id + "/users")}>View Users</MenuItem>);
                    }
                }
            }
        }
        if (menuItems.length > 0) {
            return userMenu;
        }
    };

    const createRolesMenu = () => {
        let menuItems = [];
        let accountRolesMenuItems = [];
        let applicationRolesMenuItems = [];
        let rolesMenu = (
            <React.Fragment>
                <Button onClick={handleRolesMenuClick}>Roles</Button>
                <Menu open={Boolean(rolesAnchorEl)} onClose={handleRolesMenuClose} anchorEl={rolesAnchorEl} getContentAnchorEl={null}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                    {menuItems}
                </Menu>
            </React.Fragment>
        );
        if (superUser) {
            accountRolesMenuItems.push(<MenuItem onClick={() => handleMenuItemClick("/user-management/account/" + chosenAccount.id + "/roles/create")}
                                                 key={1}>Create
                Role</MenuItem>);
            accountRolesMenuItems.push(<MenuItem onClick={() => handleMenuItemClick("/user-management/account/" + chosenAccount.id + "/roles")}
                                                 key={2}>View Roles</MenuItem>);
            applicationRolesMenuItems.push(<MenuItem onClick={() => handleMenuItemClick("/user-management/application/roles")} key={3}>View
                Roles</MenuItem>);
        } else {
            for (let role of roles) {
                if (role.accountId === chosenAccount.id) {
                    let createAccountRolePermission = hasPermission(role.servicePermissions, 'ROLE_create_account_role')
                    if (createAccountRolePermission) {
                        accountRolesMenuItems.push(<MenuItem onClick={() => handleMenuItemClick("/user-management/account/" + chosenAccount.id + "/roles/create")}
                                                             key={createAccountRolePermission}>Create
                            Role</MenuItem>);
                    }
                    let getAccountRolePermission = hasPermission(role.servicePermissions, 'ROLE_get_account_role');
                    if (getAccountRolePermission) {
                        accountRolesMenuItems.push(<MenuItem onClick={() => handleMenuItemClick("/user-management/account/" + chosenAccount.id + "/roles")}
                                                             key={getAccountRolePermission}>View Roles</MenuItem>);
                    }
                    let getApplicationRolePermission = hasPermission(role.servicePermissions, 'ROLE_get_application_role');
                    if (getApplicationRolePermission) {
                        applicationRolesMenuItems.push(<MenuItem onClick={() => handleMenuItemClick("/user-management/application/roles")} key={getApplicationRolePermission}>View
                            Roles</MenuItem>);
                    }
                }
            }
        }
        if (superUser && Boolean(superUser)) {
            applicationRolesMenuItems.push(<MenuItem onClick={() => handleMenuItemClick("/user-management/application/roles/create")} key={superUser + chosenAccount.id}>Create
                Role</MenuItem>);
        }
        if (accountRolesMenuItems.length > 0) {
            menuItems.push(
                <MenuItem onClose={handleAccountRolesMenuClose} onClick={handleAccountRolesMenuClick}>Account Roles
                    <Menu open={Boolean(accountRolesAnchorEl)} onClose={handleAccountRolesMenuClose} anchorEl={accountRolesAnchorEl} getContentAnchorEl={null}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                        {accountRolesMenuItems}
                    </Menu>
                </MenuItem>
            );
        }
        if (applicationRolesMenuItems.length > 0) {
            menuItems.push(
                <MenuItem onClose={handleApplicationRolesMenuClose} onClick={handleApplicationRolesMenuClick}>Application Roles
                    <Menu open={Boolean(applicationRolesAnchorEl)} onClose={handleApplicationRolesMenuClose} anchorEl={applicationRolesAnchorEl} getContentAnchorEl={null}
                          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                        {applicationRolesMenuItems}
                    </Menu>
                </MenuItem>
            );
        }
        if (menuItems.length > 0) {
            return rolesMenu;
        }
    }


    let userMenu = createUserMenu();
    let rolesMenu = createRolesMenu();

    return (
        <React.Fragment>
            {userMenu}
            {rolesMenu}
        </React.Fragment>
    );
};

export default UserManagementAppBar;