import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {withStyles, withTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from "@material-ui/core/Toolbar";
import ApplicationDrawer from "./ApplicationDrawer";
import AccountChooser from "./AccountChooser";
import UserProfile from "./UserProfile";
import SparkExchangeAppBar from "../spark-exchange/SparkExchangeAppBar/SparkExchangeAppBar";
import UserManagementAppBar from '../user-management/AppBar/UserManagementAppBar'
import CapacityExchangeAppBar from "../capacity-exchange/CapacityExchangeAppBar/CapacityExchangeAppBar";
import {chooseAccount} from "../../store/actions/chosenAccountActions";
import {APPLICATION_CHOSEN} from "../../store/actionTypes";
import PeakDaySignalAppBar from "../peak-day-signal/PeakDaySignalAppBar/PeakDaySignalAppBar";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        width: 'auto',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    grow: {
        flexGrow: 1,
    },
    list: {
        width: 250,
    },
});

const WPECAppBar = (props) => {

    const {classes} = props;

    useEffect(() => {
        if (props.accounts.length >= 1) {
            props.chooseAccount(props.accounts[0], true);
        }
    });

    const displayApplicationBar = () => {
        if (props.location.pathname.search("spark-exchange") !== -1) {
            return (<SparkExchangeAppBar/>);
        }
        if (props.location.pathname.search("user-management") !== -1) {
            return (<UserManagementAppBar/>);
        }
        if (props.location.pathname.search("capacity") !== -1) {
            return (<CapacityExchangeAppBar/>);
        }
        if(props.location.pathname.search("pds") !== -1) {
            return (<PeakDaySignalAppBar/>);
        }
    }

    let applicationBar = displayApplicationBar();

    return (
        <AppBar position="relative">
            <Toolbar>
                <div>
                    <ApplicationDrawer/>
                    {applicationBar}
                </div>
                <div className={classes.grow}/>
                <div className={classes.sectionDesktop}>
                    <AccountChooser/>
                    <UserProfile/>
                </div>
            </Toolbar>
        </AppBar>
    );
};

const mapStateToProps = state => {
    return {
        chosenAccount: state.chosenAccount.account,
        roles: state.auth.roles,
        accounts: state.auth.accounts,
        chosenApplication: state.chosenApplication
    };
};

const mapDispatchToProps = dispatch => {
    return {
        chooseAccount: (account, isDefault) => dispatch(chooseAccount(account, isDefault)),
        chooseDefaultApplication: () => dispatch({type: APPLICATION_CHOSEN, application: 'spark-exchange'})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(withRouter(WPECAppBar))));