import React, {Component} from 'react';

import {withStyles, withTheme} from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import {connect} from 'react-redux';
import {doAuthenticate} from '../../store/actions/authActions';
import {default as logo} from "../../images/SparkExchangeLogo_transparent.svg";

const styles = (theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    }
});

class Login extends Component {

    state = {
        usernamePassword: {
            username: '',
            password: ''
        },
        doRedirect: false
    };

    onUsernameChange = (e) => {
        let usernamePassword = {...this.state.usernamePassword};
        usernamePassword.username = e.target.value;
        this.setState({usernamePassword});
    };

    onPasswordChange = (e) => {
        let usernamePassword = {...this.state.usernamePassword};
        usernamePassword.password = e.target.value;
        this.setState({usernamePassword});
    };

    handleSubmit = (e) => {
        this.props.doAuthenticate(this.state.usernamePassword);
        this.setState({doRedirect: true});
    };

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <img src={logo} alt='' height='200px'/>
                <Paper className={classes.paper} align="center">
                    <Typography component="h1" align="center">Welcome to Spark Exchange</Typography>
                    <Typography component="p" align="center">Please Log in</Typography>
                    {this.props.authenticationFailed &&
                        <Typography component="h6" align="center" style={{color: 'red'}}>Invalid Username and/or Password</Typography>
                    }
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        this.handleSubmit()
                    }}>
                        <Grid container spacing={6}>
                            <Grid item xs={6} sm={12} align="center">
                                <TextField label="Username" variant="outlined" onChange={this.onUsernameChange} fullWidth autoFocus={true}/>
                            </Grid>
                            <Grid item xs={6} sm={12} align="center">
                                <TextField label="Password" variant="outlined" onChange={this.onPasswordChange} type='password' fullWidth/>
                            </Grid>
                            <Grid item xs={6} sm={12} align="center">
                                <Button type="submit">Login</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </main>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticationFailed: state.auth.authenticationFailed
    };
};

const mapDispatchToProps = dispatch => {
    return {
        doAuthenticate: (usernamePassword) => dispatch(doAuthenticate(usernamePassword))
    };
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Login)));