import {RETRIEVE_GENERATOR, RETRIEVE_GENERATORS, EIGHTY_SEVEN_SIXTY_RETRIEVED, STORE_GENERATOR_CONFIRM} from '../sparkExchangeActionTypes';
import {ACCOUNT_CHANGED} from '../../actionTypes';

const initialState = {
    generators: {},
    generator: {},
    eightySevenSixtyProfile: {}
}

const generatorReducer = (state= initialState, action) => {
    switch (action.type) {
        case RETRIEVE_GENERATORS:
            return retrieveGenerators(state, action);
        case RETRIEVE_GENERATOR:
            return retrieveGenerator(state, action);
        case STORE_GENERATOR_CONFIRM:
            return storeForConfirm(state, action);
        case EIGHTY_SEVEN_SIXTY_RETRIEVED:
            return eightySevenSixtyRetrieve(state, action);
        case ACCOUNT_CHANGED:
            return initialState;
        default:
            return state;
    }
}

const storeForConfirm = (state, action) => {
    return {...state, generator: action.generator};
}

const retrieveGenerators = (state, action) => {
    return {...state, generators: action.generators};
}

const retrieveGenerator = (state, action) => {
    return {...state, generator: action.generator, eightySevenSixtyProfile: null};
}

const eightySevenSixtyRetrieve = (state, action) => {
    return {...state, eightySevenSixtyProfile: action.eightSevenSixtyProfile};
}

export default generatorReducer;