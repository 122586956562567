import React, {Component} from 'react';
import {connect} from "react-redux";
import {withStyles, withTheme} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

import ViewRoles from './ViewRoles';
import {retrieveRoles} from "../../../store/user-management/actions/accountRoleActions";
import Button from "@material-ui/core/Button";
import {hasPermissionForAccount} from "../../../Utility/PermissionsUtil";

const styles = (theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1400,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(5),
    },
    table: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    tableRow: {
        "&:hover": {
            cursor: 'pointer'
        }
    }
});

class ViewAccountRoles extends Component {

    componentDidMount() {
        this.props.retrieveRoles(this.props.match.params.accountId);
    }

    createAccountRole = () => {
        this.props.history.push("/user-management/account/" + this.props.match.params.accountId + "/roles/create");
    }

    onRoleClick = (roleId) => {
        this.props.history.push("/user-management/account/" + this.props.match.params.accountId + "/roles/" + roleId);
    }

    render() {
        const {classes} = this.props;
        return (
            <main className={classes.layout}>
                <Typography variant="h3" align="center">Account Roles</Typography>
                {hasPermissionForAccount(this.props.roles, this.props.chosenAccount.id, "ROLE_create_account_role") &&
                <Button onClick={this.createAccountRole}>Create Account Role</Button>}
                <ViewRoles roles={this.props.roles} roleClickFunction={this.onRoleClick} />
            </main>
        );
    }
}

const mapStateToProps = state => {
    return {
        chosenAccount: state.chosenAccount.account,
        roles: state.accountRoles.roles
    };
};

const mapDispatchToProps = dispatch => {
    return {
        retrieveRoles: (accountId) => dispatch(retrieveRoles(accountId))
    };
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ViewAccountRoles)));