import React from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector} from "react-redux";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import GenericNumberFormat from "../../NumberFormat/GenericNumberFormat";
import {lessThanOneNotAllowed} from "../../../Utility/NumberFormatUtil";
import {cleanStringInput} from "../../../Utility/StringUtil";


const IncludeCapacityEscalationSearch = (props) => {

    const dispatch = useDispatch();
    const search = useSelector(props.selector);

    const onIncludeCapacityEscalationChangeHandler = (e) => {
        dispatch({type: props.includeCapacityEscalationDispatch, value: !search.includeCapacityEscalation});
    }

    const onInputChangeHandler = (e) => {
        if (e.target.name === 'capacityEscalationPercentage') {
            dispatch({type: props.capacityEscalationPercentageDispatch, value: e.target.value});
        } else if (e.target.name === 'capacityEscalationDelay') {
            dispatch({type: props.capacityEscalationDelayDispatch, value: e.target.value});
        } else if (e.target.name === 'capacityEscalationFrequency') {
            dispatch({type: props.capacityEscalationFrequencyDispatch, value: e.target.value});
        }
    };

    const onRemoveIconClick = () => {
        dispatch({type: props.includeCapacityEscalationDispatch, value: undefined});
        dispatch({type: props.capacityEscalationPercentageDispatch, value: ''});
        dispatch({type: props.capacityEscalationDelayDispatch, value: ''});
        dispatch({type: props.capacityEscalationFrequencyDispatch, value: ''});
        dispatch({type: props.removeDispatch, value: props.name})
    }


    return (
        <Grid item xs={6} sm={8} key="includeCapacityEscalation">
            <Box display="flex" flexWrap="nowrap">
                <Box>
                    <FormControlLabel
                        control={<Checkbox name="includeCapacityEscalation" value={search.includeCapacityEscalation} onChange={onIncludeCapacityEscalationChangeHandler}/>}
                        label="Include Capacity Escalation"/>
                </Box>
                <Box mr={2}>
                    <TextField label="Percentage" name="capacityEscalationPercentage" value={cleanStringInput(search.capacityEscalationPercentage)} onChange={onInputChangeHandler}
                               type="number" fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 2, allowNegative: false, isEdit: true}
                    }}
                               helperText="Less than or Equal"/>
                </Box>
                <Box mr={2}>
                    <TextField label="Delay" name="capacityEscalationDelay" value={cleanStringInput(search.capacityEscalationDelay)} onChange={onInputChangeHandler}
                               helperText="Greater than or Equal"
                               type="number" fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: true}
                    }}/>
                </Box>
                <Box mr={2}>
                    <TextField label="Frequency" name="capacityEscalationFrequency" value={cleanStringInput(search.capacityEscalationFrequency)} onChange={onInputChangeHandler}
                               type="number" fullWidth InputProps={{
                        endAdornment: <InputAdornment position="start">Months</InputAdornment>,
                        inputComponent: GenericNumberFormat,
                        inputProps: {fixedDecimalScale: true, decimalScale: 0, allowNegative: false, isAllowed: lessThanOneNotAllowed, isEdit: true}
                    }} helperText="Greater than or Equal"/>
                </Box>
                <Box>
                    <IconButton onClick={onRemoveIconClick}>
                        <RemoveCircleOutlineIcon/>
                    </IconButton>
                </Box>
            </Box>
        </Grid>
    );

}

export default IncludeCapacityEscalationSearch;