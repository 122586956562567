import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import {retrieveGenerators} from '../../../store/spark-exchange/actions/generatorActions'
import GeneratorComponent from "../Generator/GeneratorComponent";
import {useParams} from "react-router";
import SellOfferComponent from "./SellOfferComponent";

const useStyles = makeStyles((theme) => ({
    offset: theme.mixins.toolbar,
    layout: {
        width: 'auto',
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(10),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
        }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
        maxWidth: 500
    },
}));

const CreateSellOffer = () => {

    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const [accountId, setAccountId] = useState();
    const [generator, setGenerator] = useState();
    const generators = useSelector(state => state.generator.generators);


    useEffect(() => {
        setAccountId(params.accountId);
        dispatch(retrieveGenerators(params.accountId, 0, 1000));
        // eslint-disable-next-line
    }, [params]);

    const handleSelectChange = (e, value) => {
        setGenerator({...value});
    }

    const renderSelectBox = () => {
        if (generators && generators.results && generators.results.length > 0) {
            return (
                <Autocomplete options={generators.results} getOptionLabel={(option) => option.generatorName ? option.generatorName : ''}
                              renderInput={(params) => <TextField {...params} label="Select a generator"/>} style={{width: 300}}
                              onChange={(event, value) => handleSelectChange(event, value)}/>
            );
        } else {
            return (<Typography variant="h6" align="center">You must create a generator first</Typography>);
        }
    };

    return (
        <main className={classes.layout}>
            <Typography variant="h3" align="center">Create Sell Offer</Typography>
            <Grid container>
                <Grid item xs={12} align="center">
                    {renderSelectBox()}
                </Grid>
            </Grid>
            {generator &&
            <React.Fragment>
                <GeneratorComponent generator={generator} isEdit={false}/>
                <SellOfferComponent generator={generator} accountId={accountId} showButtons={true} isEdit={true}/>
            </React.Fragment>
            }
        </main>
    );

}

export default CreateSellOffer;